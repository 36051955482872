/* frontend/src/components/KickoffTokensView.js */
import React, { useState, useEffect } from "react";
import { apiService } from "../services/api";
import TaskItem from "./TaskItem";

const presetTokens = [1, 2, 3, 5, 8, 13, 20];

const KickoffTokensView = ({ projectId: propProjectId }) => {
  const selectedProject = propProjectId || localStorage.getItem("taskBoard_selectedProject") || "1";
  const [tasks, setTasks] = useState([]);
  const [inputTask, setInputTask] = useState(null);
  const [customToken, setCustomToken] = useState("");
  const [hoveredBlock, setHoveredBlock] = useState(null);

  // Nouveaux états pour récupérer les sprints et les employés
  const [sprints, setSprints] = useState([]);              // <-- ajout
  const [employees, setEmployees] = useState([]);          // <-- ajout

  useEffect(() => {
    apiService.get(`/projects/${selectedProject}/tasks`)
      .then((data) => {
        const transformed = data.map(task => ({
          ...task,
          // Positionner aléatoirement entre 10% et 90% si non défini
          x: task.x === undefined || task.x === null
             ? `${Math.floor(Math.random() * 80 + 10)}%`
             : task.x,
          y: task.y === undefined || task.y === null
             ? `${Math.floor(Math.random() * 80 + 10)}%`
             : task.y,
        }));
        setTasks(transformed);
      })
      .catch(console.error);
  }, [selectedProject]);

  // Charger la liste des employés
  useEffect(() => {
    apiService.get("/employees")
      .then((data) => setEmployees(data))
      .catch(console.error);
  }, []);

  // Charger la liste des sprints
  useEffect(() => {
    apiService.get(`/projects/${selectedProject}/sprints`)
      .then((data) => {
        setSprints(data);
      })
      .catch(console.error);
  }, [selectedProject]);

  // Séparation des tâches selon leur effort
  const tasksUnassigned = tasks.filter(task => task.effort === 0 && !task.fromOther);
  const tasksAssigned = tasks.filter(task => task.fromOther || task.effort > 0);

  let groupedTasks = {};
  presetTokens.forEach(token => {
    groupedTasks[token] = tasksAssigned.filter(
      task => task.effort === token && !task.fromOther
    );
  });
  groupedTasks["other"] = tasksAssigned.filter(
    task => !presetTokens.includes(task.effort) || task.fromOther
  );

  // Drag & Drop
  const handleDragStart = (e, taskId) => {
    e.dataTransfer.setData("text/plain", taskId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleTokenDrop = async (e, tokenValue) => {
    e.preventDefault();
    const taskId = e.dataTransfer.getData("text/plain");
    if (tokenValue === "other") {
      const customValue = customToken.trim() === "" ? 0 : Number(customToken);
      if (isNaN(customValue)) {
        alert("Veuillez entrer une valeur numérique pour le token personnalisé.");
        return;
      }
      assignTokenToTaskOther(taskId, customValue);
    } else {
      assignTokenToTask(taskId, tokenValue);
    }
  };

  const assignTokenToTask = async (taskId, tokenValue) => {
    try {
      const updatedTask = await apiService.patch(
        `/projects/${selectedProject}/tasks/${taskId}`,
        { effort: tokenValue }
      );
      setTasks(prev =>
        prev.map(task =>
          task.id.toString() === taskId ? { ...task, ...updatedTask } : task
        )
      );
    } catch (error) {
      console.error("Erreur lors de l'affectation du token :", error);
    }
  };

  const assignTokenToTaskOther = async (taskId, tokenValue) => {
    try {
      const updatedTask = await apiService.patch(
        `/projects/${selectedProject}/tasks/${taskId}`,
        { effort: tokenValue }
      );
      setTasks(prev =>
        prev.map(task =>
          task.id.toString() === taskId
            ? { ...task, ...updatedTask, fromOther: true }
            : task
        )
      );
    } catch (error) {
      console.error("Erreur lors de l'affectation du token (other) :", error);
    }
  };

  const handleDropOnBottom = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const taskId = e.dataTransfer.getData("text/plain");
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setTasks(prev =>
      prev.map(task =>
        task.id.toString() === taskId ? { ...task, x: `${x}px`, y: `${y}px`, effort: 0 } : task
      )
    );
    try {
      await apiService.patch(`/projects/${selectedProject}/tasks/${taskId}`, { effort: 0 });
    } catch (error) {
      console.error("Erreur lors de la remise à zéro de l'effort :", error);
    }
  };

  const handleBottomClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const newTaskId = Date.now();
    setInputTask({ id: newTaskId, x: `${x}px`, y: `${y}px`, value: "" });
  };

  const handleInputChange = (e) => {
    setInputTask({ ...inputTask, value: e.target.value });
  };

  const handleInputBlur = () => {
    if (inputTask && inputTask.value.trim() !== "") {
      const newTaskPayload = {
        project_id: Number(selectedProject),
        name: inputTask.value.trim(),
        description: "",
        sprint_id: null,
        effort: 0,
        owner_id: null,
        status: "not_started",
        blocked_by: [],
        comment: ""
      };
      apiService.post(`/projects/${selectedProject}/tasks`, newTaskPayload)
        .then(createdTask => {
          const transformedTask = {
            ...createdTask,
            x: inputTask.x,
            y: inputTask.y
          };
          setTasks(prev => [...prev, transformedTask]);
        })
        .catch(error => console.error("Erreur lors de la création de la tâche :", error));
    }
    setInputTask(null);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleInputBlur();
    } else if (e.key === "Escape") {
      e.preventDefault();
      setInputTask(null);
    }
  };

  const renderTaskCard = (task, style = {}) => (
    <TaskItem
      key={task.id}
      task={task}
      onDelete={(id) => {
        apiService.delete(`/projects/${selectedProject}/tasks/${id}`)
          .then(() => setTasks(prev => prev.filter(t => t.id !== id)))
          .catch(console.error);
      }}
      onUpdate={(updatedTask) => {
        apiService.patch(`/projects/${selectedProject}/tasks/${updatedTask.id}`, updatedTask)
          .then(response => {
            setTasks(prev =>
              prev.map(t => (t.id === response.id ? response : t))
            );
          })
          .catch(err => console.error("Erreur de mise à jour :", err));
      }}
      onDragStart={(e) => e.dataTransfer.setData("text/plain", task.id)}
      onDragOver={(e) => e.preventDefault()}
      style={style}
      sprintOptions={sprints}
      ownerOptions={employees}
    />
  );

  return (
    <div className="h-screen flex flex-col">
      {/* Partie supérieure : blocs de tokens (45% de la hauteur) */}
      <div className="h-[45%] bg-white p-4 border-b overflow-y-auto flex space-x-4 no-scrollbar"
          style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
        {presetTokens.map(tokenValue => (
          <div
            key={tokenValue}
            onDragOver={handleDragOver}
            onDrop={(e) => handleTokenDrop(e, tokenValue)}
            onDragEnter={(e) => { e.preventDefault(); setHoveredBlock(tokenValue); }}
            onDragLeave={(e) => { e.preventDefault(); setHoveredBlock(null); }}
            className={`flex flex-col p-2 shadow rounded min-h-[8rem] w-36 cursor-pointer border border-gray-200 ${hoveredBlock === tokenValue ? "bg-gray-100" : "bg-white"}`}
            style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
          >
            <div className="text-center font-bold mb-2 text-lg">{tokenValue}</div>
            <div className="flex-1 overflow-y-auto no-scrollbar">
              {groupedTasks[tokenValue] && groupedTasks[tokenValue].length > 0 ? (
                groupedTasks[tokenValue].map(task => renderTaskCard(task))
              ) : (
                <p className="text-center text-xs text-gray-500">Vide</p>
              )}
            </div>
          </div>
        ))}
        {/* Bloc "Autre" pour les valeurs non standards */}
        <div
          onDragOver={handleDragOver}
          onDrop={(e) => handleTokenDrop(e, "other")}
          onDragEnter={(e) => { e.preventDefault(); setHoveredBlock("other"); }}
          onDragLeave={(e) => { e.preventDefault(); setHoveredBlock(null); }}
          className={`flex flex-col p-2 shadow rounded min-h-[8rem] w-36 cursor-pointer border border-gray-200 ${hoveredBlock === "other" ? "bg-gray-100" : "bg-white"}`}
          style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
        >
       <div className="text-center font-bold mb-2 text-lg">Autre</div>
          <div className="flex-1 overflow-y-auto no-scrollbar">
            {groupedTasks["other"] && groupedTasks["other"].length > 0 ? (
              groupedTasks["other"].map(task => renderTaskCard(task))
            ) : (
              <p className="text-center text-xs text-gray-500">Vide</p>
            )}
          </div>
        </div>
      </div>

      {/* Partie inférieure : zone des tâches non affectées (35% de la hauteur) */}
      <div
        className="h-[35%] relative bg-gray-50 p-4 border border-dashed border-gray-300 rounded overflow-y-auto no-scrollbar"
        onClick={handleBottomClick}
        onDrop={handleDropOnBottom}
        onDragOver={(e) => e.preventDefault()}
        style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
      >
        {tasksUnassigned.map(task =>
          renderTaskCard(task, { position: "absolute", left: task.x, top: task.y })
        )}
        {inputTask && (
          <input 
            autoFocus
            type="text"
            value={inputTask.value}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            style={{ position: "absolute", left: inputTask.x, top: inputTask.y }}
            className="border rounded p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Nom de la tâche"
          />
        )}
      </div>
    </div>
  );
};

export default KickoffTokensView;