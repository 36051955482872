import React from 'react';
import HourInput from './HourInput';
import { isWeekend, isFutureDate } from '../../utils/dateUtils';

const ProjectRow = ({ 
  project, 
  weekDates, 
  getHours, 
  handleHourChange, 
  handleBlur, 
  employee, 
  getProjectTotal,
  slotTotalHours,
  plannedHours
}) => {
  const weekHours = getProjectTotal(project.id);
  
  return (
    <tr key={project.id} className="border-t">
      <td className="px-4 py-2">
        <div>
          {project.name}
          <div className="text-xs text-gray-500 mt-1">
            {plannedHours > 0 
              ? `${slotTotalHours.toFixed(1)} / ${plannedHours.toFixed(1)} heures`
              : `${slotTotalHours.toFixed(1)} heures`
            }
          </div>
        </div>
      </td>
      {weekDates.map(date => {
        const disabled = isFutureDate(date);
        const dateStr = date.toISOString().split('T')[0];
        const value = employee ? getHours(employee.id, project.id, date) : 0;
        
        return (
          <HourInput
            key={date.toISOString()}
            projectId={project.id}
            date={date}
            value={value}
            onChange={(e) => handleHourChange(project.id, date, e.target.value)}
            onBlur={handleBlur}
            disabled={disabled || !employee}
          />
        );
      })}
      <td className="px-4 py-2 text-center font-medium">
        {weekHours}
      </td>
    </tr>
  );
};

export default ProjectRow;