import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  AuthProvider,
  ProtectedRoute,
  LoginPage,
} from "./components/Auth";
import Navigation from "./components/Navigation";
import EmployeeManagement from "./components/EmployeeManagement";
import ProjectManagement from "./components/ProjectManagement";
import ProjectDetail from "./components/ProjectDetail";
import { SlotDetail, SlotsManagement } from "./components/Slot";
import AddressManagement from "./components/AddressManagement";
import ClientManagement from "./components/ClientManagement";
import HoursManagement from "./components/HoursManagement";
import TaskBoard from "./components/TaskBoard";
import InvoiceManagement from "./components/InvoiceManagement";
import KickoffView from "./components/KickoffView";
import Dashboard from "./components/Dashboard";
import ChatBot from "./components/Chat";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />

          {/* Routes protégées */}
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <div className="min-h-screen bg-gray-100">
                  <Navigation />
                  <main className="py-1">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                      <Routes>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/employees" element={<EmployeeManagement />} />
                        <Route path="/projects" element={<ProjectManagement />} />
                        <Route path="/project/:projectId" element={<ProjectDetail />} />
                        <Route path="/slots" element={<SlotsManagement displayMode="timeline" />} />
                        <Route path="/slots/current" element={<SlotsManagement displayMode="current" />} />
                        <Route path="/slot/:slotId" element={<SlotDetail />} />
                        <Route path="/hours" element={<HoursManagement />} />
                        <Route path="/clients" element={<ClientManagement />} />
                        <Route path="/addresses" element={<AddressManagement />} />
                        <Route path="/tasks" element={<TaskBoard />} />
                        <Route path="/" element={<ProjectManagement />} />
                        <Route path="/factures" element={<InvoiceManagement />} />
                        <Route path="/tasks/kickoff" element={<KickoffView />} />
                      </Routes>
                    </div>
                  </main>
                  {/* Chatbot */}
                  <ChatBot />
                  <footer className="bg-white border-t border-gray-200">
                    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                      <p className="text-center text-sm text-gray-500">
                        © {new Date().getFullYear()} Innowide DeepFlow. Tous droits réservés.
                      </p>
                    </div>
                  </footer>
                </div>
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;