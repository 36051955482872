// frontend/src/components/SprintCard.js
import React, { useState } from 'react';
import { Trash2, Edit2, X, Check, Clock, Target, DollarSign } from 'lucide-react'; // Importer DollarSign


const SprintCard = ({
  sprint,
  iterationId,
  iteration, // Objet Iteration complet
  handleDelete,
  setEditingSprint,
  editingSprint,
  handleSprintUpdate,
  handleTokensUpdate,
  calculateWeeksDuration,
  sprintHours
}) => {
  const isEditing = editingSprint?.id === sprint.id;
  const [localSprint, setLocalSprint] = useState(sprint);
  const [isEditingName, setIsEditingName] = useState(false);
  const sprintDuration = calculateWeeksDuration(sprint.start_date, sprint.end_date);
    // Calculer la part de valeur à produire pour ce sprint

  const handleSprintNameUpdate = async () => {
    setIsEditingName(false);
    // Si le nom a changé, mettre à jour via l'API en renvoyant la valeur dans "description"
    if (localSprint.description !== sprint.description) {
      const updatedSprint = { ...localSprint, description: localSprint.description };
      try {
        await handleSprintUpdate(updatedSprint, iterationId);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du nom du sprint :", error);
      }
    }
  };

  const handleNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSprintNameUpdate();
    }
  };

  // Total des tokens de tous les sprints de l'itération
  const totalTokens = iteration.sprints?.reduce((sum, s) => sum + (s.total_tokens || 0), 0) || 0;
  // Valeur à produire de l'itération
  const iterationValueToProduce = (iteration.valeur || 0) - (iteration.dont_achat || 0);
  // Part du sprint (si totalTokens vaut 0 on affiche 0)
  const sprintValueToProduce = totalTokens ? (sprint.total_tokens / totalTokens * iterationValueToProduce) : 0;

  const handleDateChange = (field, value) => {
    setLocalSprint(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const TokenProgressBar = () => {
    const progress = sprint.total_tokens ? (sprint.tokens_completed / sprint.total_tokens) * 100 : 0;
    
    return (
      <div className="mt-2">
        <div className="flex justify-between text-xs text-gray-600 mb-1">
          <span>{sprint.tokens_completed} / {sprint.total_tokens} tokens</span>
          <span>{Math.round(progress)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded h-2">
          <div 
            className="bg-green-500 h-2 rounded"
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>
    );
  };

  if (isEditing) {
    return (
      <div className="bg-white p-4 rounded-lg shadow border border-blue-200">
        <div className="space-y-3">
          <div>
            <label className="block text-xs text-gray-500 mb-1">Date de début</label>
            <input
              type="date"
              value={localSprint.start_date}
              onChange={(e) => handleDateChange('start_date', e.target.value)}
              className="border rounded p-1 text-sm w-full"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-500 mb-1">Date de fin</label>
            <input
              type="date"
              value={localSprint.end_date}
              onChange={(e) => handleDateChange('end_date', e.target.value)}
              className="border rounded p-1 text-sm w-full"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setEditingSprint(null)}
              className="p-1 text-gray-500 hover:text-gray-700"
              title="Annuler"
            >
              <X className="h-4 w-4" />
            </button>
            <button
              onClick={() => handleSprintUpdate(localSprint, iterationId)}
              className="p-1 text-green-500 hover:text-green-700"
              title="Enregistrer"
            >
              <Check className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    );
  }


  // Trouver les stats du sprint actuel
  const sprintStats = iteration.sprint_stats.find(stat => stat.id === sprint.id);

  return (
    <div className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow">
      <div className="space-y-2">
        <div className="flex justify-between items-start">
          
        <div className="text-sm font-medium">
            {isEditingName ? (
              <input
                type="text"
                value={localSprint.description}
                onChange={(e) => setLocalSprint({ ...localSprint, description: e.target.value })}
                onBlur={handleSprintNameUpdate}
                onKeyDown={handleNameKeyDown}
                autoFocus
                className="border rounded p-1 text-sm"
              />
            ) : (
              <span onClick={() => setIsEditingName(true)} title="Cliquez pour modifier le nom">
                {sprint.description}
              </span>
            )}
          </div>
          
          <div className="flex space-x-2">
            <button
              onClick={() => handleDelete(sprint.id, iterationId)}
              className="p-1 text-red-500 hover:text-red-700"
              title="Supprimer le sprint"
            >
              <Trash2 className="h-4 w-4" />
            </button>
            <button
              onClick={() => setEditingSprint(sprint)}
              className="p-1 text-gray-500 hover:text-gray-700"
              title="Modifier le sprint"
            >
              <Edit2 className="h-4 w-4" />
            </button>
          </div>
        </div>
        <div className="text-xs text-gray-500">
          <div>Début : {new Date(sprint.start_date).toLocaleDateString()}</div>
          <div>Fin : {new Date(sprint.end_date).toLocaleDateString()}</div>
          <div className="flex items-center mt-1 text-blue-600">
            <Clock className="h-3 w-3 mr-1" />
            {sprintDuration} semaines
          </div>
        </div>

        {/* Affichage et bouton d'édition des tokens */}
        {/* Affichage des heures réalisées sur le sprint */}
        <div className="text-sm text-gray-600">
          Heures réalisées : {sprintHours} h
        </div>
        <div className="pt-2 border-t mt-2">
        <div className="flex items-center text-xs text-gray-600 mb-2">
          <Target className="h-3 w-3 mr-1" />
          Progression
        </div>
        <TokenProgressBar />
        {/* Affichage de "Valeur à produire" déjà présent */}
        <div className="text-sm text-gray-600 mt-1">
          Valeur à produire : {sprintValueToProduce.toFixed(2)} €
        </div>

        {/* Nouveau bloc pour afficher le CA produit */}
        <div className="text-sm text-gray-600 mt-1">
          CA produit : {sprint.ca_produit ? sprint.ca_produit.toFixed(2) : "0.00"} €
        </div>
      </div>
      </div>
    </div>
  );
};

export default SprintCard;
