import React, { useState, useEffect } from 'react';
import { FileText, Calendar, Plus } from 'lucide-react';
import { apiService } from '../../services/api';

// Composants
import InvoiceCreationForm from './InvoiceCreationForm';
import KanbanColumn from './KanbanColumn';
import RoadmapView from './RoadmapView';

const InvoiceManagement = () => {
  // États pour la gestion des données
  const [projects, setProjects] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [projectIterations, setProjectIterations] = useState({});
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  
  // États pour les filtres et l'interface
  const [activeTab, setActiveTab] = useState("kanban"); // "kanban" ou "roadmap"
  const [roadmapYear, setRoadmapYear] = useState(new Date().getFullYear());
  const [monthlyFinancialData, setMonthlyFinancialData] = useState([]);
  const [draggedInvoice, setDraggedInvoice] = useState(null);
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);

  // Chargement des projets avec les informations clients complètes
  useEffect(() => {
    // Chargeons d'abord tous les projets
    apiService.get('/projects')
      .then(data => {
        setProjects(data);
        
        // Récupérons les clients pour avoir toutes leurs informations (payment_terms compris)
        return apiService.get('/clients');
      })
      .then(clients => {
        // Mettons à jour les projets avec les informations clients complètes
        setProjects(prevProjects => 
          prevProjects.map(project => {
            if (project.client_id) {
              const fullClient = clients.find(c => c.id === project.client_id);
              if (fullClient) {
                return {
                  ...project,
                  client: fullClient // Ajout du client complet, avec payment_terms
                };
              }
            }
            return project;
          })
        );
      })
      .catch(err => setError(err.message));
  }, []);

  // Fonction pour récupérer les factures
  const fetchInvoices = () => {
    apiService.get('/factures')
      .then(data => setInvoices(data))
      .catch(err => setError(err.message));
  };

  // Chargement initial des factures
  useEffect(() => {
    fetchInvoices();
  }, []);

  // Récupération des itérations de chaque projet
  useEffect(() => {
    const fetchProjectIterations = async () => {
      try {
        const iterationsByProject = {};
        
        for (const project of projects) {
          const iterations = await apiService.get(`/projects/${project.id}/iterations`);
          iterationsByProject[project.id] = iterations;
        }
        
        setProjectIterations(iterationsByProject);
      } catch (err) {
        setError(err.message);
      }
    };
    
    if (projects.length > 0) {
      fetchProjectIterations();
    }
  }, [projects]);

  // Calcul des données financières mensuelles pour la roadmap
  useEffect(() => {
    if (projects.length > 0 && Object.keys(projectIterations).length > 0 && invoices.length > 0) {
      calculateMonthlyFinancialData();
    }
  }, [projectIterations, invoices, roadmapYear]);

  // Fonction de calcul des données financières mensuelles
  const calculateMonthlyFinancialData = () => {
    // Initialiser les données pour chaque mois
    const monthsData = Array.from({ length: 12 }, (_, i) => ({
      income: 0,         // Recettes du mois
      expenses: 0,        // Dépenses du mois
      revenueDetails: [], // Détails des recettes
      expensesDetails: [] // Détails des dépenses
    }));
    
    // Calculer les recettes à partir des lots de facturation
    invoices.forEach(invoice => {
      // Ignorer les lots en statut 'draft' (brouillon)
      if (invoice.status === 'draft') return;
      
      const project = projects.find(p => p.id === invoice.project_id);
      const client = project?.client;
      
      invoice.allocations.forEach(allocation => {
        const iterations = projectIterations[invoice.project_id] || [];
        const iteration = iterations.find(i => i.id === allocation.iteration_id);
        
        if (!iteration) return;
        
        // Déterminer la date de facturation en fonction du type choisi
        let billingDate = new Date();
        
        if (invoice.billing_date_type === "custom" && invoice.custom_billing_date) {
          // Date personnalisée
          const [year, month, day] = invoice.custom_billing_date.split('-').map(Number);
          billingDate.setFullYear(year, month - 1, day);
        } 
        else if (invoice.billing_date_type === "project_start") {
          // Date de début du projet (première itération)
          const allProjectIterations = projectIterations[invoice.project_id] || [];
          let earliestStartDate = null;
          
          allProjectIterations.forEach(iter => {
            if (iter.start_date && (!earliestStartDate || iter.start_date < earliestStartDate)) {
              earliestStartDate = iter.start_date;
            }
          });
          
          if (earliestStartDate) {
            const [year, month, day] = earliestStartDate.split('-').map(Number);
            billingDate.setFullYear(year, month - 1, day);
          } else if (iteration.end_date) {
            // Fallback sur la date de fin de l'itération
            const [year, month, day] = iteration.end_date.split('-').map(Number);
            billingDate.setFullYear(year, month - 1, day);
          }
        } 
        else {
          // Par défaut (iteration_end), utiliser la date de fin de la dernière itération sélectionnée
          const selectedIterations = invoice.allocations.map(alloc => {
            const iter = projectIterations[invoice.project_id]?.find(i => i.id === alloc.iteration_id);
            return iter;
          }).filter(Boolean);
          
          // Trouver la date de fin la plus tardive parmi les itérations sélectionnées
          let latestEndDate = null;
          selectedIterations.forEach(iter => {
            if (iter.end_date && (!latestEndDate || iter.end_date > latestEndDate)) {
              latestEndDate = iter.end_date;
            }
          });
          
          if (latestEndDate) {
            const [year, month, day] = latestEndDate.split('-').map(Number);
            billingDate.setFullYear(year, month - 1, day);
          } else if (iteration.end_date) {
            // Fallback sur la date de fin de l'itération courante
            const [year, month, day] = iteration.end_date.split('-').map(Number);
            billingDate.setFullYear(year, month - 1, day);
          }
        }
        
        // Préparer la date de paiement en tenant compte des conditions
        let paymentDate = new Date(billingDate);
        
        // Identifier les termes de paiement du client
        let paymentTermsLabel = "30 jours"; // Valeur par défaut
        let paymentTermsMatched = false;
        
        if (client && client.payment_terms) {
          paymentTermsLabel = client.payment_terms;
          
          // Identifier le délai de paiement selon les termes
          if (client.payment_terms === "à réception" || client.payment_terms === "on_receipt") {
            // Paiement à réception = date de fin
            paymentTermsMatched = true;
          }
          else if (client.payment_terms === "30 jours nets" || client.payment_terms === "net_30") {
            // Paiement à 30 jours
            paymentDate.setDate(paymentDate.getDate() + 30);
            paymentTermsMatched = true;
          }
          else if (client.payment_terms === "45 jours nets" || client.payment_terms === "net_45") {
            // Paiement à 45 jours
            paymentDate.setDate(paymentDate.getDate() + 45);
            paymentTermsMatched = true;
          }
          else if (client.payment_terms === "45 jours fin de mois" || 
                   client.payment_terms === "45_days_eom" || 
                   client.payment_terms === "net_45_eom" || 
                   client.payment_terms === "45 jours fin de mois") {
            // 45 jours fin de mois signifie :
            // - Pour la facturation du mois M
            // - Paiement à M + 45 jours, puis aller à la fin du mois
            // Ex: facturation du 5 mai -> 5 mai + 45j = 19 juin -> fin du mois = 30 juin
                  
            // 1. Ajouter 45 jours à la date de fin d'itération
            paymentDate.setDate(paymentDate.getDate() + 45);
            
            // 2. Aller à la fin du mois où tombe cette date
            paymentDate = new Date(
              paymentDate.getFullYear(),
              paymentDate.getMonth() + 1,
              0
            );
            paymentTermsMatched = true;
          }
        } else {
          // Si pas de client ou pas de termes définis, par défaut 30 jours
          paymentDate.setDate(paymentDate.getDate() + 30);
        }
        
        // Si affacturation, on remplace par un délai de 10 jours après la fin
        let affacturageTerms = null;
        if (invoice.affacturation) {
          paymentDate = new Date(billingDate);
          paymentDate.setDate(paymentDate.getDate() + 10);
          affacturageTerms = "10 jours (affacturage)";
        }
        
        // Vérifier si le paiement est prévu pour l'année en cours
        if (paymentDate.getFullYear() === roadmapYear) {
          const month = paymentDate.getMonth();
          // Calculer le montant en tenant compte de l'affacturation et commission
          const montant = computeMontant(
            iteration, 
            allocation.percentage, 
            invoice.affacturation, 
            invoice.commission
          );
          
          if (montant > 0) {
            // Collecter les données pour le mois
            monthsData[month].income += montant;
            
            // Ajouter les détails de cette recette
            monthsData[month].revenueDetails.push({
              project: project?.name || 'Projet inconnu',
              iteration: iteration?.name || 'Itération inconnue',
              percentage: allocation.percentage,
              amount: montant,
              paymentTerms: affacturageTerms || paymentTermsLabel,
              affacturation: invoice.affacturation,
              originalPaymentTerms: affacturageTerms ? paymentTermsLabel : "",
              lot_name: invoice.lot_name || `Lot ${invoice.id}`
            });
          }
        }
      });
    });
    
    // Calculer les dépenses à partir des achats dans les itérations
    // Pour chaque projet
    Object.entries(projectIterations).forEach(([projectId, iterations]) => {
      const projectInfo = projects.find(p => p.id === parseInt(projectId));
      if (!projectInfo) return;
      
      // Pour chaque itération du projet
      iterations.forEach(iteration => {
        // Si aucun montant d'achat défini, passer
        if (!iteration.dont_achat || iteration.dont_achat <= 0) return;
        
        // Déterminer la date de début de l'itération (ou date actuelle si non définie)
        const purchaseDate = new Date();
        if (iteration.start_date) {
          const [year, month, day] = iteration.start_date.split('-').map(Number);
          purchaseDate.setFullYear(year, month - 1, day);
        }
        
        // Vérifier si la dépense est prévue pour l'année en cours
        if (purchaseDate.getFullYear() === roadmapYear) {
          const month = purchaseDate.getMonth();
          
          // Ajouter le montant aux dépenses du mois
          monthsData[month].expenses += iteration.dont_achat;
          
          // Ajouter les détails de cette dépense
          monthsData[month].expensesDetails.push({
            project: projectInfo.name,
            iteration: iteration.name,
            amount: iteration.dont_achat
          });
        }
      });
    });
    
    setMonthlyFinancialData(monthsData);
  };

  // Fonction pour gérer le drag & drop afin de mettre à jour le statut de la facture
  const handleInvoiceDrop = async (newStatus) => {
    if (draggedInvoice) {
      // Mappage des statuts frontend vers backend
      const statusMap = {
        'pending': 'a_facturer',
        'sent': 'envoye',
        'paid': 'facture'
      };
      
      // Récupérons les allocations au format attendu par le backend
      const mappedAllocations = draggedInvoice.allocations.map(alloc => ({
        iteration_id: alloc.iteration_id,
        percentage: alloc.percentage,
        montant: alloc.montant
      }));
      
      const payload = {
        project_id: draggedInvoice.project_id,
        affacturation: draggedInvoice.affacturation || false,
        commission: draggedInvoice.commission || 3.0,
        allocations: mappedAllocations,
        status: statusMap[newStatus] || 'a_facturer',
        lot_name: draggedInvoice.lot_name || '',
        billing_date_type: draggedInvoice.billing_date_type || 'iteration_end',
        custom_billing_date: draggedInvoice.custom_billing_date || null
      };
      
      try {
        console.log("Sending payload to update invoice:", payload);
        await apiService.patch(`/factures/${draggedInvoice.id}`, payload);
        fetchInvoices();
        setDraggedInvoice(null);
        setMessage("Statut mis à jour avec succès");
      } catch (err) {
        setError(`Erreur lors de la mise à jour: ${err.message}`);
        console.error("Error updating invoice:", err);
      }
    }
  };

  // Fonction pour gérer les changements d'année dans la roadmap
  const handleYearChange = (direction) => {
    if (direction === 'prev') {
      setRoadmapYear(prev => prev - 1);
    } else {
      setRoadmapYear(prev => prev + 1);
    }
  };

  // Fonctions utilitaires
  const getProjectInfo = (projectId) => {
    return projects.find(p => p.id === projectId) || {};
  };

  // Calcule le montant pour une itération selon un pourcentage et applique éventuellement la commission d'affacturage
  const computeMontant = (iteration, percentage, withAffacturation = false, commissionRate = 3.0) => {
    if (!iteration) return 0;
    let montant = (iteration.valeur * percentage) / 100;
    
    // Appliquer la commission si affacturation est activée
    if (withAffacturation) {
      montant = montant * (1 - (commissionRate / 100));
    }
    
    return Number(montant.toFixed(2));
  };

  // Calcule le total du lot (somme des montants des allocations)
  const computeTotalInvoice = (invoice) => {
    if (!invoice.allocations || invoice.allocations.length === 0) return 0;
    
    return invoice.allocations.reduce((total, alloc) => {
      // Trouver l'itération correspondante
      const iteration = projectIterations[invoice.project_id]?.find(it => it.id === alloc.iteration_id);
      
      // Si l'itération existe, calculer son montant avec la commission si applicable
      if (iteration) {
        const montant = computeMontant(
          iteration, 
          alloc.percentage, 
          invoice.affacturation, 
          invoice.commission
        );
        return total + montant;
      }
      
      // Si l'itération n'est pas trouvée mais que le montant est défini dans l'allocation
      if (alloc.montant !== undefined && alloc.montant !== null) {
        return total + Number(alloc.montant);
      }
      
      return total;
    }, 0);
  };

  // Organise les factures par statut pour l'affichage en colonnes
  const getInvoicesByStatus = () => {
    const invoicesByStatus = {
      pending: [],
      sent: [],
      paid: []
    };

    // Trier les factures par date de mise à jour (les plus récentes en premier)
    invoices.forEach((invoice) => {
      // Mappage des statuts backend vers frontend
      const backendToFrontend = {
        'a_facturer': 'pending',
        'envoye': 'sent', 
        'facture': 'paid'
      };
      
      const frontendStatus = backendToFrontend[invoice.status] || 'pending';
      
      // Ne pas inclure les statuts draft ou cancelled
      if (invoice.status !== 'draft' && invoice.status !== 'cancelled') {
        if (invoicesByStatus[frontendStatus]) {
          invoicesByStatus[frontendStatus].push(invoice);
        }
      }
    });

    return invoicesByStatus;
  };

  // Récupère les iterations d'un projet spécifique
  const getProjectIterations = (projectId) => {
    return projectIterations[projectId] || [];
  };

  // Récupère les informations d'une itération spécifique
  const getIterationInfo = (projectId, iterationId) => {
    const iterations = projectIterations[projectId] || [];
    return iterations.find(it => it.id === iterationId) || {};
  };

  // Ferme les messages d'alerte après 5 secondes
  useEffect(() => {
    if (message || error) {
      const timer = setTimeout(() => {
        setMessage(null);
        setError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message, error]);

  const invoicesByStatus = getInvoicesByStatus();

  return (
    <div className="p-6 max-w-full overflow-x-auto">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Gestion des factures</h1>
        <div className="flex space-x-4">
          <button 
            className={`px-4 py-2 rounded-lg flex items-center ${activeTab === "kanban" ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-800"}`}
            onClick={() => setActiveTab("kanban")}
          >
            <FileText className="w-5 h-5 mr-2" />
            <span>Factures</span>
          </button>
          <button 
            className={`px-4 py-2 rounded-lg flex items-center ${activeTab === "roadmap" ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-800"}`}
            onClick={() => setActiveTab("roadmap")}
          >
            <Calendar className="w-5 h-5 mr-2" />
            <span>Roadmap</span>
          </button>
        </div>
      </div>

      {/* Messages d'alerte */}
      {error && (
        <div className="mb-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded">
          <p>{error}</p>
        </div>
      )}
      {message && (
        <div className="mb-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded">
          <p>{message}</p>
        </div>
      )}

      {/* Bouton pour afficher le formulaire de création de facture */}
      {activeTab === "kanban" && (
        <div className="mb-6">
          <button 
            onClick={() => setShowInvoiceForm(!showInvoiceForm)}
            className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded-lg flex items-center"
          >
            <Plus className="w-5 h-5 mr-2" />
            {showInvoiceForm ? "Masquer le formulaire" : "Créer un nouveau lot"}
          </button>
        </div>
      )}

      {/* Formulaire de création de lot de facturation */}
      {activeTab === "kanban" && showInvoiceForm && (
        <InvoiceCreationForm 
          projects={projects}
          fetchInvoices={fetchInvoices}
          computeMontant={computeMontant}
          setError={setError}
          setMessage={setMessage}
        />
      )}

      {/* Affichage des lots de facturation par statut */}
      {activeTab === "kanban" && (
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-6">
          <KanbanColumn 
            title="À envoyer" 
            status="pending"
            invoices={invoicesByStatus.pending}
            projects={projects}
            getIterationInfo={getIterationInfo}
            computeTotalInvoice={computeTotalInvoice}
            onDragStart={setDraggedInvoice}
            onDrop={() => handleInvoiceDrop("pending")}
            fetchInvoices={fetchInvoices}
            setError={setError}
            setMessage={setMessage}
          />
          
          <KanbanColumn 
            title="Envoyée" 
            status="sent"
            invoices={invoicesByStatus.sent}
            projects={projects}
            getIterationInfo={getIterationInfo}
            computeTotalInvoice={computeTotalInvoice}
            onDragStart={setDraggedInvoice}
            onDrop={() => handleInvoiceDrop("sent")}
            fetchInvoices={fetchInvoices}
            setError={setError}
            setMessage={setMessage}
          />
          
          <KanbanColumn 
            title="Payée" 
            status="paid"
            invoices={invoicesByStatus.paid}
            projects={projects}
            getIterationInfo={getIterationInfo}
            computeTotalInvoice={computeTotalInvoice}
            onDragStart={setDraggedInvoice}
            onDrop={() => handleInvoiceDrop("paid")}
            fetchInvoices={fetchInvoices}
            setError={setError}
            setMessage={setMessage}
          />
        </div>
      )}

      {/* Affichage de la roadmap */}
      {activeTab === "roadmap" && (
        <RoadmapView 
          roadmapYear={roadmapYear}
          handleYearChange={handleYearChange}
          monthlyFinancialData={monthlyFinancialData}
        />
      )}
    </div>
  );
};

export default InvoiceManagement;