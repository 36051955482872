import React, { useState, useEffect } from 'react';
import { Trash2, FolderPlus, Building2, MapPin } from 'lucide-react';
import { useAuth } from './Auth';

const apiBaseUrl = process.env.NODE_ENV === 'development' 
  ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
  : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const ProjectManagement = () => {
  const [projects, setProjects] = useState([]);
  const [clients, setClients] = useState([]);
  const [formData, setFormData] = useState({
    project_number: '',
    name: '',
    status: 'proposition',
    client_id: ''
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { getAuthHeaders } = useAuth();

  // Traduction des statuts
  const statusTranslations = {
    proposition: "Proposition",
    in_progress: "En Cours",
    completed: "Terminé"
  };

  // Charger les projets
  const fetchProjects = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/projects`, { // Utilisation de apiBaseUrl
        headers: getAuthHeaders()
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec du chargement des projets');
      }
      
      const data = await response.json();
      setProjects(Array.isArray(data) ? data : []);
    } catch (err) {
      setError(err.message || 'Échec du chargement des projets');
      setProjects([]);
      setTimeout(() => setError(null), 3000);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  // Gérer les changements dans le formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'project_number') {
      // Uniquement des nombres positifs
      const numberValue = value.replace(/\D/g, '');
      setFormData(prev => ({
        ...prev,
        [name]: numberValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  // Gérer le changement de statut
  const handleStatusChange = async (projectId, newStatus) => {
    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}/status`, { // Utilisation de apiBaseUrl
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()  // Ajout des en-têtes d'authentification
        },
        body: JSON.stringify({ status: newStatus }),
      });
      
      if (!response.ok) throw new Error('Échec de la mise à jour du statut');
      
      await fetchProjects();
      setSuccess('Statut du projet mis à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour du statut');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Gérer la soumission du formulaire d'ajout de projet
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/projects`, { // Utilisation de apiBaseUrl
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de l\'ajout du projet');
      }
      
      await fetchProjects();
      setFormData({ project_number: '', name: '', status: 'proposition', client_id: '' });
      setSuccess('Projet ajouté avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de l\'ajout du projet');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Gérer la suppression d'un projet
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiBaseUrl}/projects/${id}`, { // Utilisation de apiBaseUrl
        method: 'DELETE',
        headers: getAuthHeaders()
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression du projet');
      }
      
      await fetchProjects();
      setSuccess('Projet supprimé avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression du projet');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Filtrer les projets par statut
  const projectsByStatus = {
    proposition: projects.filter(p => p.status === 'proposition'),
    in_progress: projects.filter(p => p.status === 'in_progress'),
    completed: projects.filter(p => p.status === 'completed'),
  };

  const formatProjectName = (project) => {
    return `P${project.project_number} - ${project.name}`;
  };

  // Charger les clients
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/clients`, { // Utilisation de apiBaseUrl
          headers: getAuthHeaders()
        });
        if (response.ok) {
          const data = await response.json();
          setClients(Array.isArray(data) ? data : []);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des clients:', error);
      }
    };
    fetchClients();
  }, [getAuthHeaders]);

  // Composant pour les colonnes de statut
  const StatusColumn = ({ title, projects }) => (
    <div className="bg-gray-50 rounded-lg p-4 flex-1">
      <h3 className="text-lg font-medium text-gray-900 mb-4">{title}</h3>
      <div className="space-y-3">
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );

  // Composant pour une carte de projet
  const ProjectCard = ({ project }) => (
    <div className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow">
      <button 
        onClick={() => window.location.href = `/project/${project.id}`}
        className="text-sm mb-3 text-blue-600 hover:text-blue-800 hover:underline w-full text-left"
      >
        {`P${project.project_number} - ${project.name}`}
      </button>
      
      {/* Info client */}
      {project.client_name && (
        <div className="flex items-center text-sm text-gray-600 mb-3">
          <Building2 className="h-4 w-4 mr-1" />
          <span className="truncate">{project.client_name}</span>
        </div>
      )}

      <div className="flex justify-between items-center">
        <select
          value={project.status}
          onChange={(e) => handleStatusChange(project.id, e.target.value)}
          className="text-sm w-full border rounded p-1"
        >
          <option value="proposition">Proposition</option>
          <option value="in_progress">En Cours</option>
          <option value="completed">Terminé</option>
        </select>
        <button
          onClick={() => handleDelete(project.id)}
          className="text-red-500 hover:text-red-700 ml-2"
          title="Supprimer le projet"
        >
          <Trash2 className="h-4 w-4" />
        </button>
      </div>
    </div>
  );

  return (
    <div className="p-2 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-2 flex items-center">
        <MapPin className="h-6 w-6 mr-2" />
        Gestion des Projets
      </h1>
      {/* Formulaire d'ajout de projet */}
      <form onSubmit={handleSubmit} className="mb-8 p-4 bg-gray-50 rounded-lg shadow">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
          <input
            type="text"
            name="project_number"
            value={formData.project_number}
            onChange={handleInputChange}
            placeholder="Numéro de projet"
            className="border rounded p-2 w-full"
            required
            pattern="[0-9]*"
          />
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder="Nom du projet"
            className="border rounded p-2 w-full"
            required
          />
          <select
            name="status"
            value={formData.status}
            onChange={handleInputChange}
            className="border rounded p-2 w-full"
            required
          >
            <option value="proposition">Proposition</option>
            <option value="in_progress">En Cours</option>
            <option value="completed">Terminé</option>
          </select>
          <select
            name="client_id"
            value={formData.client_id}
            onChange={handleInputChange}
            className="border rounded p-2 w-full"
          >
            <option value="">- Sélectionner un client -</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
        >
          <FolderPlus className="h-4 w-4 mr-2" />
          Ajouter un Projet
        </button>
      </form>

      {/* Tableau Kanban des projets */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <StatusColumn 
          title="Propositions"
          projects={projectsByStatus.proposition}
        />
        <StatusColumn 
          title="En Cours"
          projects={projectsByStatus.in_progress}
        />
        <StatusColumn 
          title="Terminés"
          projects={projectsByStatus.completed}
        />
      </div>
    </div>
  );
};

export default ProjectManagement;