import React, { useState, useEffect } from 'react';
import { Target, Zap, Users, DollarSign } from 'lucide-react';
import { getWorkingDaysDates } from '../../utils/dateUtils';

const SlotProgress = ({ slot, sprints = [], employees = [] }) => {
  const [velocityData, setVelocityData] = useState({
    dailyVelocity: 0,
    dailyProductivity: 0,
    teamSize: 0
  });

  // Calculer les tokens d'un sprint proratisés sur le slot
  const calculateSprintTokensInSlot = (sprint) => {
    const sprintStart = new Date(sprint.start_date);
    const sprintEnd = new Date(sprint.end_date);
    const slotStart = new Date(slot.start_date);
    const slotEnd = new Date(slot.end_date);

    // Calcul de l'intersection
    const intersectionStart = new Date(Math.max(sprintStart, slotStart));
    const intersectionEnd = new Date(Math.min(sprintEnd, slotEnd));

    // Si pas d'intersection, retourner 0
    if (intersectionEnd < intersectionStart) {
      return { totalTokens: 0, completedTokens: 0 };
    }

    // Proratisation basée sur les jours ouvrés
    const totalSprintDays = getWorkingDaysDates(sprintStart, sprintEnd).length;
    const intersectionDays = getWorkingDaysDates(intersectionStart, intersectionEnd).length;
    const ratio = totalSprintDays > 0 ? intersectionDays / totalSprintDays : 0;

    return {
      totalTokens: Math.round(sprint.total_tokens * ratio),
      completedTokens: Math.round(sprint.tokens_completed * ratio)
    };
  };

  // Calcule les statistiques de complétion
  useEffect(() => {
    if (!slot || !sprints.length || !employees.length) return;
  
    const slotStart = new Date(slot.start_date);
    const slotEnd = new Date(slot.end_date);
    const today = new Date();
    
    console.log("SlotProgress - Slot dates:", { slotStart, slotEnd, today });
    
    // Calcul des jours ouvrés réellement écoulés depuis le début du slot
    let workingDaysElapsed = 0;
    if (today < slotStart) {
      workingDaysElapsed = 0;
    } else if (today > slotEnd) {
      workingDaysElapsed = getWorkingDaysDates(slotStart, slotEnd).length;
    } else {
      workingDaysElapsed = getWorkingDaysDates(slotStart, today).length;
    }
    
    console.log("SlotProgress - Working days elapsed:", workingDaysElapsed);
  
    // Filtrer les employés présents pendant le slot en fonction des dates d'arrivée et de départ
    const employeesPresentDuringSlot = employees.filter(employee => {
      // Date d'arrivée de l'employé
      const employeeArrivalDate = new Date(employee.arrival_date);
      
      // Date de départ de l'employé (si spécifiée)
      const employeeDepartureDate = employee.departure_date ? new Date(employee.departure_date) : null;
      
      // Un employé est présent pendant le slot si :
      // 1. Il est arrivé avant la fin du slot
      const arrivedBeforeSlotEnd = employeeArrivalDate <= slotEnd;
      
      // 2. Et soit il n'a pas de date de départ, soit il est parti après le début du slot
      const presentDuringSlot = !employeeDepartureDate || employeeDepartureDate >= slotStart;
      
      return arrivedBeforeSlotEnd && presentDuringSlot;
    });
    
    console.log("SlotProgress - Employees present during slot for stats:", employeesPresentDuringSlot);
    
    // Pour chaque employé, on soustrait ses jours d'indisponibilité
    // du nombre de jours effectivement écoulés (et non du total du slot)
    const totalAvailableDays = employeesPresentDuringSlot.reduce((total, emp) => {
      const unavailableDays = emp.unavailable_days || 0;
      const availableDays = Math.max(workingDaysElapsed - unavailableDays, 0);
      console.log(`SlotProgress - Employee ${emp.first_name}: workingDays=${workingDaysElapsed}, unavailable=${unavailableDays}, available=${availableDays}`);
      return total + availableDays;
    }, 0);
    
    console.log("SlotProgress - Total available days:", totalAvailableDays);
  
    // Calculer les tokens pour le slot
    const slotTokens = sprints.reduce((acc, sprint) => {
      const { totalTokens, completedTokens } = calculateSprintTokensInSlot(sprint);
      console.log(`SlotProgress - Sprint ${sprint.id}: total=${totalTokens}, completed=${completedTokens}`);
      return {
        total: acc.total + totalTokens,
        completed: acc.completed + completedTokens
      };
    }, { total: 0, completed: 0 });
    
    console.log("SlotProgress - Slot tokens:", slotTokens);
  
    // Calculer le CA produit dans le slot
    const slotCAproduced = sprints.reduce((acc, sprint) => acc + (sprint.ca_produit || 0), 0);
    console.log("SlotProgress - Slot CA produced:", slotCAproduced);
  
    if (totalAvailableDays > 0) {
      const dailyVelocity = slotTokens.completed / totalAvailableDays;
      const dailyProductivity = slotCAproduced / totalAvailableDays;
      console.log("SlotProgress - Calculated velocity:", { dailyVelocity, dailyProductivity });
      setVelocityData({
        dailyVelocity: Math.round(dailyVelocity * 10) / 10,
        dailyProductivity: Math.round(dailyProductivity * 10) / 10,
        teamSize: employeesPresentDuringSlot.length
      });
    } else {
      console.log("SlotProgress - No available days, setting velocity to 0");
      setVelocityData({
        dailyVelocity: 0,
        dailyProductivity: 0,
        teamSize: employeesPresentDuringSlot.length
      });
    }
  }, [slot, sprints, employees]);

  // Calcul de la progression basée sur les tokens
  const calculateProgress = () => {
    if (!slot || !sprints.length) {
      return {
        totalTokens: 0,
        completedTokens: 0,
        completionPercentage: 0,
        theoreticalTarget: null,
        theoreticalPercentage: null
      };
    }

    const slotTokens = sprints.reduce((acc, sprint) => {
      const { totalTokens, completedTokens } = calculateSprintTokensInSlot(sprint);
      return {
        total: acc.total + totalTokens,
        completed: acc.completed + completedTokens
      };
    }, { total: 0, completed: 0 });

    const completionPercentage = slotTokens.total > 0 
      ? (slotTokens.completed / slotTokens.total) * 100 
      : 0;

    // Calcul de l'objectif théorique
    const today = new Date();
    const slotStart = new Date(slot.start_date);
    const slotEnd = new Date(slot.end_date);

    let theoreticalTarget = null;
    let theoreticalPercentage = null;

    if (today >= slotStart && today <= slotEnd) {
      const totalDays = getWorkingDaysDates(slotStart, slotEnd).length;
      const elapsedDays = getWorkingDaysDates(slotStart, today).length;

      if (totalDays > 0) {
        theoreticalTarget = Math.round((elapsedDays / totalDays) * slotTokens.total);
        theoreticalPercentage = (theoreticalTarget / slotTokens.total) * 100;
      }
    }

    return {
      totalTokens: slotTokens.total,
      completedTokens: slotTokens.completed,
      completionPercentage,
      theoreticalTarget,
      theoreticalPercentage
    };
  };

    // Calcul du CA en agrégant le CA à produire et le CA produit de chaque sprint
    const slotCAtoProduce = sprints.reduce((acc, sprint) => acc + (sprint.valeur_a_produire || 0), 0);
    const slotCAproduced = sprints.reduce((acc, sprint) => acc + (sprint.ca_produit || 0), 0);
    const caCompletionPercentage = slotCAtoProduce > 0 ? (slotCAproduced / slotCAtoProduce) * 100 : 0;
    const today = new Date();
    const slotStartDate = new Date(slot.start_date);
    const slotEndDate = new Date(slot.end_date);
    let theoreticalCATarget = null;
    let theoreticalCAPercentage = null;
    if (today >= slotStartDate && today <= slotEndDate && slotCAtoProduce > 0) {
        const totalWorkingDays = getWorkingDaysDates(slotStartDate, slotEndDate).length;
        const elapsedDays = getWorkingDaysDates(slotStartDate, today).length;
        if (totalWorkingDays > 0) {
            theoreticalCATarget = Math.round((elapsedDays / totalWorkingDays) * slotCAtoProduce);
            theoreticalCAPercentage = (theoreticalCATarget / slotCAtoProduce) * 100;
        }
    }

  const progress = calculateProgress();

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Target className="h-5 w-5 mr-2" />
        Progression du Slot
      </h2>

      <div className="space-y-4">
        {/* Métriques de vélocité */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 bg-indigo-50 rounded-lg mb-4">
          <div>
            <div className="text-sm text-indigo-600 mb-1 flex items-center">
              <Users className="h-4 w-4 inline mr-1" />
              Vélocité / personne / jour
            </div>
            <div className="font-medium text-lg">
              {velocityData.dailyVelocity} tokens/jour
            </div>
            <div className="text-xs text-indigo-500">
              Performance quotidienne moyenne par personne
            </div>
          </div>
          <div>
            <div className="text-sm text-indigo-600 mb-1 flex items-center">
              <Zap className="h-4 w-4 inline mr-1" />
              Vélocité équipe / jour
            </div>
            <div className="font-medium text-lg">
              {(velocityData.dailyVelocity * velocityData.teamSize).toFixed(1)} tokens/jour
            </div>
            <div className="text-xs text-indigo-500">
              Performance quotidienne de l'équipe
            </div>
          </div>
          <div>
            <div className="text-sm text-indigo-600 mb-1 flex items-center">
              <DollarSign className="h-4 w-4 inline mr-1" />
              Productivité / personne / jour
            </div>
            <div className="font-medium text-lg">
              {velocityData.dailyProductivity} €/jour
            </div>
            <div className="text-xs text-indigo-500">
              CA produit quotidien moyen par personne
            </div>
          </div>
        </div>

        {/* Progression et objectifs */}
        <div className="flex justify-between text-sm text-gray-600 mb-2">
          <div className="flex items-center space-x-4">
            <div>
              <span>Progression totale :</span>
              <span className="font-medium ml-2">
                {progress.completedTokens} / {progress.totalTokens} tokens
              </span>
              <span className="font-medium ml-2">
                ({Math.round(progress.completionPercentage)}%)
              </span>
            </div>
            {progress.theoreticalTarget !== null && (
              <div className="text-yellow-600">
                <span>Objectif théorique :</span>
                <span className="font-medium ml-2">
                  {progress.theoreticalTarget} tokens
                </span>
                <span className="font-medium ml-2">
                  ({Math.round(progress.theoreticalPercentage)}%)
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Barre de progression */}
        <div className="relative h-6 bg-gray-200 rounded overflow-hidden">
          {/* Progression réelle */}
          <div
            className="absolute h-full bg-blue-500 transition-all duration-300"
            style={{ width: `${Math.min(progress.completionPercentage, 100)}%` }}
          />

          {/* Ligne d'objectif théorique */}
          {progress.theoreticalTarget !== null && (
            <div 
              className="absolute h-full w-1 bg-yellow-400 transition-all duration-300"
              style={{ 
                left: `${Math.min(progress.theoreticalPercentage, 100)}%`,
                transform: 'translateX(-50%)'
              }}
            >
              <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1">
                <div className="bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded whitespace-nowrap">
                  Objectif : {progress.theoreticalTarget} tokens
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Bloc CA agrégé */}
        <div className="flex justify-between text-sm text-gray-600 mt-6 mb-2">
          <div className="flex items-center space-x-4">
            <div>
              <span>CA produit :</span>
              <span className="font-medium ml-2">
                {(slotCAproduced / 1000).toFixed(1)}k€ / {(slotCAtoProduce / 1000).toFixed(1)}k€
              </span>
              <span className="font-medium ml-2">
                ({Math.round(caCompletionPercentage)}%)
              </span>
            </div>
            {theoreticalCATarget !== null && (
              <div className="text-yellow-600">
                <span>Objectif théorique :</span>
                <span className="font-medium ml-2">
                  {theoreticalCATarget} €
                </span>
                <span className="font-medium ml-2">
                  ({Math.round(theoreticalCAPercentage)}%)
                </span>
              </div>
            )}
          </div>
        </div>
        {/* Barre de progression pour le CA */}
        <div className="mt-2">
          <div className="relative h-6 bg-gray-200 rounded overflow-hidden">
            <div
              className="absolute h-full bg-yellow-500 transition-all duration-300"
              style={{ width: `${Math.min(caCompletionPercentage, 100)}%` }}
            />
            {theoreticalCATarget !== null && (
              <div 
                className="absolute h-full w-1 bg-yellow-400 transition-all duration-300"
                style={{ 
                  left: `${Math.min(theoreticalCAPercentage, 100)}%`,
                  transform: 'translateX(-50%)'
                }}
              >
                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1">
                  <div className="bg-yellow-100 text-yellow-800 text-xs px-2 py-1 rounded whitespace-nowrap">
                    Objectif : {theoreticalCATarget} €
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotProgress;