// frontend/src/components/InnowideLogo.js
import React from 'react';

const InnowideLogo = () => {
  return (
    <div className="flex items-center space-x-2">
      <img 
        src="/df_logo512.png" 
        alt="DeepFlow Logo" 
        className="h-8 w-8 object-contain"
      />
      <div className="flex items-center text-xl font-bold text-gray-900">
        <span className="font-montserrat">DeepFlow</span>
      </div>
    </div>
  );
};

export default InnowideLogo;
