import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const STATUS_LABELS = {
  'not_started': 'Non commencé',
  'in_progress': 'En cours',
  'blocked': 'Bloqué',
  'done': 'Terminé'
};

const STATUS_COLORS = {
  'not_started': 'bg-gray-200 text-gray-800',
  'in_progress': 'bg-blue-200 text-blue-800',
  'blocked': 'bg-red-200 text-red-800',
  'done': 'bg-green-200 text-green-800'
};

const TaskTable = ({ tasks }) => {
  const navigate = useNavigate();

  // Fonction pour obtenir le statut formaté
  const getStatusDisplay = (status) => {
    return {
      label: STATUS_LABELS[status] || status,
      className: STATUS_COLORS[status] || 'bg-gray-200 text-gray-800'
    };
  };

  // Fonction pour gérer le clic sur une tâche
  const handleTaskClick = (task) => {
    navigate(`/tasks?project=${task.project_id}&task=${task.id}`);
  };

  // Déterminer si une tâche a une description ou un commentaire
  const hasDetails = (task) => {
    return task.description || task.comment;
  };

  return (
    <div className="overflow-x-auto mt-2 mb-2">
      <table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md">
        <thead className="bg-indigo-50 text-indigo-800">
          <tr>
            <th className="py-2 px-3 text-left text-xs font-medium uppercase tracking-wider">Tâche</th>
            <th className="py-2 px-3 text-left text-xs font-medium uppercase tracking-wider">Statut</th>
            <th className="py-2 px-3 text-center text-xs font-medium uppercase tracking-wider">Effort</th>
            <th className="py-2 px-3 text-left text-xs font-medium uppercase tracking-wider">Projet</th>
            <th className="py-2 px-3 text-left text-xs font-medium uppercase tracking-wider">Assigné à</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {tasks.map((task) => {
            const statusInfo = getStatusDisplay(task.status);
            return (
              <React.Fragment key={task.id}>
                <tr 
                  className={`hover:bg-indigo-50 cursor-pointer ${hasDetails(task) ? 'border-b-0' : ''}`}
                  onClick={() => handleTaskClick(task)}
                >
                  <td className="py-2 px-3 text-sm font-medium text-gray-900">
                    {task.name}
                  </td>
                  <td className="py-2 px-3 text-sm">
                    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs ${statusInfo.className}`}>
                      {statusInfo.label}
                    </span>
                  </td>
                  <td className="py-2 px-3 text-sm text-center">
                    <span className="inline-flex items-center justify-center w-6 h-6 rounded-full bg-indigo-100 text-indigo-800 text-xs font-medium">
                      {task.effort || '-'}
                    </span>
                  </td>
                  <td className="py-2 px-3 text-sm text-gray-500">
                    {task.project_name || '-'}
                  </td>
                  <td className="py-2 px-3 text-sm text-gray-500">
                    {task.owner_name || 'Non assigné'}
                  </td>
                </tr>

                {/* Ligne de détails (conditionnelle) - apparaît seulement si description ou commentaire présent */}
                {hasDetails(task) && (
                  <tr className="bg-indigo-50 hover:bg-indigo-100">
                    <td colSpan={5} className="py-1 px-4 text-xs text-indigo-800">
                      {task.description && (
                        <div className="mb-1">
                          <span className="font-semibold">Description:</span> {task.description}
                        </div>
                      )}
                      {task.comment && (
                        <div>
                          <span className="font-semibold">Commentaire:</span> {task.comment}
                        </div>
                      )}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

TaskTable.propTypes = {
  tasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      effort: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      project_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      project_name: PropTypes.string,
      owner_name: PropTypes.string,
      description: PropTypes.string,
      comment: PropTypes.string,
      sprint_name: PropTypes.string
    })
  ).isRequired
};

export default TaskTable;