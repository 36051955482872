// frontend/src/components/Slot/SlotTokensDistribution.js

import React, { useMemo, useState } from 'react';
import { Target } from 'lucide-react';

const SlotTokensDistribution = ({ slot, sprints }) => {
  // Ajout du mode avec "tokens" par défaut (ou "ca")
  const [mode, setMode] = useState("tokens");
  const MIN_TEXT_WIDTH_PERCENT = 10; // seuil en pourcentage

  // Fonction de calcul générique en fonction du mode
  const calculateSprintDataInSlot = (sprint, mode) => {
    const sprintStart = new Date(sprint.start_date);
    const sprintEnd   = new Date(sprint.end_date);
    const slotStart   = new Date(slot.start_date);
    const slotEnd     = new Date(slot.end_date);
  
    // Calculer le nombre de jours du sprint
    const totalSprintDays = (sprintEnd - sprintStart) / (1000 * 60 * 60 * 24);
    let ratio = 0;
    if (totalSprintDays > 0) {
      // Calculer l'intersection des dates
      const intersectionStart = new Date(Math.max(sprintStart, slotStart));
      const intersectionEnd   = new Date(Math.min(sprintEnd, slotEnd));
      // Si pas d'intersection, forcer à 0
      const intersectionDays = Math.max(((intersectionEnd - intersectionStart) / (1000 * 60 * 60 * 24)), 0);
      ratio = intersectionDays / totalSprintDays;
    }
  
    // Utiliser Number(...) pour forcer la conversion numérique – et fallback à 0 si la valeur est absente
    if (mode === "tokens") {
      return {
        total: Math.round((Number(sprint.total_tokens) || 0) * ratio),
        completed: Math.round((Number(sprint.tokens_completed) || 0) * ratio)
      };
    } else {
      return {
        total: Math.round((Number(sprint.valeur_a_produire) || 0) * ratio),
        completed: Math.round((Number(sprint.ca_produit) || 0) * ratio)
      };
    }
  };  

  // Préparer et trier les données des sprints en fonction du mode
  const sprintData = useMemo(() => {
    const processedSprints = sprints.map(sprint => {
      const { total, completed } = calculateSprintDataInSlot(sprint, mode);
      return {
        ...sprint,  // Conserve toutes les propriétés originales
        name: `${sprint.project_name}`,  // On affiche par exemple le nom du projet
        total,
        completed,
        progress: total > 0 ? (completed / total) : 0,
      };
    });
    return processedSprints.sort((a, b) => b.total - a.total);
  }, [sprints, slot, mode]);

  // Calcul du total général (tokens ou CA)
  const totalValue = useMemo(() => {
    return sprintData.reduce((sum, sprint) => sum + sprint.total, 0);
  }, [sprintData]);

  // Couleurs de base pour chaque sprint
  const baseColors = {
    0: { light: '#FF9B9B', dark: '#FF6B6B' },
    1: { light: '#FF9BFF', dark: '#FF6BFF' },
    2: { light: '#9BFFC4', dark: '#6BFF9F' },
    3: { light: '#9BCEFF', dark: '#6BA6FF' },
    4: { light: '#FFE59B', dark: '#FFD76B' },
    5: { light: '#E29BFF', dark: '#D26BFF' }
  };

  return (
    <div className="bg-white shadow rounded-lg p-6 mt-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold mb-6 flex items-center">
          <Target className="h-5 w-5 mr-2" />
          {mode === "tokens"
            ? "Distribution des Tokens par Sprint"
            : "Distribution du CA par Sprint"}
        </h3>
        <button
          onClick={() => setMode(mode === "tokens" ? "ca" : "tokens")}
          className="px-2 py-1 text-sm text-blue-600 border border-blue-600 rounded hover:bg-blue-50"
        >
          {mode === "tokens" ? "Voir CA" : "Voir Tokens"}
        </button>
      </div>

      <div className="space-y-8">
        {/* Barre unique avec segments */}
        <div className="relative h-16">
          <div className="absolute inset-x-0 h-full flex rounded-lg overflow-visible">
            {sprintData.map((sprint, index) => {
              const widthPercent = (sprint.total / totalValue) * 100;
              const colors = baseColors[index % Object.keys(baseColors).length];
              return (
                <div
                  key={sprint.id}
                  className="relative h-full flex items-center justify-center group"
                  style={{
                    width: `${widthPercent}%`,
                    backgroundColor: colors.light
                  }}
                >
                  {/* Barre de progression */}
                  <div 
                    className="absolute h-full left-0 top-0"
                    style={{
                      width: `${sprint.progress * 100}%`,
                      backgroundColor: colors.dark,
                      transition: 'width 0.3s ease'
                    }}
                  />

                {/* Texte uniquement si l'espace disponible est suffisant */}
                {widthPercent >= MIN_TEXT_WIDTH_PERCENT && (
                  <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-800">
                    <div
                      className="text-sm font-medium"
                      style={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        whiteSpace: 'normal',
                        textAlign: 'center'
                      }}
                    >
                      {sprint.name}
                    </div>
                    <div className="text-xs whitespace-nowrap">
                      {sprint.total} {mode === "tokens" ? "tokens" : "€"} ({Math.round(sprint.progress * 100)}%)
                    </div>
                  </div>
                )}
                  {/* Info-bulle au survol */}
                {(() => {
                  const tokensData = calculateSprintDataInSlot(sprint, "tokens");
                  const caData = calculateSprintDataInSlot(sprint, "ca");
                  return (
                    <div
                      className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out z-50 pointer-events-none"
                    >
                      <div className="bg-gray-800 text-white text-xs py-1 px-2 rounded shadow">
                        <div className="font-medium">{sprint.name}</div>
                        <div className="whitespace-nowrap">
                          Tokens: {tokensData.completed} / {tokensData.total}
                        </div>
                        <div className="whitespace-nowrap">
                          CA: {caData.completed}€ / {caData.total}€
                        </div>
                      </div>
                    </div>
                  );
                })()}
                </div>
              );
            })}
          </div>
        </div>

        {/* Légende détaillée */}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {sprintData.map((sprint, index) => {
            const colors = baseColors[index % Object.keys(baseColors).length];
            return (
              <div key={sprint.id} className="flex items-center space-x-2">
                <div className="relative w-8 h-4 rounded overflow-hidden">
                  <div className="absolute inset-0" style={{ backgroundColor: colors.light }} />
                  <div 
                    className="absolute inset-y-0 left-0" 
                    style={{ 
                      backgroundColor: colors.dark,
                      width: `${sprint.progress * 100}%`
                    }} 
                  />
                </div>
                <div className="text-sm">
                  <div className="font-medium">{sprint.name}</div>
                  <div className="text-gray-600">
                    {sprint.completed} / {sprint.total} {mode === "tokens" ? "tokens" : "€"}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* Statistiques globales */}
        <div className="grid grid-cols-3 gap-4 pt-4 border-t">
          <div className="text-center p-3 bg-gray-50 rounded">
            <p className="text-sm text-gray-600">Total Sprints</p>
            <p className="text-xl font-semibold">{sprintData.length}</p>
          </div>
          <div className="text-center p-3 bg-gray-50 rounded">
            <p className="text-sm text-gray-600">
              Total {mode === "tokens" ? "Tokens" : "CA"}
            </p>
            <p className="text-xl font-semibold">{totalValue}</p>
          </div>
          <div className="text-center p-3 bg-gray-50 rounded">
            <p className="text-sm text-gray-600">Progression Globale</p>
            <p className="text-xl font-semibold">
              {Math.round((sprintData.reduce((sum, s) => sum + s.completed, 0) / totalValue) * 100)}%
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlotTokensDistribution;
