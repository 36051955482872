import React, { useEffect } from "react";
import { Search, X } from "lucide-react";

const TaskFilters = ({
  viewMode,
  setViewMode,
  projectSearch,
  setProjectSearch,
  isSearchFocused,
  setIsSearchFocused,
  toggleProjectSelection,
  selectedProjectsMap,
  filteredProjects,
  selectedProjects,
  projects,
  clearProjectsFilter,
  ownerSearch,
  setOwnerSearch,
  isOwnerSearchFocused,
  setIsOwnerSearchFocused,
  toggleOwnerSelection,
  selectedOwnersMap,
  filteredEmployees,
  employees,
  clearOwnersFilter,
  projectSprintFilter,
  setProjectSprintFilter,
  sprintOptions,
  selectedSlot,
  setSelectedSlot,
  slots,
  myTasksSprintFilter,
  setMyTasksSprintFilter,
  getOwnerInitials,
  navigate
}) => {
  // Effet pour gérer la fermeture des popups au clic en dehors ou à l'appui sur Escape
  useEffect(() => {
    // Gestionnaire pour fermer le popup au clic en dehors
    const handleOutsideClick = (event) => {
      const projectSearch = document.getElementById("project-search");
      const ownerSearch = document.getElementById("owner-search");
      
      if (isSearchFocused && projectSearch && !projectSearch.contains(event.target)) {
        setIsSearchFocused(false);
      }
      
      if (isOwnerSearchFocused && ownerSearch && !ownerSearch.contains(event.target)) {
        setIsOwnerSearchFocused(false);
      }
    };
    
    // Gestionnaire pour fermer le popup à l'appui sur Escape
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        if (isSearchFocused) {
          setIsSearchFocused(false);
          // Retirer le focus de l'input projet
          const projectInput = document.querySelector("#project-search input");
          if (projectInput) projectInput.blur();
        }
        if (isOwnerSearchFocused) {
          setIsOwnerSearchFocused(false);
          // Retirer le focus de l'input owner
          const ownerInput = document.querySelector("#owner-search input");
          if (ownerInput) ownerInput.blur();
        }
      }
    };
    
    // Ajouter les écouteurs d'événements
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("keydown", handleKeyDown);
    
    // Nettoyer les écouteurs d'événements
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSearchFocused, isOwnerSearchFocused, setIsSearchFocused, setIsOwnerSearchFocused]);

  if (viewMode === "project") {
    return (
      <div className="mb-4 flex flex-wrap gap-4 items-start">
        <div id="project-search" className="relative w-64">
          <label className="block text-sm font-medium mb-1">Projets</label>
          <div className="relative">
            <input
              type="text"
              value={projectSearch}
              onChange={(e) => setProjectSearch(e.target.value)}
              placeholder="Rechercher un projet"
              className="border rounded p-2 w-full pl-9"
              onFocus={() => {
                setProjectSearch(""); // vide le champ pour lancer une recherche
                setIsSearchFocused(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setIsSearchFocused(false);
                  e.target.blur(); // Retirer le focus de cet élément
                }
              }}
            />
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          {isSearchFocused && (
            <ul className="absolute left-0 right-0 bg-white border rounded mt-1 max-h-60 overflow-auto z-10">
              <li
                className="p-2 cursor-pointer hover:bg-gray-100 flex items-center"
                onClick={() => toggleProjectSelection("all")}
              >
                <input
                  type="checkbox"
                  checked={selectedProjectsMap.all || false}
                  onChange={(e) => {
                    e.stopPropagation();
                    toggleProjectSelection("all");
                  }}
                  className="mr-2"
                />
                <span>Tous</span>
              </li>
              {filteredProjects.map((p) => (
                <li
                  key={p.id}
                  className="p-2 cursor-pointer hover:bg-gray-100 flex items-center"
                  onClick={() => toggleProjectSelection(p.id)}
                >
                  <input
                    type="checkbox"
                    checked={selectedProjectsMap[p.id] || false}
                    onChange={(e) => {
                      e.stopPropagation(); // Prevent the li onClick from firing
                      toggleProjectSelection(p.id);
                    }}
                    className="mr-2"
                  />
                  <span>{p.name}</span>
                </li>
              ))}
            </ul>
          )}
          <div className="mt-2 flex flex-wrap gap-2">
            {selectedProjectsMap.all ? (
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                <span>Tous</span>
                <button
                  onClick={() => toggleProjectSelection("all")}
                  className="ml-1 text-blue-600 hover:text-blue-800"
                >
                  ×
                </button>
              </span>
            ) : (
              selectedProjects.map(id => {
                if (id === "all") return null;
                const project = projects.find(p => String(p.id) === String(id));
                if (!project) return null;
                return (
                  <span key={id} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                    <span
                      className="cursor-pointer hover:underline"
                      onClick={() => navigate && navigate(`/project/${id}`)}
                    >
                      {project.name}
                    </span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleProjectSelection(id);
                      }}
                      className="ml-1 text-blue-600 hover:text-blue-800"
                    >
                      ×
                    </button>
                  </span>
                );
              })
            )}
            {(selectedProjectsMap.all ? false : Object.keys(selectedProjectsMap).some(id => selectedProjectsMap[id])) && (
              <button
                onClick={clearProjectsFilter}
                className="bg-gray-100 text-gray-700 text-xs font-medium px-2 py-0.5 rounded flex items-center"
              >
                <X className="h-3 w-3 mr-1" />
                Effacer tout
              </button>
            )}
          </div>
        </div>

        {/* Nouveau filtre d'owner simplifié */}
        <div id="owner-search" className="relative w-64">
          <label className="block text-sm font-medium mb-1">Owners</label>
          <div className="relative">
            <input
              type="text"
              value={ownerSearch}
              onChange={(e) => setOwnerSearch(e.target.value)}
              placeholder="Rechercher un owner"
              className="border rounded p-2 w-full pl-9"
              onFocus={() => {
                setOwnerSearch(""); // vide le champ pour lancer une recherche
                setIsOwnerSearchFocused(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setIsOwnerSearchFocused(false);
                  e.target.blur(); // Retirer le focus de cet élément
                }
              }}
            />
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          {isOwnerSearchFocused && (
            <ul className="absolute left-0 right-0 bg-white border rounded mt-1 max-h-60 overflow-auto z-10">
              <li
                className="p-2 cursor-pointer hover:bg-gray-100 flex items-center"
                onClick={() => toggleOwnerSelection("all")}
              >
                <input
                  type="checkbox"
                  checked={selectedOwnersMap.all || false}
                  onChange={(e) => {
                    e.stopPropagation();
                    toggleOwnerSelection("all");
                  }}
                  className="mr-2"
                />
                <span>Tous</span>
              </li>
              {filteredEmployees.map((emp) => (
                <li
                  key={emp.id}
                  className="p-2 cursor-pointer hover:bg-gray-100 flex items-center"
                  onClick={() => toggleOwnerSelection(emp.id)}
                >
                  <input
                    type="checkbox"
                    checked={selectedOwnersMap[emp.id] || false}
                    onChange={(e) => {
                      e.stopPropagation();
                      toggleOwnerSelection(emp.id);
                    }}
                    className="mr-2"
                  />
                  <div className="flex items-center">
                    <div className="h-6 w-6 rounded-full bg-gray-300 flex items-center justify-center mr-2 text-xs">
                      {getOwnerInitials && getOwnerInitials(emp.id)}
                    </div>
                    <span>{emp.name.split(" ")[0]}</span>
                  </div>
                </li>
              ))}
            </ul>
          )}
          <div className="mt-2 flex flex-wrap gap-2">
            {selectedOwnersMap.all ? (
              <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                <span>Tous</span>
                <button
                  onClick={() => toggleOwnerSelection("all")}
                  className="ml-1 text-blue-600 hover:text-blue-800"
                >
                  ×
                </button>
              </span>
            ) : (
              Object.keys(selectedOwnersMap)
                .filter(id => selectedOwnersMap[id] && id !== "all")
                .map(id => {
                  const emp = employees.find(e => String(e.id) === String(id));
                  if (!emp) return null;
                  return (
                    <span key={id} className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded flex items-center">
                      <span>{emp.name.split(" ")[0]}</span>
                      <button
                        onClick={() => toggleOwnerSelection(id)}
                        className="ml-1 text-blue-600 hover:text-blue-800"
                      >
                        ×
                      </button>
                    </span>
                  );
                })
            )}
            {(selectedOwnersMap.all ? false : Object.keys(selectedOwnersMap).some(id => selectedOwnersMap[id])) && (
              <button
                onClick={clearOwnersFilter}
                className="bg-gray-100 text-gray-700 text-xs font-medium px-2 py-0.5 rounded flex items-center"
              >
                <X className="h-3 w-3 mr-1" />
                Effacer tout
              </button>
            )}
          </div>
        </div>

        {/* Filtrage conditionnel par Sprint ou Slot avec bouton d'ajout à droite */}
        {selectedProjects.length === 1 && !selectedProjects.includes("all") ? (
          <div className="flex flex-col">
            <label className="block text-sm font-medium mb-1">Filtrer par Sprint</label>
            <select
              value={projectSprintFilter}
              onChange={(e) => setProjectSprintFilter(e.target.value)}
              className="border rounded p-2 w-48"
            >
              <option value="all">Tous</option>
              {sprintOptions && sprintOptions.map(sp => (
                <option key={sp.id} value={sp.id}>
                  {sp.description ? sp.description : `Sprint ${sp.id}`}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="flex flex-col">
            <label className="block text-sm font-medium mb-1">Filtrer par Slot</label>
            <select
              value={selectedSlot}
              onChange={(e) => setSelectedSlot(e.target.value)}
              className="border rounded p-2 w-48"
            >
              <option value="all">Tous</option>
              {slots && slots.map(slot => (
                <option key={slot.id} value={slot.id}>
                  {slot.name}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    );
  } else if (viewMode === "myTasks") {
    return (
      <div className="mb-4">
        <label className="block text-sm font-medium mb-1">Filtrer par Sprint</label>
        <div className="flex space-x-2">
          <button
            onClick={() => setMyTasksSprintFilter("all")}
            className={`px-4 py-2 rounded-md ${
              myTasksSprintFilter === "all" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            Tous
          </button>
          <button
            onClick={() => setMyTasksSprintFilter("enCours")}
            className={`px-4 py-2 rounded-md ${
              myTasksSprintFilter === "enCours" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-700"
            }`}
          >
            En cours
          </button>
        </div>
      </div>
    );
  }
  
  return null;
};

export default TaskFilters;