import React, { useState, useRef, useEffect } from 'react';
import { Clock, Target, Plus, Trash2, CalendarPlus } from 'lucide-react';
import GlobalCurrentDayLine from './GlobalCurrentDayLine';

const InteractiveTimelineView = ({ 
  iterations, 
  slots = [], // Ajout des slots à la timeline
  onIterationUpdate, 
  onSprintUpdate,
  setIterations,
  onIterationAdd = null, // Pour ajouter des itérations
  onSprintAdd = null, // Pour ajouter des sprints
  onIterationDelete = null, // Pour supprimer des itérations
  onSprintDelete = null // Pour supprimer des sprints
}) => {
  const [dragging, setDragging] = useState(null);
  const [resizing, setResizing] = useState(null);
  const [tempChanges, setTempChanges] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  // États pour la confirmation de suppression
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    type: null, // 'iteration' ou 'sprint'
    id: null,
    iterationId: null,
    name: ''
  });
  const timelineRef = useRef(null);
  
  // Calculer la plage totale des dates en incluant les slots
  const allDates = [...iterations, ...slots].reduce((dates, item) => {
    if (item.start_date) dates.push(new Date(item.start_date));
    if (item.end_date) dates.push(new Date(item.end_date));
    
    // Si c'est une itération, ajouter aussi les dates des sprints
    if (item.sprints) {
      item.sprints.forEach(sprint => {
        dates.push(new Date(sprint.start_date));
        dates.push(new Date(sprint.end_date));
      });
    }
    return dates;
  }, []);

  let startDate = new Date(Math.min(...allDates));
  let endDate = new Date(Math.max(...allDates));
  startDate = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
  endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  
  // Déterminer dynamiquement l'unité d'affichage en fonction de la durée du projet
  const totalMonths = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24 * 30.44));
  const [displayUnit, setDisplayUnit] = useState(totalMonths > 3 ? 'month' : 'week');
  
  // Permettre à l'utilisateur de changer le mode d'affichage
  const toggleDisplayUnit = () => {
    setDisplayUnit(displayUnit === 'week' ? 'month' : 'week');
  };

  const handleMouseDown = (e, item, type, action = 'move') => {
    e.preventDefault();
    
    const rect = timelineRef.current.getBoundingClientRect();
    const startX = e.clientX - rect.left;
  
    if (action === 'resize') {
      e.stopPropagation();
      setResizing({
        type,
        item,
        edge: e.target.dataset.edge,
        startX,
        originalStart: new Date(item.start_date),
        originalEnd: new Date(item.end_date)
      });
    } else {
      setDragging({
        type,
        item,
        startX,
        originalStart: new Date(item.start_date),
        originalEnd: new Date(item.end_date)
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging && !resizing) return;
  
    const rect = timelineRef.current.getBoundingClientRect();
    const currentX = e.clientX - rect.left;
    
    if (resizing) {
      const { type, item, edge, originalStart, originalEnd } = resizing;
      const pixelsPerDay = rect.width / totalDays;
      const daysDiff = Math.round((currentX - resizing.startX) / pixelsPerDay);
  
      // On clone l'item pour ne pas modifier l'original
      const updatedItem = { ...item };
  
      // On ne modifie que la date du bord qu'on déplace
      if (edge === 'start') {
        let newStart = new Date(originalStart);
        newStart.setDate(newStart.getDate() + daysDiff);
        if (newStart >= new Date(item.end_date)) {
          newStart = new Date(item.end_date);
          newStart.setDate(newStart.getDate() - 1);
        }
        updatedItem.start_date = newStart.toISOString().split('T')[0];
      } else {
        let newEnd = new Date(originalEnd);
        newEnd.setDate(newEnd.getDate() + daysDiff);
        if (newEnd <= new Date(item.start_date)) {
          newEnd = new Date(item.start_date);
          newEnd.setDate(newEnd.getDate() + 1);
        }
        updatedItem.end_date = newEnd.toISOString().split('T')[0];
      }
  
      setTempChanges({
        type,
        id: item.id,
        iteration_id: type === 'sprint' ? item.iteration_id : undefined,
        changes: updatedItem
      });
  
      // Mise à jour visuelle immédiate
      setIterations(prevIterations => 
        prevIterations.map(iteration => {
          if (type === 'iteration' && iteration.id === item.id) {
            return { ...iteration, ...updatedItem };
          }
          if (type === 'sprint') {
            return {
              ...iteration,
              sprints: iteration.sprints?.map(sprint =>
                sprint.id === item.id ? { ...sprint, ...updatedItem } : sprint
              )
            };
          }
          return iteration;
        })
      );
    }
  
    if (dragging) {
      const { type, item, originalStart, originalEnd } = dragging;
      const pixelsPerDay = rect.width / totalDays;
      const daysDiff = Math.round((currentX - dragging.startX) / pixelsPerDay);
  
      const duration = originalEnd - originalStart;
      let newStart = new Date(originalStart);
      newStart.setDate(newStart.getDate() + daysDiff);
      let newEnd = new Date(newStart);
      newEnd.setTime(newEnd.getTime() + duration);
  
      const updatedItem = {
        start_date: newStart.toISOString().split('T')[0],
        end_date: newEnd.toISOString().split('T')[0]
      };
  
      setTempChanges({
        type,
        id: item.id,
        iteration_id: type === 'sprint' ? item.iteration_id : undefined,
        changes: updatedItem
      });
  
      // Mise à jour visuelle immédiate
      setIterations(prevIterations => 
        prevIterations.map(iteration => {
          if (type === 'iteration' && iteration.id === item.id) {
            return { ...iteration, ...updatedItem };
          }
          if (type === 'sprint') {
            return {
              ...iteration,
              sprints: iteration.sprints?.map(sprint =>
                sprint.id === item.id ? { ...sprint, ...updatedItem } : sprint
              )
            };
          }
          return iteration;
        })
      );
    }
  };
  
  useEffect(() => {
    if (dragging || resizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  // Ajouter tempChanges à la liste des dépendances
  }, [dragging, resizing, tempChanges]);
  
  const handleMouseUp = async () => {
    // S'assurer qu'on a bien les derniers changements
    if (tempChanges) {
      try {
        // Sauvegarder les changements localement pour être sûr d'avoir la dernière valeur
        const finalChanges = { ...tempChanges };
        // Reset les états avant l'appel serveur pour éviter les conflits
        setDragging(null);
        setResizing(null);
        setTempChanges(null);
  
        // Envoyer les changements au serveur
        if (finalChanges.type === 'iteration') {
          await onIterationUpdate(finalChanges.id, finalChanges.changes);
        } else {
          // Pour les sprints, on passe l'objet complet
          const updateData = {
            id: finalChanges.id,
            changes: finalChanges.changes
          };
          await onSprintUpdate(updateData, finalChanges.iteration_id);
        }
      } catch (error) {
        // En cas d'erreur, on revient à l'état initial
        setIterations(iterations);
        console.error('Failed to update:', error);
      }
    } else {
      setDragging(null);
      setResizing(null);
    }
  };
  
  // Generate months and weeks for the timeline grid
  const months = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    months.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  };

  currentDate = new Date(startDate);

  // Style pour les éléments timeline (itérations, sprints, slots)
  const getTimelineStyles = (start, end, type = 'iteration') => {
    const startTime = new Date(start);
    startTime.setHours(0, 0, 0, 0);
    const endTime = new Date(end);
    endTime.setHours(0, 0, 0, 0);
    const timelineStart = new Date(startDate);
    timelineStart.setHours(0, 0, 0, 0);
    
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    
    // Différentes couleurs selon le type
    let extraClasses = '';
    if (type === 'sprint') {
      extraClasses = 'bg-indigo-50 border-indigo-200';
    } else if (type === 'iteration') {
      extraClasses = 'bg-blue-100 border-blue-300';
    } else if (type === 'slot') {
      extraClasses = 'bg-purple-50 border-purple-100 opacity-20';
    }
    
    // Calcul de la position
    let left, width;
    
    if (type === 'sprint') {
      // Pour les sprints, on ajuste seulement la date de début de -0.5 jour
      left = (((startTime - timelineStart) / MS_PER_DAY) - 0.5) / totalDays * 100;
      width = ((endTime - startTime + MS_PER_DAY) / MS_PER_DAY) / totalDays * 100;
    } else {
      // Pour les itérations et slots, calcul normal
      left = (startTime - timelineStart) / MS_PER_DAY / totalDays * 100;
      width = (endTime - startTime + MS_PER_DAY) / MS_PER_DAY / totalDays * 100;
    }
    
    return { 
      left: `${left}%`, 
      width: `${width}%`,
      transform: 'translateX(-0.5px)',
      extraClasses
    };
  };

  // Génère les divisions de temps selon l'unité d'affichage
  const getTimelineDivisions = () => {
    if (displayUnit === 'week') {
      // Génération des semaines
      const weeks = [];
      let curDate = new Date(startDate);
      curDate.setHours(0, 0, 0, 0);
      
      // Ajuster au lundi de la semaine
      const day = curDate.getDay();
      const diff = curDate.getDate() - day + (day === 0 ? -6 : 1);
      curDate.setDate(diff);

      while (curDate <= endDate) {
        weeks.push({
          date: new Date(curDate),
          weekNumber: getWeekNumber(curDate),
          type: 'week'
        });
        curDate.setDate(curDate.getDate() + 7);
      }
      return weeks;
    } else {
      // Génération des mois
      return months.map(date => ({
        date: new Date(date),
        monthName: date.toLocaleDateString('fr-FR', { month: 'short' }),
        type: 'month'
      }));
    }
  };

  const timelineDivisions = getTimelineDivisions();

  // Fonction pour calculer le pourcentage de progression
  const getCompletionPercentage = (sprint) => {
    if (!sprint.total_tokens || sprint.total_tokens === 0) return 0;
    return (sprint.tokens_completed / sprint.total_tokens) * 100;
  };

  // Fonction pour gérer la confirmation de suppression
  const handleDeleteConfirmation = (type, id, iterationId, name) => {
    setDeleteConfirmation({
      isOpen: true,
      type,
      id,
      iterationId,
      name
    });
  };

  // Fonction pour exécuter la suppression
  const executeDelete = () => {
    const { type, id, iterationId } = deleteConfirmation;
    
    if (type === 'iteration' && onIterationDelete) {
      onIterationDelete(id);
    } else if (type === 'sprint' && onSprintDelete) {
      onSprintDelete(id, iterationId);
    }
    
    setDeleteConfirmation({
      isOpen: false,
      type: null,
      id: null,
      iterationId: null,
      name: ''
    });
  };

  // Composant de dialogue de confirmation
  const ConfirmationDialog = () => {
    if (!deleteConfirmation.isOpen) return null;
    
    const itemType = deleteConfirmation.type === 'iteration' ? 'l\'itération' : 'le sprint';
    
    return (
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
        <div className="bg-white p-4 rounded-lg shadow-lg max-w-md w-full">
          <h3 className="text-lg font-medium mb-2">Confirmation de suppression</h3>
          <p>Êtes-vous sûr de vouloir supprimer {itemType} "{deleteConfirmation.name}" ?</p>
          <div className="mt-4 flex justify-end space-x-3">
            <button 
              onClick={() => setDeleteConfirmation({...deleteConfirmation, isOpen: false})}
              className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded"
            >
              Annuler
            </button>
            <button 
              onClick={executeDelete}
              className="px-3 py-1 bg-red-500 hover:bg-red-600 text-white rounded"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  // Composant Tooltip
  const Tooltip = ({ item }) => {
    if (!item) return null;
    
    const isIteration = !item.hasOwnProperty('total_tokens');
    const tooltipContent = isIteration ? (
      <div className="text-xs">
        <div className="font-medium">{item.name}</div>
        <div>{new Date(item.start_date).toLocaleDateString()} - {new Date(item.end_date).toLocaleDateString()}</div>
      </div>
    ) : (
      <div className="text-xs">
        <div className="font-medium">{item.name}</div>
        <div>{new Date(item.start_date).toLocaleDateString()} - {new Date(item.end_date).toLocaleDateString()}</div>
        <div className="flex items-center mt-1">
          <Target className="h-3 w-3 mr-1" />
          <span>{item.tokens_completed}/{item.total_tokens} tokens ({Math.round(getCompletionPercentage(item))}%)</span>
        </div>
      </div>
    );

    return (
      <div className="absolute bottom-full left-0 mb-2 bg-gray-800 text-white p-2 rounded shadow-lg z-50 whitespace-nowrap">
        {tooltipContent}
      </div>
    );
  };

 return (
  <div className="bg-white shadow rounded-lg p-6">
    {/* Dialogue de confirmation de suppression */}
    <ConfirmationDialog />
    
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-xl font-semibold flex items-center">
        <Clock className="h-5 w-5 mr-2" />
        Timeline
      </h2>
      
      <div className="flex items-center space-x-4">
        {/* Bouton pour basculer l'unité d'affichage */}
        <button 
          onClick={toggleDisplayUnit}
          className="px-3 py-1 text-sm bg-blue-100 text-blue-800 rounded hover:bg-blue-200"
        >
          Afficher par {displayUnit === 'week' ? 'mois' : 'semaine'}
        </button>
        
        {/* Bouton pour ajouter une itération directement depuis la timeline */}
        {onIterationAdd && (
          <button 
            onClick={onIterationAdd}
            className="px-3 py-1 text-sm bg-green-100 text-green-800 rounded hover:bg-green-200 flex items-center"
          >
            <CalendarPlus className="h-4 w-4 mr-1" />
            Nouvelle itération
          </button>
        )}
      </div>
    </div>

    <div className="relative select-none">
      {/* En-tête avec les slots comme fond discret */}
      <div className="h-8 relative mb-2 border-b border-gray-200">
        {months.map((date, index) => {
          const monthPosition = ((date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
          const nextMonth = new Date(date);
          nextMonth.setMonth(nextMonth.getMonth() + 1);
          const nextMonthPosition = ((nextMonth - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
          const monthWidth = nextMonthPosition - monthPosition;
          
          return (
            <div
              key={`month-${index}`}
              className="absolute text-sm text-gray-600"
              style={{ left: `${monthPosition}%`, width: `${monthWidth}%` }}
            >
              <div className="text-center">
                {date.toLocaleDateString('fr-FR', { month: 'long' })}
              </div>
            </div>
          );
        })}
      </div>

      {/* Grille avec unités de temps adaptatives (semaines ou mois) */}
      <div className="relative">
        {/* En-tête avec numéros de semaine ou noms de mois courts */}
        <div className="flex border-b border-gray-300">
          {timelineDivisions.map((division, index) => {
            const divisionWidth = 100 / timelineDivisions.length;
            
            return (
              <div
                key={`division-${index}`}
                className="relative"
                style={{ width: `${divisionWidth}%` }}
              >
                <div className="text-xs text-gray-500 pb-2 text-center">
                  {division.type === 'week' ? `S${division.weekNumber}` : division.monthName}
                </div>
              </div>
            );
          })}
        </div>

        {/* Timeline avec grille */}
        <div ref={timelineRef} className="relative mt-4">
          {/* Lignes verticales des divisions */}
          <div className="absolute w-full pointer-events-none" 
               style={{ top: '-2.5rem', height: 'calc(100% + 3.5rem)' }}>
            {timelineDivisions.map((division, index) => {
              const position = ((division.date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
              return (
                <div
                  key={`division-line-${index}`}
                  className="absolute h-full border-l border-gray-200"
                  style={{ left: `${position}%` }}
                />
              );
            })}
          </div>
          
          {/* Fonds des slots - Affichés en premier pour être en arrière-plan */}
          <div className="absolute w-full h-full pointer-events-none">
            {slots.map((slot, index) => {
              const slotStyle = getTimelineStyles(slot.start_date, slot.end_date, 'slot');
              return (
                <div
                  key={`slot-bg-${index}`}
                  className={`absolute h-full border border-dashed ${slotStyle.extraClasses}`}
                  style={{ 
                    left: slotStyle.left, 
                    width: slotStyle.width,
                    transform: slotStyle.transform,
                    zIndex: 5
                  }}
                >
                  <div className="absolute top-0 left-0 w-full text-center text-xs text-purple-400 font-medium overflow-hidden whitespace-nowrap opacity-50 pointer-events-none">
                    {slot.name}
                  </div>
                </div>
              );
            })}
          </div>

          {/* Ligne du jour actuel */}
          <GlobalCurrentDayLine
            startDate={startDate}
            endDate={endDate}
          />

        {/* Contenu des itérations et sprints */}
        <div className="relative space-y-4">
          {iterations.map((iteration) => (
            <div key={iteration.id} className="space-y-2">
              {/* Barre d'itération */}
              <div 
                className="relative h-8"
                onMouseEnter={() => setHoveredItem(iteration)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div
                  className={`absolute h-8 rounded flex items-center justify-between px-2 cursor-move group border ${getTimelineStyles(iteration.start_date, iteration.end_date, 'iteration').extraClasses}`}
                  style={{
                    left: getTimelineStyles(iteration.start_date, iteration.end_date, 'iteration').left,
                    width: getTimelineStyles(iteration.start_date, iteration.end_date, 'iteration').width,
                    transform: getTimelineStyles(iteration.start_date, iteration.end_date, 'iteration').transform
                  }}
                  onMouseDown={(e) => handleMouseDown(e, iteration, 'iteration')}
                >
                  {hoveredItem === iteration && <Tooltip item={iteration} />}
                  <div
                    className="absolute left-0 top-0 w-2 h-full cursor-ew-resize opacity-0 group-hover:opacity-100 bg-blue-300"
                    data-edge="start"
                    onMouseDown={(e) => handleMouseDown(e, iteration, 'iteration', 'resize')}
                  />
                  <span className="text-sm font-medium text-blue-800 truncate flex-grow">
                    {iteration.name}
                  </span>
                  
                  {/* Actions sur l'itération */}
                  <div className="flex items-center space-x-1 opacity-0 group-hover:opacity-100 transition-opacity">
                    {onSprintAdd && (
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          // Appeler avec l'objet iteration complet plutôt que juste l'ID
                          onSprintAdd(iteration);
                        }}
                        className="p-1 text-green-600 hover:text-green-800 hover:bg-green-100 rounded"
                        title="Ajouter un sprint"
                      >
                        <Plus className="h-3 w-3" />
                      </button>
                    )}
                    
                    {onIterationDelete && (
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleDeleteConfirmation('iteration', iteration.id, null, iteration.name);
                        }}
                        className="p-1 text-red-600 hover:text-red-800 hover:bg-red-100 rounded"
                        title="Supprimer l'itération"
                      >
                        <Trash2 className="h-3 w-3" />
                      </button>
                    )}
                  </div>
                  
                  <div
                    className="absolute right-0 top-0 w-2 h-full cursor-ew-resize opacity-0 group-hover:opacity-100 bg-blue-300"
                    data-edge="end"
                    onMouseDown={(e) => handleMouseDown(e, iteration, 'iteration', 'resize')}
                  />
                </div>
              </div>

              {/* Barres des sprints */}
              {iteration.sprints?.map((sprint) => (
                <div 
                  key={sprint.id} 
                  className="relative h-6 ml-4"
                  onMouseEnter={() => setHoveredItem(sprint)}
                  onMouseLeave={() => setHoveredItem(null)}
                >
                  <div
                    className={`absolute h-6 rounded flex items-center justify-between px-2 cursor-move group border ${getTimelineStyles(sprint.start_date, sprint.end_date, 'sprint').extraClasses}`}
                    style={{
                      left: getTimelineStyles(sprint.start_date, sprint.end_date, 'sprint').left,
                      width: getTimelineStyles(sprint.start_date, sprint.end_date, 'sprint').width,
                      transform: getTimelineStyles(sprint.start_date, sprint.end_date, 'sprint').transform
                    }}
                    onMouseDown={(e) => handleMouseDown(e, { ...sprint, iteration_id: iteration.id }, 'sprint')}
                  >
                    {hoveredItem === sprint && <Tooltip item={sprint} />}
                    <div 
                      className="absolute inset-0 bg-indigo-200 opacity-75 rounded-l"
                      style={{ 
                        width: `${getCompletionPercentage(sprint)}%`,
                        transition: 'width 0.3s ease-in-out',
                        background: 'linear-gradient(90deg, rgb(129, 140, 248, 0.6), rgb(99, 102, 241, 0.7))'
                      }} 
                    />
                    <div
                      className="absolute left-0 top-0 w-2 h-full cursor-ew-resize opacity-0 group-hover:opacity-100 bg-indigo-300 z-10"
                      data-edge="start"
                      onMouseDown={(e) => handleMouseDown(e, { ...sprint, iteration_id: iteration.id }, 'sprint', 'resize')}
                    />
                    
                    <span className="text-xs text-indigo-700 truncate relative z-10 flex-grow">
                      {sprint.description}
                    </span>
                    
                    {/* Actions sur le sprint */}
                    {onSprintDelete && (
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleDeleteConfirmation('sprint', sprint.id, iteration.id, sprint.description || sprint.name);
                        }}
                        className="p-1 text-red-600 hover:text-red-800 hover:bg-red-100 rounded opacity-0 group-hover:opacity-100 transition-opacity relative z-10"
                        title="Supprimer le sprint"
                      >
                        <Trash2 className="h-3 w-3" />
                      </button>
                    )}
                    
                    <div
                      className="absolute right-0 top-0 w-2 h-full cursor-ew-resize opacity-0 group-hover:opacity-100 bg-indigo-300 z-10"
                      data-edge="end"
                      onMouseDown={(e) => handleMouseDown(e, { ...sprint, iteration_id: iteration.id }, 'sprint', 'resize')}
                    />
                  </div>
                </div>
              ))}
            </div>
          ))}
            </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default InteractiveTimelineView;