import React from 'react';
import { isWeekend, isFrenchHoliday } from '../../utils/dateUtils';

const DayHeader = ({ date }) => {
  const isWeekendDay = isWeekend(date);
  const isHoliday = isFrenchHoliday(date);
  const baseClasses = "px-4 py-2 text-center w-32";
  
  let headerClasses = baseClasses;
  let tooltip = "";
  
  if (isWeekendDay) {
    headerClasses += " bg-gray-100";
    tooltip = "Weekend";
  } else if (isHoliday) {
    headerClasses += " bg-gray-50";
    tooltip = "Jour férié";
  }
  
  return (
    <th className={headerClasses} title={tooltip}>
      <div className="flex flex-col items-center">
        <span>{date.toLocaleDateString('fr-FR', { weekday: 'short' })}</span>
        <span className="text-xs text-gray-500">
          {date.toLocaleDateString('fr-FR', { day: 'numeric' })}
        </span>
        {isHoliday && (
          <span className="text-xs text-indigo-500 italic mt-1">
            Férié
          </span>
        )}
      </div>
    </th>
  );
};

export default DayHeader;