/* src/services/api.js */
const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
const apiBaseUrl = process.env.NODE_ENV === 'development'
  ? baseUrl
  : `${baseUrl}/api`;

export const apiService = {
  async fetch(endpoint, options = {}) {
    // S'assurer que l'endpoint commence par "/"
    const finalEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
    const token = localStorage.getItem('auth_token');
    const defaultHeaders = {
      'Content-Type': 'application/json',
      ...(token && { 'Authorization': `Bearer ${token}` })
    };

    const response = await fetch(`${apiBaseUrl}${finalEndpoint}`, {
      ...options,
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
    });

    if (!response.ok) {
      // Si la réponse n'est pas ok, on essaye de récupérer l'erreur en JSON
      const error = await response.json().catch(() => ({}));
      throw new Error(error.detail || 'Une erreur est survenue');
    }

    return response.status !== 204 ? response.json() : null;
  },

  get(endpoint) {
    return this.fetch(endpoint);
  },

  post(endpoint, data) {
    return this.fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify(data)
    });
  },

  put(endpoint, data) {
    return this.fetch(endpoint, {
      method: 'PUT',
      body: JSON.stringify(data)
    });
  },

  patch(endpoint, data) {
    return this.fetch(endpoint, {
      method: 'PATCH',
      body: JSON.stringify(data)
    });
  },

  delete(endpoint) {
    return this.fetch(endpoint, {
      method: 'DELETE'
    });
  },

  // Nouvelles méthodes pour le support du streaming

  // Récupérer l'URL de base de l'API
  getBaseUrl() {
    return apiBaseUrl;
  },

  // Récupérer les en-têtes d'authentification
  getHeaders() {
    const token = localStorage.getItem('auth_token');
    if (token) {
      return {
        'Authorization': `Bearer ${token}`
      };
    }
    return {};
  }
};
