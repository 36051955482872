import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CalendarPlus, ArrowLeft, ArrowRight, Calendar } from 'lucide-react';
import SlotsTimeline from './SlotsTimeline';
import { useAuth } from '../Auth';

const apiBaseUrl = process.env.NODE_ENV === 'development' 
  ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
  : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const SlotsManagement = ({ displayMode = "timeline" }) => {
  const [slots, setSlots] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [currentSlot, setCurrentSlot] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { getAuthHeaders } = useAuth(); // Utilisation du hook useAuth

  useEffect(() => {
    fetchSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSlots = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/slots`, { // Utilisation de apiBaseUrl
        headers: getAuthHeaders(), // Ajout des en-têtes d'authentification
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec du chargement des slots');
      }
      const data = await response.json();
      const sortedSlots = Array.isArray(data) ? [...data].sort((a, b) => new Date(a.start_date) - new Date(b.start_date)) : [];
      setSlots(sortedSlots);
      
      // Trouver le slot actuel (où la date actuelle se situe entre start_date et end_date)
      if (sortedSlots.length > 0) {
        const now = new Date();
        const current = sortedSlots.find(slot => {
          const startDate = new Date(slot.start_date);
          const endDate = new Date(slot.end_date);
          return now >= startDate && now <= endDate;
        });
        
        // Si un slot actuel est trouvé, le définir comme currentSlot
        if (current) {
          setCurrentSlot(current);
        } else {
          // Si aucun slot actuel, prendre le prochain slot à venir
          const nextSlot = sortedSlots.find(slot => new Date(slot.start_date) > now);
          if (nextSlot) {
            setCurrentSlot(nextSlot);
          } else {
            // Si pas de slot futur, prendre le plus récent
            setCurrentSlot(sortedSlots[sortedSlots.length - 1]);
          }
        }
      }
    } catch (err) {
      setError(err.message || 'Échec du chargement des slots');
      setSlots([]);
      setTimeout(() => setError(null), 3000);
    }
  };

  useEffect(() => {
    fetch(`${apiBaseUrl}/employees`, {
      headers: getAuthHeaders()
    })
      .then(response => {
        if (!response.ok) {
          return response.json().then(err => { throw new Error(err.detail || 'Erreur lors du chargement des employés'); });
        }
        return response.json();
      })
      .then(data => setEmployees(Array.isArray(data) ? data : []))
      .catch(err => {
        console.error(err);
      });
  }, [apiBaseUrl, getAuthHeaders]);  

  const getDefaultSlotDates = () => {
    const lastSlot = slots[slots.length - 1];
    let startDate;

    if (lastSlot) {
      startDate = new Date(lastSlot.end_date);
      startDate.setDate(startDate.getDate() + 1);
    } else {
      startDate = new Date();
    }

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 20); // 3 semaines par défaut

    return {
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0],
    };
  };

  const handleQuickSlotAdd = async () => {
    const dates = getDefaultSlotDates();
    const existingNumbers = slots.map((slot) => {
      const match = slot.name.match(/Slot (\d+)/);
      return match ? parseInt(match[1], 10) : 0;
    });
    const nextSlotNumber = Math.max(0, ...existingNumbers) + 1;

    try {
      const response = await fetch(`${apiBaseUrl}/slots`, { // Utilisation de apiBaseUrl
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(), // Ajout des en-têtes d'authentification
        },
        body: JSON.stringify({
          name: `Slot ${nextSlotNumber}`,
          start_date: dates.start_date,
          end_date: dates.end_date,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de l\'ajout du slot');
      }

      const newSlot = await response.json();
      setSlots((prev) => [...prev, newSlot]);
      setSuccess('Slot ajouté avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de l\'ajout du slot');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleDeleteSlot = async (slotId) => {
    try {
      const response = await fetch(`${apiBaseUrl}/slots/${slotId}`, { // Utilisation de apiBaseUrl
        method: 'DELETE',
        headers: getAuthHeaders(), // Ajout des en-têtes d'authentification
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression du slot');
      }

      setSlots((prev) => prev.filter((slot) => slot.id !== slotId));
      setSuccess('Slot supprimé avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression du slot');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleSlotUpdate = async (slotId, updatedSlot) => {
    try {
      const response = await fetch(`${apiBaseUrl}/slots/${slotId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(),
        },
        body: JSON.stringify(updatedSlot),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour du slot');
      }

      const updatedSlotData = await response.json();

      setSlots((prev) =>
        prev.map((slot) =>
          slot.id === slotId ? { ...slot, ...updatedSlotData } : slot
        )
      );

      setSuccess('Slot mis à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour du slot');
      setTimeout(() => setError(null), 3000);
    }
  };

  const goToNextSlot = () => {
    if (!currentSlot || slots.length <= 1) return;
    
    const currentIndex = slots.findIndex(slot => slot.id === currentSlot.id);
    if (currentIndex < slots.length - 1) {
      navigate(`/slot/${slots[currentIndex + 1].id}`);
    }
  };

  const goToPreviousSlot = () => {
    if (!currentSlot || slots.length <= 1) return;
    
    const currentIndex = slots.findIndex(slot => slot.id === currentSlot.id);
    if (currentIndex > 0) {
      navigate(`/slot/${slots[currentIndex - 1].id}`);
    }
  };

  const goToSlotTimeline = () => {
    navigate('/slots');
  };

  // Rediriger vers le slot actuel si le mode est "current"
  useEffect(() => {
    if (displayMode === "current" && currentSlot) {
      navigate(`/slot/${currentSlot.id}`);
    }
  }, [currentSlot, navigate, displayMode]);

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Gestion des Slots</h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={goToPreviousSlot}
            className="text-gray-600 hover:text-gray-900"
            title="Slot précédent"
            disabled={!currentSlot || slots.findIndex(slot => slot.id === currentSlot.id) === 0}
          >
            <ArrowLeft className="h-5 w-5" />
          </button>
          
          <button
            onClick={goToSlotTimeline}
            className="text-gray-600 hover:text-gray-900"
            title="Timeline des slots"
          >
            <Calendar className="h-5 w-5" />
          </button>
          
          <button
            onClick={goToNextSlot}
            className="text-gray-600 hover:text-gray-900"
            title="Slot suivant"
            disabled={!currentSlot || slots.findIndex(slot => slot.id === currentSlot.id) === slots.length - 1}
          >
            <ArrowRight className="h-5 w-5" />
          </button>
          
          <button
            onClick={handleQuickSlotAdd}
            className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600 flex items-center"
          >
            <CalendarPlus className="h-4 w-4 mr-2" />
            Nouveau Slot
          </button>
        </div>
      </div>

      {/* Timeline */}
      {slots.length > 0 ? (
        <SlotsTimeline
          slots={slots}
          employees={employees}
          onSlotUpdate={handleSlotUpdate}
          setSlots={setSlots}
          onDeleteSlot={handleDeleteSlot}
        />
      ) : (
        <div className="bg-white shadow rounded-lg p-6 text-center text-gray-500">
          Aucun slot trouvé. Cliquez sur "Nouveau Slot" pour en créer un.
        </div>
      )}
    </div>
  );
};

export default SlotsManagement;