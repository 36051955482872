import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Users, FolderKanban, Calendar, Clock, Building2, FileText, Clipboard, LogOut, BarChart3 } from "lucide-react";
import { useAuth } from './Auth';
import InnowideLogo from './InnowideLogo';

const Navigation = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const location = useLocation();

  return (
    <nav className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          <div className="flex-shrink-0">
            <InnowideLogo />
          </div>

          <div className="hidden md:flex space-x-4">
            <button
              onClick={() => navigate('/dashboard')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/dashboard') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <BarChart3 className="h-4 w-4 mr-2" />
              Dashboard
            </button>
            <button
              onClick={() => navigate('/employees')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/employees') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <Users className="h-4 w-4 mr-2" />
              Employés
            </button>
            <button
              onClick={() => navigate('/projects')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/projects') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <FolderKanban className="h-4 w-4 mr-2" />
              Projets
            </button>
            <button
              onClick={() => navigate("/tasks")}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/tasks') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <Clipboard className="h-4 w-4 mr-2" />
              Tâches
            </button>
            <button
              onClick={() => navigate('/slots/current')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/slot') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <Calendar className="h-4 w-4 mr-2" />
              Slots
            </button>
            <button
              onClick={() => navigate('/hours')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/hours') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <Clock className="h-4 w-4 mr-2" />
              Heures
            </button>
            <button
              onClick={() => navigate('/clients')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/clients') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <Building2 className="h-4 w-4 mr-2" />
              Clients
            </button>
            <button
              onClick={() => navigate('/factures')}
              className={`flex items-center px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-200 
                ${location.pathname.startsWith('/factures') ? "bg-gray-200 text-blue-600" : "text-gray-900"}`}
            >
              <FileText className="h-4 w-4 mr-2" />
              Facture
            </button>
          </div>

          {/* User Profile Menu */}
          <div className="relative">
            <button
              onClick={() => setShowUserMenu(!showUserMenu)}
              className="flex items-center space-x-3 focus:outline-none"
            >
              <img
                src={user?.picture || 'https://via.placeholder.com/40'}
                alt={user?.name}
                className="h-8 w-8 rounded-full"
              />
              <span className="hidden md:block text-sm font-medium text-gray-700">
                {user?.name}
              </span>
            </button>

            {/* Dropdown menu */}
            {showUserMenu && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <div className="px-4 py-2 text-sm text-gray-700 border-b">
                    <div className="font-medium">{user?.name}</div>
                    <div className="text-gray-500 text-xs truncate">
                      {user?.email}
                    </div>
                  </div>
                  <button
                    onClick={logout}
                    className="flex w-full items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <LogOut className="h-4 w-4 mr-2" />
                    Se déconnecter
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;