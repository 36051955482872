import React, { useState, useEffect, useRef } from 'react';
import { apiService } from '../../services/api';
import ChatBubble from './ChatBubble';
import ChatInput from './ChatInput';
import { useAuth } from '../Auth';

const ChatBot = () => {
  const { user } = useAuth(); // Récupérer les infos de l'utilisateur connecté
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState('nexus');
  const [typingMessage, setTypingMessage] = useState('');
  const [typingActivity, setTypingActivity] = useState('');
  const [currentAgent, setCurrentAgent] = useState('nexus');
  const messagesEndRef = useRef(null);
  const eventSourceRef = useRef(null);

  // Dans cette version simplifiée, nous n'utilisons que Nexus
  const agents = [
    { id: 'nexus', name: 'Nexus', description: 'Assistant IA' },
  ];

  // Scroll automatique vers le bas lors de nouveaux messages
  useEffect(() => {
    scrollToBottom();
  }, [messages, typingMessage]);

  // Nettoyer EventSource à la déconnexion
  useEffect(() => {
    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Ouvrir/fermer le chat
  const toggleChat = () => {
    setIsOpen(!isOpen);
    // Si on ouvre pour la première fois, afficher un message de bienvenue
    if (!isOpen && messages.length === 0) {
      setMessages([
        {
          content: "Bonjour ! Je suis Nexus, l'assistant IA de DeepFlow. Comment puis-je t'aider aujourd'hui ?",
          role: 'assistant',
          agent: 'nexus'
        }
      ]);
    }
  };

  // Changer l'agent
  const changeAgent = (agentId) => {
    setSelectedAgent(agentId);
    setMessages([
      ...messages,
      {
        content: `Vous parlez maintenant avec ${agents.find(a => a.id === agentId).name}.`,
        role: 'system',
        agent: agentId
      }
    ]);
  };

  // Envoyer un message avec streaming
  const streamMessage = async (message) => {
    if (!message.trim()) return;

    // Ajouter le message de l'utilisateur
    setMessages(prev => [
      ...prev,
      { content: message, role: 'user', agent: selectedAgent }
    ]);

    // Initialiser le message en cours de frappe
    setTypingMessage('');
    setTypingActivity('');
    setCurrentAgent('nexus');
    setLoading(true);

    try {
      // Fermer tout EventSource existant
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      // Créer une URL avec les paramètres pour l'API
      const apiUrl = `${apiService.getBaseUrl()}/chat/stream`;
      
      // Créer un EventSource
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...apiService.getHeaders()
        },
        body: JSON.stringify({
          message
        })
      };

      // Faire une demande fetch et utiliser la réponse en streaming
      const response = await fetch(apiUrl, fetchOptions);
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      // Fonction pour traiter les données reçues
      const processStreamData = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            // Ajouter le nouveau contenu au buffer
            buffer += decoder.decode(value, { stream: true });

            // Traiter les événements complets dans le buffer
            const lines = buffer.split('\n\n');
            buffer = lines.pop() || ''; // Garder la dernière ligne incomplète

            // Traiter chaque ligne complète
            for (const line of lines) {
              if (line.startsWith('data: ')) {
                try {
                  const eventData = JSON.parse(line.substring(6));
                  handleStreamEvent(eventData);
                } catch (e) {
                  console.error('Erreur de parsing JSON:', e);
                }
              }
            }
          }
        } catch (err) {
          console.error('Erreur de streaming:', err);
          setTypingMessage('');
          setLoading(false);
        }
      };

      processStreamData();
    } catch (error) {
      console.error('Erreur lors du streaming:', error);
      setTypingMessage('');
      setLoading(false);
      // Ajouter un message d'erreur
      setMessages(prev => [
        ...prev,
        { 
          content: "Désolé, j'ai rencontré une erreur lors du traitement de votre message. Veuillez réessayer.", 
          role: 'assistant', 
          agent: selectedAgent,
          isError: true
        }
      ]);
    }
  };

  // Gérer les événements du stream
  const handleStreamEvent = (eventData) => {
    const { event, data } = eventData;

    switch (event) {
      case 'token':
        // Ajouter un token au message en cours de frappe
        setTypingMessage(prev => prev + data.token);
        if (data.agent) {
          setCurrentAgent(data.agent);
        }
        break;

      case 'agent_change':
        // L'agent a changé
        setCurrentAgent(data.to_agent);
        if (data.activity) {
          setTypingActivity(data.activity);
        }
        break;

      case 'activity':
        // Mise à jour de l'activité
        setTypingActivity(data.activity);
        if (data.agent) {
          setCurrentAgent(data.agent);
        }
        break;

      case 'completed':
        // Le message est complet
        setLoading(false);
        setTypingMessage('');
        setTypingActivity('');
        
        // Ajouter le message final
        setMessages(prev => [
          ...prev,
          { 
            content: data.message, 
            role: 'assistant', 
            agent: data.agent || currentAgent 
          }
        ]);
        break;

      case 'error':
        // Une erreur s'est produite
        console.error('Erreur de streaming:', data.message);
        setLoading(false);
        setTypingMessage('');
        setTypingActivity('');
        
        // Ajouter un message d'erreur
        setMessages(prev => [
          ...prev,
          { 
            content: "Désolé, j'ai rencontré une erreur lors du traitement de votre message. Veuillez réessayer.", 
            role: 'assistant', 
            agent: currentAgent,
            isError: true
          }
        ]);
        break;

      default:
        console.log('Événement non traité:', event, data);
    }
  };

  // Envoyer un message (non-streaming, gardé pour compatibilité)
  const sendMessage = async (message) => {
    // Utiliser la version streaming par défaut
    return streamMessage(message);
    
    /* Version originale non-streaming
    if (!message.trim()) return;

    // Ajouter le message de l'utilisateur
    setMessages(prev => [
      ...prev,
      { content: message, role: 'user', agent: selectedAgent }
    ]);

    // Montrer l'indicateur de chargement
    setLoading(true);

    try {
      // Appeler l'API
      const response = await apiService.post('/chat/message', {
        message,
        agent: selectedAgent
      });

      // Ajouter la réponse de l'assistant
      setMessages(prev => [
        ...prev,
        { 
          content: response.message, 
          role: 'assistant', 
          agent: response.agent 
        }
      ]);
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message:', error);
      // Ajouter un message d'erreur
      setMessages(prev => [
        ...prev,
        { 
          content: "Désolé, j'ai rencontré une erreur lors du traitement de votre message. Veuillez réessayer.", 
          role: 'assistant', 
          agent: selectedAgent,
          isError: true
        }
      ]);
    } finally {
      setLoading(false);
      
      // Scroll au bas du chat après avoir obtenu la réponse
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
    */
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Bouton de chat flottant modernisé */}
      <button
        onClick={toggleChat}
        className="bg-gradient-to-r from-blue-600 to-indigo-700 hover:from-blue-700 hover:to-indigo-800 text-white rounded-full w-14 h-14 flex items-center justify-center shadow-lg focus:outline-none transform transition-all duration-300 hover:scale-105"
      >
        {isOpen ? (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <img src="/Nexus_icon.png" alt="Nexus" className="h-8 w-8" />
        )}
      </button>

      {/* Fenêtre de chat */}
      {isOpen && (
        <div className="absolute bottom-16 right-0 bg-white rounded-lg shadow-xl w-[30rem] max-h-[40rem] flex flex-col overflow-hidden">
          {/* En-tête simplifié */}
          <div className="bg-gradient-to-r from-blue-600 to-indigo-700 text-white px-4 py-3 flex justify-between items-center">
            <div className="flex items-center">
              <img src="/Nexus_icon.png" alt="Nexus" className="w-6 h-6 mr-2" />
              <h3 className="font-medium text-lg">Nexus</h3>
            </div>
          </div>

          {/* Corps du chat (messages) */}
          <div className="flex-1 p-4 overflow-y-auto bg-gradient-to-b from-gray-50 to-white">
            {messages.map((msg, index) => (
              <ChatBubble key={index} message={msg} />
            ))}
            
            {/* Message en cours de frappe */}
            {loading && (
              <>
                {typingActivity && (
                  <div className="flex justify-center my-2">
                    <div className="bg-blue-100 text-blue-700 font-bold text-sm px-3 py-1 rounded-full border border-blue-300 shadow-sm">
                      {typingActivity}
                    </div>
                  </div>
                )}
                
                {typingMessage && (
                  <div className="flex items-start gap-2 mb-4">
                    <div className="w-8 h-8 rounded-full flex items-center justify-center bg-white">
                      <img src="/Nexus_icon.png" alt="Nexus" className="w-7 h-7" />
                    </div>
                    
                    <div className="py-2 px-3 rounded-lg shadow-sm max-w-[80%] bg-indigo-100 text-indigo-800">
                      <div className="prose prose-sm max-w-none break-words">
                        {typingMessage}
                        <span className="inline-block animate-pulse ml-1">▋</span>
                      </div>
                    </div>
                  </div>
                )}
                
                {!typingMessage && !typingActivity && (
                  <div className="flex justify-center my-2">
                    <div className="animate-pulse flex space-x-1">
                      <div className="h-2 w-2 bg-gray-400 rounded-full"></div>
                      <div className="h-2 w-2 bg-gray-400 rounded-full"></div>
                      <div className="h-2 w-2 bg-gray-400 rounded-full"></div>
                    </div>
                  </div>
                )}
              </>
            )}
            
            <div ref={messagesEndRef} />
          </div>

          {/* Saisie de message */}
          <ChatInput onSendMessage={sendMessage} isLoading={loading} />
        </div>
      )}
    </div>
  );
};

export default ChatBot;