import React, { useState, useEffect } from 'react';
import { Clock } from 'lucide-react';
import { useAuth } from '../Auth';
import { getWeekDates } from '../../utils/dateUtils';
import WeekNavigation from './WeekNavigation';
import ProjectsTable from './ProjectsTable';

const apiBaseUrl = process.env.NODE_ENV === 'development' 
  ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
  : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const HoursManagement = () => {
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [projects, setProjects] = useState([]);
  const [slots, setSlots] = useState([]);
  const [hours, setHours] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [needsSave, setNeedsSave] = useState(false);
  const [showOtherProjects, setShowOtherProjects] = useState(false);
  const [activeProjects, setActiveProjects] = useState([]);
  const [inactiveProjects, setInactiveProjects] = useState([]);
  const [tasksByProject, setTasksByProject] = useState({});
  const [currentSlot, setCurrentSlot] = useState(null);
  const [plannedHoursByProject, setPlannedHoursByProject] = useState({});
  const [slotHoursByProject, setSlotHoursByProject] = useState({});

  const { user, getAuthHeaders } = useAuth();
  const [employee, setEmployee] = useState(null);
  
  // Détermine quels projets sont actifs pour la semaine sélectionnée
  const determineActiveProjects = (slotWithDetails, allProjects, selectedWeek) => {
    console.log("Determining active projects with:", { slotWithDetails, allProjects, selectedWeek });
    
    const activeProjectIds = new Set();

    if (slotWithDetails?.sprints?.length > 0) {
      console.log("Processing sprints:", slotWithDetails.sprints);
      
      slotWithDetails.sprints.forEach(sprint => {
        console.log("Processing sprint:", sprint);
        // Nous avons project_name dans les détails du sprint
        const projectName = sprint.project_name;
        if (projectName) {
          const matchingProject = allProjects.find(p => p.name === projectName);
          console.log("Looking for project with name:", projectName, "Found:", matchingProject);
          if (matchingProject) {
            activeProjectIds.add(matchingProject.id);
          }
        }
      });

      console.log("Active project IDs:", activeProjectIds);
    }

    // Séparer les projets en actifs et inactifs
    const active = allProjects.filter(project => activeProjectIds.has(project.id));
    const inactive = allProjects.filter(project => !activeProjectIds.has(project.id));

    console.log("Final split:", { active, inactive });
    
    return { active, inactive };
  };

  // Charger les données initiales
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Charger les employés
        const employeesResponse = await fetch(`${apiBaseUrl}/employees`, { // Utilisation de apiBaseUrl
          headers: getAuthHeaders(),
        });
        if (!employeesResponse.ok) {
          const errorData = await employeesResponse.json();
          throw new Error(errorData.detail || 'Échec du chargement des employés');
        }
        const employeesData = await employeesResponse.json();
        
        // Trouver l'employé correspondant à l'utilisateur connecté
        const matchingEmployee = employeesData.find(emp => emp.email === user.email);
        if (!matchingEmployee) {
          throw new Error('Aucun employé correspondant trouvé');
        }
        setEmployee(matchingEmployee);

        // Charger les projets
        const projectsResponse = await fetch(`${apiBaseUrl}/projects`, { // Utilisation de apiBaseUrl
          headers: getAuthHeaders(), // Ajout des en-têtes d'authentification
        });
        if (!projectsResponse.ok) {
          const errorData = await projectsResponse.json();
          throw new Error(errorData.detail || 'Échec du chargement des projets');
        }
        const projectsData = await projectsResponse.json();
        setProjects(Array.isArray(projectsData) ? projectsData : []);

        // Charger le slot qui contient la semaine actuelle
        const monday = getMonday(selectedWeek);
        const slotsResponse = await fetch(`${apiBaseUrl}/slots`, { // Utilisation de apiBaseUrl
          headers: getAuthHeaders(), // Ajout des en-têtes d'authentification
        });
        if (!slotsResponse.ok) {
          const errorData = await slotsResponse.json();
          throw new Error(errorData.detail || 'Échec du chargement des slots');
        }
        const slotsData = await slotsResponse.json();
        
        // Trouver le slot qui contient la semaine sélectionnée
        const relevantSlot = slotsData.find(slot => 
          monday >= new Date(slot.start_date) && monday <= new Date(slot.end_date)
        );

        let slotWithDetails = null;
        if (relevantSlot) {
          // Charger le slot avec ses détails complets (incluant les sprints)
          const slotDetailsResponse = await fetch(`${apiBaseUrl}/slots/${relevantSlot.id}`, { // Utilisation de apiBaseUrl
            headers: getAuthHeaders(), // Ajout des en-têtes d'authentification
          });
          if (!slotDetailsResponse.ok) {
            const errorData = await slotDetailsResponse.json();
            throw new Error(errorData.detail || 'Échec du chargement des détails du slot');
          }
          slotWithDetails = await slotDetailsResponse.json();
          console.log("Slot details loaded:", slotWithDetails);
        }

        setSlots(slotWithDetails ? [slotWithDetails] : []);
        setCurrentSlot(slotWithDetails);

        // Déterminer les projets actifs/inactifs
        if (slotWithDetails) {
          const { active, inactive } = determineActiveProjects(slotWithDetails, projectsData, selectedWeek);
          setActiveProjects(active);
          setInactiveProjects(inactive);
        } else {
          setActiveProjects([]);
          setInactiveProjects(Array.isArray(projectsData) ? projectsData : []);
        }

      } catch (err) {
        console.error('Erreur lors du chargement des données:', err);
        setError(err.message || "Erreur lors du chargement des données");
        setActiveProjects([]);
        setInactiveProjects([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeek]);

  // Charger les tâches de l'employé pour les projets actifs dans le slot
  const loadEmployeeTasks = async (employeeId, slot) => {
    if (!employeeId || !slot || !slot.sprints || slot.sprints.length === 0) return;
    
    try {
      // Récupérer tous les ID de projets uniques à partir des sprints
      // Convertir tous les IDs en nombres pour éviter les problèmes de type
      const projectIds = [...new Set(slot.sprints.map(sprint => Number(sprint.project_id)))];
      const sprintIds = slot.sprints.map(sprint => Number(sprint.id));
      
      // Créer un objet pour stocker les tâches par projet
      const tasksData = {};
      
      // Pour chaque projet, nous récupérons les tâches
      await Promise.all(projectIds.map(async (projectId) => {
        try {
          console.log(`Fetching tasks for project ${projectId}`);
          const tasksResponse = await fetch(`${apiBaseUrl}/projects/${projectId}/tasks`, {
            headers: getAuthHeaders(),
          });
          
          if (tasksResponse.ok) {
            const projectTasks = await tasksResponse.json();
            
            // Filtrer les tâches pour n'inclure que celles qui:
            // 1. Sont dans les sprints du slot actuel
            // 2. Sont assignées à l'employé actuel
            console.log('Project tasks before filtering:', projectTasks);
            console.log('Sprint IDs for current slot:', sprintIds);
            console.log('Employee ID:', employeeId);
            
            const employeeTasks = projectTasks.filter(task => {
              // Convertir en nombres pour la comparaison (les IDs peuvent être des strings ou des nombres)
              const inSprint = sprintIds.some(id => Number(id) === Number(task.sprint_id));
              const isOwner = Number(task.owner_id) === Number(employeeId);
              console.log(`Task ${task.id}: inSprint=${inSprint}, isOwner=${isOwner}`);
              return inSprint && isOwner;
            });
            
            // Stocker les tâches filtrées par projet
            if (employeeTasks.length > 0) {
              tasksData[projectId] = employeeTasks;
            }
          }
        } catch (error) {
          console.error(`Error fetching tasks for project ${projectId}:`, error);
        }
      }));
      
      setTasksByProject(tasksData);
      
      // Calculer les heures planifiées basées sur les tokens des tâches
      // Vérifier que la vélocité est définie et est un nombre valide
      console.log("Slot complet:", slot);
      console.log("Propriétés du slot:", Object.keys(slot));
      
      // Vérifier toutes les propriétés qui pourraient contenir la vélocité
      let slotVelocityValue = null;
      if (slot.velocity !== undefined) slotVelocityValue = slot.velocity;
      else if (slot.token_velocity !== undefined) slotVelocityValue = slot.token_velocity;
      
      // Si on n'a pas trouvé la vélocité, tester une requête spécifique
      if (slotVelocityValue === null) {
        console.log("Tentative de récupération de la vélocité depuis l'API...");
        try {
          // Tenter de récupérer la vélocité depuis l'endpoint workload
          const workloadResponse = await fetch(`${apiBaseUrl}/slots/${slot.id}/workload`, {
            headers: getAuthHeaders(),
          });
          
          if (workloadResponse.ok) {
            const workloadData = await workloadResponse.json();
            console.log("Workload data:", workloadData);
            
            if (workloadData.velocity !== undefined) {
              slotVelocityValue = workloadData.velocity;
              console.log("Vélocité trouvée dans workload:", slotVelocityValue);
            }
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données de workload:", error);
        }
      }
      
      // Réessayer de trouver la vélocité dans les données brutes du slot
      if (slotVelocityValue === null) {
        // Tester avec une valeur préconfigurée pour Vérin et Outil de gestion de projet
        // Cette valeur est hardcodée temporairement pour tester
        slotVelocityValue = 3.5; // Valeur temporaire basée sur les nombres que vous avez donnés
        console.log("Utilisation d'une vélocité temporaire de 3.5 pour le test");
      }
      
      // Par défaut, utiliser 10 comme vélocité standard
      const velocity = typeof slotVelocityValue === 'number' ? slotVelocityValue : 
                     typeof slotVelocityValue === 'string' ? parseFloat(slotVelocityValue) : 10;
                     
      console.log("Slot velocity raw value:", slotVelocityValue, "Final velocity used:", velocity);
      
      if (velocity) {
        // Constante de conversion jours → heures : 7,8h/jour
        const plannedHours = {};
        
        Object.entries(tasksData).forEach(([projectId, tasks]) => {
          // Calculer le total des tokens pour ce projet
          const totalTokens = tasks.reduce((sum, task) => {
            console.log('Task:', task);
            // Vérifier que l'effort est un nombre valide
            const effort = typeof task.effort === 'number' ? task.effort : 
                           typeof task.effort === 'string' ? parseFloat(task.effort) : 0;
            console.log(`Task ${task.id}: effort=${task.effort}, parsed=${effort}`);
            return sum + effort;
          }, 0);
          
          // Afficher les tokens pour vérification
          console.log(`Project ${projectId}: tokens totaux = ${totalTokens}`);
          
          // Convertir les tokens en jours basé sur la vélocité (tokens par jour)
          const days = totalTokens / velocity;
          console.log(`Project ${projectId}: tokens=${totalTokens} / velocity=${velocity} = ${days} jours`);
          
          // Convertir les jours en heures (7,8 heures par jour standard)
          const hoursConverted = days * 7.8;
          console.log(`Project ${projectId}: ${days} jours * 7.8 heures/jour = ${hoursConverted} heures`);
          
          // Arrondir à 1 décimale
          plannedHours[projectId] = Math.round(hoursConverted * 10) / 10;
          
          // Afficher les jours pour vérification
          console.log(`Project ${projectId}: ${days} jours ⟹ ${plannedHours[projectId]} heures`)
          
          console.log('Project ' + projectId + ': tokens=' + totalTokens + ', days=' + days + ', hours=' + plannedHours[projectId]);
        });
        
        console.log("Tasks by project:", tasksData);
        console.log("Planned hours calculated:", plannedHours);
        console.log("Slot velocity:", slot.velocity);
        
        setPlannedHoursByProject(plannedHours);
      }
    } catch (error) {
      console.error('Error loading employee tasks:', error);
    }
  };

  // Charger les heures de l'employé pour l'ensemble du slot
  const loadEmployeeSlotHours = async (employeeId, slot) => {
    if (!employeeId || !slot) return;
    
    try {
      const startDate = new Date(slot.start_date).toISOString().split('T')[0];
      const endDate = new Date(slot.end_date).toISOString().split('T')[0];
      
      // Charger les heures pour la période complète du slot
      const response = await fetch(
        `${apiBaseUrl}/hours/employee/${employeeId}?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: getAuthHeaders(),
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec du chargement des heures du slot');
      }
      
      const data = await response.json();
      
      // Calculer le total des heures par projet sur l'ensemble du slot
      const totalsByProject = {};
      
      Object.entries(data.hours_by_day).forEach(([date, projectHours]) => {
        Object.entries(projectHours).forEach(([projectId, hours]) => {
          if (!totalsByProject[projectId]) {
            totalsByProject[projectId] = 0;
          }
          totalsByProject[projectId] += hours;
        });
      });
      
      // Mettre à jour le state avec les totaux par projet
      setSlotHoursByProject(totalsByProject);
      console.log("Heures totales par projet sur le slot:", totalsByProject);
      
    } catch (error) {
      console.error('Erreur lors du chargement des heures du slot:', error);
    }
  };

  // Effet pour charger les tâches quand l'employé ou le slot change
  useEffect(() => {
    if (employee?.id && currentSlot) {
      console.log("Loading tasks and hours for slot:", currentSlot);
      loadEmployeeTasks(employee.id, currentSlot);
      loadEmployeeSlotHours(employee.id, currentSlot);
    }
  }, [employee?.id, currentSlot]);

  // Charger les heures de l'employé pour la semaine sélectionnée
  const loadEmployeeHours = async (employeeId, weekStart) => {
    if (!employeeId) return;
    
    try {
      const startDate = getMonday(weekStart).toISOString().split('T')[0];
      const endDate = new Date(getMonday(weekStart));
      endDate.setDate(endDate.getDate() + 6);
      const endDateStr = endDate.toISOString().split('T')[0];
      
      const response = await fetch(
        `${apiBaseUrl}/hours/employee/${employeeId}?start_date=${startDate}&end_date=${endDateStr}`, // Utilisation de apiBaseUrl
        {
          headers: getAuthHeaders(), // Ajout des en-têtes d'authentification
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec du chargement des heures');
      }
      
      const data = await response.json();
      
      // Convertir les données dans le format attendu
      const newHours = { [employeeId]: {} };
      
      Object.entries(data.hours_by_day).forEach(([date, projectHours]) => {
        Object.entries(projectHours).forEach(([projectId, hours]) => {
          if (!newHours[employeeId][projectId]) {
            newHours[employeeId][projectId] = {};
          }
          newHours[employeeId][projectId][date] = hours;
        });
      });
      
      setHours(prev => ({
        ...prev,
        ...newHours
      }));
    } catch (error) {
      console.error('Erreur lors du chargement des heures:', error);
      setError(error.message || 'Échec du chargement des heures');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Effet pour charger les heures quand l'employé ou la semaine change
  useEffect(() => {
    if (employee?.id) {
      loadEmployeeHours(employee.id, selectedWeek);
    }
  }, [employee?.id, selectedWeek]);

  // Fonction pour obtenir le lundi d'une semaine donnée
  const getMonday = (d) => {
    const date = new Date(d);
    const day = date.getDay();
    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(date.setDate(diff));
  };

  // Changer de semaine
  const changeWeek = (direction) => {
    const newDate = new Date(selectedWeek);
    newDate.setDate(newDate.getDate() + (direction === 'next' ? 7 : -7));
    setSelectedWeek(newDate);
  };

  const saveWorkloadData = async () => {
    if (!employee) return;
  
    try {
      const monday = getMonday(selectedWeek);
      const weekData = {
        employee_id: employee.id,
        week_start: monday.toISOString().split('T')[0],
        hours_by_day: {}
      };

      // Récupérer toutes les entrées de la semaine
      const weekDates = getWeekDates(monday);
      weekDates.forEach(weekDate => {
        const weekDateStr = weekDate.toISOString().split('T')[0];
        weekData.hours_by_day[weekDateStr] = {};
        projects.forEach(proj => {
          const hours = getHours(employee.id, proj.id, weekDate);
          if (hours > 0) {
            weekData.hours_by_day[weekDateStr][proj.id] = hours;
          }
        });
      });

      const response = await fetch(
        `${apiBaseUrl}/hours/employee/${employee.id}`, // Utilisation de apiBaseUrl
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(), // Ajout des en-têtes d'authentification
          },
          body: JSON.stringify(weekData),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Erreur détaillée:', errorData);
        throw new Error(errorData.detail || 'Échec de la sauvegarde des heures');
      }

      setSuccess('Heures sauvegardées avec succès');
      setTimeout(() => setSuccess(null), 3000);
      
      // Mettre à jour les heures du slot après sauvegarde
      if (currentSlot) {
        loadEmployeeSlotHours(employee.id, currentSlot);
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des heures:', error);
      setError(error.message || 'Échec de la sauvegarde des heures');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Gérer le changement d'heures
  const handleHourChange = (projectId, date, value) => {
    if (!employee) return;
    
    const dateStr = date.toISOString().split('T')[0];
    const newValue = Math.max(0, Math.min(24, Number(value) || 0));
    
    setHours(prev => ({
      ...prev,
      [employee.id]: {
        ...prev[employee.id],
        [projectId]: {
          ...prev[employee.id]?.[projectId],
          [dateStr]: newValue
        }
      }
    }));
  
    setNeedsSave(true);
  };

  // Gérer la perte de focus
  const handleBlur = () => {
    if (needsSave) {
      saveWorkloadData();
      setNeedsSave(false);
    }
  };

  const getHours = (employeeId, projectId, date) => {
    const dateStr = date.toISOString().split('T')[0];
    return hours[employeeId]?.[projectId]?.[dateStr] || 0;
  };

  // Calculer le total des heures par jour
  const getDayTotal = (date) => {
    if (!employee) return 0;
    const dateStr = date.toISOString().split('T')[0];
    return Object.values(hours[employee.id] || {}).reduce((total, project) => {
      return total + (project[dateStr] || 0);
    }, 0);
  };

  // Calculer le total des heures par projet
  const getProjectTotal = (projectId) => {
    if (!employee) return 0;
    return Object.values(hours[employee.id]?.[projectId] || {}).reduce((total, hours) => {
      return total + (hours || 0);
    }, 0);
  };

  // Sauvegarder automatiquement lorsque needsSave est true
  useEffect(() => {
    if (!needsSave) return;
    
    const timeoutId = setTimeout(() => {
      saveWorkloadData();
      setNeedsSave(false);
    }, 1000);
    
    return () => clearTimeout(timeoutId);
  }, [needsSave]);

  if (loading) {
    return <div className="text-center p-4">Chargement...</div>;
  }

  if (error) {
    return <div className="text-red-600 p-4">{error}</div>;
  }

  const monday = getMonday(selectedWeek);
  const weekDates = getWeekDates(monday);

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          Gestion des Heures
        </h2>
        
        {employee ? (
          <div className="flex items-center space-x-2">
            <span className="text-gray-600">Connecté en tant que :</span>
            <span className="font-medium">{employee.name}</span>
          </div>
        ) : (
          <div className="text-yellow-600">
            Recherche de l'employé correspondant...
          </div>
        )}
      </div>

      {/* Navigation des semaines */}
      <WeekNavigation 
        monday={monday} 
        changeWeek={changeWeek} 
      />

      {/* Tableau des heures */}
      <ProjectsTable 
        weekDates={weekDates}
        activeProjects={activeProjects}
        inactiveProjects={inactiveProjects}
        showOtherProjects={showOtherProjects}
        setShowOtherProjects={setShowOtherProjects}
        getHours={getHours}
        handleHourChange={handleHourChange}
        handleBlur={handleBlur}
        employee={employee}
        getProjectTotal={getProjectTotal}
        getDayTotal={getDayTotal}
        slotHoursByProject={slotHoursByProject}
        plannedHoursByProject={plannedHoursByProject}
      />
    </div>
  );
};

export default HoursManagement;