import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const WeekNavigation = ({ monday, changeWeek }) => {
  return (
    <div className="flex items-center justify-between mb-6">
      <button
        onClick={() => changeWeek('prev')}
        className="p-2 hover:bg-gray-100 rounded-full"
      >
        <ChevronLeft className="h-5 w-5" />
      </button>
      
      <div className="text-lg font-medium">
        Semaine du {monday.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long' })}
      </div>
      
      <button
        onClick={() => changeWeek('next')}
        className="p-2 hover:bg-gray-100 rounded-full"
      >
        <ChevronRight className="h-5 w-5" />
      </button>
    </div>
  );
};

export default WeekNavigation;