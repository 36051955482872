import React from 'react';
import { Edit, Trash2 } from 'lucide-react';
import { Link } from "react-router-dom";

const InvoiceCard = ({ 
  invoice, 
  projects, 
  projectIterations, 
  computeMontant,
  computeTotalInvoice,
  setEditingInvoice, 
  handleDeleteInvoice, 
  setDraggedInvoice 
}) => {
  return (
    <div
      className="bg-white shadow rounded-lg p-4 mb-4 cursor-move"
      draggable
      onDragStart={() => setDraggedInvoice(invoice)}
      onDragEnd={() => setDraggedInvoice(null)}
    >
      {invoice.lot_name && (
        <div className="mb-2">
          <span className="block text-sm font-semibold text-gray-800">
            {invoice.lot_name}
          </span>
        </div>
      )}
      <div className="flex justify-between items-center">
        <Link 
          to={`/project/${invoice.project_id}`}
          className="text-blue-600 font-bold hover:underline"
        >
          {projects.find(p => p.id === invoice.project_id)?.name}
        </Link>
        <div className="flex items-center space-x-2">
          {invoice.affacturation && (
            <span className="text-xs font-semibold text-green-600 border border-green-600 rounded px-2 py-1">
              Affacturé {invoice.commission && `(${invoice.commission}%)`}
            </span>
          )}
          <button onClick={() => setEditingInvoice(invoice)}>
            <Edit className="h-5 w-5 text-blue-600" />
          </button>
        </div>
      </div>
      <div className="mt-2">
        {(invoice.allocations || []).map(allocation => {
          const iteration = (projectIterations[invoice.project_id] || []).find(i => i.id === allocation.iteration_id);
          const iterName = iteration ? iteration.name : `Itération ${allocation.iteration_id}`;
          // Montant avant commission (brut)
          const montantBrut = computeMontant(iteration, allocation.percentage, false);
          // Montant après commission si applicable (net)
          const montantNet = (allocation.montant !== undefined && allocation.montant !== null)
            ? allocation.montant
            : computeMontant(iteration, allocation.percentage, invoice.affacturation, invoice.commission);
          return (
            <p key={allocation.iteration_id} className="text-sm">
              {iterName} : {montantBrut.toFixed(2)} € ({Number(allocation.percentage.toFixed(2))}%)
              {invoice.affacturation && montantBrut !== montantNet && 
                <span className="text-green-600 ml-2">→ {montantNet.toFixed(2)} € après commission</span>
              }
            </p>
          );
        })}
      </div>
      <div className="mt-2">
        <p className="font-bold text-sm">
          Total : {computeTotalInvoice(invoice)} €
          {invoice.affacturation && ` (après commission de ${invoice.commission || 3}%)`}
        </p>
      </div>
      <div className="mt-2">
        <button onClick={() => handleDeleteInvoice(invoice.id)} className="text-red-600 hover:text-red-800">
          <Trash2 className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default InvoiceCard;