/* frontend/src/components/TaskItem.js */
import React, { useState } from 'react';
import { Trash2 } from 'lucide-react';

const TaskItem = ({
  task,
  onDelete,    // fonction appelée avec task.id pour supprimer
  onUpdate,    // fonction appelée avec l'objet task mis à jour (ex. suite à l'édition d'un champ)
  onDragStart,
  onDragOver,
  onDrop,
  sprintOptions, // Liste des sprints du projet, chaque sprint devant comporter au moins un id et une description
  ownerOptions,  // Liste des owners (exemple : employés), chaque owner devant comporter au moins un id et un nom
  ...rest
}) => {
  // On utilise deux états pour savoir quel champ est en cours d’édition et en sauvegarder la valeur modifiée
  const [editingField, setEditingField] = useState(null);
  const [editingValue, setEditingValue] = useState("");

  // Démarrer l'édition d’un champ
  const handleFieldClick = (field, currentValue) => {
    setEditingField(field);
    setEditingValue(currentValue !== null ? currentValue.toString() : "");
  };

  // Mis à jour de la valeur pendant l'édition
  const handleFieldChange = (e) => {
    setEditingValue(e.target.value);
  };

  // Fin de l'édition : si la valeur a changé, on notifie le parent via onUpdate
  const handleFieldBlur = () => {
    if (onUpdate && editingField) {
      let newValue;
      if (editingField === "effort") {
        newValue = Number(editingValue);
      } else if (editingField === "sprint_id" || editingField === "owner_id") {
        newValue = editingValue === "" ? null : Number(editingValue);
      } else {
        newValue = editingValue;
      }
      // Envoyer uniquement le champ modifié
      onUpdate({ [editingField]: newValue });
    }
    setEditingField(null);
    setEditingValue("");
  };



  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleFieldBlur();
    } else if (e.key === "Escape") {
      e.preventDefault();
      setEditingField(null);
      setEditingValue("");
    }
  };

  // Pour le sprint, si sprintOptions est fourni et qu'un sprint est attribué,
  // on recherche dans sprintOptions le sprint dont l'id correspond à task.sprint_id.
  const getSprintDescription = () => {
    if (sprintOptions && task.sprint_id) {
      const sprint = sprintOptions.find(s => Number(s.id) === Number(task.sprint_id));
      return sprint ? sprint.description : "-- Aucun --";
    }
    return "-- Aucun --";
  };

  // Pour l'owner, si ownerOptions est fourni et qu'un owner est attribué,
  // on recherche dans ownerOptions celui dont l'id correspond à task.owner_id et on extrait son prénom.
  const getOwnerFirstName = () => {
    if (ownerOptions && task.owner_id) {
      const owner = ownerOptions.find(o => Number(o.id) === Number(task.owner_id));
      return owner ? owner.name.split(" ")[0] : "-- Aucun --";
    }
    return "-- Aucun --";
  };

  return (
    <div
      draggable={!editingField}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onClick={(e) => e.stopPropagation()}
      {...rest}
      className="group relative bg-white shadow rounded p-2 mb-2 text-sm w-[230px] max-w-[230px] min-h-[40px] flex flex-col cursor-move"
    >
      {/* Zone toujours visible : Nom de la tâche */}
      <div className="font-medium">
        {editingField === "name" ? (
          <input
            type="text"
            value={editingValue}
            onChange={handleFieldChange}
            onBlur={handleFieldBlur}
            onKeyDown={handleKeyDown}
            className="border rounded p-1 w-full"
            autoFocus
          />
        ) : (
          <span onClick={() => handleFieldClick("name", task.name)}>
            {task.name}
          </span>
        )}
      </div>

      {/* Zone complémentaire (affichée au hover) */}
      <div className="overflow-hidden max-h-0 group-hover:max-h-40 transition-all duration-300">

        {/* Champ Effort */}
        <div
          className="mt-1 text-xs text-gray-500"
          onClick={() => handleFieldClick("effort", task.effort)}
        >
          Effort:{" "}
          {editingField === "effort" ? (
            <input
              type="number"
              value={editingValue}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
              onKeyDown={handleKeyDown}
              className="border rounded p-1 w-16"
              autoFocus
            />
          ) : (
            task.effort
          )}
        </div>

        {/* Champ Sprint : affiche le nom du sprint et en édition propose un menu déroulant */}
        <div
          className="mt-1 text-xs text-gray-500"
          onClick={() => handleFieldClick("sprint_id", task.sprint_id ? task.sprint_id.toString() : "")}
        >
          Sprint:{" "}
          {editingField === "sprint_id" ? (
            <select
              value={editingValue}
              onChange={(e) => {
                handleFieldChange(e);
                handleFieldBlur();
              }}
              className="border rounded p-1 w-full"
              autoFocus
            >
              <option value="">-- Aucun --</option>
              {sprintOptions && sprintOptions.map((sprint) => (
                <option key={sprint.id} value={sprint.id}>
                  {sprint.description ? sprint.description : `Sprint ${sprint.id}`}
                </option>
              ))}
            </select>
          ) : (
            getSprintDescription()
          )}
        </div>
        {/* Champ Owner : affiche le prénom et en édition propose un menu déroulant */}
        <div className="mt-1 text-xs text-gray-500" onClick={() => handleFieldClick("owner_id", task.owner_id ? task.owner_id.toString() : "")}>
          Owner:{" "}
          {editingField === "owner_id" ? (
            <select
              value={editingValue}
              onChange={(e) => {
                handleFieldChange(e);
                handleFieldBlur();
              }}
              className="border rounded p-1 w-full"
              autoFocus
            >
              <option value="">-- Aucun --</option>
              {ownerOptions && ownerOptions.map((owner) => (
                <option key={owner.id} value={owner.id}>
                  {owner.name.split(" ")[0]}
                </option>
              ))}
            </select>
          ) : (
            getOwnerFirstName()
          )}
        </div>
        {/* Champ Description */}
        <div className="mt-1 text-xs text-gray-500" onClick={() => handleFieldClick("description", task.description || "")}>
          {editingField === "description" ? (
            <textarea
              value={editingValue}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
              onKeyDown={handleKeyDown}
              className="border rounded p-1 w-full"
              autoFocus
            />
          ) : (
            task.description ? task.description : "Cliquez pour ajouter une description"
          )}
        </div>

        {/* Champ Commentaire */}
        <div
          className="mt-1 text-xs text-gray-500"
          onClick={() => handleFieldClick("comment", task.comment || "")}
        >
          Comment:{" "}
          {editingField === "comment" ? (
            <textarea
              value={editingValue}
              onChange={handleFieldChange}
              onBlur={handleFieldBlur}
              onKeyDown={handleKeyDown}
              className="border rounded p-1 w-full"
              autoFocus
            />
          ) : (
            task.comment ? task.comment : "Cliquez pour ajouter un commentaire"
          )}
        </div>

        {/* Bouton de suppression */}
        <div className="mt-1">
          <button
            onClick={(e) => { e.stopPropagation(); if(onDelete) onDelete(task.id); }}
            className="text-red-500 hover:text-red-700"
            title="Supprimer la tâche"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskItem;