/* frontend/src/components/KickoffOwnerView.js */
import React, { useState, useEffect } from "react";
import { apiService } from "../services/api";
import TaskItem from "./TaskItem";

const KickoffOwnerView = ({ projectId: propProjectId }) => {
  const [tasks, setTasks] = useState([]);
  const [inputTask, setInputTask] = useState(null);
  const [hoveredBlock, setHoveredBlock] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [sprints, setSprints] = useState([]);

  useEffect(() => {
    // Récupérer les employés actifs
    apiService.get("/employees")
      .then((data) => {
        const activeEmployees = data.filter(emp => emp.is_active);
        setEmployees(activeEmployees);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    // Récupérer les tâches du projet
    apiService.get(`/projects/${propProjectId}/tasks`)
      .then((data) => {
        const transformedTasks = data.map(task => ({
          ...task,
          x: task.x === undefined || task.x === null
              ? `${Math.floor(Math.random() * 80 + 10)}%`
              : task.x,
          y: task.y === undefined || task.y === null
              ? `${Math.floor(Math.random() * 80 + 10)}%`
              : task.y,
        }));
        setTasks(transformedTasks);
      })
      .catch(console.error);
  }, [propProjectId]);

  useEffect(() => {
    // Récupérer la liste des sprints du projet et stocker dans "sprints"
    apiService.get(`/projects/${propProjectId}/sprints`)
      .then((data) => {
        setSprints(data);
      })
      .catch(console.error);
  }, [propProjectId]);

  // Drag & Drop
  const handleDragStart = (e, taskId) => {
    e.dataTransfer.setData("text/plain", taskId);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDropOnOwner = async (e, ownerId) => {
    e.preventDefault();
    const taskId = e.dataTransfer.getData("text/plain");
    setTasks(prev =>
      prev.map(task =>
        task.id.toString() === taskId ? { ...task, owner_id: ownerId } : task
      )
    );
    try {
      await apiService.patch(`/projects/${propProjectId}/tasks/${taskId}`, { owner_id: ownerId });
    } catch (error) {
      console.error("Erreur lors de l'affectation de la tâche à l'owner :", error);
    }
  };

  const handleDropOnBottom = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const taskId = e.dataTransfer.getData("text/plain");
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setTasks(prev =>
      prev.map(task =>
        task.id.toString() === taskId ? { ...task, owner_id: null, x: `${x}px`, y: `${y}px` } : task
      )
    );
    try {
      await apiService.patch(`/projects/${propProjectId}/tasks/${taskId}`, { owner_id: null, x: `${x}px`, y: `${y}px` });
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la position de la tâche :", error);
    }
  };

  const handleBottomClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const newTaskId = Date.now();
    setInputTask({ id: newTaskId, x: `${x}px`, y: `${y}px`, value: "" });
  };

  const handleInputChange = (e) => {
    setInputTask({ ...inputTask, value: e.target.value });
  };

  const handleInputBlur = () => {
    if (inputTask && inputTask.value.trim() !== "") {
      const newTaskPayload = {
        project_id: propProjectId,
        name: inputTask.value.trim(),
        description: "",
        sprint_id: null,
        effort: 0,
        owner_id: null,
        status: "not_started",
        blocked_by: [],
        comment: ""
      };
      apiService.post(`/projects/${propProjectId}/tasks`, newTaskPayload)
        .then(createdTask => {
          const transformedTask = {
            ...createdTask,
            x: inputTask.x,
            y: inputTask.y,
          };
          setTasks(prev => [...prev, transformedTask]);
        })
        .catch(error => console.error("Erreur lors de la création de la tâche :", error));
    }
    setInputTask(null);
  };

  const handleInputKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleInputBlur();
    } else if (e.key === "Escape") {
      e.preventDefault();
      setInputTask(null);
    }
  };
  const renderTaskCard = (task, style = {}) => (
    <TaskItem
      key={task.id}
      task={task}
      onDelete={(id) => {
        apiService.delete(`/projects/${propProjectId}/tasks/${id}`)
          .then(() => setTasks(prev => prev.filter(t => t.id !== id)))
          .catch(console.error);
      }}
      onUpdate={(updatedTask) =>
        setTasks(prev =>
          prev.map(t => (t.id === updatedTask.id ? updatedTask : t))
        )
      }
      onDragStart={(e) => handleDragStart(e, task.id)}
      onDragOver={handleDragOver}
      style={style}
      sprintOptions={sprints}       // Passage de la liste des sprints
      ownerOptions={employees}      // Passage des employés comme owners
    />
  );
  // Regrouper les tâches par owner
  const tasksByOwner = {};
  employees.forEach(emp => {
    tasksByOwner[emp.id] = tasks.filter(task => String(task.owner_id) === String(emp.id));
  });
  const tasksUnassigned = tasks.filter(task => !task.owner_id);

  return (
    <div className="h-screen flex flex-col">
      {/* Section supérieure : colonnes d'affectation par owner (45% de la hauteur) */}
      <div className="h-[45%] bg-white p-4 border-b overflow-y-auto flex space-x-4 no-scrollbar"
          style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
        {employees.map(emp => (
          <div
            key={emp.id}
            className="flex flex-col p-2 shadow rounded min-h-[10rem] w-40 cursor-pointer border border-gray-200 overflow-y-auto no-scrollbar"  
            onDragOver={handleDragOver}
            onDrop={(e) => handleDropOnOwner(e, emp.id)}
            onDragEnter={(e) => { e.preventDefault(); setHoveredBlock(emp.id); }}
            onDragLeave={(e) => { e.preventDefault(); setHoveredBlock(null); }}
            style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
          >
            <div className="text-center font-bold mb-2 text-lg">{emp.name}</div>
            <div className="flex-1 overflow-y-auto no-scrollbar"
                style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
              {tasksByOwner[emp.id] && tasksByOwner[emp.id].length > 0 ? (
                tasksByOwner[emp.id].map(task => renderTaskCard(task))
              ) : (
                <p className="text-center text-xs text-gray-500">Vide</p>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Zone inférieure */}
      <div
        className="h-[35%] relative bg-gray-50 p-4 border border-dashed border-gray-300 rounded overflow-y-auto no-scrollbar"
        onClick={handleBottomClick}
        onDrop={handleDropOnBottom}
        onDragOver={(e) => e.preventDefault()}
        style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}
      >
        {tasksUnassigned.map(task => 
          renderTaskCard(task, { position: "absolute", left: task.x, top: task.y })
        )}
        {inputTask && (
          <input 
            autoFocus
            type="text"
            value={inputTask.value}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onKeyDown={handleInputKeyDown}
            style={{ position: "absolute", left: inputTask.x, top: inputTask.y }}
            className="border rounded p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
            placeholder="Nom de la tâche"
          />
        )}
      </div>
    </div>
  );
};

export default KickoffOwnerView;
