/**
 * Utilitaires de gestion des dates pour l'application
 */

/**
 * Obtient le lundi d'une semaine donnée
 * @param {Date} date - La date pour laquelle trouver le lundi
 * @returns {Date} Le lundi de la semaine
 */
export const getMonday = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  };
  
  /**
   * Obtient toutes les dates d'une semaine à partir d'un lundi
   * @param {Date} monday - Le lundi de la semaine
   * @returns {Date[]} Un tableau des 7 jours de la semaine
   */
  export const getWeekDates = (monday) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(monday);
      date.setDate(monday.getDate() + i);
      dates.push(date);
    }
    return dates;
  };
  
  /**
   * Formate une date pour l'affichage
   * @param {Date} date - La date à formater
   * @param {Object} options - Options de formatage
   * @returns {string} La date formatée
   */
  export const formatDate = (date, options = {}) => {
    const defaultOptions = { weekday: 'short', day: 'numeric' };
    return date.toLocaleDateString('fr-FR', { ...defaultOptions, ...options });
  };
  
  /**
   * Vérifie si une date est dans le futur
   * @param {Date} date - La date à vérifier
   * @returns {boolean} True si la date est dans le futur
   */
  export const isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);
    return compareDate > today;
  };
  
  /**
   * Obtient le numéro de semaine d'une date
   * @param {Date} date - La date
   * @returns {number} Le numéro de la semaine
   */
  export const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  };
  
  // Calcule la date de Pâques pour une année donnée (algorithme de Meeus/Jones/Butcher)
  export const getEasterDate = (year) => {
    const a = year % 19;
    const b = Math.floor(year / 100);
    const c = year % 100;
    const d = Math.floor(b / 4);
    const e = b % 4;
    const f = Math.floor((b + 8) / 25);
    const g = Math.floor((b - f + 1) / 3);
    const h = (19 * a + b - d - g + 15) % 30;
    const i = Math.floor(c / 4);
    const k = c % 4;
    const l = (32 + 2 * e + 2 * i - h - k) % 7;
    const m = Math.floor((a + 11 * h + 22 * l) / 451);
    const month = Math.floor((h + l - 7 * m + 114) / 31);
    const day = ((h + l - 7 * m + 114) % 31) + 1;
    return new Date(year, month - 1, day);
  };
  
  // Obtient tous les jours fériés français pour une année donnée
  export const getFrenchHolidays = (year) => {
    const holidays = [];
    
    // 1. Jours fériés fixes
    holidays.push(
      new Date(year, 0, 1),   // Jour de l'an
      new Date(year, 4, 1),   // Fête du travail
      new Date(year, 4, 8),   // Victoire 1945
      new Date(year, 6, 14),  // Fête nationale
      new Date(year, 7, 15),  // Assomption
      new Date(year, 10, 1),  // Toussaint
      new Date(year, 10, 11), // Armistice 1918
      new Date(year, 11, 25)  // Noël
    );
  
    // 2. Jours fériés basés sur Pâques
    const easter = getEasterDate(year);
    
    // Lundi de Pâques (Pâques + 1 jour)
    const easterMonday = new Date(easter);
    easterMonday.setDate(easter.getDate() + 1);
    holidays.push(easterMonday);
    
    // Jeudi de l'Ascension (Pâques + 39 jours)
    const ascension = new Date(easter);
    ascension.setDate(easter.getDate() + 39);
    holidays.push(ascension);
    
    // Lundi de Pentecôte (Pâques + 50 jours)
    const pentecost = new Date(easter);
    pentecost.setDate(easter.getDate() + 50);
    holidays.push(pentecost);
  
    return holidays.map(date => {
      // Normaliser les dates pour une comparaison fiable
      date.setHours(0, 0, 0, 0);
      return date;
    });
  };
  
  // Vérifie si une date est un jour férié
  export const isFrenchHoliday = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const holidays = getFrenchHolidays(d.getFullYear());
    return holidays.some(holiday => holiday.getTime() === d.getTime());
  };
  
  // Vérifie si une date est un weekend
  export const isWeekend = (date) => {
    const d = new Date(date);
    return d.getDay() === 0 || d.getDay() === 6;
  };
  
  // Compte les jours ouvrés entre deux dates en excluant weekends et jours fériés
  export const getWorkingDays = (startDate, endDate) => {
    let count = 0;
    let current = new Date(startDate);
    current.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);
  
    while (current <= end) {
      // Vérifier si ce n'est pas un weekend ni un jour férié
      if (!isWeekend(current) && !isFrenchHoliday(current)) {
        count++;
      }
      current.setDate(current.getDate() + 1);
    }
    
    return count;
  };
  
  // Retourne les dates de tous les jours ouvrés dans une période
  export const getWorkingDaysDates = (startDate, endDate) => {
    const dates = [];
    let current = new Date(startDate);
    current.setHours(0, 0, 0, 0);
    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);
  
    while (current <= end) {
      if (!isWeekend(current) && !isFrenchHoliday(current)) {
        dates.push(new Date(current));
      }
      current.setDate(current.getDate() + 1);
    }
    
    return dates;
  };
  
  // Obtient le prochain jour ouvré à partir d'une date
  export const getNextWorkingDay = (date) => {
    let nextDay = new Date(date);
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay.setHours(0, 0, 0, 0);
  
    while (isWeekend(nextDay) || isFrenchHoliday(nextDay)) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
  
    return nextDay;
  };
  
  // Calcule la durée en semaines (arrondi au 0.5 le plus proche)
  export const calculateWeeksDuration = (startDate, endDate) => {
    const workingDays = getWorkingDays(startDate, endDate);
    const weeks = workingDays / 5; // 5 jours ouvrés par semaine
    return Math.round(weeks * 2) / 2; // Arrondir au 0.5 le plus proche
  };