// ClientSelector.jsx

import React, { useState, useEffect } from 'react';
import { Edit2, Check, X, Building2 } from 'lucide-react';
import { useAuth } from './Auth'; // Import du hook useAuth

const apiBaseUrl = process.env.NODE_ENV === 'development' 
  ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
  : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const ClientSelector = ({ projectId, currentClient, onClientChange, clients }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedClient, setSelectedClient] = useState(
    currentClient?.id?.toString() || ''
  );
  const { getAuthHeaders } = useAuth();

  // Mettre à jour selectedClient quand currentClient change
  useEffect(() => {
    setSelectedClient(currentClient?.id?.toString() || '');
  }, [currentClient]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}`, { // Utilisation de apiBaseUrl
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify({
          client_id: selectedClient === '' ? null : parseInt(selectedClient, 10)
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour du client');
      }

      const updatedProject = await response.json();
      onClientChange(updatedProject);
      setIsEditing(false);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du client:', error);
      // Vous pouvez également gérer l'affichage des erreurs ici
    }
  };

  if (!isEditing) {
    return (
      <div className="flex items-center space-x-2">
        <Building2 className="h-4 w-4 text-gray-500" />
        <span className="text-gray-600">
          {currentClient ? currentClient.name : 'Aucun client'}
        </span>
        <button
          onClick={() => setIsEditing(true)}
          className="text-blue-500 hover:text-blue-700 p-1 rounded"
          title="Modifier le client"
        >
          <Edit2 className="h-4 w-4" />
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="flex items-center space-x-2">
      <select
        value={selectedClient}
        onChange={(e) => setSelectedClient(e.target.value)}
        className="border rounded px-2 py-1 flex-grow max-w-md"
      >
        <option value="">- Aucun client -</option>
        {clients.map(client => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </select>
      <button
        type="submit"
        className="text-green-500 hover:text-green-700 p-1 rounded"
        title="Valider"
      >
        <Check className="h-4 w-4" />
      </button>
      <button
        type="button"
        onClick={() => {
          setIsEditing(false);
          setSelectedClient(currentClient?.id?.toString() || '');
        }}
        className="text-gray-500 hover:text-gray-700 p-1 rounded"
        title="Annuler"
      >
        <X className="h-4 w-4" />
      </button>
    </form>
  );
};

export default ClientSelector;
