import React from 'react';
import { ArrowLeft, ArrowRight, Copy } from 'lucide-react';

const RoadmapView = ({ roadmapYear, handleYearChange, monthlyFinancialData }) => {
  // Formatage des montants avec séparateur de milliers
  const formatMoney = (amount) => {
    return new Intl.NumberFormat('fr-FR', { 
      style: 'currency', 
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0 
    }).format(amount);
  };
  
  // Fonction pour copier les données au format tableur
  const copyToClipboard = () => {
    const months = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
    
    // Créer l'en-tête du tableau
    let clipboardText = `Roadmap ${roadmapYear}\t${months.join('\t')}\n`;
    
    // Ajouter les recettes
    clipboardText += `Recettes prévues\t`;
    clipboardText += monthlyFinancialData.map(month => month.income).join('\t') + '\n';
    
    // Ajouter les dépenses
    clipboardText += `Dépenses prévues\t`;
    clipboardText += monthlyFinancialData.map(month => -month.expenses).join('\t') + '\n';
    
    // Ajouter le solde mensuel
    clipboardText += `Solde mensuel\t`;
    clipboardText += monthlyFinancialData.map(month => month.income - month.expenses).join('\t') + '\n';
    
    // Ajouter le solde cumulé
    clipboardText += `Solde cumulé\t`;
    let cumulative = 0;
    clipboardText += monthlyFinancialData.map(month => {
      cumulative += (month.income - month.expenses);
      return cumulative;
    }).join('\t');
    
    // Copier dans le presse-papier
    navigator.clipboard.writeText(clipboardText)
      .then(() => {
        alert('Tableau copié dans le presse-papier!');
      })
      .catch(err => {
        console.error('Erreur lors de la copie:', err);
        alert('Erreur lors de la copie dans le presse-papier');
      });
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      {/* Contrôle de navigation pour l'année */}
      <div className="flex justify-between items-center mb-6">
        <button 
          onClick={() => handleYearChange('prev')} 
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 p-2 rounded"
        >
          <ArrowLeft className="h-5 w-5" />
        </button>
        <h2 className="text-2xl font-semibold">Roadmap de facturation {roadmapYear}</h2>
        <div className="flex space-x-2">
          <button 
            onClick={copyToClipboard} 
            className="text-gray-500 hover:text-gray-700 p-2 rounded"
            title="Copier le tableau pour Google Sheets"
          >
            <Copy className="h-5 w-5" />
          </button>
          <button 
            onClick={() => handleYearChange('next')} 
            className="bg-gray-200 hover:bg-gray-300 text-gray-800 p-2 rounded"
          >
            <ArrowRight className="h-5 w-5" />
          </button>
        </div>
      </div>

      {/* Grille des mois */}
      <div style={{ overflow: 'visible' }}>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th 
                className="py-2 border-b-2 border-gray-200 bg-gray-50 text-left pl-4 w-48 font-medium"
              >
                Roadmap {roadmapYear}
              </th>
              {['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'].map((month, index) => (
                <th 
                  key={index} 
                  className="py-2 border-b-2 border-gray-200 bg-gray-50 text-center font-medium"
                >
                  {month}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* Ligne pour les recettes */}
            <tr>
              <td className="border-b border-gray-200 bg-gray-100 pl-4 font-medium">
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-green-600 mr-2"></div>
                  <span>Recettes prévues</span>
                </div>
              </td>
              {monthlyFinancialData.map((monthData, index) => {
                // Créer le contenu du tooltip à partir des détails des recettes du mois
                const tooltipContent = monthData.revenueDetails && monthData.revenueDetails.length > 0 
                  ? (() => {
                      // Organiser les recettes par lot et projet
                      const groupedRevenues = monthData.revenueDetails.reduce((groups, rev) => {
                        const key = `${rev.lot_name || ''}|${rev.project || ''}|${rev.paymentTerms || rev.affacturation ? 'affacturage' : ''}`;
                        if (!groups[key]) {
                          groups[key] = {
                            lot_name: rev.lot_name,
                            project: rev.project,
                            paymentTerms: rev.paymentTerms,
                            affacturation: rev.affacturation,
                            originalPaymentTerms: rev.originalPaymentTerms,
                            iterations: []
                          };
                        }
                        groups[key].iterations.push(rev);
                        return groups;
                      }, {});

                      // Générer l'affichage HTML pour chaque groupe
                      return Object.values(groupedRevenues).map(group => {
                        let displayInfo = '';
                        
                        // Ajouter le nom du lot
                        if (group.lot_name) {
                          displayInfo += `<strong>${group.lot_name}</strong>`;
                        }
                        
                        // Ajouter le nom du projet avec les termes de paiement
                        displayInfo += `<br>${group.project}`;
                        
                        // Ajouter les termes de paiement à côté du projet
                        if (group.affacturation) {
                          displayInfo += " (affacturage)";
                        } else if (group.paymentTerms) {
                          displayInfo += ` (${group.paymentTerms})`;
                        }
                        
                        // Ajouter chaque itération sans répéter le lot et le projet
                        group.iterations.forEach(iter => {
                          displayInfo += `<br>${iter.iteration} (${iter.percentage}%) : ${formatMoney(iter.amount)}`;
                        });
                        
                        // Afficher seulement les termes de paiement d'origine en cas d'affacturage
                        if (group.affacturation && group.originalPaymentTerms) {
                          displayInfo += `<br><span style="color:#666; font-style:italic">Termes d'origine: ${group.originalPaymentTerms}</span>`;
                        }
                        
                        return displayInfo;
                      }).join('<br><hr>');
                    })()
                  : 'Aucune recette pour ce mois';
                  
                return (
                  <td 
                    key={`income-${index}`} 
                    className="py-4 px-2 border-b border-gray-200 text-center relative group"
                  >
                    <div className="text-green-600 font-semibold">
                      {formatMoney(monthData.income)}
                    </div>
                    {monthData.income > 0 && (
                      <div 
                        className="invisible group-hover:visible absolute -ml-32 top-full mt-2 w-96 p-3 bg-white border border-gray-200 rounded shadow-xl text-left text-sm"
                        style={{ zIndex: 9999 }}
                      >
                        <div className="font-semibold mb-1">Détail des recettes:</div>
                        <div dangerouslySetInnerHTML={{ __html: tooltipContent }}></div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
            
            {/* Ligne pour les dépenses */}
            <tr>
              <td className="border-b border-gray-200 bg-gray-100 pl-4 font-medium">
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded-full bg-red-600 mr-2"></div>
                  <span>Dépenses prévues</span>
                </div>
              </td>
              {monthlyFinancialData.map((monthData, index) => {
                // Trouver toutes les sources de dépenses pour ce mois depuis monthlyFinancialData
                const monthExpenses = monthData.expensesDetails || [];
                
                // Créer le contenu du tooltip
                const tooltipContent = monthExpenses.length > 0 
                  ? monthExpenses.map(exp => 
                      `${exp.project} - ${exp.iteration} : ${formatMoney(exp.amount)}`
                    ).join('<br>')
                  : 'Aucune dépense pour ce mois';
                
                return (
                  <td 
                    key={`expense-${index}`} 
                    className="py-4 px-2 border-b border-gray-200 text-center relative group"
                  >
                    <div className="text-red-600 font-semibold">
                      {formatMoney(-monthData.expenses)}
                    </div>
                    {monthData.expenses > 0 && (
                      <div 
                        className="invisible group-hover:visible absolute -ml-32 top-full mt-2 w-96 p-3 bg-white border border-gray-200 rounded shadow-xl text-left text-sm"
                        style={{ zIndex: 9999 }}
                      >
                        <div className="font-semibold mb-1">Détail des dépenses:</div>
                        <div dangerouslySetInnerHTML={{ __html: tooltipContent }}></div>
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
            
            {/* Ligne pour le solde */}
            <tr className="bg-gray-50">
              <td className="border-b border-gray-200 bg-gray-200 pl-4 font-medium">
                <div className="flex items-center">
                  <div className="w-3 h-3 mr-2 border border-gray-700"></div>
                  <span>Solde mensuel</span>
                </div>
              </td>
              {monthlyFinancialData.map((monthData, index) => {
                const balance = monthData.income - monthData.expenses;
                return (
                  <td key={`balance-${index}`} className="py-4 px-2 border-b border-gray-200 text-center">
                    <div className={`font-bold ${balance >= 0 ? 'text-green-700' : 'text-red-700'}`}>
                      {formatMoney(balance)}
                    </div>
                  </td>
                );
              })}
            </tr>
            
            {/* Ligne pour le solde cumulé */}
            <tr>
              <td className="border-b border-gray-200 bg-gray-100 pl-4 font-medium">
                <div className="flex items-center">
                  <div className="w-3 h-3 rounded mr-2 bg-blue-600"></div>
                  <span>Solde cumulé</span>
                </div>
              </td>
              {monthlyFinancialData.reduce((acc, monthData, index) => {
                const previousTotal = index > 0 ? acc[index - 1].total : 0;
                const monthBalance = monthData.income - monthData.expenses;
                const total = previousTotal + monthBalance;
                
                acc.push({
                  index,
                  total
                });
                
                return acc;
              }, []).map((item) => (
                <td key={`cumulative-${item.index}`} className="py-4 px-2 border-b border-gray-200 text-center">
                  <div className="text-blue-700 font-medium">
                    {formatMoney(item.total)}
                  </div>
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoadmapView;