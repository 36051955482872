import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeft, Search, MapPin } from "lucide-react";
import KickoffSprintView from "./KickoffSprintView";
import KickoffTokensView from "./KickoffTokensView";
import KickoffOwnerView from "./KickoffOwnerView";
import { apiService } from "../services/api";

const KickoffView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultProjectId = localStorage.getItem("taskBoard_selectedProject") || "1";
  
  // État pour le projet sélectionné 
  const [selectedProjectId, setSelectedProjectId] = useState(defaultProjectId);
  const [project, setProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [activeTab, setActiveTab] = useState("sprints");
  
  // État pour la recherche de projet
  const [projectSearch, setProjectSearch] = useState("");
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const searchRef = useRef(null);

  // Récupérer tous les projets
  useEffect(() => {
    apiService.get("/projects")
      .then(data => {
        setProjects(data);
      })
      .catch(console.error);
  }, []);

  // Récupérer les détails du projet sélectionné
  useEffect(() => {
    if (selectedProjectId) {
      apiService.get(`/projects/${selectedProjectId}`)
        .then(data => {
          setProject(data);
          setProjectSearch(data.name);
        })
        .catch(console.error);
    }
  }, [selectedProjectId]);

  // Filtrer les projets en fonction de la recherche
  const filteredProjects = projects.filter(p =>
    p.name.toLowerCase().includes(projectSearch.toLowerCase())
  );

  // Gestionnaire pour les clics en dehors du champ de recherche
  const handleDocumentClick = (e) => {
    if (isSearchFocused && searchRef.current && !searchRef.current.contains(e.target)) {
      setIsSearchFocused(false);
    }
  };

  // Ajouter/supprimer les gestionnaires d'événements pour les clics
  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [isSearchFocused]);

  return (
    <div className="p-2 max-w-6xl mx-auto">
      {/* En-tête avec navigation */}
      <h1 className="text-2xl font-bold mb-2 flex items-center">
        <MapPin className="h-6 w-6 mr-2" />
        Backlog des Tâches
      </h1>
      
      {/* Bascule entre vues - comme dans le TaskBoard */}
      <div className="border-b border-gray-200 mb-4">
        <div className="flex space-x-4">
          <button
            onClick={() => navigate("/tasks")}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors 
              text-gray-600 hover:bg-gray-100`}
          >
            Par projet
          </button>
          <button
            onClick={() => navigate("/tasks?viewMode=myTasks")}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors 
              text-gray-600 hover:bg-gray-100`}
          >
            Mes tâches
          </button>
          <button
            onClick={() => {}}
            className="flex items-center px-4 py-2 rounded-lg transition-colors 
              bg-purple-100 text-purple-700 border-b-2 border-purple-700"
          >
            Kickoff
          </button>
        </div>
      </div>

      {/* Sélecteur de projet */}
      <div className="mb-4">
        <div ref={searchRef} className="relative w-64 mb-4">
          <label className="block text-sm font-medium mb-1">Projet pour le Kickoff</label>
          <div className="relative">
            <input
              type="text"
              value={projectSearch}
              onChange={(e) => setProjectSearch(e.target.value)}
              placeholder="Rechercher un projet"
              className="border rounded p-2 w-full pl-9"
              onFocus={() => {
                setIsSearchFocused(true);
              }}
              onKeyDown={(e) => {
                if (e.key === "Escape") {
                  setIsSearchFocused(false);
                }
              }}
            />
            <Search className="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>
          {isSearchFocused && (
            <ul className="absolute left-0 right-0 bg-white border rounded mt-1 max-h-60 overflow-auto z-10">
              {filteredProjects.map((p) => (
                <li
                  key={p.id}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    setSelectedProjectId(p.id);
                    setProjectSearch(p.name);
                    setIsSearchFocused(false);
                  }}
                >
                  <span>{p.name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Menu de navigation pour basculer entre les affectations */}
      <div className="bg-white p-4 flex space-x-4 border-b">
        <button
          onClick={() => setActiveTab("sprints")}
          className={`px-4 py-2 rounded ${activeTab === "sprints" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
        >
          Affectation des Sprints
        </button>
        <button
          onClick={() => setActiveTab("tokens")}
          className={`px-4 py-2 rounded ${activeTab === "tokens" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
        >
          Affectation des Tokens
        </button>
        <button
          onClick={() => setActiveTab("owners")}
          className={`px-4 py-2 rounded ${activeTab === "owners" ? "bg-blue-500 text-white" : "bg-gray-200"}`}
        >
          Affectation des Owners
        </button>
      </div>

      {/* Affichage en fonction de l'onglet actif */}
      <div>
        {activeTab === "sprints" && <KickoffSprintView projectId={selectedProjectId} />}
        {activeTab === "tokens" && <KickoffTokensView projectId={selectedProjectId} />}
        {activeTab === "owners" && <KickoffOwnerView projectId={selectedProjectId} />}
      </div>
    </div>
  );
};

export default KickoffView;