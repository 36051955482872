import React, { useState, useEffect } from 'react';
import { Settings, Calendar, AlertCircle, ExternalLink } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './Auth';
import { getWorkingDays, getWorkingDaysDates } from '../utils/dateUtils';
import { apiService } from '../services/api';

const apiBaseUrl =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
    : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const WorkloadManagement = ({ slot, sprints, employees }) => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [saveMessage, setSaveMessage] = useState(null);
  const [velocity, setVelocity] = useState(10);
  const [unavailabilities, setUnavailabilities] = useState({});
  const [needsSave, setNeedsSave] = useState(false);
  const [showOtherProjects, setShowOtherProjects] = useState(false);
  const [activeProjects, setActiveProjects] = useState([]);
  const [inactiveProjects, setInactiveProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [showProgress, setShowProgress] = useState(false); 

  const { getAuthHeaders } = useAuth(); // Utilisation du hook useAuth

  useEffect(() => {
    if (sprints.length > 0) {
      // Extraire tous les project_id uniques depuis les sprints
      const projectIds = Array.from(new Set(sprints.map(sprint => sprint.project_id)));
      // Récupérer les tâches pour chaque projet
      Promise.all(
        projectIds.map(projectId => apiService.get(`/projects/${projectId}/tasks`))
      )
        .then(results => {
          // Fusionner les tableaux de tâches de chaque projet
          const allTasks = results.flat();
          setTasks(allTasks);
        })
        .catch(console.error);
    }
  }, [sprints]);

  useEffect(() => {
    const fetchWorkloadData = async () => {
      if (!slot?.id) return;
      try {
        const response = await fetch(`${apiBaseUrl}/slots/${slot.id}/workload`, {
          // Utilisation de apiBaseUrl
          headers: getAuthHeaders(), // Ajout des en-têtes d'authentification
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.detail || 'Échec du chargement des données de charge de travail'
          );
        }
        const data = await response.json();
        setVelocity(data.velocity || 10);
        // Initialiser les indisponibilités
        if (Array.isArray(data.employeeUnavailabilities)) {
          const newUnavailabilities = {};
          data.employeeUnavailabilities.forEach((item) => {
            newUnavailabilities[item.employee_id] = Number(item.unavailable_days);
          });
          setUnavailabilities(newUnavailabilities);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des données de charge de travail:', error);
        setSaveMessage({
          type: 'error',
          text: 'Échec du chargement des données de charge de travail',
        });
      }
    };
    fetchWorkloadData();
  }, [slot?.id, getAuthHeaders]); // Ajout de getAuthHeaders aux dépendances

  const totalWorkingDays = getWorkingDays(slot.start_date, slot.end_date);

  // Calculer les jours disponibles par employé
  const getEmployeeAvailableDays = (employeeId) => {
    return totalWorkingDays - (unavailabilities[employeeId] || 0);
  };

  // Vérifier si un employé était présent pendant le slot
  const wasEmployeePresentDuringSlot = (employee) => {
    // Date d'arrivée de l'employé
    const employeeArrivalDate = new Date(employee.arrival_date);
    
    // Date de départ de l'employé (si spécifiée)
    const employeeDepartureDate = employee.departure_date ? new Date(employee.departure_date) : null;
    
    // Dates du slot
    const slotStartDate = new Date(slot.start_date);
    const slotEndDate = new Date(slot.end_date);
    
    // Un employé est présent pendant le slot si :
    // 1. Il est arrivé avant la fin du slot
    const arrivedBeforeSlotEnd = employeeArrivalDate <= slotEndDate;
    
    // 2. Et soit il n'a pas de date de départ, soit il est parti après le début du slot
    const presentDuringSlot = !employeeDepartureDate || employeeDepartureDate >= slotStartDate;
    
    return arrivedBeforeSlotEnd && presentDuringSlot;
  };
  
  // Calculer la charge de travail par employé
  const getEmployeeWorkload = (employeeId) => {
    // Si l'employé n'était pas présent pendant le slot, sa charge est nulle
    const employee = employees.find(e => e.id === employeeId);
    if (!employee || !wasEmployeePresentDuringSlot(employee)) {
      return {
        allocated: 0,
        available: 0,
        percentage: 0
      };
    }
    
    const available = getEmployeeAvailableDays(employeeId);
    // Calculer le total des jours ouvrés sur l'ensemble des sprints
    const totalSprintDays = sprints.reduce((sum, sprint) => {
      const sprintDays = getWorkingDaysDates(new Date(sprint.start_date), new Date(sprint.end_date)).length;
      return sum + sprintDays;
    }, 0);
    // Pour l'employé, la somme des jours alloués sur tous les sprints
    const allocated = sprints.reduce((sum, sprint) => {
      const sprintDays = getWorkingDaysDates(new Date(sprint.start_date), new Date(sprint.end_date)).length;
      const allocation = totalSprintDays > 0 ? (sprintDays / totalSprintDays) * available : 0;
      return sum + allocation;
    }, 0);
    return {
      allocated: parseFloat(allocated.toFixed(1)),
      available: parseFloat(available.toFixed(1)),
      percentage: available > 0 ? (allocated / available) * 100 : 0,
    };
  };

  // Sauvegarder les données
  const saveWorkloadData = async () => {
    if (!slot?.id) return;
    setIsSaving(true);
    try {
      const totalSprintDays = sprints.reduce((sum, sprint) => {
        const sprintDays = getWorkingDaysDates(new Date(sprint.start_date), new Date(sprint.end_date)).length;
        return sum + sprintDays;
      }, 0);
      
      const sprintAllocations = employees.flatMap(employee => {
        const available = getEmployeeAvailableDays(employee.id); // calcule = totalWorkingDays - indisponibilités
        return sprints.map(sprint => {
          const sprintDays = getWorkingDaysDates(new Date(sprint.start_date), new Date(sprint.end_date)).length;
          const allocatedDays = totalSprintDays > 0 ? (sprintDays / totalSprintDays) * available : 0;
          return {
            employee_id: employee.id,
            sprint_id: sprint.id,
            allocated_days: Number(allocatedDays.toFixed(1))
          };
        });
      });
      
      const workloadData = {
        velocity: Number(velocity),
        employee_unavailabilities: Object.entries(unavailabilities)
          .filter(([_, days]) => days > 0)
          .map(([employeeId, days]) => ({
            employee_id: Number(employeeId),
            unavailable_days: Number(days)
          })),
        sprint_allocations: sprintAllocations
      };

      const response = await fetch(`${apiBaseUrl}/slots/${slot.id}/workload`, {
        // Utilisation de apiBaseUrl
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(), // Ajout des en-têtes d'authentification
        },
        body: JSON.stringify(workloadData),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail || "Échec de l'enregistrement des données de charge de travail"
        );
      }
      const result = await response.json();
      setSaveMessage({ type: 'success', text: 'Modifications enregistrées avec succès' });
    } catch (err) {
      console.error('Erreur lors de la sauvegarde des données:', err);
      setSaveMessage({ type: 'error', text: "Échec de l'enregistrement des modifications" });
    } finally {
      setIsSaving(false);
      setTimeout(() => setSaveMessage(null), 3000);
    }
  };

  // Effet pour sauvegarder automatiquement
  useEffect(() => {
    if (!slot?.id || !needsSave) return;
    const timeoutId = setTimeout(() => {
      saveWorkloadData();
      setNeedsSave(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [needsSave, slot?.id, unavailabilities, getAuthHeaders]); // Ajout de getAuthHeaders aux dépendances

  const calculateTeamCapacity = () => {
    let totalCapacity = 0;
    employees.forEach((employee) => {
      const availableDays = getEmployeeAvailableDays(employee.id);
      totalCapacity += velocity * availableDays; // Vélocité quotidienne × jours disponibles
    });
    return totalCapacity;
  };

  const handleUnavailabilityChange = (employeeId, days) => {
    const numericDays = Number(days) || 0;
    setUnavailabilities((prev) => ({
      ...prev,
      [employeeId]: numericDays,
    }));
    setNeedsSave(true);
  };

  // Ajout de la fonction de navigation vers le projet
  const handleProjectClick = (sprintData) => {
    navigate(`/project/${sprintData.project_id}`);
  };

  return (
    <div className="space-y-6">
      {/* Section Configuration de la Vélocité */}
      <div className="bg-white shadow rounded-lg p-6">
        <h3 className="text-lg font-medium flex items-center mb-4">
          <Settings className="h-5 w-5 mr-2" />
          Configuration de la Vélocité du Projet
        </h3>
        <div className="space-y-4">
          <div className="flex items-center space-x-4 mb-4">
            <label className="text-sm text-gray-600">Tokens par personne par jour :</label>
            <div className="relative flex items-center">
              <input
                type="number"
                value={velocity}
                onChange={(e) => {
                  const newValue = Math.max(0, parseFloat(e.target.value) || 0);
                  setVelocity(newValue);
                  setNeedsSave(true);
                }}
                onBlur={() => setNeedsSave(true)}
                className="border rounded p-2 w-24 pr-12"
                min="0"
                step="0.1"
              />
              <span className="absolute right-3 text-gray-500 text-sm">/ jour</span>
            </div>
          </div>
          <div className="bg-blue-50 border border-blue-200 rounded-lg p-4">
            <h4 className="text-sm font-medium text-blue-800 mb-2">
              Capacité de l'équipe pour ce slot
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <div className="text-sm text-blue-600">Jours ouvrés dans le slot :</div>
                <div className="text-lg font-medium">{totalWorkingDays} jours</div>
              </div>
              <div>
                <div className="text-sm text-blue-600">Vélocité journalière équipe :</div>
                <div className="text-lg font-medium">
                  {(velocity * employees.length).toFixed(1)} tokens/jour
                </div>
              </div>
              <div>
                <div className="text-sm text-blue-600">Capacité totale de tokens :</div>
                <div className="text-lg font-medium">
                  {calculateTeamCapacity().toFixed(1)} tokens
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Section des employés */}
      <div className="bg-white shadow rounded-lg p-6">
        <h3 className="text-lg font-medium flex items-center mb-4">
          <Calendar className="h-5 w-5 mr-2" />
          Disponibilité des Employés
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {employees
            .filter(employee => wasEmployeePresentDuringSlot(employee))
            .map((employee) => {
              const workload = getEmployeeWorkload(employee.id);
              return (
                <EmployeeCard
                  key={employee.id}
                  employee={employee}
                  workload={workload}
                  unavailabilities={unavailabilities}
                  handleUnavailabilityChange={handleUnavailabilityChange}
                  totalWorkingDays={totalWorkingDays}
                  tasks={tasks}
                  velocity={velocity}
                  sprints={sprints}
                />
              );
            })}
        </div>
      </div>

      {/* Section des Sprints */}
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            Allocations des Sprints
          </h3>
          <button 
            onClick={() => setShowProgress(prev => !prev)}
            className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none"
          >
            {showProgress ? "Masquer l'avancement" : "Afficher l'avancement"}
          </button>
        </div>
        <SprintMatrix
          slot={slot}
          sprints={sprints}
          employees={employees.filter(employee => wasEmployeePresentDuringSlot(employee))}
          tasks={tasks}
          velocity={velocity}
          getEmployeeAvailableDays={getEmployeeAvailableDays}
          showProgress={showProgress}
        />
      </div>

      {/* Affichage des messages de sauvegarde */}
      {saveMessage && (
        <div
          className={`p-4 rounded ${
            saveMessage.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
          }`}
        >
          {saveMessage.text}
        </div>
      )}

      {/* Bouton de sauvegarde manuelle (optionnel) */}
      {isSaving && (
        <div className="text-sm text-gray-500">En cours de sauvegarde...</div>
      )}
    </div>
  );
};

// Composant pour afficher chaque employé
const EmployeeCard = ({
  employee,
  workload,
  unavailabilities,
  handleUnavailabilityChange,
  totalWorkingDays,
  tasks,
  velocity,
  sprints
}) => {
  // Calculer la somme totale des jours planifiés pour l'employé en se basant sur les tâches du slot actuel
  const sprintIds = sprints.map(sprint => sprint.id);
  const employeeTasks = tasks.filter(task => 
    task.owner_id === employee.id && 
    sprintIds.includes(task.sprint_id)
  );
  const totalTokens = employeeTasks.reduce((sum, task) => sum + Number(task.effort), 0);
  const totalPlannedDays = velocity > 0 ? parseFloat((totalTokens / velocity).toFixed(1)) : 0;
  
  // Calculer le pourcentage sur la base des jours planifiés / jours disponibles
  const percentage = workload.available > 0 ? (totalPlannedDays / workload.available) * 100 : 0;
  const isOverloaded = percentage > 100;
  const isInactive = !employee.is_active;

  return (
    <div className={`border rounded-lg p-4 ${isInactive ? 'bg-gray-50' : ''}`}>
      <div className="flex items-center justify-between mb-2">
        <div>
          <h4 className={`font-medium ${isInactive ? 'text-gray-500' : ''}`}>
            {employee.name}
          </h4>
        </div>
        <div className="text-right">
          <div
            className={`text-sm ${
              isOverloaded
                ? 'text-red-600 font-medium'
                : isInactive
                ? 'text-gray-500'
                : 'text-gray-600'
            }`}
          >
            {Math.round(percentage)}%
          </div>
          <div className="text-xs text-gray-500">
            {totalPlannedDays} / {workload.available} jours
          </div>
        </div>
      </div>
      <div className="flex items-center space-x-2 mb-2">
        <input
          type="number"
          value={unavailabilities[employee.id] || 0}
          onChange={(e) =>
            handleUnavailabilityChange(employee.id, parseFloat(e.target.value) || 0)
          }
          className={`border rounded p-1 w-20 text-sm ${
            isInactive ? 'bg-gray-100' : ''
          }`}
          min="0"
          step="0.5"
          disabled={isInactive}
        />
        <span className="text-xs text-gray-500">
          jours indispo / {totalWorkingDays}
        </span>
      </div>
      <div className="relative pt-1">
        <div
          className={`overflow-hidden h-2 text-xs flex rounded ${
            isInactive
              ? 'bg-gray-200'
              : isOverloaded
              ? 'bg-red-200'
              : 'bg-purple-200'
          }`}
        >
          <div
            style={{ width: `${Math.min(100, percentage)}%` }}
            className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
              isInactive
                ? 'bg-gray-400'
                : isOverloaded
                ? 'bg-red-500'
                : 'bg-purple-500'
            }`}
          />
        </div>
        {isOverloaded && !isInactive && (
          <div className="text-xs text-red-600 mt-1">⚠️ Surcharge de travail</div>
        )}
      </div>
    </div>
  );
};

const SprintMatrix = ({ slot, sprints, employees, tasks, getEmployeeAvailableDays, velocity, showProgress }) => {
  const navigate = useNavigate();
  // Etat pour gérer le tooltip
  const [tooltip, setTooltip] = useState({ visible: false, x: 0, y: 0, title: '', tasks: [] });

  // Fonction pour calculer la position optimale du tooltip pour qu'il reste visible
  const calculateTooltipPosition = (e) => {
    const margin = 10;
    let x = e.clientX + margin;
    let y = e.clientY + margin;
    // On ne peut pas vraiment obtenir les dimensions du tooltip avant qu'il soit rendu
    // Mais on peut faire des estimations basées sur la taille de la fenêtre
    const estimatedWidth = 300; // estimation de la largeur max du tooltip
    const estimatedHeight = 200; // estimation de la hauteur max du tooltip
    // Vérifier si le tooltip dépasserait à droite
    if (x + estimatedWidth > window.innerWidth) {
      x = e.clientX - estimatedWidth - margin;
    }
    // Vérifier si le tooltip dépasserait en bas
    if (y + estimatedHeight > window.innerHeight) {
      y = e.clientY - estimatedHeight - margin;
    }
    return { x, y };
  };

  // Lors du hover sur le nom d'un employé
  const handleEmployeeTooltipEnter = (employee, e) => {
    const employeeTasks = tasks.filter(
      t => t.owner_id === employee.id && sprints.some(s => s.id === t.sprint_id)
    );
    const { x, y } = calculateTooltipPosition(e);
    setTooltip({
      visible: true,
      x,
      y,
      title: employee.name,
      tasks: employeeTasks,
    });
  };

  // Lors du hover sur le header (nom du projet)
  const handleSprintTooltipEnter = (sprint, e) => {
    const sprintTasks = tasks.filter(t => t.sprint_id === sprint.id);
    const { x, y } = calculateTooltipPosition(e);
    setTooltip({
      visible: true,
      x,
      y,
      title: sprint.project_name,
      tasks: sprintTasks,
    });
  };

  // Fonction de formatage pour le mode normal
  const formatAllocation = (val) => {
    return val === 0 ? "" : val.toFixed(1);
  };

  // Fonction de formatage pour le mode avancement
  const formatProgress = (val) => {
    return val === 0 ? "0" : val.toFixed(1);
  };

  // Fonction pour le hover sur la valeur (allocation jour) dans une cellule
  const handleCellTooltipEnter = (employee, sprint, e) => {
    const cellTasks = tasks.filter(
      t => t.owner_id === employee.id && t.sprint_id === sprint.id
    );
    const { x, y } = calculateTooltipPosition(e);
    setTooltip({
      visible: true,
      x,
      y,
      title: `${employee.name} – ${sprint.project_name}`,
      tasks: cellTasks,
    });
  };

  // Pour masquer le tooltip
  const handleTooltipLeave = () => {
    setTooltip(prev => ({ ...prev, visible: false }));
  };

  return (
    <div className="relative overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {/* Colonne Employés fixée à gauche */}
            <th
              scope="col"
              className="sticky left-0 bg-white z-10 py-3 pl-4 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              Employés
            </th>
            {sprints.map((sprint) => (
              <th
                key={sprint.id}
                scope="col"
                className="px-2 py-2 whitespace-nowrap"
              >
                <div
                  className="text-sm font-medium text-gray-900 mb-1 break-words whitespace-normal max-w-[15ch] cursor-pointer hover:text-blue-600 hover:underline"
                  onMouseEnter={(e) => handleSprintTooltipEnter(sprint, e)}
                  onMouseLeave={handleTooltipLeave}
                  onClick={() => {
                    const params = new URLSearchParams();
                    params.set('project', sprint.project_id);
                    params.set('owner', 'all');
                    // Réinitialiser explicitement le mode de vue à "project"
                    localStorage.setItem("taskBoard_viewMode", "project");
                    navigate(`/tasks?${params.toString()}`);
                  }}
                >
                  {sprint.project_name}
                </div>
              </th>
            ))}
            {/* Colonne Total fixée à droite */}
            <th
              scope="col"
              className="sticky right-0 bg-white z-10 px-3 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
            >
              Total
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {employees.map((employee) => {
            const availableDays = getEmployeeAvailableDays(employee.id);
            let totalEmployeeAllocation = 0;
            let totalEmployeeRealized = 0;
            return (
              <tr key={employee.id} className={employee.is_active ? "" : "bg-gray-50"}>
                {/* Colonne Employés */}
                <td className="sticky left-0 bg-white z-10 py-2 pl-4 pr-3 text-sm whitespace-nowrap">
                  <div
                    className="font-medium text-gray-900 cursor-pointer hover:text-blue-600 hover:underline"
                    onMouseEnter={(e) => handleEmployeeTooltipEnter(employee, e)}
                    onMouseLeave={handleTooltipLeave}
                    onClick={() => {
                    const params = new URLSearchParams();
                    params.set('owner', employee.id);
                    params.set('project', 'all');
                    // Réinitialiser explicitement le mode de vue à "project"
                    localStorage.setItem("taskBoard_viewMode", "project");
                    navigate(`/tasks?${params.toString()}`);
                  }}
                  >
                    {employee.name}
                  </div>
                </td>
                {sprints.map((sprint) => {
                  // Filtrer les tâches de cet employé dans le sprint
                  const sprintTasks = tasks.filter(
                    task => task.owner_id === employee.id && task.sprint_id === sprint.id
                  );
                  const tokenSum = sprintTasks.reduce((sum, task) => sum + Number(task.effort), 0);
                  const computedAllocation = velocity > 0 ? tokenSum / velocity : 0;
                  totalEmployeeAllocation += computedAllocation;
                  if (showProgress) {
                    const realizedTokenSum = sprintTasks
                      .filter(task => task.status === "done")
                      .reduce((sum, task) => sum + Number(task.effort), 0);
                    const realizedAllocation = velocity > 0 ? realizedTokenSum / velocity : 0;
                    totalEmployeeRealized += realizedAllocation;
                    return (
                      <td key={sprint.id} className="px-2 py-2 whitespace-nowrap">
                        <div className="flex flex-col items-center">
                          <span
                            onMouseEnter={(e) => handleCellTooltipEnter(employee, sprint, e)}
                            onMouseLeave={handleTooltipLeave}
                            onClick={() => {
                              if (computedAllocation > 0) {
                                const params = new URLSearchParams();
                                params.set('project', sprint.project_id);
                                params.set('owner', employee.id);
                                params.set('sprint', sprint.id);
                                // Réinitialiser explicitement le mode de vue à "project"
                                localStorage.setItem("taskBoard_viewMode", "project");
                                navigate(`/tasks?${params.toString()}`);
                              }
                            }}
                            className={computedAllocation > 0 ? "cursor-pointer hover:text-blue-600 hover:underline" : ""}
                          >
                            {computedAllocation === 0 ? "" : `${realizedAllocation.toFixed(1)} / ${computedAllocation.toFixed(1)} j`}
                          </span>
                        </div>
                      </td>
                    );
                  } else {
                    return (
                      <td key={sprint.id} className="px-2 py-2 whitespace-nowrap">
                        <div className="flex items-center justify-center">
                          <span
                            onMouseEnter={(e) => handleCellTooltipEnter(employee, sprint, e)}
                            onMouseLeave={handleTooltipLeave}
                            onClick={() => {
                              if (computedAllocation > 0) {
                                const params = new URLSearchParams();
                                params.set('project', sprint.project_id);
                                params.set('owner', employee.id);
                                params.set('sprint', sprint.id);
                                // Réinitialiser explicitement le mode de vue à "project"
                                localStorage.setItem("taskBoard_viewMode", "project");
                                navigate(`/tasks?${params.toString()}`);
                              }
                            }}
                            className={computedAllocation > 0 ? "cursor-pointer hover:text-blue-600 hover:underline" : ""}
                          >
                            {computedAllocation === 0 ? "" : `${computedAllocation.toFixed(1)} j`}
                          </span>
                        </div>
                      </td>
                    );
                  }
                })}
                {/* Colonne Total */}
                <td
                  className={`sticky right-0 bg-white z-10 px-3 py-2 text-sm text-right whitespace-nowrap font-medium ${
                    !showProgress && totalEmployeeAllocation > availableDays ? "text-red-500" : "text-gray-900"
                  }`}
                >
                  {showProgress
                    ? totalEmployeeAllocation === 0
                      ? ""
                      : `${totalEmployeeRealized.toFixed(1)} / ${totalEmployeeAllocation.toFixed(1)} j`
                    : totalEmployeeAllocation === 0
                      ? ""
                      : `${totalEmployeeAllocation.toFixed(1)} / ${availableDays} j`}
                </td>
              </tr>
            );
          })}
        </tbody>
        <tfoot className="bg-gray-50">
          <tr>
            <th
              scope="row"
              className="sticky left-0 bg-white z-10 py-3 pl-4 pr-3 text-sm font-medium text-gray-900 text-right whitespace-nowrap"
            >
              Total
            </th>
            {sprints.map((sprint) => {
              const totalAlloc = employees.reduce((sum, employee) => {
                const sprintTasks = tasks.filter(
                  (task) =>
                    task.owner_id === employee.id &&
                    task.sprint_id === sprint.id
                );
                const tokenSum = sprintTasks.reduce((s, task) => s + Number(task.effort), 0);
                return sum + (velocity > 0 ? tokenSum / velocity : 0);
              }, 0);
              if (showProgress) {
                const totalRealized = employees.reduce((sum, employee) => {
                  const sprintTasks = tasks.filter(
                    (task) =>
                      task.owner_id === employee.id &&
                      task.sprint_id === sprint.id &&
                      task.status === "done"
                  );
                  const realizedSum = sprintTasks.reduce((s, task) => s + Number(task.effort), 0);
                  return sum + (velocity > 0 ? realizedSum / velocity : 0);
                }, 0);
                return (
                  <td key={sprint.id} className="px-2 py-2 whitespace-nowrap">
                    <div className="text-center text-sm font-medium">
                      {totalAlloc === 0 ? "" : `${formatProgress(totalRealized)} / ${formatProgress(totalAlloc)} j`}
                    </div>
                  </td>
                );
              } else {
                return (
                  <td key={sprint.id} className="px-2 py-2 whitespace-nowrap">
                    <div className="text-center text-sm font-medium">
                      {totalAlloc === 0 ? "" : `${formatAllocation(totalAlloc)} j`}
                    </div>
                  </td>
                );
              }
            })}
            <td className="sticky right-0 bg-white z-10"></td>
          </tr>
        </tfoot>
      </table>
      {/* Tooltip personnalisé */}
      {tooltip.visible && (
        <div
          className="fixed bg-white border border-gray-300 shadow-lg p-2 rounded text-sm z-50 tooltip-container"
          style={{ left: tooltip.x, top: tooltip.y }}
        >
          <div className="font-semibold mb-1">{tooltip.title}</div>
          <ul className="list-none">
            {tooltip.tasks.length > 0 ? (
              tooltip.tasks.map(task => (
                <li key={task.id} className="flex items-center mb-1">
                  {/* Icône d'état de la tâche */}
                  <span className="mr-2 inline-flex items-center justify-center">
                    {task.status === "done" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                      </svg>
                    ) : task.status === "in_progress" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clipRule="evenodd" />
                      </svg>
                    ) : task.status === "blocked" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-red-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                      </svg>
                    ) : task.status === "backlog" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM14 11a1 1 0 011 1v1h1a1 1 0 110 2h-1v1a1 1 0 11-2 0v-1h-1a1 1 0 110-2h1v-1a1 1 0 011-1z" />
                      </svg>
                    ) : task.status === "ready" ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z" clipRule="evenodd" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
                      </svg>
                    )}
                  </span>
                  <span>
                    {task.name} <span className="text-gray-500">({(velocity > 0 ? Number(task.effort) / velocity : 0).toFixed(1)}j)</span>
                  </span>
                </li>
              ))
            ) : (
              <li>Aucune tâche</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default WorkloadManagement;