import React, { useState, useEffect, useMemo } from 'react';
import { apiService } from '../../services/api';

const InvoiceCreationForm = ({ 
  projects, 
  fetchInvoices, 
  computeMontant,
  setMessage,
  setError
}) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [iterations, setIterations] = useState([]);
  const [allocations, setAllocations] = useState({});
  const [affacturation, setAffacturation] = useState(false);
  const [commission, setCommission] = useState(3.0);
  const [lotName, setLotName] = useState("");
  const [billingDateType, setBillingDateType] = useState("iteration_end");
  const [customBillingDate, setCustomBillingDate] = useState("");

  // Charger les itérations lorsqu'un projet est sélectionné
  useEffect(() => {
    if (selectedProject) {
      apiService.get(`/projects/${selectedProject.id}/iterations`)
        .then(data => {
          setIterations(data);
          const initAlloc = {};
          data.forEach(iter => {
            initAlloc[iter.id] = {
              selected: false, // Par défaut, les itérations ne sont pas sélectionnées
              percentage: 100,
              ca: computeMontant(iter, 100)
            };
          });
          setAllocations(initAlloc);
        })
        .catch(err => setError(err.message));
    } else {
      setIterations([]);
      setAllocations({});
    }
  }, [selectedProject, computeMontant, setError]);

  // Mettre à jour le type de date de facturation par défaut en fonction des itérations sélectionnées
  useEffect(() => {
    // Compter les itérations sélectionnées
    const selectedCount = Object.values(allocations).filter(alloc => alloc.selected).length;
    
    // Si plus d'une itération est sélectionnée, choisir "Début de projet" par défaut
    if (selectedCount > 1) {
      setBillingDateType("project_start");
    } else if (selectedCount === 1) {
      // Sinon, si une seule est sélectionnée, choisir "Fin d'itération" par défaut
      setBillingDateType("iteration_end");
    }
  }, [allocations]);

  const resetCreateForm = () => {
    setSelectedProject(null);
    setIterations([]);
    setAllocations({});
    setAffacturation(false);
    setCommission(3.0);
    setLotName("");
    setBillingDateType("iteration_end");
    setCustomBillingDate("");
  };

  const handleCreationPercentageChange = (iter, value) => {
    const newPercentage = parseFloat(value);
    if (isNaN(newPercentage)) return;
    setAllocations(prev => ({
      ...prev,
      [iter.id]: {
        ...prev[iter.id],
        percentage: newPercentage,
        ca: computeMontant(iter, newPercentage, affacturation, commission)
      }
    }));
  };
  
  const handleCreationCAChange = (iter, value) => {
    const newCA = parseFloat(value);
    if (isNaN(newCA)) return;
    const base = iter.valeur;
    // Calculer le pourcentage exact sans arrondir
    const newPercentage = base > 0 ? (newCA * 100) / base : 0;
    setAllocations(prev => ({
      ...prev,
      [iter.id]: {
        ...prev[iter.id],
        ca: newCA,
        percentage: newPercentage
      }
    }));
  };

  const handleProjectChange = (e) => {
    const projectId = parseInt(e.target.value);
    const proj = projects.find(p => p.id === projectId);
    setSelectedProject(proj);
  };

  const handleAllocationChange = (iterId, field, value) => {
    setAllocations(prev => ({
      ...prev,
      [iterId]: {
        ...prev[iterId],
        [field]: field === 'percentage' ? parseFloat(value) : value
      }
    }));
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    if (!selectedProject) {
      setError("Veuillez sélectionner un projet");
      return;
    }
    const selectedAllocations = [];
    Object.keys(allocations).forEach(iterId => {
      if (allocations[iterId].selected) {
        selectedAllocations.push({
          iteration_id: parseInt(iterId),
          percentage: allocations[iterId].percentage
        });
      }
    });
    if (selectedAllocations.length === 0) {
      setError("Veuillez sélectionner au moins une itération");
      return;
    }

    // Vérifier que la date personnalisée est définie si nécessaire
    if (billingDateType === "custom" && !customBillingDate) {
      setError("Veuillez définir une date de facturation personnalisée");
      return;
    }

    const payload = {
      project_id: selectedProject.id,
      affacturation,
      commission,
      allocations: selectedAllocations,
      status: "a_facturer",
      lot_name: lotName,
      billing_date_type: billingDateType,
      custom_billing_date: billingDateType === "custom" ? customBillingDate : null
    };
    try {
      await apiService.post('/factures', payload);
      setMessage("Lot de facturation créé avec succès");
      resetCreateForm();
      fetchInvoices();
    } catch (err) {
      setError(err.message);
    }
  };

  // Calculer le total du lot
  const totalLot = useMemo(() => {
    let total = 0;
    
    // Parcourir toutes les allocations sélectionnées
    Object.keys(allocations).forEach(iterId => {
      if (allocations[iterId].selected) {
        const iteration = iterations.find(iter => iter.id === parseInt(iterId));
        if (iteration) {
          // Calculer le montant de cette allocation avec commission si applicable
          const montant = computeMontant(
            iteration, 
            allocations[iterId].percentage,
            affacturation,
            commission
          );
          total += montant;
        }
      }
    });
    
    return total;
  }, [allocations, iterations, affacturation, commission, computeMontant]);

  // Déterminer les dates de début et de fin
  const projectDates = useMemo(() => {
    if (!iterations.length) return { start: null, end: null };
    
    let earliestStart = null;
    let latestEnd = null;

    // Trouver les itérations sélectionnées
    const selectedIterIds = Object.keys(allocations)
      .filter(id => allocations[id]?.selected)
      .map(id => parseInt(id));

    // Si au moins une itération est sélectionnée, utiliser seulement les dates de ces itérations
    if (selectedIterIds.length > 0) {
      const selectedIterations = iterations.filter(iter => selectedIterIds.includes(iter.id));
      
      selectedIterations.forEach(iter => {
        if (iter.start_date && (!earliestStart || iter.start_date < earliestStart)) {
          earliestStart = iter.start_date;
        }
        if (iter.end_date && (!latestEnd || iter.end_date > latestEnd)) {
          latestEnd = iter.end_date;
        }
      });
    } else {
      // Sinon utiliser toutes les itérations (comportement initial)
      iterations.forEach(iter => {
        if (iter.start_date && (!earliestStart || iter.start_date < earliestStart)) {
          earliestStart = iter.start_date;
        }
        if (iter.end_date && (!latestEnd || iter.end_date > latestEnd)) {
          latestEnd = iter.end_date;
        }
      });
    }
    
    return { start: earliestStart, end: latestEnd };
  }, [iterations, allocations]);

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-2xl font-semibold mb-4">Créer un nouveau lot</h2>
      <form onSubmit={handleCreate} className="space-y-4">
        <div>
          <label className="block font-medium mb-1">Projet</label>
          <select
            value={selectedProject ? selectedProject.id : ''}
            onChange={handleProjectChange}
            className="border rounded p-2 w-full"
          >
            <option value="">Sélectionnez un projet</option>
            {projects.map(proj => (
              <option key={proj.id} value={proj.id}>
                {proj.name} (#{proj.project_number})
              </option>
            ))}
          </select>
        </div>
        <div className="mt-4">
          <label className="block font-medium mb-1">Nom du lot</label>
          <input
            type="text"
            value={lotName}
            onChange={(e) => setLotName(e.target.value)}
            placeholder="Laisser vide pour utiliser le numéro généré"
            className="border rounded p-2 w-full"
          />
        </div>
        {selectedProject && iterations.length > 0 && (
          <div>
            <h3 className="font-medium mb-2">Sélectionnez les itérations</h3>
            <div className="space-y-2">
              {iterations.map(iter => (
                <div key={iter.id} className="flex items-center gap-4 border p-2 rounded">
                  <input
                    type="checkbox"
                    checked={allocations[iter.id]?.selected || false}
                    onChange={(e) => handleAllocationChange(iter.id, 'selected', e.target.checked)}
                    className="form-checkbox"
                  />
                  <div className="flex-1">
                    <div className="font-semibold">{iter.name}</div>
                    <div className="text-sm text-gray-600">
                      Valeur totale : {iter.valeur.toFixed(2)} €
                    </div>
                    <div className="text-xs text-gray-500">
                      {iter.start_date && `Début: ${new Date(iter.start_date).toLocaleDateString()}`}
                      {iter.start_date && iter.end_date && ' - '}
                      {iter.end_date && `Fin: ${new Date(iter.end_date).toLocaleDateString()}`}
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div>
                      <label className="block text-sm">%</label>
                      <input
                        type="number"
                        min="0"
                        max="100"
                        step="0.01"
                        value={allocations[iter.id]?.percentage ? Number(allocations[iter.id].percentage.toFixed(2)) : 100}
                        onChange={(e) => handleCreationPercentageChange(iter, e.target.value)}
                        className="border rounded p-1 w-16"
                      />
                    </div>
                    <div>
                      <label className="block text-sm">CA</label>
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        value={
                          allocations[iter.id]?.ca !== undefined
                            ? allocations[iter.id].ca.toFixed(2)
                            : computeMontant(iter, allocations[iter.id]?.percentage || 100, affacturation, commission)
                        }
                        onChange={(e) => handleCreationCAChange(iter, e.target.value)}
                        className="border rounded p-1 w-20"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Options de date de facturation */}
        {selectedProject && iterations.length > 0 && (
          <div className="space-y-3 border-t pt-4">
            <h3 className="font-medium">Date de facturation</h3>
            <div className="space-y-2">
              <div className="flex items-center">
                <input
                  type="radio"
                  id="iteration_end"
                  name="billing_date_type"
                  value="iteration_end"
                  checked={billingDateType === "iteration_end"}
                  onChange={() => setBillingDateType("iteration_end")}
                  className="mr-2"
                />
                <label htmlFor="iteration_end">
                  Fin d'itération ({projectDates.end ? new Date(projectDates.end).toLocaleDateString() : 'N/A'})
                </label>
              </div>
              
              <div className="flex items-center">
                <input
                  type="radio"
                  id="project_start"
                  name="billing_date_type"
                  value="project_start"
                  checked={billingDateType === "project_start"}
                  onChange={() => setBillingDateType("project_start")}
                  className="mr-2"
                />
                <label htmlFor="project_start">
                  Début de projet ({projectDates.start ? new Date(projectDates.start).toLocaleDateString() : 'N/A'})
                </label>
              </div>
              
              <div className="flex items-center">
                <input
                  type="radio"
                  id="custom"
                  name="billing_date_type"
                  value="custom"
                  checked={billingDateType === "custom"}
                  onChange={() => setBillingDateType("custom")}
                  className="mr-2"
                />
                <label htmlFor="custom" className="mr-2">Personnalisée:</label>
                <input
                  type="date"
                  value={customBillingDate}
                  onChange={(e) => setCustomBillingDate(e.target.value)}
                  disabled={billingDateType !== "custom"}
                  className="border rounded p-1"
                />
              </div>
            </div>
          </div>
        )}

        <div className="space-y-3">
          <div className="flex items-center gap-3">
            <label className="font-medium">Affacturation</label>
            <input
              type="checkbox"
              checked={affacturation}
              onChange={(e) => setAffacturation(e.target.checked)}
              className="h-5 w-5"
            />
          </div>
          
          {affacturation && (
            <div className="pl-5 flex items-center gap-3">
              <label className="font-medium">Commission (%)</label>
              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                value={commission}
                onChange={(e) => setCommission(parseFloat(e.target.value))}
                className="border rounded p-1 w-20"
              />
              <span className="text-sm text-gray-600">Pourcentage prélevé par la société d'affacturage</span>
            </div>
          )}
        </div>

        {/* Afficher le total du lot */}
        {selectedProject && iterations.length > 0 && (
          <div className="font-semibold text-lg border-t pt-4 mt-4">
            Total du lot : {totalLot.toFixed(2)} €
            {affacturation && ` (après commission de ${commission}%)`}
          </div>
        )}

        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
          Créer le lot
        </button>
      </form>
    </div>
  );
};

export default InvoiceCreationForm;