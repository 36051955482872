import React, { useState, useRef } from 'react';
import { Clock, Target, ExternalLink } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import CurrentDayLine from '../CurrentDayLine';
import { getWeekNumber } from '../../utils/dateUtils';

const SprintsTimeline = ({ slot, sprints }) => {
  const navigate = useNavigate();
  const [hoveredSprint, setHoveredSprint] = useState(null);
  const timelineRef = useRef(null);

  // Dates de début et fin du slot
  const startDate = new Date(slot.start_date);
  const endDate = new Date(slot.end_date);
  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));

  // Générer les mois pour l'affichage
  const months = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    months.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }


  // Générer les semaines pour l'affichage
  const weeks = [];
  currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    weeks.push({
      date: new Date(currentDate),
      weekNumber: getWeekNumber(currentDate)
    });
    currentDate.setDate(currentDate.getDate() + 7);
  }

  // Calculer la position et la largeur d'un élément sur la timeline
  const getTimelineStyles = (start, end) => {
    const elementStart = new Date(Math.max(new Date(start), startDate));
    const elementEnd = new Date(Math.min(new Date(end), endDate));
    
    const left = ((elementStart - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
    const width = ((elementEnd - elementStart) / (1000 * 60 * 60 * 24)) / totalDays * 100;
    
    return { left: `${left}%`, width: `${width}%` };
  };

  // Calculer le pourcentage d'avancement d'un sprint
  const getCompletionPercentage = (sprint) => {
    if (!sprint.total_tokens || sprint.total_tokens === 0) return 0;
    return (sprint.tokens_completed / sprint.total_tokens) * 100;
  };

  // Composant Tooltip pour afficher les détails au survol
  const Tooltip = ({ sprint }) => {
    if (!sprint) return null;
    
    return (
      <div className="absolute bottom-full left-0 mb-2 bg-gray-800 text-white p-2 rounded shadow-lg z-50 whitespace-nowrap">
        <div className="text-xs">
          <div className="font-medium">{sprint.project_name} (#{sprint.project_number})</div>
          <div>{sprint.description}</div>
          <div>{new Date(sprint.start_date).toLocaleDateString()} - {new Date(sprint.end_date).toLocaleDateString()}</div>
          <div className="flex items-center mt-1">
            <Target className="h-3 w-3 mr-1" />
            <span>
              {sprint.tokens_completed}/{sprint.total_tokens} tokens 
              ({Math.round(getCompletionPercentage(sprint))}%)
            </span>
          </div>
        </div>
      </div>
    );
  };

  // Grouper les sprints par projet
  console.log("Sprints reçus:", sprints);
  const sprintsByProject = sprints.reduce((acc, sprint) => {
    // Log pour debug
    console.log("Traitement sprint:", {
      project_number: sprint.project_number,
      project_name: sprint.project_name,
      project_id: sprint.project_id
    });
  
    // Utiliser une clé qui sera toujours valide
    const projectKey = sprint.project_name || sprint.description || 'Projet inconnu';
    
    if (!acc[projectKey]) {
      acc[projectKey] = {
        projectId: sprint.project_id, // Peut être undefined
        name: sprint.project_name || 'Projet inconnu',
        number: sprint.project_number || 'N/A',
        sprints: []
      };
    }
    acc[projectKey].sprints.push(sprint);
    return acc;
  }, {});

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-6 flex items-center">
        <Clock className="h-5 w-5 mr-2" />
        Sprints Timeline
      </h2>

      <div className="relative select-none">
        {/* En-tête des mois */}
        <div className="h-8 relative mb-2 border-b border-gray-200">
          {months.map((date, index) => {
            const monthPosition = ((date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
            const nextMonth = new Date(date);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            const nextMonthPosition = Math.min(
              ((nextMonth - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100,
              100
            );
            const monthWidth = nextMonthPosition - monthPosition;
            
            return (
              <div
                key={`month-${index}`}
                className="absolute text-sm text-gray-600"
                style={{ left: `${monthPosition}%`, width: `${monthWidth}%` }}
              >
                <div className="text-center">
                  {date.toLocaleDateString('fr-FR', { month: 'long' })}
                </div>
              </div>
            );
          })}
        </div>

        {/* Numéros des semaines */}
        <div className="relative border-b border-gray-300 mb-4">
          <div className="flex">
            {weeks.map((week, index) => (
              <div key={`week-${index}`} className="flex-1 text-center">
                <div className="text-xs text-gray-500 pb-2">W{week.weekNumber}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Timeline principale */}
        <div ref={timelineRef} className="relative">
          {/* Lignes de grille pour les mois */}
          <div className="absolute w-full h-full pointer-events-none">
            {months.map((date, index) => (
              <div
                key={`month-line-${index}`}
                className="absolute h-full border-l border-gray-200"
                style={{ left: `${((date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100}%` }}
              />
            ))}
          </div>

          {/* Lignes de grille pour les semaines */}
          <div className="absolute w-full h-full pointer-events-none">
            {weeks.map((week, index) => (
              <div
                key={`week-line-${index}`}
                className="absolute h-full border-l border-gray-100"
                style={{ left: `${((week.date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100}%` }}
              />
            ))}
          </div>

          {/* Ligne du jour actuel */}
          <CurrentDayLine slot={slot} startDate={startDate} endDate={endDate} />

          {/* Sprints groupés par projet */}
          <div className="relative space-y-8">
            {Object.entries(sprintsByProject).map(([projectKey, projectData]) => (
              <div key={projectKey} className="space-y-2">
                {/* En-tête du projet cliquable */}
                <button
                  onClick={() => {
                    if (projectData.projectId) {  // Vérification explicite
                      navigate(`/project/${projectData.projectId}`);
                    }
                  }}
                  className="group text-sm font-medium text-gray-700 mb-2 hover:text-blue-600 flex items-center"
                >
                  {projectData.name} (#{projectData.number})
                  <ExternalLink className="h-3 w-3 ml-1 opacity-0 group-hover:opacity-100 transition-opacity" />
                </button>

                {/* Sprints du projet */}
                {projectData.sprints.map((sprint) => (
                  <div 
                    key={sprint.id} 
                    className="relative h-8"
                    onMouseEnter={() => setHoveredSprint(sprint)}
                    onMouseLeave={() => setHoveredSprint(null)}
                  >
                    <div
                      className="absolute h-8 bg-indigo-50 border border-indigo-200 rounded flex items-center overflow-hidden group"
                      style={getTimelineStyles(sprint.start_date, sprint.end_date)}
                    >
                      {hoveredSprint === sprint && <Tooltip sprint={sprint} />}
                      
                      {/* Barre de progression */}
                      <div 
                        className="absolute inset-0 bg-indigo-200 opacity-75"
                        style={{ 
                          width: `${getCompletionPercentage(sprint)}%`,
                          background: 'linear-gradient(90deg, rgb(129, 140, 248, 0.6), rgb(99, 102, 241, 0.7))'
                        }} 
                      />
                      
                      {/* Description du sprint */}
                      <span className="text-xs text-indigo-700 truncate relative z-10 px-2">
                        {sprint.description}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SprintsTimeline;