import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, Trash2, Edit } from 'lucide-react';
import { getWeekNumber, getMonday as getStartOfWeek, getWorkingDaysDates } from '../../utils/dateUtils';
import GlobalCurrentDayLine from '../GlobalCurrentDayLine';
import { apiService } from '../../services/api';

const ConfirmSlotDelete = ({ isOpen, slot, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
        <h2 className="text-lg font-semibold mb-4">
          Êtes-vous sûr de vouloir supprimer ce slot ?
        </h2>
        <p className="text-gray-600 mb-6">
          Cette action supprimera définitivement le slot "{slot?.name}". 
          Cette action est irréversible.
        </p>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
          >
            Annuler
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-md"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>
  );
};

const EditSlotModal = ({ isOpen, slot, onSave, onClose }) => {
  const [slotName, setSlotName] = useState(slot?.name || '');

  useEffect(() => {
    setSlotName(slot?.name || '');
  }, [slot]);

  const handleNameChange = (e) => {
    setSlotName(e.target.value);
  };

  const handleSave = () => {
    onSave({ ...slot, name: slotName });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 shadow-xl">
        <h2 className="text-lg font-semibold mb-4">Modifier le Slot</h2>
        <div className="mb-4">
          <label htmlFor="slotName" className="block text-sm font-medium text-gray-700">
            Nom du Slot
          </label>
          <input
            type="text"
            id="slotName"
            value={slotName}
            onChange={handleNameChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded-md"
          >
            Annuler
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 text-sm font-medium text-white bg-green-500 hover:bg-green-600 rounded-md"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  );
};

const SlotsTimeline = ({ slots, onSlotUpdate, setSlots, onDeleteSlot, employees }) => {
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(null);
  const [resizing, setResizing] = useState(null);
  const [tempChanges, setTempChanges] = useState(null);
  const [hoveredSlot, setHoveredSlot] = useState(null);
  const [isDraggingOrResizing, setIsDraggingOrResizing] = useState(false);
  const timelineRef = useRef(null);
  const dragStartTimeRef = useRef(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    slot: null
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEditingSlot, setCurrentEditingSlot] = useState(null);

  const [timelineRange, setTimelineRange] = useState(() => {
    const today = new Date();
    // On positionne le début sur le premier jour du mois précédent (gestion automatique de l'année)
    const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    // La fenêtre de 3 mois complète depuis ce début
    const end = new Date(start.getFullYear(), start.getMonth() + 3, 0);
    return { start, end };
  });
  
  
  const startDate = timelineRange.start;
  const endDate = timelineRange.end;
  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  
  const handlePrevTimeline = () => {
    setTimelineRange(prev => {
      const newStart = new Date(prev.start.getFullYear(), prev.start.getMonth() - 1, 1);
      const newEnd = new Date(newStart.getFullYear(), newStart.getMonth() + 3, 0);
      return { start: newStart, end: newEnd };
    });
  };
  
  const handleNextTimeline = () => {
    setTimelineRange(prev => {
      const newStart = new Date(prev.start.getFullYear(), prev.start.getMonth() + 1, 1);
      const newEnd = new Date(newStart.getFullYear(), newStart.getMonth() + 3, 0);
      return { start: newStart, end: newEnd };
    });
  };
  
  // Générer les semaines avec un alignement correct
  const getWeeks = () => {
    const weeks = [];
    let currentDate = getStartOfWeek(new Date(startDate));

    // Si le premier lundi est après notre date de début, ajouter une semaine avant
    if (currentDate > startDate) {
      currentDate.setDate(currentDate.getDate() - 7);
    }

    while (currentDate <= endDate) {
      weeks.push({
        date: new Date(currentDate),
        weekNumber: getWeekNumber(currentDate)
      });
      currentDate.setDate(currentDate.getDate() + 7);
    }

    return weeks;
  };
  
  const weeks = getWeeks();

  // Générer les mois pour l'affichage
  const months = [];
  let currentDate = new Date(startDate);
  while (currentDate <= endDate) {
    months.push(new Date(currentDate));
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  const handleMouseDown = (e, slot, action = 'move') => {
    e.preventDefault();
    dragStartTimeRef.current = {
      time: new Date().getTime(),
      x: e.clientX,
    };
    setIsDraggingOrResizing(true);

    const rect = timelineRef.current.getBoundingClientRect();
    const startX = e.clientX - rect.left;

    if (action === 'resize') {
      e.stopPropagation();
      setResizing({
        slot,
        edge: e.target.dataset.edge,
        startX,
        originalStart: new Date(slot.start_date),
        originalEnd: new Date(slot.end_date)
      });
    } else {
      setDragging({
        slot,
        startX,
        originalStart: new Date(slot.start_date),
        originalEnd: new Date(slot.end_date)
      });
    }
  };

  const handleMouseUp = async (e) => {
    if (!dragStartTimeRef.current) return;

    const dragEndTime = new Date().getTime();
    const dragDuration = dragEndTime - dragStartTimeRef.current.time;
    const mouseMoved = Math.abs(e.clientX - dragStartTimeRef.current.x) > 5;
    const wasJustAClick = dragDuration < 200 && !mouseMoved;

    if (tempChanges) {
      try {
        const finalChanges = { ...tempChanges };
        await onSlotUpdate(finalChanges.id, finalChanges.changes);
      } catch (error) {
        setSlots(slots);
        console.error('Échec de la mise à jour:', error);
      }
    }

    // Suppression de la navigation automatique ici
    setDragging(null);
    setResizing(null);
    setTempChanges(null);
    setIsDraggingOrResizing(false);
    dragStartTimeRef.current = null;
  };

  const handleDelete = (e, slotId) => {
    e.preventDefault();
    e.stopPropagation();
    const slot = slots.find(s => s.id === slotId);
    setDeleteConfirmation({
      isOpen: true,
      slot: slot
    });
    return false;
  };

  const handleConfirmDelete = async () => {
    if (deleteConfirmation.slot) {
      await onDeleteSlot(deleteConfirmation.slot.id);
      setDeleteConfirmation({
        isOpen: false,
        slot: null
      });
    }
  };

  const handleCancelDelete = () => {
    setDeleteConfirmation({
      isOpen: false,
      slot: null
    });
  };

  const handleMouseMove = (e) => {
    if (!dragging && !resizing) return;

    const rect = timelineRef.current.getBoundingClientRect();
    const currentX = e.clientX - rect.left;
    const pixelsPerDay = rect.width / totalDays;

    if (resizing) {
      const { slot, edge, originalStart, originalEnd } = resizing;
      let daysDiff = Math.round((currentX - resizing.startX) / pixelsPerDay);

      // Ajuster pour aligner sur les jours de la semaine
      const updatedSlot = { ...slot };

      if (edge === 'start') {
        let newStart = new Date(originalStart);
        newStart.setDate(newStart.getDate() + daysDiff);
        if (newStart >= new Date(slot.end_date)) {
          newStart = new Date(slot.end_date);
          newStart.setDate(newStart.getDate() - 1);
        }
        updatedSlot.start_date = newStart.toISOString().split('T')[0];
      } else {
        let newEnd = new Date(originalEnd);
        newEnd.setDate(newEnd.getDate() + daysDiff);
        if (newEnd <= new Date(slot.start_date)) {
          newEnd = new Date(slot.start_date);
          newEnd.setDate(newEnd.getDate() + 1);
        }
        updatedSlot.end_date = newEnd.toISOString().split('T')[0];
      }

      setTempChanges({
        id: slot.id,
        changes: updatedSlot
      });

      setSlots(prevSlots => 
        prevSlots.map(s => s.id === slot.id ? { ...s, ...updatedSlot } : s)
      );
    }

    if (dragging) {
      const { slot, originalStart, originalEnd } = dragging;
      const daysDiff = Math.round((currentX - dragging.startX) / pixelsPerDay);

      const duration = originalEnd - originalStart;
      let newStart = new Date(originalStart);
      newStart.setDate(newStart.getDate() + daysDiff);
      let newEnd = new Date(newStart);
      newEnd.setTime(newEnd.getTime() + duration);

      const updatedSlot = {
        start_date: newStart.toISOString().split('T')[0],
        end_date: newEnd.toISOString().split('T')[0]
      };

      setTempChanges({
        id: slot.id,
        changes: updatedSlot
      });

      setSlots(prevSlots => 
        prevSlots.map(s => s.id === slot.id ? { ...s, ...updatedSlot } : s)
      );
    }
  };

  const handleSlotClick = (e, slotId) => {
    if (!isDraggingOrResizing) {
      navigate(`/slot/${slotId}`);
    }
  };

  const handleEdit = (e, slot) => {
    e.stopPropagation();
    setCurrentEditingSlot(slot);
    setIsEditModalOpen(true);
  };

  const handleSaveEdit = async (updatedSlot) => {
    try {
      await onSlotUpdate(updatedSlot.id, updatedSlot);
      setSlots((prevSlots) =>
        prevSlots.map((s) => (s.id === updatedSlot.id ? updatedSlot : s))
      );
      setIsEditModalOpen(false);
      setCurrentEditingSlot(null);
    } catch (error) {
      console.error('Échec de la mise à jour du slot:', error);
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentEditingSlot(null);
  };

  useEffect(() => {
    if (dragging || resizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [dragging, resizing, tempChanges]);

  const getTimelineStyles = (slotStartStr, slotEndStr) => {
    const rawStart = new Date(slotStartStr);
    const rawEnd = new Date(slotEndStr);
    // On "clippe" le début et la fin du slot à la fenêtre de la timeline
    const clipStart = new Date(Math.max(rawStart, startDate));
    const clipEnd = new Date(Math.min(rawEnd, endDate));
    // Si il n'y a pas d'intersection, retourner width 0
    if (clipEnd <= clipStart) {
      return { left: '0%', width: '0%' };
    }
    const left = ((clipStart - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
    const width = ((clipEnd - clipStart) / (1000 * 60 * 60 * 24)) / totalDays * 100;
    return { left: `${left}%`, width: `${width}%` };
  };
  

  const Tooltip = ({ slot }) => {
    if (!slot) return null;
    return (
      <div className="absolute bottom-full left-0 mb-2 bg-gray-800 text-white p-2 rounded shadow-lg z-50 whitespace-nowrap">
        <div className="text-xs">
          <div className="font-medium">{slot.name}</div>
          <div>
            Du {new Date(slot.start_date).toLocaleDateString('fr-FR')} {" "}
            au {new Date(slot.end_date).toLocaleDateString('fr-FR')}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-6 flex items-center">
        <Clock className="h-5 w-5 mr-2" />
        Timeline des Slots
      </h2>
      <div className="flex justify-between items-center mb-2">
        <button onClick={handlePrevTimeline} className="px-2 py-1 text-sm bg-gray-200 rounded">
        </button>
        <div className="text-sm font-medium">
          {startDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}
          {" - "}
          {endDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}
        </div>
        <button onClick={handleNextTimeline} className="px-2 py-1 text-sm bg-gray-200 rounded">

        </button>
      </div>

      <div className="relative select-none">
        {/* En-tête des mois */}
        <div className="h-8 relative mb-2 border-b border-gray-200">
          {months.map((date, index) => {
            const monthPosition = ((date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
            const nextMonth = new Date(date);
            nextMonth.setMonth(nextMonth.getMonth() + 1);
            const nextMonthPosition = ((nextMonth - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
            const monthWidth = nextMonthPosition - monthPosition;

            return (
              <div
                key={`month-${index}`}
                className="absolute text-sm text-gray-600"
                style={{ left: `${monthPosition}%`, width: `${monthWidth}%` }}
              >
                <div className="text-center">
                  {date.toLocaleDateString('fr-FR', { month: 'long' })}
                </div>
              </div>
            );
          })}
        </div>

        {/* Numéros des semaines */}
        <div className="relative border-b border-gray-300">
          <div className="flex">
            {weeks.map((week, index) => (
              <div key={`week-${index}`} className="flex-1 text-center">
                <div className="text-xs text-gray-500 pb-2">S{week.weekNumber}</div>
              </div>
            ))}
          </div>
        </div>

        {/* Timeline grid */}
        <div ref={timelineRef} className="relative">
          {/* Grid lines for months */}
          <div className="absolute w-full h-full pointer-events-none">
            {months.map((date, index) => {
              const monthPosition = ((date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
              return (
                <div
                  key={`month-line-${index}`}
                  className="absolute h-full border-l border-gray-200"
                  style={{ left: `${monthPosition}%` }}
                />
              );
            })}
          </div>

          {/* Grid lines for weeks */}
          <div className="absolute w-full h-full pointer-events-none">
            {weeks.map((week, index) => {
              const weekPosition = ((week.date - startDate) / (1000 * 60 * 60 * 24)) / totalDays * 100;
              return (
                <div
                  key={`week-line-${index}`}
                  className="absolute h-full border-l border-gray-100"
                  style={{ 
                    left: `${weekPosition}%`,
                    borderStyle: 'solid'  // Tous les traits sont solides car ils représentent les lundis
                  }}
                />
              );
            })}
          </div>

        {/* Ligne du jour actuel (utiliser GlobalCurrentDayLine) */}
        <GlobalCurrentDayLine
          key="current-day-line"
          startDate={startDate}
          endDate={endDate}
        />

          {/* Timeline items */}
          <div className="relative space-y-4">
          {slots
            .filter(slot => {
              const s = new Date(slot.start_date);
              const e = new Date(slot.end_date);
              return e >= startDate && s <= endDate; // ne conserver que les slots dont l'intervalle chevauche la timeline
            })
            .map((slot) => (
            <div key={slot.id} className="relative h-24" 
                onMouseEnter={() => setHoveredSlot(slot)}
                onMouseLeave={() => setHoveredSlot(null)}>
              <div className="absolute h-full bg-purple-100 border border-purple-300 rounded flex flex-col justify-between px-2 group hover:bg-purple-200 transition-colors"
                  style={getTimelineStyles(slot.start_date, slot.end_date)}
                  onMouseDown={(e) => handleMouseDown(e, slot)}
                  onClick={(e) => handleSlotClick(e, slot.id)}>
                {/* Ligne d'en-tête : affichage du nom et des actions */}
                <div className="flex items-center justify-between pointer-events-none">
                  <span className="text-sm font-medium text-purple-800 truncate">
                    {slot.name}
                  </span>
                  <div className="flex pointer-events-auto space-x-2">
                    <button onClick={(e) => handleEdit(e, slot)} className="p-1 text-blue-500 hover:text-blue-700 rounded opacity-0 group-hover:opacity-100 transition-opacity" title="Modifier le slot">
                      <Edit className="h-4 w-4" />
                    </button>
                    <button onClick={(e) => handleDelete(e, slot.id)} className="p-1 text-red-500 hover:text-red-700 rounded opacity-0 group-hover:opacity-100 transition-opacity" title="Supprimer le slot">
                      <Trash2 className="h-4 w-4" />
                    </button>
                  </div>
                </div>
                {/* Bloc d'infos complémentaires (mini graph de progression et vélocité/productivité) */}
                <SlotMiniInfo slot={slot} employees={employees} />
                {/* Boutons de redimensionnement */}
                <div className="absolute left-0 top-0 w-2 h-full cursor-ew-resize opacity-0 group-hover:opacity-100 bg-purple-300"
                    data-edge="start"
                    onMouseDown={(e) => handleMouseDown(e, slot, 'resize')} />
                <div className="absolute right-0 top-0 w-2 h-full cursor-ew-resize opacity-0 group-hover:opacity-100 bg-purple-300"
                    data-edge="end"
                    onMouseDown={(e) => handleMouseDown(e, slot, 'resize')} />
              </div>
            </div>
          ))}
          </div>
        </div>
      </div>
      <ConfirmSlotDelete 
        isOpen={deleteConfirmation.isOpen}
        slot={deleteConfirmation.slot}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
      <EditSlotModal
        isOpen={isEditModalOpen}
        slot={currentEditingSlot}
        onSave={handleSaveEdit}
        onClose={handleCloseEditModal}
      />
    </div>
  );
};

// Fonction utilitaire pour forcer la conversion d'une date en minuit
const parseDate = (dateStr) => new Date(dateStr + "T00:00:00");

const SlotMiniInfo = ({ slot, employees }) => {
  const [slotDetail, setSlotDetail] = useState(null);

  useEffect(() => {
    apiService.get(`/slots/${slot.id}`)
      .then(data => {
        setSlotDetail(data);
      })
      .catch(error => {
        console.error("Erreur lors du chargement du slot détaillé :", error);
      });
  }, [slot.id]);

  if (!slotDetail) {
    return <div className="m-0 p-0 text-xs text-gray-500">Chargement…</div>;
  }

  // Calcul des tokens en appliquant le même arrondi que dans SlotProgress
  const calculateSprintTokensInSlot = (sprint) => {
    const sprintStart = parseDate(sprint.start_date);
    const sprintEnd = parseDate(sprint.end_date);
    const slotStart = parseDate(slotDetail.start_date);
    const slotEnd = parseDate(slotDetail.end_date);
    const intersectionStart = new Date(Math.max(sprintStart, slotStart));
    const intersectionEnd = new Date(Math.min(sprintEnd, slotEnd));
    if (intersectionEnd < intersectionStart) {
      return { totalTokens: 0, completedTokens: 0 };
    }
    const totalSprintDays = getWorkingDaysDates(sprintStart, sprintEnd).length;
    const intersectionDays = getWorkingDaysDates(intersectionStart, intersectionEnd).length;
    const ratio = totalSprintDays > 0 ? intersectionDays / totalSprintDays : 0;
    return {
      totalTokens: Math.round(sprint.total_tokens * ratio),
      completedTokens: Math.round(sprint.tokens_completed * ratio)
    };
  };

  const calculateSprintCAInSlot = (sprint) => {
    const sprintStart = parseDate(sprint.start_date);
    const sprintEnd = parseDate(sprint.end_date);
    const slotStart = parseDate(slotDetail.start_date);
    const slotEnd = parseDate(slotDetail.end_date);
    const intersectionStart = new Date(Math.max(sprintStart, slotStart));
    const intersectionEnd = new Date(Math.min(sprintEnd, slotEnd));
    if (intersectionEnd < intersectionStart) {
      return { totalCA: 0, producedCA: 0 };
    }
    const totalSprintDays = getWorkingDaysDates(sprintStart, sprintEnd).length;
    const intersectionDays = getWorkingDaysDates(intersectionStart, intersectionEnd).length;
    const ratio = totalSprintDays > 0 ? intersectionDays / totalSprintDays : 0;
    return {
      totalCA: Math.round((sprint.valeur_a_produire || 0) * ratio),
      producedCA: Math.round((sprint.ca_produit || 0) * ratio)
    };
  };

  // Agrégation des résultats sur les sprints du slot
  const slotTokens = (slotDetail.sprints || []).reduce((acc, sprint) => {
    const { totalTokens, completedTokens } = calculateSprintTokensInSlot(sprint);
    return { total: acc.total + totalTokens, completed: acc.completed + completedTokens };
  }, { total: 0, completed: 0 });

  const slotCA = (slotDetail.sprints || []).reduce((acc, sprint) => {
    const { totalCA, producedCA } = calculateSprintCAInSlot(sprint);
    return { total: acc.total + totalCA, produced: acc.produced + producedCA };
  }, { total: 0, produced: 0 });

  // Calcul des jours ouvrés écoulés dans le slot (en forçant la lecture à minuit)
  const slotStartDate = parseDate(slotDetail.start_date);
  const slotEndDate = parseDate(slotDetail.end_date);
  const today = parseDate(new Date().toISOString().split("T")[0]);
  let workingDaysElapsed = 0;
  if (today < slotStartDate) {
    workingDaysElapsed = 0;
  } else if (today > slotEndDate) {
    workingDaysElapsed = getWorkingDaysDates(slotStartDate, slotEndDate).length;
  } else {
    workingDaysElapsed = getWorkingDaysDates(slotStartDate, today).length;
  }

  // Filtrer les employés présents pendant le slot en fonction des dates d'arrivée et de départ
  const employeesPresentDuringSlot = employees ? employees.filter(employee => {
    // Date d'arrivée de l'employé
    const employeeArrivalDate = new Date(employee.arrival_date);
    
    // Date de départ de l'employé (si spécifiée)
    const employeeDepartureDate = employee.departure_date ? new Date(employee.departure_date) : null;
    
    // Un employé est présent pendant le slot si :
    // 1. Il est arrivé avant la fin du slot
    const arrivedBeforeSlotEnd = employeeArrivalDate <= slotEndDate;
    
    // 2. Et soit il n'a pas de date de départ, soit il est parti après le début du slot
    const presentDuringSlot = !employeeDepartureDate || employeeDepartureDate >= slotStartDate;
    
    return arrivedBeforeSlotEnd && presentDuringSlot;
  }) : [];
  
  // Calcul de la disponibilité totale des employés présents pendant le slot
  const totalAvailableDays = employeesPresentDuringSlot.reduce((total, emp) => {
    const unavail = emp.unavailable_days || 0;
    return total + Math.max(workingDaysElapsed - unavail, 0);
  }, 0);

  // Calcul de la vélocité et productivité exactement comme dans SlotProgress
  const dailyVelocity = totalAvailableDays > 0 ? slotTokens.completed / totalAvailableDays : 0;
  const dailyProductivity = totalAvailableDays > 0 ? slotCA.produced / totalAvailableDays : 0;

  // Pour la barre de progression on laisse utiliser slotDetail.statistics
  const tokenProgress = (slotDetail.statistics && slotDetail.statistics.completion_percentage !== undefined)
                          ? slotDetail.statistics.completion_percentage
                          : (slotDetail.completion_percentage || 0);
  let caProgress = null;
  if (slotDetail.statistics && slotDetail.statistics.ca_a_produire && slotDetail.statistics.ca_a_produire > 0) {
    caProgress = (slotDetail.statistics.ca_produit / slotDetail.statistics.ca_a_produire) * 100;
  }

  return (
    // On utilise ici m-0 et p-0 pour minimiser l'espace
    <div className="m-0 p-0">
      {/* En-tête compact sans marge */}
      <div className="flex justify-between items-center text-xs m-0 p-0">
        <span className="m-0 p-0">Progression: {tokenProgress.toFixed(0)}%</span>
        {caProgress !== null && <span className="m-0 p-0">CA: {caProgress.toFixed(0)}%</span>}
      </div>
      {/* Barre de progression */}
      <div className="w-full bg-gray-200 h-2 rounded m-0 p-0">
        <div className="bg-blue-500 h-full rounded" style={{ width: `${tokenProgress}%` }}></div>
      </div>
      {caProgress !== null && (
        <div className="w-full bg-gray-200 h-2 rounded mt-0.5 m-0 p-0">
          <div className="bg-yellow-500 h-full rounded" style={{ width: `${caProgress}%` }}></div>
        </div>
      )}
      {/* Infos de vélocité et productivité dans une seule ligne compacte */}
      <div className="flex justify-between text-[10px] text-gray-500 m-0 p-0" style={{ marginTop: '2px' }}>
        <div className="m-0 p-0">Vélocité: {dailyVelocity.toFixed(1)} tokens/j</div>
        <div className="m-0 p-0">Prod: {dailyProductivity.toFixed(1)} €/j</div>
      </div>
    </div>
  );
};

export default SlotsTimeline;