import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from './AuthProvider';
import InnowideLogo from '../InnowideLogo';

const LoginPage = () => {
  const { login } = useAuth();
  const [error, setError] = useState(null);
  const googleButtonRef = useRef(null);

  const handleCredentialResponse = useCallback(async (response) => {
    try {
      console.log("Received Google response:", response);
      setError(null);
      await login(response.credential);
    } catch (err) {
      console.error('Login failed:', err);
      setError('Échec de la connexion. Veuillez réessayer.');
    }
  }, [login]);

  useEffect(() => {
    console.log("Client ID:", process.env.REACT_APP_GOOGLE_CLIENT_ID);
    
    let googleScript = null;
    
    const initializeGoogle = () => {
      console.log("Initializing Google Sign-In...");
      try {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });
        
        console.log("Rendering button...");
        window.google.accounts.id.renderButton(
          googleButtonRef.current,
          {
            type: 'standard',
            theme: 'outline',
            size: 'large',
            width: '250',
            locale: 'fr'
          }
        );
        
        console.log("Button should be rendered");
      } catch (error) {
        console.error("Error in Google initialization:", error);
        setError("Erreur d'initialisation Google Sign-In");
      }
    };

    const loadGoogleScript = () => {
      console.log("Loading Google script...");
      googleScript = document.createElement('script');
      googleScript.src = "https://accounts.google.com/gsi/client";
      googleScript.async = true;
      googleScript.defer = true;
      googleScript.onload = () => {
        console.log("Google script loaded");
        if (googleButtonRef.current) {
          initializeGoogle();
        } else {
          console.error("Button container not found");
        }
      };
      googleScript.onerror = () => {
        console.error("Failed to load Google script");
        setError("Erreur de chargement Google Sign-In");
      };
      document.body.appendChild(googleScript);
    };

    loadGoogleScript();

    // Cleanup function
    return () => {
      if (googleScript && document.body.contains(googleScript)) {
        document.body.removeChild(googleScript);
      }
      if (window.google?.accounts) {
        window.google.accounts.id.cancel();
      }
    };
  }, [handleCredentialResponse]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <InnowideLogo />
        </div>
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Connexion à l'application
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Erreur de connexion
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    {error}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-col items-center justify-center space-y-4">
            {/* Conteneur pour le bouton Google */}
            <div 
              ref={googleButtonRef} 
              id="googleButton" 
              className="w-full flex justify-center"
              style={{ minHeight: '40px' }}
            ></div>
            <p className="mt-4 text-center text-sm text-gray-600">
              Utilisez votre compte Google professionnel pour vous connecter
            </p>
            
            
            {process.env.NODE_ENV === 'development' && (
              <div className="mt-4 text-xs text-gray-500">
                Client ID: {process.env.REACT_APP_GOOGLE_CLIENT_ID?.substring(0, 8)}...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;