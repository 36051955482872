import React from "react";

const AddTaskForm = ({
  pendingNewTask,
  newTaskAnimating,
  handleNewTaskChange,
  handleNewTaskKeyDown,
  projects,
  employees,
  sprintOptions,
  handleCancelNewTask,
  handleSaveNewTask
}) => {
  if (!pendingNewTask) return null;
  
  return (
    <div
      id="new-task-card"
      className={`group bg-white p-4 rounded shadow mb-4 border-2 border-blue-500 ${
        newTaskAnimating ? 'transform translate-y-10 opacity-0 transition-all duration-500' : 'transition-all duration-300'
      }`}
      style={{ width: '100%', maxWidth: '800px' }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="mb-2">
          <input
            type="text"
            value={pendingNewTask.name}
            onChange={(e) => handleNewTaskChange("name", e.target.value)}
            placeholder="Nom de la tâche"
            className="border rounded p-2 w-full font-medium"
            autoFocus
            onKeyDown={handleNewTaskKeyDown}
          />
        </div>
        <div className="flex items-center space-x-2 text-sm">
          <div className="flex items-center">
            <span className="mr-2">Projet:</span>
            <select
              value={pendingNewTask.project_id}
              onChange={(e) => handleNewTaskChange("project_id", e.target.value)}
              className="border rounded p-1"
            >
              <option value="">-- Sélectionner --</option>
              {projects.map(p => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="mb-2">
          <textarea
            value={pendingNewTask.description || ""}
            onChange={(e) => handleNewTaskChange("description", e.target.value)}
            placeholder="Description (optionnelle)"
            className="border rounded p-2 w-full h-20 text-sm"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) return; // Permettre les sauts de ligne avec Shift+Enter
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSaveNewTask();
              }
            }}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <div className="flex items-center">
            <span className="mr-2 text-sm">Owner:</span>
            <select
              value={pendingNewTask.owner_id || ""}
              onChange={(e) => handleNewTaskChange("owner_id", e.target.value === "" ? null : Number(e.target.value))}
              className="border rounded p-1 text-sm"
            >
              <option value="">-- Aucun --</option>
              {employees.map(emp => (
                <option key={emp.id} value={emp.id}>
                  {emp.name.split(" ")[0]}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
            <div className="flex items-center">
              <span className="mr-2 text-sm">Tokens:</span>
              <input
                type="number"
                value={pendingNewTask.effort}
                onChange={(e) => handleNewTaskChange("effort", e.target.value)}
                className="border rounded p-1 w-16 text-sm"
                min="0"
              />
            </div>
            <div className="flex items-center">
              <span className="mr-2 text-sm">Sprint:</span>
              <select
                value={pendingNewTask.sprint_id || ""}
                onChange={(e) => handleNewTaskChange("sprint_id", e.target.value ? Number(e.target.value) : null)}
                className="border rounded p-1 text-sm w-40"
              >
                <option value="">-- Aucun --</option>
                {sprintOptions.map(sp => (
                  <option key={sp.id} value={sp.id}>
                    {sp.description ? sp.description : `Sprint ${sp.id}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-end space-x-2">
        <button
          onClick={handleCancelNewTask}
          data-ignore="true"
          className="bg-gray-200 text-gray-700 px-3 py-1 rounded text-sm"
        >
          Annuler
        </button>
        <button
          onClick={handleSaveNewTask}
          data-ignore="true"
          className="bg-green-500 text-white px-3 py-1 rounded text-sm"
        >
          Ajouter
        </button>
      </div>
    </div>
  );
};

export default AddTaskForm;