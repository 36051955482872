const CurrentDayLine = ({ slot, startDate, endDate }) => {
    // Si aucunes dates fournies, ne rien afficher
    if (!slot && !startDate && !endDate) {
      return null;
    }

    // Si le jour actuel n'est pas dans la plage, on ne montre rien
    const today = new Date();
    
    // Utiliser soit les propriétés du slot, soit les dates directement fournies
    const start = slot ? new Date(slot.start_date) : new Date(startDate);
    const end = slot ? new Date(slot.end_date) : new Date(endDate);
    
    if (today < start || today > end) {
      return null;
    }
  
    // Calcul de la position de la ligne
    const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    const daysPassed = Math.ceil((today - start) / (1000 * 60 * 60 * 24));
    const position = (daysPassed / totalDays) * 100;
  
    return (
      <div
        className="absolute h-full w-0.5 bg-red-500 z-10"
        style={{ 
          left: `${position}%`,
          pointerEvents: 'none'
        }}
      >
        {/* Étiquette du jour actuel */}
        <div className="absolute top-0 transform -translate-x-1/2 -translate-y-full">
          <div className="bg-red-100 text-red-800 text-xs px-2 py-1 rounded whitespace-nowrap">
            Today
          </div>
        </div>
      </div>
    );
  };
  
  export default CurrentDayLine;