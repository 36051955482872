import React from "react";

const CommentPopup = ({ commentTask, commentText, setCommentText, handleCommentSave, closeCommentPopup }) => {
  if (!commentTask) return null;
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded w-full max-w-2xl">
        <h2 className="text-xl font-bold mb-4">
          Commentaire pour : {commentTask.name}
        </h2>
        <textarea
          className="w-full border rounded p-2"
          rows={10}
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        ></textarea>
        <div className="mt-4 flex justify-end space-x-2">
          <button
            onClick={handleCommentSave}
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            Enregistrer
          </button>
          <button
            onClick={closeCommentPopup}
            className="bg-red-500 text-white px-4 py-2 rounded"
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentPopup;