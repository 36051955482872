import React, { useState, useEffect } from 'react';
import { MapPin, Plus, Edit2, Trash2, Check, X } from 'lucide-react';
import { useAuth } from './Auth';
import { useNavigate } from 'react-router-dom';

const apiBaseUrl = process.env.NODE_ENV === 'development' 
  ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
  : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const AddressSelect = ({ selectedId, addresses, onChange }) => {
  const navigate = useNavigate();
  
  return (
    <div className="relative w-full">
      <select
        value={selectedId || ''}
        onChange={(e) => {
          const value = e.target.value;
          if (value === 'new') {
            navigate('/addresses');
          } else {
            onChange(value === '' ? null : parseInt(value));
          }
        }}
        className="border rounded p-2 w-full"
      >
        <option value="">- Aucune adresse -</option>
        {addresses.map(address => (
          <option key={address.id} value={address.id}>
            {address.recipient} - {address.address}, {address.ZIP} {address.city}
          </option>
        ))}
        <option value="new" className="font-medium text-blue-600">
          + Ajouter une nouvelle adresse...
        </option>
      </select>
    </div>
  );
};

const ClientManagement = () => {
  const [clients, setClients] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editingClient, setEditingClient] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    billing_add_id: null,
    delivery_add_id: null,
    invoice_email: '',
    payment_terms: 'à réception'
  });
  
  const { getAuthHeaders } = useAuth();
  const navigate = useNavigate();

  // Charger les données initiales
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Charger les clients
        const clientsResponse = await fetch(`${apiBaseUrl}/clients`, {
          headers: getAuthHeaders()
        });
        
        if (!clientsResponse.ok) {
          const errorData = await clientsResponse.json();
          throw new Error(errorData.detail || 'Échec du chargement des clients');
        }
        
        const clientsData = await clientsResponse.json();
        setClients(Array.isArray(clientsData) ? clientsData : []);

        // Charger les adresses
        const addressesResponse = await fetch(`${apiBaseUrl}/addresses`, {
          headers: getAuthHeaders()
        });
        
        if (!addressesResponse.ok) {
          const errorData = await addressesResponse.json();
          throw new Error(errorData.detail || 'Échec du chargement des adresses');
        }
        
        const addressesData = await addressesResponse.json();
        setAddresses(Array.isArray(addressesData) ? addressesData : []);
      } catch (err) {
        setError(err.message || 'Échec du chargement des données');
        setTimeout(() => setError(null), 3000);
      }
    };

    fetchData();
  }, [getAuthHeaders]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/clients`, { // Utilisation de apiBaseUrl
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify(formData),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de l\'ajout du client');
      }
      
      const newClient = await response.json();
      setClients(prev => [...prev, newClient]);
      
      // Réinitialiser le formulaire
      setFormData({
        name: '',
        billing_add_id: null,
        delivery_add_id: null,
        invoice_email: '',
        payment_terms: 'à réception'
      });
      
      setSuccess('Client ajouté avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de l\'ajout du client');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingClient(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddressChange = (type, value) => {
    setFormData(prev => ({
      ...prev,
      [type === 'billing' ? 'billing_add_id' : 'delivery_add_id']: value
    }));
  };

  const handleEditAddressChange = (type, value) => {
    setEditingClient(prev => ({
      ...prev,
      [type === 'billing' ? 'billing_add_id' : 'delivery_add_id']: value
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/clients/${editingClient.id}`, { // Utilisation de apiBaseUrl
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify({
          name: editingClient.name,
          invoice_email: editingClient.invoice_email,
          billing_add_id: editingClient.billing_add_id,
          delivery_add_id: editingClient.delivery_add_id,
          payment_terms: editingClient.payment_terms
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour du client');
      }

      const updatedClient = await response.json();
      setClients(prev =>
        prev.map(client =>
          client.id === updatedClient.id ? updatedClient : client
        )
      );
      setEditingClient(null);
      setSuccess('Client mis à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour du client');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) {
      return;
    }

    try {
      const response = await fetch(`${apiBaseUrl}/clients/${id}`, { // Utilisation de apiBaseUrl
        method: 'DELETE',
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression du client');
      }

      setClients(prev => prev.filter(client => client.id !== id));
      setSuccess('Client supprimé avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression du client');
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <div className="p-2 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-2 flex items-center">
        <MapPin className="h-6 w-6 mr-2" />
        Gestion des Clients
      </h1>

      {/* Affichage des messages d'erreur et de succès */}
      {error && (
        <div className="mb-4 p-4 bg-red-100 text-red-700 rounded">
          {error}
        </div>
      )}
      {success && (
        <div className="mb-4 p-4 bg-green-100 text-green-700 rounded">
          {success}
        </div>
      )}

      {/* Formulaire d'ajout de client */}
      <form onSubmit={handleSubmit} className="mb-8 p-4 bg-gray-50 rounded-lg shadow">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Nom du client
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Email de facturation
            </label>
            <input
              type="email"
              name="invoice_email"
              value={formData.invoice_email}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Conditions de paiement
            </label>
            <select
              name="payment_terms"
              value={formData.payment_terms}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            >
              <option value="à réception">À réception</option>
              <option value="30 jours nets">30 jours nets</option>
              <option value="45 jours nets">45 jours nets</option>
              <option value="45 jours fin de mois">45 jours fin de mois</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Adresse de facturation (optionnelle)
            </label>
            <AddressSelect
              selectedId={formData.billing_add_id}
              addresses={addresses}
              onChange={(value) => handleAddressChange('billing', value)}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Adresse de livraison (optionnelle)
            </label>
            <AddressSelect
              selectedId={formData.delivery_add_id}
              addresses={addresses}
              onChange={(value) => handleAddressChange('delivery', value)}
            />
          </div>
        </div>

        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
        >
          <Plus className="h-4 w-4 mr-2" />
          Ajouter un Client
        </button>
      </form>

      {/* Liste des clients */}
      <div className="grid grid-cols-1 gap-4">
        {clients.map((client) => (
          <div 
            key={client.id}
            className="bg-white rounded-lg border p-4 hover:shadow-md transition-shadow"
          >
            {editingClient?.id === client.id ? (
              // Mode édition
              <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      Nom du client
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={editingClient.name}
                      onChange={handleEditInputChange}
                      className="border rounded p-2 w-full"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      Email de facturation
                    </label>
                    <input
                      type="email"
                      name="invoice_email"
                      value={editingClient.invoice_email}
                      onChange={handleEditInputChange}
                      className="border rounded p-2 w-full"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      Conditions de paiement
                    </label>
                    <select
                      name="payment_terms"
                      value={editingClient.payment_terms}
                      onChange={handleEditInputChange}
                      className="border rounded p-2 w-full"
                      required
                    >
                      <option value="à réception">À réception</option>
                      <option value="30 jours nets">30 jours nets</option>
                      <option value="45 jours nets">45 jours nets</option>
                      <option value="45 jours fin de mois">45 jours fin de mois</option>
                    </select>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      Adresse de facturation
                    </label>
                    <AddressSelect
                      selectedId={editingClient.billing_add_id}
                      addresses={addresses}
                      onChange={(value) => handleEditAddressChange('billing', value)}
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-600 mb-1">
                      Adresse de livraison
                    </label>
                    <AddressSelect
                      selectedId={editingClient.delivery_add_id}
                      addresses={addresses}
                      onChange={(value) => handleEditAddressChange('delivery', value)}
                    />
                  </div>
                </div>

                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={handleUpdate}
                    className="text-green-500 hover:text-green-700"
                    title="Enregistrer les modifications"
                  >
                    <Check className="h-5 w-5" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setEditingClient(null)}
                    className="text-gray-500 hover:text-gray-700"
                    title="Annuler"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ) : (
              // Mode affichage
              <div>
                <div className="flex justify-between mb-4">
                  <div>
                    <h3 className="text-lg font-medium">{client.name}</h3>
                    <p className="text-gray-600">{client.invoice_email}</p>
                    <p className="text-sm text-gray-500">
                      Conditions de paiement : {client.payment_terms}
                    </p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => setEditingClient(client)}
                      className="text-blue-500 hover:text-blue-700"
                      title="Modifier le client"
                    >
                      <Edit2 className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => handleDelete(client.id)}
                      className="text-red-500 hover:text-red-700"
                      title="Supprimer le client"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                  {/* Adresse de facturation */}
                  <div className="border-t pt-4">
                    <h4 className="font-medium text-gray-700 mb-2 flex items-center">
                      <MapPin className="h-4 w-4 mr-2" />
                      Adresse de facturation
                    </h4>
                    <div className="text-sm text-gray-600">
                      {client.billing_address ? (
                        <>
                          <p className="font-medium">{client.billing_address.recipient}</p>
                          <p>{client.billing_address.address}</p>
                          <p>{client.billing_address.ZIP} {client.billing_address.city}</p>
                          <p>{client.billing_address.country}</p>
                        </>
                      ) : (
                        <p className="italic text-gray-500">Aucune adresse définie</p>
                      )}
                    </div>
                  </div>

                  {/* Adresse de livraison */}
                  <div className="border-t pt-4">
                    <h4 className="font-medium text-gray-700 mb-2 flex items-center">
                      <MapPin className="h-4 w-4 mr-2" />
                      Adresse de livraison
                    </h4>
                    <div className="text-sm text-gray-600">
                      {client.delivery_address ? (
                        <>
                          <p className="font-medium">{client.delivery_address.recipient}</p>
                          <p>{client.delivery_address.address}</p>
                          <p>{client.delivery_address.ZIP} {client.delivery_address.city}</p>
                          <p>{client.delivery_address.country}</p>
                        </>
                      ) : (
                        <p className="italic text-gray-500">Aucune adresse définie</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {clients.length === 0 && (
          <div className="text-center text-gray-500 p-8 bg-white rounded-lg border">
            Aucun client trouvé
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientManagement;