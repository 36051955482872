import React, { useState, useEffect, useRef } from "react";
import { MapPin } from "lucide-react";
import { apiService } from "../../services/api";
import { useAuth } from "../Auth";
import { useNavigate, useLocation } from "react-router-dom";

// Composants
import TaskFilters from "./TaskFilters";
import AddTaskForm from "./AddTaskForm";
import KanbanColumn from "./KanbanColumn";
import CommentPopup from "./CommentPopup";

const TaskBoard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const initialRenderRef = useRef(true);

  // Récupérer les paramètres d'URL
  const queryParams = new URLSearchParams(location.search);
  const projectParam = queryParams.get('project');
  const ownerParam = queryParams.get('owner');
  const sprintParam = queryParams.get('sprint');
  const viewModeParam = queryParams.get('view');

  const [viewMode, setViewMode] = useState(() => {
    if (viewModeParam === 'myTasks') return 'myTasks';
    return localStorage.getItem("taskBoard_viewMode") || "project";
  });

  // Conversion des paramètres en tableaux pour les sélections multiples
  const getProjectsFromParam = () => {
    if (!projectParam) {
      const storedProject = localStorage.getItem("taskBoard_selectedProject");
      if (storedProject === "all" || !storedProject) return ["all"];
      // Traiter comme une liste séparée par des virgules, comme pour les paramètres d'URL
      return storedProject.includes(',') ? storedProject.split(',').filter(Boolean) : [storedProject];
    }
    return projectParam.split(',').filter(Boolean);
  };
  const [selectedProjects, setSelectedProjects] = useState(getProjectsFromParam());
  // Pour la compatibilité avec le reste du code
  const selectedProject = selectedProjects.length > 0 && selectedProjects[0] !== "all" ? selectedProjects[0] : "";

  const getOwnersFromParam = () => {
    if (!ownerParam) {
      const storedOwner = localStorage.getItem("taskBoard_filterOwner");
      if (!storedOwner) return ["all"];
      // Traiter comme une liste séparée par des virgules, comme pour les paramètres d'URL
      return storedOwner.includes(',') ? storedOwner.split(',').filter(Boolean) : [storedOwner];
    }
    return ownerParam.split(',').filter(Boolean);
  };
  const [filterOwners, setFilterOwners] = useState(getOwnersFromParam());
  // Pour la compatibilité avec le reste du code
  const filterOwner = filterOwners.includes("all") ? "all" : filterOwners[0] || "all";

  const [projectSprintFilter, setProjectSprintFilter] = useState(() =>
    sprintParam || localStorage.getItem("taskBoard_project_sprintFilter") || "all"
  );
  const [myTasksSprintFilter, setMyTasksSprintFilter] = useState(() =>
    localStorage.getItem("taskBoard_myTasks_sprintFilter") || "enCours"
  );
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState(null);
  // Pour les sprints (détaillés pour "par projet" et options fixes pour "mes tâches")
  const [sprints, setSprints] = useState([]);
  const [sprintOptions, setSprintOptions] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newTask, setNewTask] = useState({
    project_id: "",
    name: "",
    description: "",
    sprint_id: null,
    effort: 0,
    owner_id: ""
  });
  // Pour l'édition inline
  const [editing, setEditing] = useState({ taskId: null, field: null, value: "" });
  // Pour le drag & drop
  const [draggedTask, setDraggedTask] = useState(null);
  const [currentSprintsMapping, setCurrentSprintsMapping] = useState({});
  const [allSprints, setAllSprints] = useState({});
  // Nouvel état pour l'animation de la nouvelle tâche
  const [newTaskAnimating, setNewTaskAnimating] = useState(false);
  const [pendingNewTask, setPendingNewTask] = useState(null);

  // Nouveaux états pour le popup de commentaire
  const [commentTask, setCommentTask] = useState(null);
  const [commentText, setCommentText] = useState("");

  const [projectSearch, setProjectSearch] = useState("");
  const filteredProjects = projects.filter(p =>
    p.name.toLowerCase().includes(projectSearch.toLowerCase())
  );
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  // Recherche d'owner
  const [ownerSearch, setOwnerSearch] = useState("");
  const [isOwnerSearchFocused, setIsOwnerSearchFocused] = useState(false);
  const filteredEmployees = employees.filter(emp =>
    emp.name.toLowerCase().includes(ownerSearch.toLowerCase())
  );

  // Nouvel état pour la sélection multiple de projets
  const [selectedProjectsMap, setSelectedProjectsMap] = useState({});
  // Nouvel état pour la sélection multiple d'owners
  const [selectedOwnersMap, setSelectedOwnersMap] = useState({});

  // Nouveau state pour les slots
  const [slots, setSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState("all");

  // Récupère l'objet projet correspondant au premier projet sélectionné
  const selectedProjectObj = projects.find(
    (p) => p.id?.toString() === selectedProject?.toString()
  );
  const selectedProjectName = selectedProjectObj ? selectedProjectObj.name : "";

  useEffect(() => {
    localStorage.setItem("taskBoard_viewMode", viewMode);
  }, [viewMode]);

  // Mettre à jour URL et localStorage uniquement quand les sélections changent
  // et après le premier rendu
  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
      return;
    }

    // Mise à jour de l'URL avec les projets et owners sélectionnés
    const params = new URLSearchParams(location.search);
    
    // Préserver le paramètre view s'il existe
    const viewParam = params.get('view');

    if (selectedProjects.length > 0 && !selectedProjects.includes("all")) {
      params.set('project', selectedProjects.join(','));
    } else {
      params.delete('project');
    }

    if (filterOwners.length > 0 && !filterOwners.includes("all")) {
      params.set('owner', filterOwners.join(','));
    } else {
      params.delete('owner');
    }

    if (projectSprintFilter !== "all") {
      params.set('sprint', projectSprintFilter);
    } else {
      params.delete('sprint');
    }
    
    // Remettre le paramètre view s'il existait
    if (viewParam) {
      params.set('view', viewParam);
    }

    const newUrl = `${location.pathname}${params.toString() ? '?' + params.toString() : ''}`;
    window.history.replaceState(null, '', newUrl);

    // Enregistrement dans le localStorage
    if (selectedProjects.length > 0) {
      localStorage.setItem("taskBoard_selectedProject", selectedProjects.join(','));
    }

    if (filterOwners.length > 0) {
      localStorage.setItem("taskBoard_filterOwner", filterOwners.join(','));
    }
  }, [selectedProjects, filterOwners, projectSprintFilter, location.pathname]);

  useEffect(() => {
    localStorage.setItem("taskBoard_project_sprintFilter", projectSprintFilter);
  }, [projectSprintFilter]);

  useEffect(() => {
    localStorage.setItem("taskBoard_myTasks_sprintFilter", myTasksSprintFilter);
  }, [myTasksSprintFilter]);

  // Au montage, initialiser les maps de sélection
  useEffect(() => {
    // Initialiser la map des projets sélectionnés
    const projectsMap = {};
    if (selectedProjects.includes("all")) {
      projectsMap.all = true;
    } else {
      selectedProjects.forEach(id => {
        if (id) projectsMap[id] = true;
      });
    }
    setSelectedProjectsMap(projectsMap);

    // Initialiser la map des owners sélectionnés
    const ownersMap = {};
    if (filterOwners.includes("all")) {
      ownersMap.all = true;
    } else {
      filterOwners.forEach(id => {
        if (id) ownersMap[id] = true;
      });
    }
    setSelectedOwnersMap(ownersMap);
  }, []);

  // Au montage, récupérer projets et employés
  useEffect(() => {
    apiService.get("/projects")
      .then((data) => {
        setProjects(data);
        if (data.length > 0 && selectedProjects.length === 0) {
          setSelectedProjects(["all"]);
        }
      })
      .catch(console.error);

    apiService.get("/employees")
      .then(setEmployees)
      .catch(console.error);
  }, []);

  // Récupérer les slots détaillés lorsque plusieurs projets sont sélectionnés
  useEffect(() => {
    if (viewMode === "project" && (selectedProjects.length > 1 || selectedProjects.includes("all"))) {
      // D'abord récupérer la liste des slots
      apiService.get("/slots")
        .then(slotsData => {
          console.log("Liste des slots récupérés:", slotsData);

          // Ensuite récupérer les détails de chaque slot (qui contiennent les sprints)
          Promise.all(
            slotsData.map(slot =>
              apiService.get(`/slots/${slot.id}`)
                .catch(() => ({ ...slot, sprints: [] }))
            )
          )
          .then(detailedSlots => {
            console.log("Slots détaillés récupérés:", detailedSlots);
            setSlots(detailedSlots);
          })
          .catch(console.error);
        })
        .catch(console.error);
    }
  }, [viewMode, selectedProjects]);

  useEffect(() => {
    if (viewMode === "myTasks" && projects.length > 0) {
      Promise.all(
        projects.map(p =>
          apiService.get(`/projects/${p.id}/sprints`).catch(() => [])
        )
      )
        .then(sprintsArrays => {
          const mapping = {};
          sprintsArrays.forEach(arr => {
            arr.forEach(sprint => {
              mapping[sprint.id] = sprint;
            });
          });
          setAllSprints(mapping);
        })
        .catch(console.error);
    }
  }, [viewMode, projects]);

  // Lors du passage en vue "Par projet", définir par défaut le filtre Owner sur l'utilisateur connecté
  useEffect(() => {
    if (viewMode === "project" && user && filterOwners.length === 0) {
      setFilterOwners([String(user.id)]);
    }
  }, [viewMode, user, filterOwners.length]);

  // Fonction pour récupérer les sprints pour tous les projets sélectionnés
  const fetchSprintsForProjects = async () => {
    if (selectedProjects.length === 0) return;

    try {
      const projectIds = selectedProjects.includes("all")
        ? projects.map(p => p.id)
        : selectedProjects;

      const allSprintsData = await Promise.all(
        projectIds.map(projectId =>
          apiService.get(`/projects/${projectId}/sprints`)
            .catch(() => [])
        )
      );

      // Fusionner tous les sprints
      const mergedSprints = allSprintsData.flat();
      setSprints(mergedSprints);
      setSprintOptions(mergedSprints);

      // Conserver le filtre sprint si possible
      const storedSprint = localStorage.getItem("taskBoard_project_sprintFilter");
      if (storedSprint !== null) {
        setProjectSprintFilter(storedSprint);
      } else {
        // Sinon, déterminer le sprint courant par défaut
        const currentDate = new Date();
        const currentSprint = mergedSprints.find(s => {
          const start = new Date(s.start_date);
          const end = new Date(s.end_date);
          return start <= currentDate && currentDate <= end;
        });
        if (currentSprint) {
          setProjectSprintFilter(currentSprint.id.toString());
        } else {
          setProjectSprintFilter("all");
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des sprints:", error);
    }
  };

  // En vue "Par projet", récupérer les sprints des projets sélectionnés
  useEffect(() => {
    if (viewMode === "project" && selectedProjects.length > 0) {
      fetchSprintsForProjects();
    }
  }, [viewMode, selectedProjects, projects]);

  // Récupération des tâches pour les projets sélectionnés
  useEffect(() => {
    if (viewMode === "project") {
      let projectIds = selectedProjects;

      if (selectedProjects.includes("all")) {
        projectIds = projects.map(p => p.id);
      }

      if (projectIds.length > 0) {
        Promise.all(
          projectIds.map(projectId =>
            apiService.get(`/projects/${projectId}/tasks`)
              .catch(() => [])
          )
        )
          .then((results) => {
            // Fusionner les tableaux de tâches de chaque projet
            const allProjectTasks = results.flat();
            setTasks(allProjectTasks);
          })
          .catch(console.error);

        // Définir le projet par défaut pour la nouvelle tâche
        if (selectedProject) {
          setNewTask(prev => ({ ...prev, project_id: selectedProject }));
        }
      }
    } else if (viewMode === "myTasks" && projects.length > 0 && employee) {
      // Pour "Mes tâches", on récupère toutes les tâches de chaque projet et on filtre par l'ID de l'employé associé à l'email de l'utilisateur
      Promise.all(projects.map(p => apiService.get(`/projects/${p.id}/tasks`)))
        .then(arrays => {
          let allTasks = arrays.flat();
          allTasks = allTasks.filter(t => String(t.owner_id) === String(employee.id));
          setTasks(allTasks);
        })
        .catch(console.error);
    }
  }, [viewMode, selectedProjects, projects, employee, selectedProject]);

  useEffect(() => {
    if (viewMode === "myTasks" && projects.length > 0) {
      Promise.all(projects.map(p =>
        apiService.get(`/projects/${p.id}/sprints`).catch(() => [])
      ))
        .then(sprintsArrays => {
          let mapping = {};
          sprintsArrays.forEach((sprints, index) => {
            const project = projects[index];
            const currentDate = new Date();
            const currentSprint = sprints.find(s => {
              const start = new Date(s.start_date);
              const end = new Date(s.end_date);
              return start <= currentDate && currentDate <= end;
            });
            if (currentSprint) {
              mapping[project.id] = currentSprint.id;
            }
          });
          setCurrentSprintsMapping(mapping);
        })
        .catch(console.error);
    }
  }, [viewMode, projects]);

  useEffect(() => {
    if (user && employees.length > 0) {
      const currentEmployee = employees.find(emp => emp.email === user.email);
      setEmployee(currentEmployee);
    }
  }, [user, employees]);

  // Mettre à jour les listes des projets et owners sélectionnés
  // quand leurs maps respectives changent
  useEffect(() => {
    // Vérifier s'il y a eu un changement réel
    const currentProjectsAsString = JSON.stringify(selectedProjects.sort());

    let newProjects;
    if (selectedProjectsMap.all) {
      newProjects = ["all"];
    } else {
      newProjects = Object.keys(selectedProjectsMap)
        .filter(id => selectedProjectsMap[id] && id !== "all");

      if (newProjects.length === 0) {
        newProjects = ["all"];
      }
    }

    const newProjectsAsString = JSON.stringify(newProjects.sort());

    if (currentProjectsAsString !== newProjectsAsString) {
      setSelectedProjects(newProjects);
    }
  }, [selectedProjectsMap]);

  useEffect(() => {
    // Vérifier s'il y a eu un changement réel
    const currentOwnersAsString = JSON.stringify(filterOwners.sort());

    let newOwners;
    if (selectedOwnersMap.all) {
      newOwners = ["all"];
    } else {
      newOwners = Object.keys(selectedOwnersMap)
        .filter(id => selectedOwnersMap[id] && id !== "all");

      if (newOwners.length === 0) {
        newOwners = ["all"];
      }
    }

    const newOwnersAsString = JSON.stringify(newOwners.sort());

    if (currentOwnersAsString !== newOwnersAsString) {
      setFilterOwners(newOwners);
    }
  }, [selectedOwnersMap]);

  // Fonction pour basculer la sélection d'un projet
  const toggleProjectSelection = (projectId) => {
    if (projectId === "all") {
      // Si "Tous" est sélectionné, désélectionner tous les autres
      setSelectedProjectsMap({ all: true });
    } else {
      setSelectedProjectsMap(prev => {
        const newMap = { ...prev, all: false, [projectId]: !prev[projectId] };
        // Vérifier si aucun projet n'est sélectionné, alors sélectionner "Tous"
        const hasSelected = Object.keys(newMap).some(id => id !== "all" && newMap[id]);
        if (!hasSelected) {
          newMap.all = true;
        }
        return newMap;
      });
    }
  };

  // Fonction pour effacer tous les filtres de projets
  const clearProjectsFilter = () => {
    setSelectedProjectsMap({ all: true });
    setIsSearchFocused(false);
  };

  // Fonction pour basculer la sélection d'un owner
  const toggleOwnerSelection = (ownerId) => {
    if (ownerId === "all") {
      // Si "Tous" est sélectionné, désélectionner tous les autres
      setSelectedOwnersMap({ all: true });
    } else {
      // Sinon, désélectionner "Tous" et basculer la sélection de l'owner
      setSelectedOwnersMap(prev => {
        const newMap = { ...prev, all: false, [ownerId]: !prev[ownerId] };
        // Vérifier si aucun owner n'est sélectionné, alors sélectionner "Tous"
        const hasSelected = Object.keys(newMap).some(id => id !== "all" && newMap[id]);
        if (!hasSelected) {
          newMap.all = true;
        }
        return newMap;
      });
    }
  };

  // Fonction pour effacer tous les filtres d'owners
  const clearOwnersFilter = () => {
    setSelectedOwnersMap({ all: true });
    setIsOwnerSearchFocused(false);
  };

  // Filtrage des tâches en fonction des sélections
  let tempTasks = tasks;
  if (viewMode === "project") {
    // Filtrer par owner(s)
    if (!selectedOwnersMap.all) {
      const selectedOwnerIds = Object.keys(selectedOwnersMap).filter(id => selectedOwnersMap[id]);
      if (selectedOwnerIds.length > 0) {
        tempTasks = tempTasks.filter(t => selectedOwnerIds.includes(String(t.owner_id)));
      }
    }

    // Filtrer par sprint ou slot
    if (selectedProjects.length === 1 && !selectedProjects.includes("all")) {
      // En mode un seul projet sélectionné: filtrer par sprint
      if (projectSprintFilter !== "all") {
        tempTasks = tempTasks.filter(t => String(t.sprint_id) === projectSprintFilter);
      }
    } else if (selectedSlot !== "all") {
      // En mode multi-projet ou "tous": filtrer par slot via les sprints appartenant à ce slot
      const slotObj = slots.find(s => String(s.id) === selectedSlot);
      console.log("Slot sélectionné:", slotObj);

      if (slotObj && slotObj.sprints) {
        // Extraire les IDs des sprints, avec gestion des différentes structures possibles
        const sprintIds = Array.isArray(slotObj.sprints)
          ? slotObj.sprints.map(s => {
              if (typeof s === 'object' && s !== null) {
                return String(s.id);
              } else {
                return String(s);
              }
            })
          : [];

        console.log("Sprint IDs dans slot:", sprintIds);
        tempTasks = tempTasks.filter(t => {
          return t.sprint_id && sprintIds.includes(String(t.sprint_id));
        });
      }
    }
  } else if (viewMode === "myTasks") {
    if (myTasksSprintFilter === "enCours") {
      tempTasks = tempTasks.filter(t => {
        const currentId = currentSprintsMapping[t.project_id];
        return currentId && Number(t.sprint_id) === Number(currentId);
      });
    }
  }
  const filteredTasks = tempTasks;

  // Fonctions utilitaires
  const getOwnerName = (owner_id) => {
    const emp = employees.find(e => e.id === owner_id);
    return emp ? emp.name.split(" ")[0] : "-";
  };

  const getSprintName = (sprint_id) => {
    if (sprint_id != null) {
      if (viewMode === "project") {
        const sp = sprintOptions.find(s => s.id === sprint_id);
        return sp ? (sp.description ? sp.description : `Sprint ${sp.id}`) : `Sprint ${sprint_id}`;
      } else { // Pour "Mes tâches"
        const sp = allSprints[sprint_id];
        return sp ? (sp.description ? sp.description : `Sprint ${sp.id}`) : `Sprint ${sprint_id}`;
      }
    }
    return "-- Aucun --";
  };

  const getProjectName = (project_id) => {
    const proj = projects.find(p => p.id === project_id);
    return proj ? proj.name : "";
  };

  // Edition inline
  const enableEditing = (task, field) => {
    setEditing({
      taskId: task.id,
      field,
      value: field === "sprint_id"
        ? (task.sprint_id !== null ? task.sprint_id.toString() : "")
        : (task[field] ?? "")
    });
  };

  const handleEditingChange = (e) => {
    setEditing({ ...editing, value: e.target.value });
  };

  const handleEditingBlur = async () => {
    const { taskId, field, value } = editing;
    const fieldValue =
      field === "effort"
        ? Number(value)
        : field === "sprint_id"
          ? (value === "" ? null : Number(value))
          : value;
    // Pour l'URL, obtenir l'ID de projet depuis selectedProject en mode "Par projet"
    // ou depuis la tâche en mode "Mes tâches"
    const projectId = viewMode === "project"
      ? tasks.find(t => t.id === taskId)?.project_id || selectedProject
      : (tasks.find(t => t.id === taskId)?.project_id || "");
    try {
      const updatedTask = await apiService.patch(
        `/projects/${projectId}/tasks/${taskId}`,
        { [field]: fieldValue }
      );
      setTasks(prev =>
        prev.map(t =>
          t.id === taskId ? { ...t, [field]: updatedTask[field] } : t
        )
      );
    } catch (error) {
      console.error(error);
    }
    setEditing({ taskId: null, field: null, value: "" });
  };

  // Drag & drop
  const handleDragStart = (e, task) => {
    setDraggedTask(task);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, newStatus) => {
    e.preventDefault();
    if (!draggedTask) return;
    
    // Créer une copie locale de la tâche avec le nouveau statut
    const updatedTask = { ...draggedTask, status: newStatus };
    
    // Mettre à jour l'état immédiatement pour une UI réactive
    setTasks(prev => 
      prev.map(t => t.id === draggedTask.id ? updatedTask : t)
    );
    
    if (draggedTask.status !== newStatus) {
      const projectId = viewMode === "project"
        ? draggedTask.project_id
        : (tasks.find(t => t.id === draggedTask.id)?.project_id || "");
      
      try {
        await apiService.patch(
          `/projects/${projectId}/tasks/${draggedTask.id}`,
          { status: newStatus }
        );
        // La mise à jour de l'état a déjà été faite au-dessus
      } catch (error) {
        console.error(error);
        // En cas d'erreur, rétablir l'état précédent
        setTasks(prev => 
          prev.map(t => t.id === draggedTask.id ? draggedTask : t)
        );
      }
    }
    setDraggedTask(null);
  };

  // Gestionnaire pour les champs de la nouvelle tâche en mode édition en ligne
  const handleNewTaskChange = (field, value) => {
    // Si le champ modifié est project_id, on met à jour également le sprint_id
    // avec le sprint le plus récent du projet sélectionné
    if (field === "project_id" && value) {
      const projectId = value;
      let latestSprintId = null;
      
      // Trouver le sprint le plus récent du projet sélectionné
      const projectSprints = sprints.filter(s => String(s.project_id) === String(projectId));
      if (projectSprints.length > 0) {
        const sortedSprints = [...projectSprints].sort((a, b) => 
          new Date(b.start_date) - new Date(a.start_date)
        );
        latestSprintId = sortedSprints[0].id;
      }
      
      // Mettre à jour à la fois project_id et sprint_id
      setPendingNewTask(prev => ({
        ...prev,
        project_id: projectId,
        sprint_id: latestSprintId
      }));
    } else {
      // Pour les autres champs, comportement normal
      setPendingNewTask(prev => ({
        ...prev,
        [field]: field === "effort" ? Number(value) : value
      }));
    }
  };

  // Création de tâche mise à jour pour la carte animée
  const handleCreateNewTask = () => {
    // Définir le projet par défaut (premier projet sélectionné, ou premier projet disponible)
    let defaultProjectId = "";
    
    if (selectedProject) {
      // Si un projet est déjà sélectionné, l'utiliser
      defaultProjectId = selectedProject;
    } else if (selectedProjects.length > 0 && !selectedProjects.includes("all")) {
      // Sinon, utiliser le premier projet des projets sélectionnés
      defaultProjectId = selectedProjects[0];
    } else if (projects.length > 0) {
      // Si "Tous les projets" est sélectionné, utiliser le premier projet disponible
      defaultProjectId = projects[0].id.toString();
    }
    
    // Trouver le dernier sprint du projet par défaut
    let latestSprintId = null;
    if (defaultProjectId) {
      const projectSprints = sprints.filter(s => String(s.project_id) === String(defaultProjectId));
      if (projectSprints.length > 0) {
        // Trier par date de début décroissante pour obtenir le sprint le plus récent
        const sortedSprints = [...projectSprints].sort((a, b) => 
          new Date(b.start_date) - new Date(a.start_date)
        );
        latestSprintId = sortedSprints[0].id;
      }
    }
    
    // Initialisation d'une tâche par défaut
    const defaultTask = {
      project_id: defaultProjectId,
      name: "",
      description: "",
      sprint_id: latestSprintId,  // Utiliser le dernier sprint
      effort: 0,
      owner_id: "",
      status: "not_started"
    };
    setPendingNewTask(defaultTask);
    setShowAddForm(true);
  };

  // Sauvegarde d'une nouvelle tâche
  const handleSaveNewTask = async (e) => {
    if (e) e.preventDefault();
    if (!pendingNewTask || !pendingNewTask.project_id || !pendingNewTask.name) return;

    // Si l'owner_id est une chaîne vide, on la transforme en null pour le backend
    const taskToSave = {
      ...pendingNewTask,
      owner_id: pendingNewTask.owner_id === "" ? null : pendingNewTask.owner_id
    };

    try {
      const createdTask = await apiService.post(
        `/projects/${taskToSave.project_id}/tasks`,
        taskToSave
      );

      // Animer la tâche qui entre dans le backlog
      setNewTaskAnimating(true);

      // Après l'animation, ajouter la tâche au backlog et réinitialiser
      setTimeout(() => {
        setTasks(prev => [...prev, createdTask]);
        setShowAddForm(false);
        setPendingNewTask(null);
        setNewTaskAnimating(false);
      }, 500); // Durée de l'animation
    } catch (error) {
      console.error(error);
      // En cas d'erreur, fermer directement
      setShowAddForm(false);
      setPendingNewTask(null);
    }
  };

  // Fonction pour fermer le formulaire d'ajout
  const handleCancelNewTask = () => {
    setShowAddForm(false);
    setPendingNewTask(null);
  };

  const handleDeleteTask = async (taskId) => {
    try {
      let projectId = selectedProject;
      if (viewMode !== "project") {
        const taskToDelete = tasks.find(t => t.id === taskId);
        if (!taskToDelete) return;
        projectId = taskToDelete.project_id;
      }
      await apiService.delete(`/projects/${projectId}/tasks/${taskId}`);
      setTasks(prev => prev.filter(t => t.id !== taskId));
    } catch (error) {
      console.error(error);
    }
  };

  // Fonction pour ouvrir le popup
  const openCommentPopup = (task) => {
    setCommentTask(task);
    setCommentText(task.comment || "");
  };

  // Fermeture du popup
  const closeCommentPopup = () => {
    setCommentTask(null);
    setCommentText("");
  };

  // Sauvegarder le commentaire
  const handleCommentSave = async () => {
    if (!commentTask) return;
    const projectId =
      viewMode === "project"
        ? commentTask.project_id
        : (tasks.find((t) => t.id === commentTask.id)?.project_id || "");
    try {
      await apiService.patch(
        `/projects/${projectId}/tasks/${commentTask.id}`,
        { comment: commentText }
      );
      setTasks((prev) =>
        prev.map((t) =>
          t.id === commentTask.id ? { ...t, comment: commentText } : t
        )
      );
      closeCommentPopup();
    } catch (error) {
      console.error(error);
    }
  };

  const getOwnerInitials = (owner_id) => {
    const emp = employees.find(e => e.id === owner_id);
    if (emp && emp.email) {
      // Extraction de la partie avant le @ (exemple "remy.cartier")
      const emailName = emp.email.split('@')[0]; 
      const nameParts = emailName.split('.');
      
      // Premier caractère de chaque partie du nom
      return nameParts.map(part => part[0]?.toUpperCase() || '').join('');
    }
    return "";
  };

  const handleNewTaskKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSaveNewTask();
    }
  };

  const handleCommentTextChange = (e) => {
    setCommentText(e.target.value);
  };

  return (
    <div className="p-4 pb-16">
      <h1 className="text-2xl font-bold mb-4 flex items-center">
        <MapPin className="h-6 w-6 mr-2" />
        Backlog des Tâches
      </h1>
      {/* Bascule entre vues */}
      <div className="border-b border-gray-200 mb-4">
        <div className="flex space-x-4">
          <button
            onClick={() => setViewMode("project")}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              viewMode === "project"
                ? "bg-purple-100 text-purple-700 border-b-2 border-purple-700"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            Par projet
          </button>
          <button
            onClick={() => setViewMode("myTasks")}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              viewMode === "myTasks"
                ? "bg-purple-100 text-purple-700 border-b-2 border-purple-700"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            Mes tâches
          </button>
          <button
            onClick={() => navigate(`/tasks/kickoff`)}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              location.pathname.startsWith('/tasks/kickoff')
                ? "bg-purple-100 text-purple-700 border-b-2 border-purple-700"
                : "text-gray-600 hover:bg-gray-100"
            }`}
          >
            Kickoff
          </button>
        </div>
      </div>
      
      {/* Filtres de tâches */}
      <TaskFilters
        viewMode={viewMode}
        setViewMode={setViewMode}
        employee={employee}
        projects={projects}
        filteredProjects={filteredProjects}
        selectedProjects={selectedProjects}
        selectedProjectsMap={selectedProjectsMap}
        toggleProjectSelection={toggleProjectSelection}
        selectedProjectName={selectedProjectName}
        filterOwners={filterOwners}
        filterOwner={filterOwner}
        selectedOwnersMap={selectedOwnersMap}
        toggleOwnerSelection={toggleOwnerSelection}
        employees={employees}
        filteredEmployees={filteredEmployees}
        sprints={sprints}
        projectSprintFilter={projectSprintFilter}
        setProjectSprintFilter={setProjectSprintFilter}
        myTasksSprintFilter={myTasksSprintFilter}
        setMyTasksSprintFilter={setMyTasksSprintFilter}
        isSearchFocused={isSearchFocused}
        setIsSearchFocused={setIsSearchFocused}
        isOwnerSearchFocused={isOwnerSearchFocused}
        setIsOwnerSearchFocused={setIsOwnerSearchFocused}
        projectSearch={projectSearch}
        setProjectSearch={setProjectSearch}
        ownerSearch={ownerSearch}
        setOwnerSearch={setOwnerSearch}
        clearProjectsFilter={clearProjectsFilter}
        clearOwnersFilter={clearOwnersFilter}
        slots={slots}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        getOwnerInitials={getOwnerInitials}
        navigate={navigate}
        sprintOptions={sprintOptions}
      />

      {/* Bouton d'ajout de tâche (seulement en mode "par projet") */}
      {viewMode === "project" && !showAddForm && (
        <div className="mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded flex items-center"
            onClick={handleCreateNewTask}
            disabled={selectedProjects.length === 0}
          >
            <span className="mr-2">+</span> Ajouter une tâche
          </button>
        </div>
      )}

      {/* Formulaire d'ajout de tâche */}
      {showAddForm && (
        <AddTaskForm
          pendingNewTask={pendingNewTask}
          newTaskAnimating={newTaskAnimating}
          handleNewTaskChange={handleNewTaskChange}
          handleNewTaskKeyDown={handleNewTaskKeyDown}
          projects={projects}
          employees={employees}
          sprintOptions={sprintOptions}
          handleCancelNewTask={handleCancelNewTask}
          handleSaveNewTask={handleSaveNewTask}
        />
      )}

      {/* Popup d'édition de commentaire */}
      {commentTask && (
        <CommentPopup
          commentText={commentText}
          handleCommentTextChange={handleCommentTextChange}
          handleCommentSave={handleCommentSave}
          closeCommentPopup={closeCommentPopup}
        />
      )}

      {/* Colonnes Kanban */}
      <div className="flex flex-col lg:flex-row gap-4 overflow-x-auto">
        {/* Colonne "À Faire" */}
        <KanbanColumn
          title="À Faire"
          status="not_started"
          tasks={filteredTasks.filter(t => t.status === "not_started")}
          viewMode={viewMode}
          getProjectName={getProjectName}
          getOwnerName={getOwnerName}
          getSprintName={getSprintName}
          getOwnerInitials={getOwnerInitials}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          enableEditing={enableEditing}
          handleEditingChange={handleEditingChange}
          handleEditingBlur={handleEditingBlur}
          editing={editing}
          sprintOptions={sprintOptions}
          handleDeleteTask={handleDeleteTask}
          openCommentPopup={openCommentPopup}
          navigate={navigate}
          allSprints={allSprints}
          employees={employees}
        />

        {/* Colonne "En cours" */}
        <KanbanColumn
          title="En Cours"
          status="in_progress"
          tasks={filteredTasks.filter(t => t.status === "in_progress")}
          viewMode={viewMode}
          getProjectName={getProjectName}
          getOwnerName={getOwnerName}
          getSprintName={getSprintName}
          getOwnerInitials={getOwnerInitials}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          enableEditing={enableEditing}
          handleEditingChange={handleEditingChange}
          handleEditingBlur={handleEditingBlur}
          editing={editing}
          sprintOptions={sprintOptions}
          handleDeleteTask={handleDeleteTask}
          openCommentPopup={openCommentPopup}
          navigate={navigate}
          allSprints={allSprints}
          employees={employees}
        />

        {/* Colonne "Bloqué" */}
        <KanbanColumn
          title="Bloqué"
          status="blocked"
          tasks={filteredTasks.filter(t => t.status === "blocked")}
          viewMode={viewMode}
          getProjectName={getProjectName}
          getOwnerName={getOwnerName}
          getSprintName={getSprintName}
          getOwnerInitials={getOwnerInitials}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          enableEditing={enableEditing}
          handleEditingChange={handleEditingChange}
          handleEditingBlur={handleEditingBlur}
          editing={editing}
          sprintOptions={sprintOptions}
          handleDeleteTask={handleDeleteTask}
          openCommentPopup={openCommentPopup}
          navigate={navigate}
          allSprints={allSprints}
          employees={employees}
        />

        {/* Colonne "Terminé" */}
        <KanbanColumn
          title="Terminé"
          status="done"
          tasks={filteredTasks.filter(t => t.status === "done")}
          viewMode={viewMode}
          getProjectName={getProjectName}
          getOwnerName={getOwnerName}
          getSprintName={getSprintName}
          getOwnerInitials={getOwnerInitials}
          handleDragStart={handleDragStart}
          handleDragOver={handleDragOver}
          handleDrop={handleDrop}
          enableEditing={enableEditing}
          handleEditingChange={handleEditingChange}
          handleEditingBlur={handleEditingBlur}
          editing={editing}
          sprintOptions={sprintOptions}
          handleDeleteTask={handleDeleteTask}
          openCommentPopup={openCommentPopup}
          navigate={navigate}
          allSprints={allSprints}
          employees={employees}
        />
      </div>
    </div>
  );
};

export default TaskBoard;