// frontend/src/components/Slot/SlotWorkloadComparison.js

import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { Clock } from 'lucide-react';
import { useAuth } from '../Auth';

const apiBaseUrl =
  process.env.NODE_ENV === 'development'
    ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
    : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const SlotWorkloadComparison = ({ slot, sprints, employees, projects }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getAuthHeaders } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (!slot || !employees.length) return;
      try {
        // 1. Récupérer tous les projets pour obtenir leur nom réel
        const projectsRes = await fetch(`${apiBaseUrl}/projects`, {
          headers: getAuthHeaders()
        });
        const projectsData = await projectsRes.json();

        // 2. Récupérer les données de charge du slot
        const response = await fetch(`${apiBaseUrl}/slots/${slot.id}/workload`, {
          headers: getAuthHeaders()
        });
        if (!response.ok) throw new Error(`Erreur HTTP: ${response.status}`);
        const workloadData = await response.json();

        // Calculer les heures planifiées par projet à partir des sprints
        const plannedHoursByProject = {};
        sprints.forEach((sprint) => {
          const projId = sprint.project_id;
          // Calcul des jours planifiés uniquement basés sur les tokens et la vélocité
          const plannedDays = sprint.total_tokens / workloadData.velocity;
          const plannedHours = plannedDays * 7.8;

          if (!plannedHoursByProject[projId]) {
            plannedHoursByProject[projId] = {
              projectName: sprint.project_name,
              plannedHours: 0,
              actualHours: 0,
              isOutside: false
            };
          }
          plannedHoursByProject[projId].plannedHours += plannedHours;
          // On n'initialise plus actualHours à partir des tokens complétés
          // Les heures réelles seront uniquement celles saisies par les employés
        });

        // 3. Récupérer les heures réelles saisies par chaque employé
        const employeePromises = employees.map(async (employee) => {
          const res = await fetch(
            `${apiBaseUrl}/hours/employee/${employee.id}?start_date=${slot.start_date}&end_date=${slot.end_date}`,
            { headers: getAuthHeaders() }
          );
          if (!res.ok)
            throw new Error(`Erreur pour l'employé ${employee.id}`);
          return res.json();
        });
        const employeesHours = await Promise.all(employeePromises);

        // Agréger les heures réelles saisies par les employés
        employeesHours.forEach((employeeData) => {
          Object.entries(employeeData.hours_by_day).forEach(([date, projectHours]) => {
            Object.entries(projectHours).forEach(([projectId, hours]) => {
              const projId = Number(projectId);
              if (!plannedHoursByProject[projId]) {
                // Pour les projets hors slot, on utilise le nom réel issu de projectsData
                const found = projectsData.find((p) => p.id === projId);
                plannedHoursByProject[projId] = {
                  projectName: found ? found.name : `Projet ${projId}`,
                  plannedHours: 0, // Aucune allocation de sprint
                  actualHours: 0,
                  isOutside: true
                };
              }
              plannedHoursByProject[projId].actualHours += hours;
            });
          });
        });

        // Conversion en tableau pour le graphique avec séparation entre heures "en slot" et "hors slot"
        const chartData = Object.values(plannedHoursByProject).map(item => ({
          ...item,
          completionRate: item.plannedHours > 0 ? (item.actualHours / item.plannedHours) * 100 : 0,
          actualHoursInSlot: item.isOutside ? 0 : item.actualHours,
          actualHoursOutSlot: item.isOutside ? item.actualHours : 0
        }));

        // Tri décroissant selon les heures planifiées
        chartData.sort((a, b) => b.plannedHours - a.plannedHours);
        setData(chartData);
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors du chargement des données:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slot, sprints, employees, getAuthHeaders]);

  // Affichage personnalisé du tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const planned = payload.find((p) => p.dataKey === 'plannedHours')?.value || 0;
      const actualIn = payload.find((p) => p.dataKey === 'actualHoursInSlot')?.value || 0;
      const actualOut = payload.find((p) => p.dataKey === 'actualHoursOutSlot')?.value || 0;
      const totalActual = actualIn + actualOut;
      const completion = planned > 0 ? ((totalActual / planned) * 100).toFixed(1) : 0;

      return (
        <div className="bg-white p-4 border rounded shadow-lg">
          <p className="font-medium mb-2">{label}</p>
          <p className="text-sm">Planifié : {planned.toFixed(1)}h</p>
          <p className="text-sm">Réel (en slot) : {actualIn.toFixed(1)}h</p>
          <p className="text-sm">Réel (hors slot) : {actualOut.toFixed(1)}h</p>
          <p className="text-sm font-medium mt-1">
            Taux de réalisation : {completion}%
          </p>
        </div>
      );
    }
    return null;
  };

  // Affichage en mode horizontal uniquement
  const renderHorizontalChart = () => (
    <ResponsiveContainer width="100%" height={data.length * 60 + 100}>
      <BarChart
        data={data}
        layout="vertical"
        margin={{ top: 20, right: 30, left: 100, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis type="number" unit="h" />
        <YAxis dataKey="projectName" type="category" width={140} />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar
          dataKey="plannedHours"
          name="Heures Planifiées"
          fill="#93c5fd"
        />
        <Bar
          dataKey="actualHoursInSlot"
          name="Heures Réelles (en slot)"
          fill="#3b82f6"
        />
        <Bar
          dataKey="actualHoursOutSlot"
          name="Heures Réelles (hors slot)"
          fill="#fbbf24"
        />
      </BarChart>
    </ResponsiveContainer>
  );

  // Calcul des totaux pour afficher les statistiques
  const totalActual = data.reduce((acc, cur) => acc + cur.actualHoursInSlot + cur.actualHoursOutSlot, 0);
  const sumProd = data.reduce((acc, cur) => acc + cur.actualHoursInSlot, 0);
  const sumNonProd = data.reduce((acc, cur) => acc + cur.actualHoursOutSlot, 0);
  const percentageProd = totalActual > 0 ? (sumProd / totalActual) * 100 : 0;

  if (loading) {
    return (
      <div className="bg-white shadow rounded-lg p-6 animate-pulse">
        <div className="h-64 bg-gray-200 rounded" />
      </div>
    );
  }

  return (
    <div className="bg-white shadow rounded-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          Comparaison Heures Planifiées vs Réelles
        </h2>
      </div>
      {/* Bloc de résumé des heures */}
      <div className="mb-4 p-4 border rounded bg-gray-50 flex flex-col md:flex-row md:justify-around">
        <div className="text-center mb-2 md:mb-0">
          <span className="font-bold">{totalActual.toFixed(1)} h</span> passées pendant ce slot
        </div>
        <div className="text-center">
          <span className="font-bold">{sumNonProd.toFixed(1)} h</span> hors prod –
          Prod : <span className="font-bold">{sumProd.toFixed(1)} h</span> ({percentageProd.toFixed(1)} %)
        </div>
      </div>
      {renderHorizontalChart()}
      {data.length === 0 && (
        <div className="text-center text-gray-500 mt-4">
          Aucune donnée disponible pour la période sélectionnée
        </div>
      )}
    </div>
  );
};

export default SlotWorkloadComparison;