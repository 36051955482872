import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, ArrowRight, Calendar, Clock, BarChart2, Users } from 'lucide-react';
import { useAuth } from '../Auth';
import SprintsTimeline from './SprintsTimeline';
import SlotProgress from './SlotProgress';
import SlotTokensDistribution from './SlotTokensDistribution';
import WorkloadManagement from '../WorkloadManagement';
import SlotWorkloadComparison from './SlotWorkloadComparison';
import EfficiencyStats from '../EfficiencyStats';

const apiBaseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL || 'http://localhost:8000'
    : (process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/api` : 'http://localhost:8000');

const SlotDetail = () => {
  const { slotId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('analytics');
  const [slot, setSlot] = useState(null);
  const [sprints, setSprints] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [workloadData, setWorkloadData] = useState(null); // State pour stocker les données de workload
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allSlots, setAllSlots] = useState([]);

  const { getAuthHeaders } = useAuth();

  const fetchAllSlots = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/slots`, {
        headers: getAuthHeaders(),
      });
      if (!response.ok) {
        throw new Error('Échec du chargement des slots');
      }
      const data = await response.json();
      // Trier les slots par date de début
      const sortedSlots = Array.isArray(data) 
        ? [...data].sort((a, b) => new Date(a.start_date) - new Date(b.start_date)) 
        : [];
      setAllSlots(sortedSlots);
    } catch (err) {
      console.error('Error loading all slots:', err);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
  
      // Charger les détails du slot
      const slotResponse = await fetch(`${apiBaseUrl}/slots/${slotId}`, {
        headers: getAuthHeaders(),
      });
      if (!slotResponse.ok) {
        throw new Error('Échec du chargement des détails du slot');
      }
      const slotData = await slotResponse.json();
      setSlot(slotData);
  
      // Charger les données de workload
      const workloadResponse = await fetch(`${apiBaseUrl}/slots/${slotId}/workload`, {
        headers: getAuthHeaders(),
      });
      if (!workloadResponse.ok) {
        throw new Error('Échec du chargement des données de charge');
      }
      const fetchedWorkloadData = await workloadResponse.json();
      console.log("Fetched workload data:", fetchedWorkloadData);
      setWorkloadData(fetchedWorkloadData);
  
      // Charger les employés
      const employeesResponse = await fetch(`${apiBaseUrl}/employees`, {
        headers: getAuthHeaders(),
      });
      if (!employeesResponse.ok) {
        throw new Error('Échec du chargement des employés');
      }
      const employeesData = await employeesResponse.json();
      console.log("Employees data:", employeesData);
  
      // 1. Filtrer les employés présents pendant le slot en fonction des dates d'arrivée et de départ
      const slotStartDate = new Date(slotData.start_date);
      const slotEndDate = new Date(slotData.end_date);
      
      const employeesPresentDuringSlot = employeesData.filter(employee => {
        // Date d'arrivée de l'employé
        const employeeArrivalDate = new Date(employee.arrival_date);
        
        // Date de départ de l'employé (si spécifiée)
        const employeeDepartureDate = employee.departure_date ? new Date(employee.departure_date) : null;
        
        // Un employé est présent pendant le slot si :
        // 1. Il est arrivé avant la fin du slot
        const arrivedBeforeSlotEnd = employeeArrivalDate <= slotEndDate;
        
        // 2. Et soit il n'a pas de date de départ, soit il est parti après le début du slot
        const presentDuringSlot = !employeeDepartureDate || employeeDepartureDate >= slotStartDate;
        
        return arrivedBeforeSlotEnd && presentDuringSlot;
      });
      
      console.log("Employees present during slot:", employeesPresentDuringSlot);
      
      // 2. Récupérer les tâches pour les sprints du slot afin d'identifier qui a contribué
      let employeesWithTasks = [];
      
      // Si nous avons des sprints, récupérons les tâches pour chaque projet
      if (slotData.sprints && slotData.sprints.length > 0) {
        // Récupérer tous les ID de projets uniques à partir des sprints
        const projectIds = [...new Set(slotData.sprints.map(sprint => sprint.project_id))];
        
        // Pour chaque projet, nous allons récupérer les tâches 
        await Promise.all(projectIds.map(async (projectId) => {
          try {
            const tasksResponse = await fetch(`${apiBaseUrl}/projects/${projectId}/tasks`, {
              headers: getAuthHeaders(),
            });
            
            if (tasksResponse.ok) {
              const projectTasks = await tasksResponse.json();
              
              // Filtrer les tâches pour n'inclure que celles qui sont dans nos sprints
              const sprintIds = slotData.sprints
                .filter(sprint => sprint.project_id === projectId)
                .map(sprint => sprint.id);
                
              const relevantTasks = projectTasks.filter(task => 
                sprintIds.includes(task.sprint_id) && task.owner_id
              );
              
              // Trouver tous les employés qui ont des tâches dans ces sprints
              relevantTasks.forEach(task => {
                const employeeId = task.owner_id;
                const employee = employeesData.find(emp => emp.id === employeeId);
                
                // Si l'employé existe et n'est pas déjà dans la liste
                if (employee && !employeesWithTasks.some(emp => emp.id === employeeId)) {
                  employeesWithTasks.push(employee);
                }
              });
            }
          } catch (error) {
            console.error(`Error fetching tasks for project ${projectId}:`, error);
          }
        }));
      }

      console.log("Employees with tasks in slot:", employeesWithTasks);
      
      // Combiner les deux listes d'employés et supprimer les doublons
      const allEmployeeIds = new Set();
      const combinedEmployees = [];
      
      // D'abord ajouter tous les employés présents pendant le slot
      employeesPresentDuringSlot.forEach(emp => {
        allEmployeeIds.add(emp.id);
        combinedEmployees.push(emp);
      });
      
      // Ensuite ajouter les employés avec des tâches qui ne sont pas déjà inclus
      employeesWithTasks.forEach(emp => {
        if (!allEmployeeIds.has(emp.id)) {
          allEmployeeIds.add(emp.id);
          combinedEmployees.push(emp);
        }
      });
      console.log("Combined employees:", combinedEmployees);
  
      // Préparation des données employés avec indisponibilités (si nécessaire)
      const employeesWithUnavailability = combinedEmployees.map((emp) => ({
        ...emp,
        unavailable_days:
          fetchedWorkloadData.employeeUnavailabilities.find((u) => u.employee_id === emp.id)
            ?.unavailable_days || 0,
      }));
  
      setEmployees(employeesWithUnavailability);
      setSprints(slotData.sprints || []);
      setLoading(false);
    } catch (err) {
      console.error('Error loading data:', err);
      setError(err.message);
      setLoading(false);
    }
  };  

  // Effet pour charger les données initiales
  useEffect(() => {
    if (slotId) {
      fetchData();
      fetchAllSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotId]);

  const goToNextSlot = () => {
    if (!slot || allSlots.length <= 1) return;
    
    const currentIndex = allSlots.findIndex(s => s.id === parseInt(slotId));
    if (currentIndex < allSlots.length - 1) {
      navigate(`/slot/${allSlots[currentIndex + 1].id}`);
    }
  };

  const goToPreviousSlot = () => {
    if (!slot || allSlots.length <= 1) return;
    
    const currentIndex = allSlots.findIndex(s => s.id === parseInt(slotId));
    if (currentIndex > 0) {
      navigate(`/slot/${allSlots[currentIndex - 1].id}`);
    }
  };

  const goToSlotTimeline = () => {
    navigate('/slots');
  };

  if (loading) {
    return <div className="p-4">Chargement...</div>;
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 bg-red-100 rounded">
        {error}
      </div>
    );
  }

  // Déterminer si les boutons suivant/précédent doivent être désactivés
  const currentIndex = allSlots.findIndex(s => s.id === parseInt(slotId));
  const isPreviousDisabled = currentIndex <= 0;
  const isNextDisabled = currentIndex >= allSlots.length - 1;

  return (
    <div className="space-y-6">
      {/* En-tête */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={goToPreviousSlot}
              className={`p-2 rounded-full ${isPreviousDisabled ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-100 text-gray-600'}`}
              disabled={isPreviousDisabled}
              title="Slot précédent"
            >
              <ArrowLeft className="h-5 w-5" />
            </button>
            <button
              onClick={goToSlotTimeline}
              className="p-2 hover:bg-gray-100 rounded-full text-gray-600"
              title="Timeline des slots"
            >
              <Calendar className="h-5 w-5" />
            </button>
            <button
              onClick={goToNextSlot}
              className={`p-2 rounded-full ${isNextDisabled ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-100 text-gray-600'}`}
              disabled={isNextDisabled}
              title="Slot suivant"
            >
              <ArrowRight className="h-5 w-5" />
            </button>
          </div>
          <h1 className="text-2xl font-bold">{slot?.name}</h1>
        </div>
        <div className="flex items-center space-x-4 text-sm text-gray-500">
          <Clock className="h-4 w-4" />
          <span>
            Du {new Date(slot?.start_date).toLocaleDateString('fr-FR')} au{' '}
            {new Date(slot?.end_date).toLocaleDateString('fr-FR')}
          </span>
        </div>
      </div>

      {/* Onglets */}
      <div className="border-b border-gray-200">
        <div className="flex space-x-4">
          <button
            onClick={() => setActiveTab('analytics')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'analytics'
                ? 'bg-purple-100 text-purple-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <BarChart2 className="h-4 w-4 mr-2" />
            Analytiques
          </button>
          <button
            onClick={() => setActiveTab('workload')}
            className={`flex items-center px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'workload'
                ? 'bg-purple-100 text-purple-700'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
          >
            <Users className="h-4 w-4 mr-2" />
            Gestion de la Charge
          </button>
        </div>
      </div>

      {/* Contenu des onglets */}
      {activeTab === 'analytics' ? (
        <div className="space-y-6">
          {/* Condition pour SlotProgress */}
          {slot && workloadData && (
            <SlotProgress
              slot={slot}
              sprints={sprints}
              employees={employees}
              workloadData={workloadData}
            />
          )}
          {/* Condition pour SlotTokensDistribution */}
          {workloadData && (
            <SlotTokensDistribution
              slot={slot}
              sprints={sprints}
              workloadData={workloadData}
            />
          )}
          {/* Encart de statistiques d'efficacité */}
          <EfficiencyStats slot={slot} sprints={sprints} employees={employees} />
          {/* Condition pour SprintsTimeline */}
          {sprints.length > 0 && workloadData ? (
            <>
              <SprintsTimeline
                slot={slot}
                sprints={sprints}
                setSprints={setSprints}
                workloadData={workloadData}
              />
              {/* Condition pour SlotWorkloadComparison */}
              <SlotWorkloadComparison
                slot={slot}
                sprints={sprints}
                employees={employees}
                workloadData={workloadData}
              />
            </>
          ) : (
            <div className="bg-white rounded-lg shadow p-6 text-center text-gray-500">
              Aucun sprint trouvé pendant cette période.
            </div>
          )}
        </div>
      ) : (
        <WorkloadManagement
          slot={slot}
          sprints={sprints}
          employees={employees}
          workloadData={workloadData}
          setWorkloadData={setWorkloadData}
          onWorkloadUpdate={(newWorkloadData) => {
            setWorkloadData(newWorkloadData);
            // Mise à jour des indisponibilités des employés
            const updatedEmployees = employees.map((emp) => ({
              ...emp,
              unavailable_days:
                newWorkloadData.employeeUnavailabilities.find((u) => u.employee_id === emp.id)
                  ?.unavailable_days || 0,
            }));
            setEmployees(updatedEmployees);
          }}
        />
      )}
    </div>
  );
};

export default SlotDetail;