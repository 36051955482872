import React from 'react';
import DayHeader from './DayHeader';
import ProjectRow from './ProjectRow';
import { isWeekend, isFrenchHoliday } from '../../utils/dateUtils';
import { ChevronDown, ChevronRight } from 'lucide-react';

const ProjectsTable = ({
  weekDates,
  activeProjects,
  inactiveProjects,
  showOtherProjects,
  setShowOtherProjects,
  getHours,
  handleHourChange,
  handleBlur,
  employee,
  getProjectTotal,
  getDayTotal,
  slotHoursByProject,
  plannedHoursByProject
}) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left">Projets</th>
            {weekDates.map(date => (
              <DayHeader key={date.toISOString()} date={date} />
            ))}
            <th className="px-4 py-2 text-center">Total</th>
          </tr>
        </thead>
        
        <tbody>
          {/* Active Projects */}
          {activeProjects.map(project => (
            <ProjectRow
              key={project.id}
              project={project}
              weekDates={weekDates}
              getHours={getHours}
              handleHourChange={handleHourChange}
              handleBlur={handleBlur}
              employee={employee}
              getProjectTotal={getProjectTotal}
              slotTotalHours={slotHoursByProject[project.id] || 0}
              plannedHours={plannedHoursByProject[project.id] || 0}
            />
          ))}

          {/* Inactive Projects Section */}
          {inactiveProjects.length > 0 && (
            <>
              <tr className="bg-gray-50">
                <td colSpan={weekDates.length + 2}>
                  <button
                    onClick={() => setShowOtherProjects(!showOtherProjects)}
                    className="flex items-center w-full px-4 py-2 hover:bg-gray-100"
                  >
                    {showOtherProjects ? (
                      <ChevronDown className="h-4 w-4 mr-2" />
                    ) : (
                      <ChevronRight className="h-4 w-4 mr-2" />
                    )}
                    Projets hors slots ({inactiveProjects.length})
                  </button>
                </td>
              </tr>
              {showOtherProjects && inactiveProjects.map(project => (
                <ProjectRow
                  key={project.id}
                  project={project}
                  weekDates={weekDates}
                  getHours={getHours}
                  handleHourChange={handleHourChange}
                  handleBlur={handleBlur}
                  employee={employee}
                  getProjectTotal={getProjectTotal}
                  slotTotalHours={slotHoursByProject[project.id] || 0}
                  plannedHours={plannedHoursByProject[project.id] || 0}
                />
              ))}
            </>
          )}

          {/* Totals Row */}
          <tr className="border-t bg-gray-50 font-medium">
            <td className="px-4 py-2">Total</td>
            {weekDates.map(date => (
              <td 
                key={date.toISOString()} 
                className={`px-4 py-2 text-center ${
                  isWeekend(date) ? 'bg-gray-100' : 
                  isFrenchHoliday(date) ? 'bg-gray-50' : ''
                }`}
              >
                {getDayTotal(date)}
              </td>
            ))}
            <td className="px-4 py-2 text-center">
              {weekDates.reduce((total, date) => total + getDayTotal(date), 0)}
            </td>
          </tr>
        </tbody>
      </table>
      
      {/* Légende */}
      <div className="mt-4 text-xs text-gray-500 flex items-center space-x-4">
        <div className="flex items-center">
          <div className="w-4 h-4 bg-gray-100 mr-2"></div>
          <span>Weekend</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-gray-50 mr-2"></div>
          <span>Jour férié</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-white border mr-2"></div>
          <span>Jour ouvré</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectsTable;