/* frontend/src/components/EfficiencyStats.js */
import React, { useState, useEffect } from 'react';
import { useAuth } from './Auth';
import { useNavigate } from 'react-router-dom';

const apiBaseUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_API_URL || 'http://localhost:8000'
    : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const EfficiencyStats = ({ slot, sprints, employees }) => {
  const { getAuthHeaders } = useAuth();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('tokens'); // 'tokens' ou 'ca'
  const [rankingData, setRankingData] = useState({ tokens: [], ca: [] });
  const [loading, setLoading] = useState(true);

  // Récupération des heures réelles par projet
  const fetchEmployeeHours = async () => {
    try {
      const promises = employees.map(emp =>
        fetch(
          `${apiBaseUrl}/hours/employee/${emp.id}?start_date=${slot.start_date}&end_date=${slot.end_date}`,
          { headers: getAuthHeaders() }
        )
          .then(res => res.json())
          .catch(err => null)
      );
      const employeesHours = await Promise.all(promises);
      let projectHoursMap = {};
      employeesHours.forEach(empData => {
        if (!empData || !empData.hours_by_day) return;
        Object.entries(empData.hours_by_day).forEach(([day, projData]) => {
          Object.entries(projData).forEach(([projId, hrs]) => {
            const projectId = Number(projId);
            if (!projectHoursMap[projectId]) {
              // Recherche du nom dans les sprints
              const sprintFound = sprints.find(s => s.project_id === projectId);
              const projectName = sprintFound ? sprintFound.project_name : `Projet ${projectId}`;
              projectHoursMap[projectId] = { projectName, actualHours: 0 };
            }
            projectHoursMap[projectId].actualHours += Number(hrs);
          });
        });
      });
      return projectHoursMap;
    } catch (error) {
      console.error("Error fetching employee hours", error);
      return {};
    }
  };

  useEffect(() => {
    if (!slot || !sprints || !employees || employees.length === 0) return;
    const computeRankings = async () => {
      setLoading(true);
      const projectHoursMap = await fetchEmployeeHours();

      let tokensRanking = [];
      let caRanking = [];
      // Agrégation des données des sprints par projet
      let projectAggregates = {};
      sprints.forEach(sprint => {
        const projId = sprint.project_id;
        if (!projectAggregates[projId]) {
          projectAggregates[projId] = { projectName: sprint.project_name, tokens: 0, ca: 0 };
        }
        projectAggregates[projId].tokens += sprint.tokens_completed || 0;
        projectAggregates[projId].ca += sprint.ca_produit || 0;
      });

      Object.entries(projectAggregates).forEach(([projId, agg]) => {
        const projectId = Number(projId);
        const hoursInfo = projectHoursMap[projectId];
        const actualHours = hoursInfo ? hoursInfo.actualHours : 0;
        // Conversion : on considère 1 jour = 7,8 h
        // Pour tokens : vélocité = (tokens réalisés * 7.8) / heures réelles
        const tokensPerDay = (actualHours > 0 && agg.tokens > 0) ? (agg.tokens * 7.8) / actualHours : null;
        // Pour le CA : vélocité = (CA produit * 7.8) / heures réelles
        const caPerDay = (actualHours > 0 && agg.ca > 0) ? (agg.ca * 7.8) / actualHours : null;
        if (tokensPerDay !== null) {
          tokensRanking.push({
            projectId,
            projectName: agg.projectName,
            efficiency: tokensPerDay
          });
        }
        if (caPerDay !== null) {
          caRanking.push({
            projectId,
            projectName: agg.projectName,
            efficiency: caPerDay
          });
        }
      });

      // Ici, plus la valeur est élevée, mieux c'est
      tokensRanking.sort((a, b) => b.efficiency - a.efficiency);
      caRanking.sort((a, b) => b.efficiency - a.efficiency);

      setRankingData({ tokens: tokensRanking, ca: caRanking });
      setLoading(false);
    };

    computeRankings();
  }, [slot, sprints, employees, getAuthHeaders]);

  // Pour calculer la largeur de la barre : 
  // Ici, le meilleur (valeur max) aura 100%, le moins efficace (valeur min) 0%
  const computeProgressPercentage = (dataArray, efficiencyValue) => {
    if (dataArray.length === 0) return 100;
    const best = dataArray[0].efficiency;         // maximum
    const worst = dataArray[dataArray.length - 1].efficiency; // minimum
    if (best === worst) return 100;
    const percentage = ((efficiencyValue - worst) / (best - worst)) * 100;
    return Math.round(percentage);
  };

  return (
    <div className="border rounded-lg shadow p-4 mb-6 bg-white">
      <h2 className="text-xl font-semibold mb-4">Statistiques d'efficacité</h2>
      {/* Onglets */}
      <div className="flex space-x-4 mb-4">
        <button 
          onClick={() => setActiveTab('tokens')}
          className={`px-4 py-2 rounded-lg transition-colors ${activeTab === 'tokens' ? 'bg-blue-100 text-blue-700' : 'text-gray-600 hover:bg-gray-100'}`}
        >
          Vélocité (tokens/j)
        </button>
        <button 
          onClick={() => setActiveTab('ca')}
          className={`px-4 py-2 rounded-lg transition-colors ${activeTab === 'ca' ? 'bg-blue-100 text-blue-700' : 'text-gray-600 hover:bg-gray-100'}`}
        >
          CA (€/j)
        </button>
      </div>
      {loading ? (
        <div>Chargement des statistiques...</div>
      ) : (
        <div>
          {(activeTab === 'tokens' ? rankingData.tokens : rankingData.ca).length === 0 ? (
            <div>Aucun projet avec des heures enregistrées.</div>
          ) : (
            <div className="space-y-4">
              {(activeTab === 'tokens' ? rankingData.tokens : rankingData.ca).map((item, index, array) => (
                <div key={item.projectId} className="border-t pt-2">
                  <div className="flex justify-between items-center text-sm mb-1">
                    <span>
                      {index + 1}.{' '}
                      <span 
                        onClick={() => navigate(`/project/${item.projectId}`)}
                        className="cursor-pointer text-blue-600 hover:underline"
                      >
                        {item.projectName}
                      </span>
                    </span>
                    <span className="font-medium">
                      {item.efficiency.toFixed(2)} {activeTab === 'tokens' ? 'tokens/j' : '€/j'}
                    </span>
                  </div>
                  <div className="relative h-4 bg-gray-200 rounded">
                    <div 
                      className={`absolute h-full rounded transition-all duration-300 ${activeTab === 'ca' ? 'bg-yellow-500' : 'bg-blue-500'}`}
                      style={{ width: `${computeProgressPercentage(activeTab === 'tokens' ? rankingData.tokens : rankingData.ca, item.efficiency)}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EfficiencyStats;
