import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart3, RefreshCw } from 'lucide-react';
import { apiService } from '../services/api';

const Dashboard = () => {
  const [velocityData, setVelocityData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);

  useEffect(() => {
    fetchSlotData();
  }, []);

  const fetchSlotData = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const slots = await apiService.get('/slots');
      
      // Filtrer pour ne pas inclure les slots du futur
      const today = new Date();
      const currentAndPastSlots = slots.filter(slot => {
        const slotStartDate = new Date(slot.start_date);
        return slotStartDate <= today;
      });
      
      // Trier par date de début
      const sortedSlots = currentAndPastSlots.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
      
      console.log("Slots filtrés (seulement actuels et passés):", sortedSlots.map(s => s.name));
      
      // Pour chaque slot, obtenir les détails
      const slotsWithDetails = await Promise.all(
        sortedSlots.map(async (slot) => {
          const slotDetail = await apiService.get(`/slots/${slot.id}`);
          
          // Debug - Afficher toutes les statistiques disponibles
          console.log(`Slot ${slot.id} (${slotDetail.name}) statistiques:`, slotDetail.statistics);
          
          // Pour le slot sélectionné, affichez les valeurs de vélocité vues dans la page détaillée
          if (slot.id === 1) {
            console.log("Valeurs du slot 1 (à comparer avec la page détaillée):");
            console.log("- Vélocité/personne/jour:", slotDetail.statistics?.daily_velocity_per_person);
            console.log("- Vélocité équipe/jour:", slotDetail.statistics?.daily_team_velocity);
          }
          
          // Récupérer le nombre d'employés actifs à partir des statistiques
          const teamSize = slotDetail.statistics?.team_size || 0;
          
          return {
            id: slot.id,
            name: slot.name,
            startDate: new Date(slot.start_date),
            endDate: new Date(slot.end_date),
            // Utiliser directement les statistiques précalculées
            dailyVelocity: slotDetail.statistics?.daily_velocity_per_person || 0,
            teamVelocity: slotDetail.statistics?.daily_team_velocity || 0,
            dailyProductivity: slotDetail.statistics?.daily_productivity_per_person || 0,
            completionPercentage: slotDetail.statistics?.completion_percentage || 0,
            teamSize: teamSize,
            caProduit: slotDetail.statistics?.ca_produit || 0,
            caAProduire: slotDetail.statistics?.ca_a_produire || 0,
            lastUpdate: slotDetail.statistics?.last_stats_update
          };
        })
      );
      
      // Formater pour recharts - garder les valeurs exactes sans modification
      const formattedData = slotsWithDetails.map(slot => ({
        name: slot.name,
        vélocité: slot.dailyVelocity,
        'vélocité équipe': slot.teamVelocity,
        productivité: slot.dailyProductivity,
        complétion: slot.completionPercentage,
        'équipe': slot.teamSize,  // Ajout du nombre d'employés
        'ca_produit': slot.caProduit,
        'ca_a_produire': slot.caAProduire,
        date: slot.startDate.toISOString().split('T')[0],
        lastUpdate: slot.lastUpdate
      }));
      
      console.log("Données formatées pour le graphique:", formattedData);
      
      // Trouver la date de dernière mise à jour la plus récente
      const lastUpdates = slotsWithDetails
        .map(slot => slot.lastUpdate)
        .filter(date => date)
        .sort((a, b) => new Date(b) - new Date(a));
      
      if (lastUpdates.length > 0) {
        setLastUpdateTime(lastUpdates[0]);
      }
      
      setVelocityData(formattedData);
      setIsLoading(false);
    } catch (err) {
      console.error('Erreur lors du chargement des données:', err);
      setError('Impossible de charger les données');
      setIsLoading(false);
    }
  };
  
  const triggerStatsUpdate = async () => {
    try {
      setIsUpdating(true);
      // Déclencher la mise à jour des statistiques en arrière-plan
      await apiService.post('/slots/update-statistics');
      console.log("Mise à jour manuelle des statistiques déclenchée");
      
      // Attendre que les statistiques se mettent à jour
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      // Récupérer les données mises à jour
      await fetchSlotData();
      setIsUpdating(false);
    } catch (err) {
      console.error('Erreur lors de la mise à jour des statistiques:', err);
      setIsUpdating(false);
    }
  };

  return (
    <div className="py-6">
      <header className="mb-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-2xl font-bold text-gray-900 flex items-center">
                <BarChart3 className="h-7 w-7 mr-2 text-blue-500" />
                Dashboard
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Indicateurs clés de performance de l'entreprise
              </p>
            </div>
            <div>
              <button 
                className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${isUpdating ? 'bg-gray-400' : 'bg-blue-600 hover:bg-blue-700'}`}
                disabled={isUpdating}
                onClick={triggerStatsUpdate}
              >
                <RefreshCw className={`h-4 w-4 mr-2 ${isUpdating ? 'animate-spin' : ''}`} />
                {isUpdating ? 'Mise à jour en cours...' : 'Mettre à jour les statistiques'}
              </button>
              {lastUpdateTime && (
                <p className="mt-1 text-xs text-gray-500 text-right">
                  Dernière mise à jour: {new Date(lastUpdateTime).toLocaleDateString('fr-FR')}
                </p>
              )}
            </div>
          </div>
        </div>
      </header>
      
      <main>
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-4">Évolution de la vélocité par slot</h2>
              
              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
              ) : error ? (
                <div className="text-center text-red-500 py-10">{error}</div>
              ) : (
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={velocityData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis 
                        label={{ 
                          value: 'Tokens/jour/personne', 
                          angle: -90, 
                          position: 'insideLeft',
                          style: { textAnchor: 'middle' }
                        }}
                      />
                      <Tooltip 
                        formatter={(value, name) => {
                          if (name === 'vélocité') return [`${value} tokens/j/personne`, 'Vélocité individuelle'];
                          if (name === 'vélocité équipe') return [`${value} tokens/jour`, 'Vélocité équipe'];
                          if (name === 'productivité') return [`${value} €/j/personne`, 'Productivité'];
                          if (name === 'complétion') return [`${value}%`, 'Taux de complétion'];
                          return [value, name];
                        }}
                        labelFormatter={(name) => `Slot: ${name}`}
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="vélocité" 
                        name="Vélocité individuelle"
                        stroke="#3b82f6" 
                        activeDot={{ r: 8 }} 
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
              
              <div className="mt-4 text-sm text-gray-500">
                <p>Ce graphique montre l'évolution de la vélocité (tokens/jour/personne) pour chaque slot.</p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-4">Évolution de la vélocité d'équipe par slot</h2>
              
              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
              ) : error ? (
                <div className="text-center text-red-500 py-10">{error}</div>
              ) : (
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={velocityData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis 
                        yAxisId="left"
                        label={{ 
                          value: 'Tokens/jour', 
                          angle: -90, 
                          position: 'insideLeft',
                          style: { textAnchor: 'middle' }
                        }}
                      />
                      <YAxis 
                        yAxisId="right"
                        orientation="right"
                        label={{ 
                          value: 'Nombre d\'employés', 
                          angle: 90, 
                          position: 'insideRight',
                          style: { textAnchor: 'middle' }
                        }}
                        domain={[0, 'dataMax + 2']}
                      />
                      <Tooltip 
                        formatter={(value, name) => {
                          if (name === 'vélocité équipe') return [`${value} tokens/jour`, 'Vélocité équipe'];
                          if (name === 'équipe') return [`${value} personnes`, 'Taille équipe'];
                          return [value, name];
                        }}
                        labelFormatter={(name) => `Slot: ${name}`}
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="vélocité équipe" 
                        name="Vélocité équipe"
                        stroke="#10b981" 
                        yAxisId="left"
                        activeDot={{ r: 8 }} 
                        strokeWidth={2}
                      />
                      <Line 
                        type="monotone" 
                        dataKey="équipe" 
                        name="Taille équipe"
                        stroke="#f97316" 
                        yAxisId="right"
                        strokeDasharray="5 5"
                        activeDot={{ r: 6 }} 
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
              
              <div className="mt-4 text-sm text-gray-500">
                <p>Ce graphique montre l'évolution de la vélocité d'équipe (tokens/jour) pour chaque slot.</p>
              </div>
            </div>
          </div>
          
          <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-4">Évolution de la productivité individuelle par slot</h2>
              
              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
              ) : error ? (
                <div className="text-center text-red-500 py-10">{error}</div>
              ) : (
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={velocityData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis 
                        label={{ 
                          value: 'Euros/jour/personne', 
                          angle: -90, 
                          position: 'insideLeft',
                          style: { textAnchor: 'middle' }
                        }}
                      />
                      <Tooltip 
                        formatter={(value) => [`${value} €/jour/personne`, 'Productivité individuelle']}
                        labelFormatter={(name) => `Slot: ${name}`}
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="productivité" 
                        name="Productivité individuelle"
                        stroke="#f59e0b" 
                        activeDot={{ r: 8 }} 
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
              
              <div className="mt-4 text-sm text-gray-500">
                <p>Ce graphique montre l'évolution de la productivité individuelle (€/jour/personne) pour chaque slot.</p>
              </div>
            </div>
          </div>

          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-4">Évolution du chiffre d'affaires par slot</h2>
              
              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
                </div>
              ) : error ? (
                <div className="text-center text-red-500 py-10">{error}</div>
              ) : (
                <div className="h-80">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={velocityData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis 
                        label={{ 
                          value: 'Euros', 
                          angle: -90, 
                          position: 'insideLeft',
                          style: { textAnchor: 'middle' }
                        }}
                      />
                      <Tooltip 
                        formatter={(value, name) => {
                          if (name === 'ca_produit') return [`${value} €`, 'CA produit'];
                          if (name === 'ca_a_produire') return [`${value} €`, 'CA à produire'];
                          return [value, name];
                        }}
                        labelFormatter={(name) => `Slot: ${name}`}
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="ca_produit" 
                        name="CA produit"
                        stroke="#6366f1" 
                        activeDot={{ r: 8 }} 
                        strokeWidth={2}
                      />
                      <Line 
                        type="monotone" 
                        dataKey="ca_a_produire" 
                        name="CA à produire"
                        stroke="#ec4899" 
                        strokeDasharray="5 5"
                        activeDot={{ r: 6 }} 
                        strokeWidth={2}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
              
              <div className="mt-4 text-sm text-gray-500">
                <p>Ce graphique montre l'évolution du chiffre d'affaires produit par rapport à l'objectif pour chaque slot.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;