// C:/Users/remyc/OneDrive/Desktop/Projets/lab/Web/sprint_manager/frontend/src/components/GlobalCurrentDayLine.js
import React from 'react';

const GlobalCurrentDayLine = ({ startDate, endDate }) => {
  const today = new Date();

  // Ignorer si aujourd'hui n'est pas dans la plage de la timeline
  if (today < startDate || today > endDate) {
    return null;
  }

  // Calcul de la position de la ligne en fonction de startDate et endDate globaux
  const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
  const daysPassed = Math.ceil((today - startDate) / (1000 * 60 * 60 * 24));
  const position = (daysPassed / totalDays) * 100;

  return (
    <div
      className="absolute h-full w-0.5 bg-red-500 z-10"
      style={{
        left: `${position}%`,
        pointerEvents: 'none',
      }}
    >
      {/* Étiquette du jour actuel */}
      <div className="absolute top-0 transform -translate-x-1/2 -translate-y-full">
        <div className="bg-red-100 text-red-800 text-xs px-2 py-1 rounded whitespace-nowrap">
          Today
        </div>
      </div>
    </div>
  );
};

export default GlobalCurrentDayLine;
