/* frontend/src/components/KickoffSprintView.js */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiService } from "../services/api";
import TaskItem from "./TaskItem";

const KickoffSprintView = ({ projectId: propProjectId }) => {
  const navigate = useNavigate();
  const params = useParams();
  const projectId = propProjectId || params.projectId;

  const [tasks, setTasks] = useState([]);
  const [inputTask, setInputTask] = useState(null);
  const [sprints, setSprints] = useState([]);
  const [employees, setEmployees] = useState([]);

  // Charger les tâches du projet
  useEffect(() => {
    apiService.get(`/projects/${projectId}/tasks`)
      .then((data) => {
        const transformedTasks = data.map(task => ({
          ...task,
          x: task.x === undefined || task.x === null ? `${Math.floor(Math.random() * 80 + 10)}%` : task.x,
          y: task.y === undefined || task.y === null ? `${Math.floor(Math.random() * 80 + 10)}%` : task.y,
        }));
        setTasks(transformedTasks);
      })
      .catch(console.error);
  }, [projectId]);

  // Charger la liste des employés
  useEffect(() => {
    apiService.get("/employees")
      .then((data) => setEmployees(data))
      .catch(console.error);
  }, []);

  // Charger la liste des sprints du projet
  useEffect(() => {
    apiService.get(`/projects/${projectId}/sprints`)
      .then((data) => setSprints(data))
      .catch(console.error);
  }, [projectId]);

  // Fonction de rendu d'une TaskItem en passant les listes de sprints et d'employés
  const renderTaskCard = (task, style = {}) => (
    <TaskItem
      key={task.id}
      task={task}
      onDelete={(id) => {
        apiService.delete(`/projects/${projectId}/tasks/${id}`)
          .then(() => setTasks(prev => prev.filter(t => t.id !== id)))
          .catch(console.error);
      }}
       onUpdate={(updatedFields) => {
         apiService.patch(`/projects/${projectId}/tasks/${task.id}`, updatedFields)
           .then(response => {
             setTasks(prev =>
               prev.map(t =>
                 t.id === task.id ? { ...response, x: t.x, y: t.y } : t
               )
             );
           })
           .catch(err => console.error("Erreur de mise à jour :", err));
       }}
      onDragStart={(e) => e.dataTransfer.setData("text/plain", task.id)}
      onDragOver={(e) => e.preventDefault()}
      style={style}
      sprintOptions={sprints}
      ownerOptions={employees}
    />
  );

  // Gestion du drop dans la zone non affectée (utilisation de la phase capture)
  const handleDropNonAssigned = (e) => {
    e.preventDefault();
    const taskId = e.dataTransfer.getData("text/plain");
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    // Mettre à jour localement : désaffecter le sprint et actualiser la position
    setTasks(prev =>
      prev.map(t =>
        t.id.toString() === taskId ? { ...t, sprint_id: null, x: `${x}px`, y: `${y}px` } : t
      )
    );
    // Mise à jour via l'API
    apiService.patch(`/projects/${projectId}/tasks/${taskId}`, { sprint_id: null, x: `${x}px`, y: `${y}px` })
      .catch(console.error);
  };
  // Gestion du click dans la zone non attribuée pour créer une nouvelle tâche
  const handleBottomClick = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const newTaskId = Date.now();
    setInputTask({ id: newTaskId, x: `${x}px`, y: `${y}px`, value: "" });
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="flex flex-col flex-1 overflow-hidden">
        {/* Zone haute : affichage des sprints */}
        <div className="h-[45%] border-b overflow-y-auto p-4 flex space-x-4 bg-gray-50">
          {sprints.map(sprint => (
            <div
              key={sprint.id}
              className="bg-white shadow rounded p-4 min-w-[200px] flex-shrink-0 h-full overflow-y-auto"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                const taskId = e.dataTransfer.getData("text/plain");
                // Mise à jour locale : affecter ce sprint à la tâche
                setTasks(prev =>
                  prev.map(t =>
                    t.id.toString() === taskId ? { ...t, sprint_id: sprint.id } : t
                  )
                );
                // Mise à jour via l'API
                apiService.patch(`/projects/${projectId}/tasks/${taskId}`, { sprint_id: sprint.id })
                  .catch(console.error);
              }}
            >
              <h2 className="text-lg font-semibold mb-2">
                {sprint.description || `Sprint ${sprint.id}`}
              </h2>
              {tasks.filter(t => t.sprint_id === sprint.id).map(task =>
                renderTaskCard(task)
              )}
            </div>
          ))}
        </div>

        {/* Zone basse : tâches non affectées à un sprint */}
        <div
          className="h-[35%] relative bg-gray-50 p-4 border border-dashed border-gray-300 rounded overflow-y-auto no-scrollbar"
          onClick={handleBottomClick}
          onDragOver={(e) => e.preventDefault()}
          onDropCapture={handleDropNonAssigned}
        >
          {tasks.filter(t => t.sprint_id === null).map(task =>
            renderTaskCard(task, { position: "absolute", left: task.x, top: task.y })
          )}
          {inputTask && (
            <input 
              autoFocus
              type="text"
              value={inputTask.value}
              onChange={(e) => setInputTask({ ...inputTask, value: e.target.value })}
              onBlur={() => {
                if (inputTask && inputTask.value.trim() !== "") {
                  const newTaskPayload = {
                    project_id: projectId,
                    name: inputTask.value.trim(),
                    description: "",
                    sprint_id: null,
                    effort: 0,
                    owner_id: null,
                    status: "not_started",
                    blocked_by: [],
                    comment: ""
                  };
                  // Envoi de la tâche au backend, puis annulation explicite de l'assignation automatique
                  apiService.post(`/projects/${projectId}/tasks`, newTaskPayload)
                    .then(createdTask => {
                      // Deuxième appel pour forcer sprint_id à null et contourner l'assignation automatique
                      return apiService.patch(`/projects/${projectId}/tasks/${createdTask.id}`, { sprint_id: null });
                    })
                    .then(createdTask => {
                      const transformedTask = {
                        ...createdTask,
                        x: inputTask.x,
                        y: inputTask.y,
                      };
                      setTasks(prev => [...prev, transformedTask]);
                    })
                    .catch(console.error);
                }
                setInputTask(null);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                   e.target.blur(); // déclenche le onBlur pour créer la tâche
                } else if (e.key === "Escape") {
                  e.preventDefault();
                  setInputTask(null);
                }
              }}
              style={{ position: "absolute", left: inputTask.x, top: inputTask.y }}
              className="border rounded p-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400"
              placeholder="Nom de la tâche"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default KickoffSprintView;