import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useAuth } from '../Auth';
import { TaskTable } from './TaskComponents';

const ChatBubble = ({ message }) => {
  const { user } = useAuth(); // Récupérer les informations de l'utilisateur
  const { content, role, agent, isError } = message;
  
  // Fonction pour traiter les blocs spéciaux dans le contenu
  const parseContent = () => {
    let processedContent = content;
    let specialComponents = [];
    
    try {
      // Rechercher les blocs tasks-table
      const tasksTableRegex = /```tasks-table\n([\s\S]*?)\n```/g;
      let tasksTableMatch;
      while ((tasksTableMatch = tasksTableRegex.exec(content)) !== null) {
        try {
          const tableData = JSON.parse(tasksTableMatch[1]);
          specialComponents.push({
            type: 'tasks-table',
            data: tableData.tasks,
            placeholder: `[TASKS_TABLE_${specialComponents.length}]`,
            original: tasksTableMatch[0]
          });
        } catch (e) {
          console.error("Erreur lors du parsing du tableau de tâches:", e);
        }
      }
      
      // Remplacer les blocs par des placeholders dans le contenu
      specialComponents.forEach(component => {
        processedContent = processedContent.replace(component.original, component.placeholder);
      });
    } catch (e) {
      console.error("Erreur lors du parsing du contenu:", e);
      return { processedContent: content, specialComponents: [] };
    }
    
    return { processedContent, specialComponents };
  };
  
  // Sélectionner les couleurs appropriées selon le rôle et l'agent
  const getBubbleClass = () => {
    if (role === 'user') {
      return 'bg-blue-100 text-blue-800 ml-auto';
    }
    if (role === 'system') {
      return 'bg-gray-200 text-gray-800 mx-auto';
    }
    if (isError) {
      return 'bg-red-100 text-red-800';
    }
    
    // Couleurs pour Nexus uniquement dans cette version simplifiée
    return 'bg-indigo-100 text-indigo-800';
  };

  // Icône pour différents agents ou rôles
  const getAgentIcon = () => {
    if (role === 'user') {
      // Utiliser la photo de profil de l'utilisateur connecté s'il en a une
      if (user && user.picture) {
        return (
          <img 
            src={user.picture} 
            alt={user.name || "Utilisateur"} 
            className="w-8 h-8 rounded-full object-cover border-2 border-blue-500"
          />
        );
      }
      // Fallback si pas de photo
      return (
        <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold">
          {user?.name?.charAt(0) || "U"}
        </div>
      );
    }
    
    if (role === 'system') {
      return (
        <div className="w-8 h-8 rounded-full bg-gray-500 flex items-center justify-center text-white">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
      );
    }
    
    switch (agent) {
      case 'task':
        return (
          <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center text-white font-semibold">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
            </svg>
          </div>
        );
      case 'nexus':
      default:
        return (
          <div className="w-8 h-8 rounded-full flex items-center justify-center bg-white">
            <img src="/Nexus_icon.png" alt="Nexus" className="w-7 h-7" />
          </div>
        );
    }
  };

  // Fonction pour rendre le contenu avec les composants spéciaux
  const renderContent = () => {
    const { processedContent, specialComponents } = parseContent();
    
    if (specialComponents.length === 0) {
      // Si pas de composants spéciaux, rendre simplement le markdown
      return (
        <ReactMarkdown className="prose prose-sm max-w-none break-words">
          {content}
        </ReactMarkdown>
      );
    }
    
    // Diviser le contenu par les placeholders
    const parts = processedContent.split(/\[TASKS_TABLE_\d+\]/);
    const placeholderMatches = processedContent.match(/\[TASKS_TABLE_\d+\]/g) || [];
    
    // Reconstruire le contenu avec les composants spéciaux
    const result = [];
    parts.forEach((part, index) => {
      if (part) {
        result.push(
          <ReactMarkdown key={`text-${index}`} className="prose prose-sm max-w-none break-words">
            {part}
          </ReactMarkdown>
        );
      }
      
      if (index < placeholderMatches.length) {
        const placeholder = placeholderMatches[index];
        const componentIndex = parseInt(placeholder.match(/\d+/)[0], 10);
        const component = specialComponents[componentIndex];
        
        if (component && component.type === 'tasks-table') {
          result.push(
            <TaskTable key={`table-${componentIndex}`} tasks={component.data} />
          );
        }
      }
    });
    
    return result;
  };

  return (
    <div className={`flex items-start gap-2 mb-4 ${role === 'user' ? 'flex-row-reverse' : 'flex-row'}`}>
      {getAgentIcon()}
      
      <div className={`py-2 px-3 rounded-lg shadow-sm max-w-[80%] ${getBubbleClass()}`}>
        {renderContent()}
      </div>
    </div>
  );
};

ChatBubble.propTypes = {
  message: PropTypes.shape({
    content: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['user', 'assistant', 'system']).isRequired,
    agent: PropTypes.string,
    isError: PropTypes.bool
  }).isRequired
};

export default ChatBubble;