import React from "react";
import { Trash2 } from "lucide-react";

const TaskCard = ({
  task,
  editing,
  handleEditingChange,
  handleEditingBlur,
  enableEditing,
  getOwnerInitials,
  viewMode,
  getProjectName,
  navigate,
  getSprintName,
  sprintOptions,
  allSprints,
  employees,
  getOwnerName,
  openCommentPopup,
  handleDeleteTask,
  handleDragStart
}) => {
  return (
    <div
      key={task.id}
      className="group bg-white p-2 rounded shadow mb-2 flex flex-col"
      draggable
      onDragStart={(e) => handleDragStart(e, task)}
    >
      {/* Première partie toujours visible */}
      <div className="flex justify-between items-center">
        {/* Nom de la tâche */}
        {editing.taskId === task.id && editing.field === "name" ? (
          <input
            type="text"
            value={editing.value}
            onChange={handleEditingChange}
            onBlur={handleEditingBlur}
            className="border rounded p-1 flex-1"
            autoFocus
          />
        ) : (
          <span
            onClick={() => enableEditing(task, "name")}
            className="font-medium cursor-pointer flex-1"
          >
            {task.name}
          </span>
        )}
        {/* Badge effort */}
        {editing.taskId === task.id && editing.field === "effort" ? (
          <input
            type="number"
            value={editing.value}
            onChange={handleEditingChange}
            onBlur={handleEditingBlur}
            className="border rounded p-1 w-16 ml-2"
            autoFocus
          />
        ) : (
          <div
            onClick={() => enableEditing(task, "effort")}
            className="text-xs bg-blue-500 text-white rounded-full px-2 py-1 cursor-pointer ml-2"
          >
            {task.effort}
          </div>
        )}
        {/* Badge avec les initiales de l'owner */}
        {viewMode !== "myTasks" && task.owner_id && (
          <div
            onClick={() => enableEditing(task, "owner_id")}
            className="flex items-center justify-center h-6 w-6 rounded-md ml-2 bg-gray-300 text-black text-xs cursor-pointer"
          >
            {getOwnerInitials(task.owner_id)}
          </div>
        )}
      </div>
      {/* Affichage du nom du projet pour toutes les tâches */}
      <div
        className="mt-1 text-sm font-semibold cursor-pointer text-blue-500"
        onClick={() => navigate && typeof navigate === 'function' ? navigate(`/project/${task.project_id}`) : null}
      >
        {getProjectName(task.project_id)}
      </div>
      {/* Ligne du Sprint (affiché sous le nom) */}
      <div className="mt-1">
        {editing.taskId === task.id && editing.field === "sprint_id" ? (
          <select
            value={editing.value}
            onChange={handleEditingChange}
            onBlur={handleEditingBlur}
            className="border rounded p-1 w-full"
            autoFocus
          >
            <option value="">-- Aucun --</option>
            {(viewMode === "project"
              ? sprintOptions
              : Object.values(allSprints).filter(
                  (sp) => String(sp.project_id) === String(task.project_id)
                )
            ).map((sp) => (
              <option key={sp.id} value={sp.id}>
                {sp.description ? sp.description : sp.id}
              </option>
            ))}
          </select>
        ) : (
          <p
            onClick={() => enableEditing(task, "sprint_id")}
            className="text-xs text-gray-500 cursor-pointer"
          >
            {task.sprint_id ? getSprintName(task.sprint_id) : "-- Aucun --"}
          </p>
        )}
      </div>
      {/* Zone complémentaire cachée par défaut */}
      <div className="overflow-hidden max-h-0 group-hover:max-h-40 transition-all duration-300">
        <div className="mt-1">
          {editing.taskId === task.id && editing.field === "description" ? (
            <textarea
              value={editing.value}
              onChange={handleEditingChange}
              onBlur={handleEditingBlur}
              className="border rounded p-1 mt-1 w-full"
              autoFocus
            />
          ) : (
            <p
              onClick={() => enableEditing(task, "description")}
              className="text-sm text-gray-600 cursor-pointer"
            >
              {task.description || "Cliquez pour ajouter une description"}
            </p>
          )}
        </div>
        <div className="mt-1">
          {editing.taskId === task.id && editing.field === "owner_id" ? (
            <select
              value={editing.value}
              onChange={handleEditingChange}
              onBlur={handleEditingBlur}
              className="border rounded p-1 mt-1 w-full"
              autoFocus
            >
              <option value="">-- Aucun --</option>
              {employees.map(emp => (
                <option key={emp.id} value={emp.id}>
                  {emp.name.split(" ")[0]}
                </option>
              ))}
            </select>
          ) : (
            <p
              onClick={() => enableEditing(task, "owner_id")}
              className="text-xs text-gray-500 cursor-pointer mt-1"
            >
              Owner: {getOwnerName(task.owner_id)}
            </p>
          )}
        </div>
        <div className="mt-1">
          <span
            onClick={() => openCommentPopup(task)}
            className="text-xs text-blue-500 cursor-pointer"
            title="Voir/éditer le commentaire"
          >
            {task.comment ? "Modifier Commentaire" : "Ajouter Commentaire"}
          </span>
        </div>
      </div>
      {/* Bouton de suppression, visible uniquement au hover */}
      <div className="overflow-hidden max-h-0 group-hover:max-h-10 transition-all duration-300 flex item">
        <button
          onClick={() => handleDeleteTask(task.id)}
          className="text-red-500 hover:text-red-700"
          title="Supprimer la tâche"
        >
          <Trash2 className="h-4 w-4" />
        </button>
      </div>
    </div>
  );
};

export default TaskCard;