import React, { useMemo } from 'react';
import { Check, X } from 'lucide-react';
import { apiService } from '../../services/api';

const InvoiceEditForm = ({ 
  editingInvoice, 
  setEditingInvoice, 
  projectIterations, 
  computeMontant,
  fetchInvoices,
  setMessage,
  setError
}) => {
  const handleSaveEdit = async () => {
    // Vérifier que la date personnalisée est définie si nécessaire
    if (editingInvoice.billing_date_type === "custom" && !editingInvoice.custom_billing_date) {
      setError("Veuillez définir une date de facturation personnalisée");
      return;
    }
    
    const payload = {
      project_id: editingInvoice.project_id,
      affacturation: editingInvoice.affacturation,
      commission: editingInvoice.commission || 3.0,
      allocations: editingInvoice.allocations,
      status: editingInvoice.status,
      lot_name: editingInvoice.lot_name,
      billing_date_type: editingInvoice.billing_date_type || 'iteration_end',
      custom_billing_date: editingInvoice.billing_date_type === 'custom' ? editingInvoice.custom_billing_date : null
    };
    try {
      await apiService.patch(`/factures/${editingInvoice.id}`, payload);
      setMessage("Lot de facturation mis à jour avec succès");
      setEditingInvoice(null);
      fetchInvoices();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleAllocationToggle = (iterationId, isChecked) => {
    setEditingInvoice(prev => {
      let allocations = prev.allocations || [];
      if (isChecked) {
        // Ajouter l'allocation si elle n'existe pas déjà
        if (!allocations.find(a => a.iteration_id === iterationId)) {
          allocations.push({ iteration_id: iterationId, percentage: 100 });
        }
      } else {
        // Supprimer l'allocation
        allocations = allocations.filter(a => a.iteration_id !== iterationId);
      }
      return { ...prev, allocations };
    });
  };
  
  const handleAllocationPercentageChange = (iterationId, newPercentage) => {
    const iteration = (projectIterations[editingInvoice.project_id] || []).find(i => i.id === iterationId);
    let newCA = 0;
    if (iteration) {
      newCA = computeMontant(
        iteration, 
        newPercentage, 
        editingInvoice.affacturation, 
        editingInvoice.commission
      );
    }
    setEditingInvoice(prev => {
      const allocations = prev.allocations.map(a =>
        a.iteration_id === iterationId ? { ...a, percentage: newPercentage, ca: newCA } : a
      );
      return { ...prev, allocations };
    });
  };

  const handleAllocationCAChange = (iterationId, newCA) => {
    const iteration = (projectIterations[editingInvoice.project_id] || []).find(i => i.id === iterationId);
    let newPercentage = 100;
    if (iteration && (iteration.valeur) !== 0) {
      // Si affacturation activée, le CA est après déduction de la commission
      if (editingInvoice.affacturation && editingInvoice.commission) {
        const commissionFactor = 1 - (editingInvoice.commission / 100);
        // Garder le pourcentage exact sans arrondir
        newPercentage = (newCA * 100) / (iteration.valeur * commissionFactor);
      } else {
        // Garder le pourcentage exact sans arrondir
        newPercentage = (newCA * 100) / iteration.valeur;
      }
    }
    setEditingInvoice(prev => {
      const allocations = prev.allocations.map(a =>
        a.iteration_id === iterationId ? { ...a, ca: newCA, percentage: newPercentage } : a
      );
      return { ...prev, allocations };
    });
  };

  // Calculer le total du lot
  const totalLot = useMemo(() => {
    if (!editingInvoice || !editingInvoice.allocations) return 0;
    
    return editingInvoice.allocations.reduce((total, alloc) => {
      const iteration = (projectIterations[editingInvoice.project_id] || []).find(it => it.id === alloc.iteration_id);
      if (iteration) {
        const montant = computeMontant(
          iteration, 
          alloc.percentage, 
          editingInvoice.affacturation, 
          editingInvoice.commission
        );
        return total + montant;
      }
      return total;
    }, 0);
  }, [editingInvoice, projectIterations, computeMontant]);

  // Déterminer les dates de début et de fin du projet
  const projectDates = useMemo(() => {
    if (!editingInvoice || !projectIterations[editingInvoice.project_id]) {
      return { start: null, end: null };
    }
    
    const iterations = projectIterations[editingInvoice.project_id] || [];
    let earliestStart = null;
    let latestEnd = null;
    
    iterations.forEach(iter => {
      if (iter.start_date && (!earliestStart || iter.start_date < earliestStart)) {
        earliestStart = iter.start_date;
      }
      if (iter.end_date && (!latestEnd || iter.end_date > latestEnd)) {
        latestEnd = iter.end_date;
      }
    });
    
    return { start: earliestStart, end: latestEnd };
  }, [editingInvoice, projectIterations]);

  return (
    <div className="bg-white shadow rounded-lg p-4 mb-4">
      <div className="flex justify-between items-center">
        <h4 className="font-bold">Modification Lot #{editingInvoice.id}</h4>
        <div className="flex space-x-2">
          <button 
            onClick={() => setEditingInvoice(null)}
            title="Annuler"
            className="p-1 text-gray-600 hover:text-gray-800"
          >
            <X className="h-5 w-5" />
          </button>
          <button 
            onClick={handleSaveEdit}
            title="Enregistrer"
            className="p-1 text-green-500 hover:text-green-700"
          >
            <Check className="h-5 w-5" />
          </button>
        </div>
      </div>
      <div className="mt-2 space-y-3">
        {/* Edition du toggle pour Affacturation */}
        <div className="space-y-3">
          <div className="flex items-center">
            <label className="font-medium mr-2">Affacturation :</label>
            <input
              type="checkbox"
              checked={editingInvoice.affacturation}
              onChange={(e) =>
                setEditingInvoice({
                  ...editingInvoice,
                  affacturation: e.target.checked
                })
              }
              className="h-5 w-5"
            />
            <span className="ml-2">{editingInvoice.affacturation ? "Oui" : "Non"}</span>
          </div>
          
          {editingInvoice.affacturation && (
            <div className="pl-5 flex items-center gap-3">
              <label className="font-medium">Commission (%) :</label>
              <input
                type="number"
                min="0"
                max="100"
                step="0.1"
                value={editingInvoice.commission || 3.0}
                onChange={(e) =>
                  setEditingInvoice({
                    ...editingInvoice,
                    commission: parseFloat(e.target.value)
                  })
                }
                className="border rounded p-1 w-20"
              />
            </div>
          )}
        </div>
        
        {/* Options de date de facturation */}
        <div className="space-y-2 border-t pt-3">
          <label className="font-medium">Date de facturation :</label>
          
          <div className="flex items-center">
            <input
              type="radio"
              id="edit_iteration_end"
              name="edit_billing_date_type"
              value="iteration_end"
              checked={editingInvoice.billing_date_type === "iteration_end" || !editingInvoice.billing_date_type}
              onChange={() => setEditingInvoice({...editingInvoice, billing_date_type: "iteration_end"})}
              className="mr-2"
            />
            <label htmlFor="edit_iteration_end">
              Fin d'itération ({projectDates.end ? new Date(projectDates.end).toLocaleDateString() : 'N/A'})
            </label>
          </div>
          
          <div className="flex items-center">
            <input
              type="radio"
              id="edit_project_start"
              name="edit_billing_date_type"
              value="project_start"
              checked={editingInvoice.billing_date_type === "project_start"}
              onChange={() => setEditingInvoice({...editingInvoice, billing_date_type: "project_start"})}
              className="mr-2"
            />
            <label htmlFor="edit_project_start">
              Début de projet ({projectDates.start ? new Date(projectDates.start).toLocaleDateString() : 'N/A'})
            </label>
          </div>
          
          <div className="flex items-center">
            <input
              type="radio"
              id="edit_custom"
              name="edit_billing_date_type"
              value="custom"
              checked={editingInvoice.billing_date_type === "custom"}
              onChange={() => setEditingInvoice({...editingInvoice, billing_date_type: "custom"})}
              className="mr-2"
            />
            <label htmlFor="edit_custom" className="mr-2">Personnalisée:</label>
            <input
              type="date"
              value={editingInvoice.custom_billing_date || ""}
              onChange={(e) => setEditingInvoice({...editingInvoice, custom_billing_date: e.target.value})}
              disabled={editingInvoice.billing_date_type !== "custom"}
              className="border rounded p-1"
            />
          </div>
        </div>
        
        {/* Edition du nom du lot*/}
        <div>
          <label className="block font-medium">Nom du lot</label>
          <input
            type="text"
            value={editingInvoice.lot_name || ""}
            onChange={(e) =>
              setEditingInvoice({ ...editingInvoice, lot_name: e.target.value })
            }
            className="border rounded p-1 w-full"
          />
        </div>
        {/* Edition des allocations avec % et CA réactifs */}
        <div>
          <label className="font-medium">Allocations :</label>
          {(projectIterations[editingInvoice.project_id] || []).map(iter => {
            const allocation = editingInvoice.allocations.find(a => a.iteration_id === iter.id);
            // Montant brut sans commission
            const montantBrut = allocation ? computeMontant(iter, allocation.percentage, false) : computeMontant(iter, 100, false);
            // Montant net avec commission si applicable
            const montantNet = allocation ? computeMontant(iter, allocation.percentage, editingInvoice.affacturation, editingInvoice.commission) : computeMontant(iter, 100, editingInvoice.affacturation, editingInvoice.commission);
            
            return (
              <div key={iter.id} className="flex items-center space-x-2 mt-1">
                <input
                  type="checkbox"
                  checked={!!allocation}
                  onChange={(e) => handleAllocationToggle(iter.id, e.target.checked)}
                  className="form-checkbox"
                />
                <div className="flex-1">
                  <span className="font-semibold">{iter.name}</span>
                  <div className="text-xs text-gray-500">
                    {iter.start_date && `Début: ${new Date(iter.start_date).toLocaleDateString()}`}
                    {iter.start_date && iter.end_date && ' - '}
                    {iter.end_date && `Fin: ${new Date(iter.end_date).toLocaleDateString()}`}
                  </div>
                  {allocation && editingInvoice.affacturation && (
                    <div className="text-sm">
                      <span className="text-gray-600">CA: {montantBrut.toFixed(2)} € brut</span>
                      <span className="text-green-600 ml-2">→ {montantNet.toFixed(2)} € net</span>
                    </div>
                  )}
                </div>
                <div>
                  <label className="block text-sm">%</label>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    value={allocation ? Number(allocation.percentage.toFixed(2)) : 100}
                    onChange={(e) => handleAllocationPercentageChange(iter.id, parseFloat(e.target.value))}
                    className="border rounded p-1 w-16"
                    disabled={!allocation}
                  />
                </div>
                <div>
                  <label className="block text-sm">CA {editingInvoice.affacturation ? 'net' : ''}</label>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={
                      allocation && allocation.ca !== undefined
                        ? allocation.ca.toFixed(2)
                        : montantNet.toFixed(2)
                    }
                    onChange={(e) => handleAllocationCAChange(iter.id, parseFloat(e.target.value))}
                    className="border rounded p-1 w-20"
                    disabled={!allocation}
                  />
                </div>
              </div>
            );
          })}
        </div>
        {/* Total du lot */}
        <div className="mt-3 font-bold text-lg border-t pt-3">
          Total Lot : {totalLot.toFixed(2)} €
          {editingInvoice.affacturation && ` (après commission de ${editingInvoice.commission || 3}%)`}
        </div>
      </div>
    </div>
  );
};

export default InvoiceEditForm;