import React from "react";
import TaskCard from "./TaskCard";

const KanbanColumn = ({ 
  status, 
  title,
  tasks, 
  handleDragOver, 
  handleDrop,
  editing,
  handleEditingChange,
  handleEditingBlur,
  enableEditing,
  getOwnerInitials,
  viewMode,
  getProjectName,
  navigate,
  getSprintName,
  sprintOptions,
  allSprints,
  employees,
  getOwnerName,
  openCommentPopup,
  handleDeleteTask,
  handleDragStart
}) => {
  return (
    <div
      className="flex-1 bg-gray-50 rounded p-4 min-w-[250px]"
      onDragOver={handleDragOver}
      onDrop={(e) => handleDrop(e, status)}
    >
      <h2 className="text-lg font-semibold mb-2">
        {title}
      </h2>
      
      {tasks.map((task) => (
        <TaskCard
          key={task.id}
          task={task}
          editing={editing}
          handleEditingChange={handleEditingChange}
          handleEditingBlur={handleEditingBlur}
          enableEditing={enableEditing}
          getOwnerInitials={getOwnerInitials}
          viewMode={viewMode}
          getProjectName={getProjectName}
          navigate={navigate}
          getSprintName={getSprintName}
          sprintOptions={sprintOptions}
          allSprints={allSprints}
          employees={employees}
          getOwnerName={getOwnerName}
          openCommentPopup={openCommentPopup}
          handleDeleteTask={handleDeleteTask}
          handleDragStart={handleDragStart}
        />
      ))}
    </div>
  );
};

export default KanbanColumn;