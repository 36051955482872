import React, { useState } from 'react';
import InvoiceCard from './InvoiceCard';
import InvoiceEditForm from './InvoiceEditForm';
import { apiService } from '../../services/api';

const KanbanColumn = ({ 
  title, 
  status, 
  invoices, 
  projects, 
  getIterationInfo, 
  computeTotalInvoice,
  onDragStart, 
  onDrop,
  fetchInvoices,
  setMessage,
  setError
}) => {
  const [editingInvoice, setEditingInvoice] = useState(null);

  // Handle invoice deletion
  const handleDeleteInvoice = async (invoiceId) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce lot de facturation? Cette action est irréversible.")) {
      try {
        await apiService.delete(`/factures/${invoiceId}`);
        setMessage("Lot de facturation supprimé avec succès");
        fetchInvoices();
      } catch (err) {
        setError(err.message || "Erreur lors de la suppression du lot");
      }
    }
  };

  // Adaptateur pour le calcul du montant - utilise getIterationInfo
  const computeMontant = (iteration, percentage, withAffacturation = false, commissionRate = 3.0) => {
    if (!iteration) return 0;
    let montant = (iteration.valeur * percentage) / 100;
    
    // Appliquer la commission si affacturation est activée
    if (withAffacturation) {
      montant = montant * (1 - (commissionRate / 100));
    }
    
    return Number(montant.toFixed(2));
  };

  return (
    <div
      className="flex-1 bg-gray-50 rounded p-4"
      onDragOver={(e) => e.preventDefault()}
      onDrop={onDrop}
    >
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      {invoices.length === 0 ? (
        <p className="text-gray-600">Aucune facture</p>
      ) : (
        invoices.map(invoice => {
          // Adapter la structure d'itération pour InvoiceCard
          const projectId = invoice.project_id;
          const projectIterations = {};
          if (projectId) {
            const iterations = [];
            invoice.allocations.forEach(alloc => {
              if (alloc.iteration_id) {
                const iter = getIterationInfo(projectId, alloc.iteration_id);
                if (iter && iter.id) {
                  iterations.push(iter);
                }
              }
            });
            projectIterations[projectId] = iterations;
          }

          return (
            // Si le lot est en mode édition
            editingInvoice && editingInvoice.id === invoice.id ? (
              <InvoiceEditForm 
                key={invoice.id}
                editingInvoice={editingInvoice} 
                setEditingInvoice={setEditingInvoice}
                projectIterations={projectIterations}
                computeMontant={computeMontant}
                fetchInvoices={fetchInvoices}
                setMessage={setMessage}
                setError={setError}
              />
            ) : (
              // Affichage en lecture seule
              <InvoiceCard 
                key={invoice.id}
                invoice={invoice}
                projects={projects}
                projectIterations={projectIterations}
                computeMontant={computeMontant}
                computeTotalInvoice={computeTotalInvoice}
                setEditingInvoice={setEditingInvoice}
                handleDeleteInvoice={handleDeleteInvoice}
                setDraggedInvoice={onDragStart}
              />
            )
          );
        })
      )}
    </div>
  );
};

export default KanbanColumn;