import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  CalendarPlus,
  ArrowLeft,
  Plus,
  Calendar,
  Trash2,
  Edit2,
  Clock,
  FileText,
  Layout
} from 'lucide-react';
import InteractiveTimelineView from './InteractiveTimelineView';
import SprintCard from './SprintCard';
import { calculateWeeksDuration } from '../utils/dateUtils';
import { useAuth } from './Auth';
import ClientSelector from './ClientSelector';
import { SlotsTimeline } from './Slot';

const statusTranslations = {
  proposition: "Proposition",
  in_progress: "En Cours",
  completed: "Terminé"
};

const apiBaseUrl = process.env.NODE_ENV === 'development'
  ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
  : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const ProjectDetail = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState(null);
  const [iterations, setIterations] = useState([]);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editingSprint, setEditingSprint] = useState(null);
  const [sprintHours, setSprintHours] = useState({});
  const [iterationHours, setIterationHours] = useState({});
  const [slots, setSlots] = useState([]);
  const [employees, setEmployees] = useState([]);
  
  // Nouveaux états pour la saisie des champs monétaires de l'itération
  const [newIterationValeur, setNewIterationValeur] = useState("");
  const [newIterationDontAchat, setNewIterationDontAchat] = useState("");

  const { getAuthHeaders } = useAuth();


  const [editingIteration, setEditingIteration] = useState({
    iterationId: null,
    field: null,
    value: ""
  });

  const [editingVision, setEditingVision] = useState(false);
  const [tempVision, setTempVision] = useState("");

  useEffect(() => {
    if (project) {
      setTempVision(project.vision || "");
    }
  }, [project]);

  /* Fonction pour activer l’édition d’un champ d’itération */
  const enableIterationEditing = (iteration, field) => {
    setEditingIteration({
      iterationId: iteration.id,
      field,
      value: iteration[field]?.toString() || ""
    });
  };

  const handleVisionUpdate = async () => {
    // Si la vision n'a pas changé, on sort
    if (project.vision === tempVision) {
      setEditingVision(false);
      return;
    }
    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify({ vision: tempVision })
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour de la vision');
      }
      const updatedProject = await response.json();
      setProject(updatedProject);
      setEditingVision(false);
      setSuccess('Vision mise à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Erreur lors de la mise à jour de la vision');
      setTimeout(() => setError(null), 3000);
    }
  };
  
  /* Gère la modification de la valeur pendant l’édition */
  const handleIterationEditingChange = (e) => {
    setEditingIteration({ ...editingIteration, value: e.target.value });
  };
  
  /* Fonction pour envoyer la mise à jour à l’API lorsque l’input perd le focus */
  const handleIterationInlineUpdate = async () => {
    const { iterationId, field, value } = editingIteration;
    const iteration = iterations.find(it => it.id === iterationId);
    if (!iteration) return;
    // Pour utiliser le même endpoint PATCH qui met à jour les dates,
    // on doit renvoyer start_date et end_date en plus.
    const updateData = {
      start_date: iteration.start_date,
      end_date: iteration.end_date
    };
    // Ajoute le champ modifié dans la payload
    updateData[field] = (field === "valeur" || field === "dont_achat")
      ? parseFloat(value)
      : value;
    try {
      const response = await fetch(
        `${apiBaseUrl}/projects/${projectId}/iterations/${iterationId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders()
          },
          body: JSON.stringify(updateData)
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Échec de la mise à jour de l'itération");
      }
      const updatedIteration = await response.json();
      setIterations(prev =>
        prev.map(it =>
          it.id === iterationId ? updatedIteration : it
        )
      );
      setEditingIteration({ iterationId: null, field: null, value: "" });
    } catch (err) {
      setError(err.message || "Erreur de mise à jour");
      setTimeout(() => setError(null), 3000);
    }
  };

  // Fonction pour gérer le changement de client
  const handleClientChange = (updatedProject) => {
    setProject(updatedProject);
    setSuccess('Client mis à jour avec succès');
    setTimeout(() => setSuccess(null), 3000);
  };

  useEffect(() => {
    if (iterations.length > 0) {
      iterations.forEach(iteration => {
        if (iteration.sprints) {
          iteration.sprints.forEach(sprint => {
            fetchHoursForSprint(sprint);
          });
        }
      });
    }
  }, [iterations]);  

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/projects/${projectId}`, { // Utilisation de apiBaseUrl
          headers: getAuthHeaders() // Ajout des en-têtes d'authentification
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Échec du chargement des détails du projet');
        }
        const data = await response.json();
        setProject(data);
      } catch (err) {
        setError(err.message || 'Échec du chargement des détails du projet');
        setProject(null);
      }
    };  

    const fetchClients = async () => { // Fonction pour récupérer les clients
      try {
        const response = await fetch(`${apiBaseUrl}/clients`, { // Utilisation de apiBaseUrl
          headers: getAuthHeaders()
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Échec du chargement des clients');
        }
        const data = await response.json();
        setClients(data);
      } catch (err) {
        setError(err.message || 'Échec du chargement des clients');
        setClients([]);
      }
    };
    
    const fetchSlots = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/slots`, {
          headers: getAuthHeaders()
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Échec du chargement des slots');
        }
        const data = await response.json();
        const sortedSlots = Array.isArray(data) 
          ? [...data].sort((a, b) => new Date(a.start_date) - new Date(b.start_date)) 
          : [];
        setSlots(sortedSlots);
      } catch (err) {
        setError(err.message || 'Échec du chargement des slots');
        setSlots([]);
      }
    };
    
    const fetchEmployees = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/employees`, {
          headers: getAuthHeaders()
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Échec du chargement des employés');
        }
        const data = await response.json();
        setEmployees(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error(err.message || 'Échec du chargement des employés');
        setEmployees([]);
      }
    };

    fetchProjectDetails();
    fetchIterations();
    fetchClients();
    fetchSlots();
    fetchEmployees();
  }, [projectId, getAuthHeaders]);

   // Remplacez l'actuelle définition de fetchIterations (la deuxième, qui est utilisée)
    const fetchIterations = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/projects/${projectId}/iterations`, {
          headers: getAuthHeaders()
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Échec du chargement des itérations');
        }
        const data = await response.json();

        // Initialiser un compteur global de sprint pour l'ensemble du projet
        let globalSprintCounter = 0;

        // Pour chaque itération, recharger aussi la liste des sprints
        const renamedIterations = await Promise.all(data.map(async (iteration, iterIndex) => {
          const sprintsResponse = await fetch(`${apiBaseUrl}/projects/${projectId}/sprints`, {
            headers: getAuthHeaders()
          });
          if (!sprintsResponse.ok) {
            const errorData = await sprintsResponse.json();
            throw new Error(errorData.detail || 'Échec du chargement des sprints');
          }
          const allSprints = await sprintsResponse.json();
          // Filtrer puis trier les sprints de cette itération par date de début
          const iterationSprints = allSprints
            .filter(sprint => sprint.iteration_id === iteration.id)
            .sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
            .map(sprint => {
              globalSprintCounter++;
              return {
                ...sprint,
                name: sprint.description ? sprint.description : `Sprint ${globalSprintCounter}`
              };
            });
          return {
            ...iteration,
            name: `Itération ${iterIndex + 1}`,
            sprints: iterationSprints
          };
        }));

        setIterations(renamedIterations);
      } catch (err) {
        setError(err.message || 'Échec du chargement des itérations');
        setIterations([]);
      }
    };

    
    // Modifiez la fonction handleTokensUpdate pour rafraîchir ensuite les itérations :
    const handleTokensUpdate = async (sprintId, iterationId, tokenData) => {
      try {
        const response = await fetch(`${apiBaseUrl}/projects/${projectId}/sprints/${sprintId}/tokens`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders()
          },
          body: JSON.stringify(tokenData),
        });
    
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || 'Échec de la mise à jour des tokens du sprint');
        }
    
        const updatedSprint = await response.json();
        setIterations(prev => prev.map(iteration =>
          iteration.id === iterationId
            ? {
                ...iteration,
                sprints: iteration.sprints.map(s =>
                  s.id === sprintId ? { ...updatedSprint, name: s.name } : s
                )
              }
            : iteration
        ));
    
        // Rafraîchir les itérations pour être sûr d'avoir les tokens recalculés
        await fetchIterations();
        setSuccess('Tokens du sprint mis à jour avec succès');
        setTimeout(() => setSuccess(null), 3000);
      } catch (err) {
        setError(err.message || 'Échec de la mise à jour des tokens du sprint');
        setTimeout(() => setError(null), 3000);
      }
    };

  const getDefaultIterationDates = () => {
    const lastIteration = iterations[iterations.length - 1];
    let startDate;
    if (lastIteration) {
      startDate = new Date(lastIteration.end_date);
      startDate.setDate(startDate.getDate() + 1);
    } else {
      startDate = new Date();
    }
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    return {
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0]
    };
  };

  const handleQuickIterationAdd = async () => {
    // Trouver des dates libres pour une nouvelle itération
    // Vérifier les itérations existantes et trouver un créneau libre
    const sortedIterations = [...iterations].sort(
      (a, b) => new Date(a.end_date) - new Date(b.end_date)
    );
    
    let iterStartDate, iterEndDate;
    
    if (sortedIterations.length === 0) {
      // Aucune itération existante, commencer aujourd'hui pour une durée standard
      iterStartDate = new Date();
      iterEndDate = new Date(iterStartDate);
      iterEndDate.setDate(iterEndDate.getDate() + 13); // Durée de 2 semaines
    } else {
      // Commencer après la fin de la dernière itération (triée par date de fin)
      const lastIteration = sortedIterations[sortedIterations.length - 1];
      iterStartDate = new Date(lastIteration.end_date);
      iterStartDate.setDate(iterStartDate.getDate() + 1); // Un jour après la dernière itération
      
      // Durée standard de 2 semaines
      iterEndDate = new Date(iterStartDate);
      iterEndDate.setDate(iterEndDate.getDate() + 13);
      
      // Vérifier qu'il n'y a pas de chevauchement avec une autre itération qui pourrait commencer plus tard
      for (const iteration of sortedIterations) {
        const start = new Date(iteration.start_date);
        const end = new Date(iteration.end_date);
        
        // Si nos nouvelles dates chevauchent une itération existante, ajuster
        if ((iterStartDate <= end && iterStartDate >= start) || 
            (iterEndDate <= end && iterEndDate >= start) ||
            (iterStartDate <= start && iterEndDate >= end)) {
          console.log("Potentiel chevauchement détecté, ajustement des dates");
          // On déplace notre itération après celle-ci
          iterStartDate = new Date(end);
          iterStartDate.setDate(iterStartDate.getDate() + 1);
          iterEndDate = new Date(iterStartDate);
          iterEndDate.setDate(iterEndDate.getDate() + 13);
        }
      }
    }
    
    // Formater les dates au format YYYY-MM-DD
    const startDateStr = iterStartDate.toISOString().split('T')[0];
    const endDateStr = iterEndDate.toISOString().split('T')[0];
    
    const nextIterationNumber = iterations.length + 1;
    const iterationName = `Itération ${nextIterationNumber}`;
    
    // Créer le payload selon les exigences du modèle IterationCreate
    const iterationPayload = {
      name: iterationName,           // Champ obligatoire
      start_date: startDateStr,      // Champ obligatoire
      end_date: endDateStr,          // Champ obligatoire
      description: "",               // Champ optionnel
      valeur: 0,                     // Champ optionnel avec valeur par défaut
      dont_achat: 0                  // Champ optionnel avec valeur par défaut
    };

    console.log("Création d'itération avec payload:", JSON.stringify(iterationPayload, null, 2));

    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}/iterations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify(iterationPayload),
      });

      // Capturer et afficher la réponse brute pour le débogage
      const responseText = await response.text();
      console.log("Réponse brute du serveur:", responseText);
      
      let jsonResponse;
      try {
        jsonResponse = JSON.parse(responseText);
      } catch (e) {
        console.error("Erreur d'analyse de la réponse JSON:", e);
        throw new Error("Réponse serveur invalide");
      }
      
      if (!response.ok) {
        console.error("Erreur API:", jsonResponse);
        
        // Gérer spécifiquement l'erreur de chevauchement de dates
        if (jsonResponse.detail && jsonResponse.detail.includes("chevauchent")) {
          throw new Error("Les dates de l'itération chevauchent une itération existante. " + 
                          "Veuillez choisir une autre période.");
        }
        
        throw new Error(jsonResponse.detail || "Échec de création de l'itération");
      }

      console.log("Itération créée avec succès:", jsonResponse);

      // Ajouter l'itération au tableau local
      setIterations(prev => [...prev, { ...jsonResponse, sprints: [] }]);
      
      setSuccess("Itération ajoutée avec succès");
      setTimeout(() => setSuccess(null), 3000);
      
      // Rafraîchir les données
      fetchIterations();
    } catch (err) {
      console.error("Erreur complète:", err);
      setError(`${err.message}`);
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleDeleteIteration = async (iterationId) => {
    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}/iterations/${iterationId}`, { // Utilisation de apiBaseUrl
        method: 'DELETE',
        headers: getAuthHeaders() // Ajout des en-têtes d'authentification
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression de l\'itération');
      }

      // Supprimer l'itération et mettre à jour les noms
      const updatedIterations = iterations
        .filter(it => it.id !== iterationId)
        .map((iteration, index) => ({
          ...iteration,
          name: `Itération ${index + 1}`
        }));

      setIterations(updatedIterations);
      setSuccess('Itération supprimée avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression de l\'itération');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleDeleteSprint = async (sprintId, iterationId) => {
    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}/sprints/${sprintId}`, { // Utilisation de apiBaseUrl
        method: 'DELETE',
        headers: getAuthHeaders() // Ajout des en-têtes d'authentification
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression du sprint');
      }

      // Mettre à jour l'état local
      setIterations(prev => prev.map(iteration => {
        if (iteration.id === iterationId) {
          // Filtrer les sprints et renommer les sprints restants
          const updatedSprints = iteration.sprints
            .filter(sprint => sprint.id !== sprintId)
            .map((sprint, index) => ({
              ...sprint,
              name: `Sprint ${index + 1}`
            }));

          return {
            ...iteration,
            sprints: updatedSprints
          };
        }
        return iteration;
      }));

      setSuccess('Sprint supprimé avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression du sprint');
      setTimeout(() => setError(null), 3000);
    }
  };

  const getDefaultSprintDates = (iteration, currentSprints) => {
    let startDate;

    if (currentSprints.length === 0) {
      startDate = new Date(iteration.start_date);
    } else {
      const lastSprint = currentSprints[currentSprints.length - 1];
      startDate = new Date(lastSprint.end_date);
      startDate.setDate(startDate.getDate() + 1);
    }

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    return {
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0]
    };
  };

  const handleQuickSprintAdd = async (iteration) => {
    // Accepter directement un objet iteration ou un ID
    const iterationId = typeof iteration === 'object' ? iteration.id : iteration;
    const iterationObj = typeof iteration === 'object' ? 
      iteration : 
      iterations.find(it => it.id === iterationId);
    
    if (!iterationObj) {
      console.error("Iteration non trouvée", { iteration, iterationId });
      // Erreur supprimée
      return;
    }

    // Vérifier que les dates d'itération sont définies
    if (!iterationObj.start_date || !iterationObj.end_date) {
      console.warn("Les dates de l'itération sont manquantes");
      return;
    }

    // Trouver une plage de temps pour un nouveau sprint
    const existingSprints = iterationObj.sprints || [];
    
    // Trier les sprints par date de début
    const sortedSprints = [...existingSprints].sort(
      (a, b) => new Date(a.start_date) - new Date(b.start_date)
    );
    
    // Récupérer les dates de l'itération
    const iterStartDate = new Date(iterationObj.start_date);
    
    // Déterminer la date de début du sprint
    let sprintStartDate;
    
    if (sortedSprints.length === 0) {
      // Aucun sprint existant, commencer au début de l'itération
      sprintStartDate = new Date(iterStartDate);
    } else {
      // Trouver la dernière date de fin existante
      const lastSprint = sortedSprints[sortedSprints.length - 1];
      const lastEndDate = new Date(lastSprint.end_date);
      
      // Commencer un jour après la fin du dernier sprint
      sprintStartDate = new Date(lastEndDate);
      sprintStartDate.setDate(sprintStartDate.getDate() + 1);
      
      // Pas besoin de vérifier si la date dépasse l'itération
    }
    
    // Formater la date au format YYYY-MM-DD
    const startDateStr = sprintStartDate.toISOString().split('T')[0];
    const nextSprintNumber = (iterationObj.sprints || []).length + 1;

    try {
      // Créer le payload avec uniquement les champs requis
      const sprintPayload = {
        iteration_id: parseInt(iterationId), // S'assurer que c'est bien un nombre
        start_date: startDateStr,
        description: `Sprint ${nextSprintNumber}`
        // Ne pas inclure end_date, le backend le calculera
      };
      
      console.log("Payload envoyé au serveur:", JSON.stringify(sprintPayload, null, 2));
      
      let jsonResponse;
      try {
        const response = await fetch(`${apiBaseUrl}/projects/${projectId}/sprints`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders()
          },
          body: JSON.stringify(sprintPayload),
        });

        // Capturer la réponse complète
        let responseText;
        try {
          responseText = await response.text();
          console.log("Réponse brute du serveur:", responseText);
        } catch (e) {
          console.error("Erreur de lecture de la réponse:", e);
          return; // Sortir silencieusement
        }
        
        // Analyser la réponse
        try {
          jsonResponse = JSON.parse(responseText);
        } catch (e) {
          console.error("Erreur d'analyse de la réponse JSON:", e);
          return; // Sortir silencieusement
        }
        
        if (!response.ok) {
          console.error("Erreur API:", jsonResponse);
          // Ne pas afficher l'erreur à l'utilisateur
          console.warn("Création du sprint échouée:", jsonResponse.detail || "Raison inconnue");
          return; // Sortir sans montrer d'erreur
        }
      } catch (networkError) {
        console.error("Erreur réseau:", networkError);
        return; // Sortir silencieusement
      }

      console.log("Sprint créé avec succès:", jsonResponse);

      // Mettre à jour l'état local
      setIterations(prev => prev.map(it =>
        it.id === iterationId
          ? {
              ...it,
              sprints: [...(it.sprints || []), { ...jsonResponse, name: jsonResponse.description || `Sprint ${nextSprintNumber}` }]
            }
          : it
      ));
      
      setSuccess('Sprint ajouté avec succès');
      setTimeout(() => setSuccess(null), 3000);
      
      // Rafraîchir les données
      fetchIterations();
    } catch (err) {
      console.error("Erreur complète:", err);
      setError(`Échec de l'ajout du sprint: ${err.message}`);
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleSprintUpdate = async (sprint, iterationId) => {
    // Debug log pour voir la structure exacte
    console.log('Sprint update payload:', { sprint, iterationId });

    // Si le sprint est undefined, sortir immédiatement
    if (!sprint) {
      console.error("Sprint is undefined");
      setError("Échec de la mise à jour du sprint : sprint indéfini");
      return;
    }

    // Pour le drag & drop, sprint peut être l'ID directement
    const sprintId = typeof sprint === 'number' 
      ? sprint 
      : sprint.changes 
        ? sprint.id  // Pour les changements de date via drag
        : sprint.id; // Pour les changements via datepicker

    if (!sprintId) {
      console.error("Sprint ID is undefined", { sprint, iterationId });
      setError("Échec de la mise à jour du sprint en raison d'un ID manquant.");
      return;
    }

    try {
      // Déterminer les données à envoyer selon le type de mise à jour
      let updateData;
      if (sprint.changes) {
        // Cas du drag & drop
        updateData = {
          start_date: sprint.changes.start_date,
          end_date: sprint.changes.end_date
        };
      } else {
        // Cas du datepicker
        updateData = {
          start_date: sprint.start_date,
          end_date: sprint.end_date,
          description: sprint.description || ""
        };
      }

      console.log('Update data being sent:', updateData);

      const response = await fetch(`${apiBaseUrl}/projects/${projectId}/sprints/${sprintId}`, { // Utilisation de apiBaseUrl
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders() // Ajout des en-têtes d'authentification
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour du sprint');
      }

      const updatedSprint = await response.json();
      
      setIterations(prev => prev.map(iteration =>
        iteration.id === iterationId ? {
          ...iteration,
          sprints: iteration.sprints.map(s =>
            s.id === sprintId ? { ...updatedSprint, name: s.name } : s
          )
        } : iteration
      ));
      
      setSuccess('Sprint mis à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
      setEditingSprint(null);
    } catch (err) {
      console.error('Erreur de mise à jour du sprint:', err);
      setError(err.message || 'Échec de la mise à jour du sprint');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleIterationDateUpdate = async (iterationId, field, value) => {
    const iteration = iterations.find(it => it.id === iterationId);
    if (!iteration) return;

    const updatedDates = {
      ...iteration,
      [field]: value
    };

    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}/iterations/${iterationId}`, { // Utilisation de apiBaseUrl
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders() // Ajout des en-têtes d'authentification
        },
        body: JSON.stringify({
          start_date: updatedDates.start_date,
          end_date: updatedDates.end_date
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour des dates de l\'itération');
      }

      const updatedIteration = await response.json();
      
      setIterations(prev => prev.map(it =>
        it.id === iterationId
          ? { ...it, ...updatedIteration }
          : it
      ));
      setSuccess('Dates de l\'itération mises à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour des dates de l\'itération');
      setTimeout(() => setError(null), 3000);
    }
  };


  const handleIterationTimelineUpdate = async (iterationId, dates) => {
    try {
      const response = await fetch(`${apiBaseUrl}/projects/${projectId}/iterations/${iterationId}`, { // Utilisation de apiBaseUrl
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders() // Ajout des en-têtes d'authentification
        },
        body: JSON.stringify({
          start_date: dates.start_date,
          end_date: dates.end_date
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour des dates de l\'itération');
      }

      const updatedIteration = await response.json();
      
      setIterations(prev => prev.map(it =>
        it.id === iterationId
          ? { ...it, ...updatedIteration }
          : it
      ));
      
      setSuccess('Itération mise à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour de l\'itération');
      setTimeout(() => setError(null), 3000);
    }
  };

  const fetchHoursForSprint = (sprint) => {
    fetch(`${apiBaseUrl}/hours/project/${projectId}?start_date=${sprint.start_date}&end_date=${sprint.end_date}`, {
      headers: getAuthHeaders()
    })
      .then(res => res.ok ? res.json() : Promise.reject("Erreur de chargement des heures du sprint"))
      .then(data => {
        setSprintHours(prev => ({ ...prev, [sprint.id]: data.total_hours }));
      })
      .catch(err => console.error(err));
  };
  
  // La fonction fetchHoursForIteration a été supprimée car elle n'est plus utilisée
  
  const handleSlotUpdate = async (slotId, updatedSlot) => {
    try {
      const response = await fetch(`${apiBaseUrl}/slots/${slotId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders(),
        },
        body: JSON.stringify(updatedSlot),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour du slot');
      }

      const updatedSlotData = await response.json();
      setSlots((prev) =>
        prev.map((slot) =>
          slot.id === slotId ? { ...slot, ...updatedSlotData } : slot
        )
      );

      setSuccess('Slot mis à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour du slot');
      setTimeout(() => setError(null), 3000);
    }
  };
  
  const handleDeleteSlot = async (slotId) => {
    try {
      const response = await fetch(`${apiBaseUrl}/slots/${slotId}`, {
        method: 'DELETE',
        headers: getAuthHeaders(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression du slot');
      }

      setSlots((prev) => prev.filter((slot) => slot.id !== slotId));
      setSuccess('Slot supprimé avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression du slot');
      setTimeout(() => setError(null), 3000);
    }
  };

  if (!project) {
    return <div className="flex justify-center items-center h-screen">Chargement...</div>;
  }

  return (
    <div className="space-y-6">
      {/* Les messages d'erreur et de succès ont été supprimés */}
      
      {/* En-tête du projet */}
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex items-center justify-between mb-4">
          {/* Bloc d’en-tête */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => navigate("/projects")}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ArrowLeft className="h-6 w-6" />
            </button>
            <div>
              <h1 className="text-2xl font-bold">
                {project?.name} <span className="text-gray-500">#{project?.project_number}</span>
              </h1>
              <div className="mt-2">
              <h3 className="font-medium">Vision du projet :</h3>
              { editingVision ? (
                <div>
                  <textarea
                    className="border rounded p-2 w-[600px]"
                    value={tempVision}
                    onChange={(e) => setTempVision(e.target.value)}
                    rows={4}
                  />
                  <div className="mt-2">
                    <button
                      onClick={handleVisionUpdate}
                      className="bg-green-500 text-white px-3 py-1 rounded mr-2"
                    >
                      Sauvegarder
                    </button>
                    <button
                      onClick={() => { 
                          setEditingVision(false);
                          setTempVision(project.vision || "");
                      }}
                      className="bg-gray-500 text-white px-3 py-1 rounded"
                    >
                      Annuler
                    </button>
                  </div>
                </div>
              ) : (
                <p
                  className="cursor-pointer text-gray-700 max-w-[600px]"
                  onClick={() => setEditingVision(true)}
                >
                  {project.vision ? project.vision : "Cliquez pour ajouter la vision du projet"}
                </p>

              )}
            </div>

              {/* Statistiques supprimées */}
              {/* Section Client */}
              <div className="mt-2">
                <ClientSelector
                  projectId={project?.id}
                  currentClient={
                    project?.client_id
                      ? {
                          id: project.client_id,
                          name: project.client_name,
                          email: project.client_email,
                        }
                      : null
                  }
                  onClientChange={handleClientChange}
                  clients={clients}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-end">
            <span
              className={`px-3 py-1 rounded-full text-sm font-medium mb-2
                ${
                  project?.status === "proposition"
                    ? "bg-yellow-100 text-yellow-800"
                    : ""
                }
                ${
                  project?.status === "in_progress"
                    ? "bg-blue-100 text-blue-800"
                    : ""
                }
                ${
                  project?.status === "completed"
                    ? "bg-green-100 text-green-800"
                    : ""
                }
              `}
            >
              {statusTranslations[project?.status] || project?.status}
            </span>

            {/* Email client supprimé */}
            {/* Bouton pour accéder au backlog */}
            <button
              onClick={() => {
                if (project && project.id) {
                  localStorage.setItem("taskBoard_viewMode", "project");
                  localStorage.setItem("taskBoard_selectedProject", project.id);
                  navigate("/tasks");
                }
              }}
              className="mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center"
            >
              <FileText className="h-4 w-4 mr-1" />
              Backlog
            </button>
          </div>
        </div>
      </div>

      {/* Timeline Interactive unifiée */}
      <div className="bg-white shadow rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-4 flex items-center">
          <Clock className="h-5 w-5 mr-2" />
          Timeline du projet
        </h2>
        <InteractiveTimelineView
          iterations={iterations}
          slots={slots}
          onIterationUpdate={handleIterationTimelineUpdate}
          onSprintUpdate={handleSprintUpdate}
          setIterations={setIterations}
          onIterationAdd={handleQuickIterationAdd}
          onSprintAdd={handleQuickSprintAdd}
          onIterationDelete={handleDeleteIteration}
          onSprintDelete={handleDeleteSprint}
        />
      </div>

      {/* Section des Itérations */}
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Itérations</h2>
          <button
            onClick={handleQuickIterationAdd}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
          >
            <CalendarPlus className="h-4 w-4 mr-2" />
            Nouvelle Itération
          </button>
        </div>
        <div className="space-y-6">
          {iterations.map((iteration) => {
            const iterationDuration = calculateWeeksDuration(
              iteration.start_date,
              iteration.end_date
            );
            const iterationTokensCompleted = iteration.sprints
              ? iteration.sprints.reduce(
                  (acc, sprint) => acc + (sprint.tokens_completed || 0),
                  0
                )
              : 0;
            const iterationCAproduced = iteration.sprints
              ? iteration.sprints.reduce(
                  (acc, sprint) => acc + (sprint.ca_produit || 0),
                  0
                )
              : 0;
            // Variables liées aux heures supprimées
            const iterationToProduce =
              (iteration.valeur || 0) - (iteration.dont_achat || 0);
            const totalCAIteration = iterationToProduce + (iteration.dont_achat || 0);

            return (
              <div key={iteration.id} className="border rounded-lg p-4">
                <div className="space-y-4">
                  {/* En-tête de l'itération */}
                  <div className="flex justify-between items-start">
                    <div className="space-y-2">
                      <div className="flex items-center space-x-2">
                        <h3 className="text-lg font-medium">{iteration.name}</h3>
                        <div className="flex items-center text-sm text-blue-600">
                          <Clock className="h-4 w-4 mr-1" />
                          {iterationDuration} semaines
                        </div>
                        <span className="text-sm text-gray-600">
                          Heures
                        </span>
                        {/* Zone pour Valeur et Dont Achat */}
                        <div className="mt-1 text-sm text-gray-500">
                          Valeur:{" "}
                          {editingIteration.iterationId === iteration.id &&
                          editingIteration.field === "valeur" ? (
                            <input
                              type="number"
                              step="0.01"
                              value={editingIteration.value}
                              onChange={handleIterationEditingChange}
                              onBlur={handleIterationInlineUpdate}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  handleIterationInlineUpdate();
                                  setTimeout(() => {
                                    document.activeElement.blur();
                                  }, 0);
                                }
                              }}
                              autoFocus
                              className="border rounded p-1 w-24"
                            />
                          ) : (
                            <span
                              className="cursor-pointer bg-gray-100 hover:bg-yellow-200 px-1 rounded"
                              onClick={() =>
                                enableIterationEditing(iteration, "valeur")
                              }
                              title="Cliquez pour éditer"
                            >
                              {iteration.valeur} €
                            </span>
                          )}
                          | Dont Achat:{" "}
                          {editingIteration.iterationId === iteration.id &&
                          editingIteration.field === "dont_achat" ? (
                            <input
                              type="number"
                              step="0.01"
                              value={editingIteration.value}
                              onChange={handleIterationEditingChange}
                              onBlur={handleIterationInlineUpdate}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();
                                  handleIterationInlineUpdate();
                                  setTimeout(() => {
                                    document.activeElement.blur();
                                  }, 0);
                                }
                              }}
                              autoFocus
                              className="border rounded p-1 w-24"
                            />
                          ) : (
                            <span
                              className="cursor-pointer bg-gray-100 hover:bg-yellow-200 px-1 rounded"
                              onClick={() =>
                                enableIterationEditing(iteration, "dont_achat")
                              }
                              title="Cliquez pour éditer"
                            >
                              {iteration.dont_achat} €
                            </span>
                          )}
                        </div>
                        <div className="text-sm font-bold text-green-600">
                          Valeur à produire :{" "}
                          {(iteration.valeur - iteration.dont_achat).toFixed(2)} €
                        </div>
                        <button
                          onClick={() => handleDeleteIteration(iteration.id)}
                          className="text-red-500 hover:text-red-700"
                          title="Supprimer l'itération"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                      <div className="flex items-center space-x-4">
                        <div className="flex items-center space-x-2">
                          <Calendar className="h-4 w-4 text-gray-500" />
                          <input
                            type="date"
                            value={iteration.start_date}
                            onChange={(e) =>
                              handleIterationDateUpdate(
                                iteration.id,
                                "start_date",
                                e.target.value
                              )
                            }
                            className="border rounded p-1 text-sm"
                          />
                          <span>au</span>
                          <input
                            type="date"
                            value={iteration.end_date}
                            onChange={(e) =>
                              handleIterationDateUpdate(
                                iteration.id,
                                "end_date",
                                e.target.value
                              )
                            }
                            className="border rounded p-1 text-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Indicateurs de l'itération supprimés */}

                  {/* Section des Sprints */}
                  <div className="mt-4">
                    <div className="flex items-center justify-between mb-4">
                      <h4 className="text-md font-medium">Sprints</h4>
                      <button
                        onClick={() => handleQuickSprintAdd(iteration.id)}
                        className="text-blue-500 hover:text-blue-700 flex items-center text-sm"
                      >
                        <Plus className="h-4 w-4 mr-1" />
                        Ajouter un Sprint
                      </button>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {iteration.sprints.map((sprint) => (
                        <SprintCard
                          key={sprint.id}
                          sprint={sprint}
                          iterationId={iteration.id}
                          iteration={iteration} // passer l'objet iteration
                          handleDelete={handleDeleteSprint}
                          setEditingSprint={setEditingSprint}
                          editingSprint={editingSprint}
                          handleSprintUpdate={handleSprintUpdate}
                          handleTokensUpdate={handleTokensUpdate}
                          calculateWeeksDuration={calculateWeeksDuration}
                          sprintHours={sprintHours[sprint.id] || 0}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;