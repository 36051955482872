import React, { useState, useEffect } from 'react';
import { MapPin, Plus, Edit2, Trash2, Check, X } from 'lucide-react';
import { useAuth } from './Auth';

const apiBaseUrl = process.env.NODE_ENV === 'development' 
  ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
  : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

const AddressManagement = () => {
  const [addresses, setAddresses] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);
  const [formData, setFormData] = useState({
    recipient: '',
    address: '',
    ZIP: '',
    city: '',
    country: ''
  });
  
  const { getAuthHeaders } = useAuth();

  // Charger les adresses
  const fetchAddresses = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/addresses`, { // Utilisation de apiBaseUrl
        headers: getAuthHeaders()
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec du chargement des adresses');
      }
      
      const data = await response.json();
      setAddresses(Array.isArray(data) ? data : []);
    } catch (err) {
      setError(err.message || 'Échec du chargement des adresses');
      setAddresses([]);
      setTimeout(() => setError(null), 3000);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingAddress(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/addresses`, { // Utilisation de apiBaseUrl
        method: 'POST',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de l\'ajout de l\'adresse');
      }
      
      await fetchAddresses();
      setFormData({ recipient: '', address: '', ZIP: '', city: '', country: '' });
      setSuccess('Adresse ajoutée avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de l\'ajout de l\'adresse');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiBaseUrl}/addresses/${id}`, { // Utilisation de apiBaseUrl
        method: 'DELETE',
        headers: getAuthHeaders()
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression de l\'adresse');
      }
      
      await fetchAddresses();
      setSuccess('Adresse supprimée avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression de l\'adresse');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/addresses/${editingAddress.id}`, { // Utilisation de apiBaseUrl
        method: 'PATCH',
        headers: {
          ...getAuthHeaders(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipient: editingAddress.recipient,
          address: editingAddress.address,
          ZIP: editingAddress.ZIP,
          city: editingAddress.city,
          country: editingAddress.country
        }),
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour de l\'adresse');
      }
      
      await fetchAddresses();
      setEditingAddress(null);
      setSuccess('Adresse mise à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour de l\'adresse');
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <div className="p-6 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-6 flex items-center">
        <MapPin className="h-6 w-6 mr-2" />
        Gestion des Adresses
      </h1>

      {/* Formulaire d'ajout d'adresse */}
      <form onSubmit={handleSubmit} className="mb-8 p-4 bg-gray-50 rounded-lg shadow">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Destinataire
            </label>
            <input
              type="text"
              name="recipient"
              value={formData.recipient}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Adresse
            </label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Code Postal
            </label>
            <input
              type="text"
              name="ZIP"
              value={formData.ZIP}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Ville
            </label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Pays
            </label>
            <input
              type="text"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className="border rounded p-2 w-full"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
        >
          <Plus className="h-4 w-4 mr-2" />
          Ajouter une Adresse
        </button>
      </form>

      {/* Liste des adresses */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="min-w-full">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
            {addresses.map((address) => (
              <div 
                key={address.id} 
                className="bg-white rounded-lg border p-4 hover:shadow-md transition-shadow"
              >
                {editingAddress?.id === address.id ? (
                  // Mode édition
                  <div className="space-y-3">
                    <input
                      type="text"
                      name="recipient"
                      value={editingAddress.recipient}
                      onChange={handleEditInputChange}
                      className="border rounded p-2 w-full"
                      placeholder="Destinataire"
                      required
                    />
                    <input
                      type="text"
                      name="address"
                      value={editingAddress.address}
                      onChange={handleEditInputChange}
                      className="border rounded p-2 w-full"
                      placeholder="Adresse"
                      required
                    />
                    <div className="grid grid-cols-3 gap-2">
                      <input
                        type="text"
                        name="ZIP"
                        value={editingAddress.ZIP}
                        onChange={handleEditInputChange}
                        className="border rounded p-2 w-full"
                        placeholder="Code postal"
                        required
                      />
                      <input
                        type="text"
                        name="city"
                        value={editingAddress.city}
                        onChange={handleEditInputChange}
                        className="border rounded p-2 w-full"
                        placeholder="Ville"
                        required
                      />
                      <input
                        type="text"
                        name="country"
                        value={editingAddress.country}
                        onChange={handleEditInputChange}
                        className="border rounded p-2 w-full"
                        placeholder="Pays"
                        required
                      />
                    </div>
                    <div className="flex justify-end space-x-2 mt-2">
                      <button
                        type="button"
                        onClick={handleUpdate}
                        className="text-green-500 hover:text-green-700"
                        title="Enregistrer les modifications"
                      >
                        <Check className="h-5 w-5" />
                      </button>
                      <button
                        type="button"
                        onClick={() => setEditingAddress(null)}
                        className="text-gray-500 hover:text-gray-700"
                        title="Annuler"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                ) : (
                  // Mode affichage
                  <div>
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="font-medium">{address.recipient}</h3>
                        <p className="text-gray-600">{address.address}</p>
                        <p className="text-gray-600">
                          {address.ZIP} {address.city}
                        </p>
                        <p className="text-gray-600">{address.country}</p>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          onClick={() => setEditingAddress(address)}
                          className="text-blue-500 hover:text-blue-700"
                          title="Modifier l'adresse"
                        >
                          <Edit2 className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDelete(address.id)}
                          className="text-red-500 hover:text-red-700"
                          title="Supprimer l'adresse"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressManagement;
