import React from 'react';
import { isWeekend, isFrenchHoliday, isFutureDate } from '../../utils/dateUtils';

const HourInput = ({ projectId, date, value, onChange, onBlur, disabled }) => {
  const isWeekendDay = isWeekend(date);
  const isHoliday = isFrenchHoliday(date);
  const isFuture = isFutureDate(date);
  
  let inputClasses = "w-full text-center border rounded p-1";
  let cellClasses = "px-4 py-2";
  
  // Appliquer le fond grisé mais garder l'input actif
  if (isWeekendDay) {
    cellClasses += " bg-gray-100";
    inputClasses += " bg-gray-100";
  } else if (isHoliday) {
    cellClasses += " bg-gray-50";
    inputClasses += " bg-gray-50";
  }
  
  // Désactiver uniquement pour les dates futures ou si explicitement désactivé
  if (disabled || isFuture) {
    inputClasses += " cursor-not-allowed opacity-50";
  }
  
  return (
    <td className={cellClasses}>
      <input
        type="number"
        className={inputClasses}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled || isFuture}
        min="0"
        step="0.5"
        title={isHoliday ? "Jour férié" : isWeekendDay ? "Weekend" : ""}
      />
    </td>
  );
};

export default HourInput;