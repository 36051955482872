import React, { useState, useEffect } from 'react';
import { Trash2, UserPlus, Edit2, Check, X, Power, CalendarDays, MapPin } from 'lucide-react';
import { useAuth } from './Auth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    production_rate: 85,
    is_active: true,
    arrival_date: new Date(),
    departure_date: null
  });
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { getAuthHeaders } = useAuth();
  const apiBaseUrl = process.env.NODE_ENV === 'development' 
    ? `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`
    : `${process.env.REACT_APP_API_URL}/api` || 'http://localhost:8000';

  const fetchEmployees = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}/employees`, {
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec du chargement des employés');
      }

      const data = await response.json();
      setEmployees(Array.isArray(data) ? data : []);
    } catch (err) {
      setError(err.message || 'Échec du chargement des employés');
      setEmployees([]);
      setTimeout(() => setError(null), 3000);
    }
  };

  useEffect(() => {
    fetchEmployees();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'production_rate') {
      const numValue = Math.min(100, Math.max(0, parseInt(value) || 0));
      setFormData(prev => ({
        ...prev,
        [name]: numValue
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleDateChange = (date, fieldName = 'arrival_date') => {
    setFormData(prev => ({
      ...prev,
      [fieldName]: date
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'production_rate') {
      const numValue = Math.min(100, Math.max(0, parseInt(value) || 0));
      setEditingEmployee(prev => ({
        ...prev,
        [name]: numValue
      }));
    } else {
      setEditingEmployee(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleEditDateChange = (date, fieldName = 'arrival_date') => {
    setEditingEmployee(prev => ({
      ...prev,
      [fieldName]: date
    }));
  };

  const handleToggleActive = async (employeeId, currentStatus, employee) => {
    try {
      // Si on désactive l'employé, demander la date de départ
      if (currentStatus) {
        // On modifie l'employé pour édition
        setEditingEmployee({
          ...employee,
          is_active: false,
          departure_date: new Date()
        });
        return; // On ne fait pas la mise à jour tout de suite
      }
      
      // Si on active l'employé, enlever la date de départ
      const response = await fetch(`${apiBaseUrl}/employees/${employeeId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify({
          is_active: true,
          departure_date: null
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Échec de la mise à jour du statut de l'employé");
      }

      await fetchEmployees();
      setSuccess(`Employé activé avec succès`);
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || "Échec de la mise à jour du statut de l'employé");
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Formater la date en ISO 8601 avant l'envoi
    const formattedFormData = {
      ...formData,
      arrival_date: formData.arrival_date.toISOString().split('T')[0],
    };
  
    try {
      const response = await fetch(`${apiBaseUrl}/employees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify(formattedFormData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de l\'ajout de l\'employé');
      }

      await fetchEmployees();
      setFormData({ name: '', email: '', production_rate: 85, is_active: true, arrival_date: new Date() });
      setSuccess('Employé ajouté avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de l\'ajout de l\'employé');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${apiBaseUrl}/employees/${id}`, {
        method: 'DELETE',
        headers: getAuthHeaders()
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la suppression de l\'employé');
      }

      await fetchEmployees();
      setSuccess('Employé supprimé avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la suppression de l\'employé');
      setTimeout(() => setError(null), 3000);
    }
  };

  const handleUpdate = async () => {
    try {
      // Prépare les données avec les dates formatées
      const updateData = {
        name: editingEmployee.name,
        email: editingEmployee.email,
        production_rate: editingEmployee.production_rate,
        is_active: editingEmployee.is_active,
        arrival_date: editingEmployee.arrival_date 
          ? new Date(editingEmployee.arrival_date).toISOString().split('T')[0]
          : new Date().toISOString().split('T')[0] // Convertir la date en format ISO 8601
      };
      
      // Ajouter la date de départ seulement si elle existe
      if (editingEmployee.departure_date) {
        updateData.departure_date = new Date(editingEmployee.departure_date).toISOString().split('T')[0];
      }
      
      const response = await fetch(`${apiBaseUrl}/employees/${editingEmployee.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          ...getAuthHeaders()
        },
        body: JSON.stringify(updateData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Échec de la mise à jour de l\'employé');
      }

      await fetchEmployees();
      setEditingEmployee(null);
      setSuccess('Employé mis à jour avec succès');
      setTimeout(() => setSuccess(null), 3000);
    } catch (err) {
      setError(err.message || 'Échec de la mise à jour de l\'employé');
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <div className="p-2 max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-2 flex items-center">
        <MapPin className="h-6 w-6 mr-2" />
        Gestion des Employés
      </h1>
      {/* Formulaire d'ajout d'employé avec layout amélioré */}
      <form onSubmit={handleSubmit} className="mb-8 p-4 bg-gray-50 rounded-lg shadow">
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex-1 min-w-[200px]">
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Nom"
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div className="flex-1 min-w-[200px]">
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email"
              className="border rounded p-2 w-full"
              required
            />
          </div>
          <div className="w-32">
            <div className="relative">
              <input
                type="number"
                name="production_rate"
                value={formData.production_rate}
                onChange={handleInputChange}
                min="0"
                max="100"
                className="border rounded p-2 w-full pr-8"
                required
              />
              <span className="absolute right-3 top-2 text-gray-500">%</span>
            </div>
          </div>
          <div className="w-40">
            <div className="relative">
              <DatePicker
                selected={formData.arrival_date}
                onChange={handleDateChange}
                dateFormat="dd/MM/yyyy"
                className="border rounded p-2 w-full"
                placeholderText="Date d'arrivée"
              />
              <CalendarDays className="absolute right-3 top-3 text-gray-400 h-4 w-4" />
            </div>
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center whitespace-nowrap"
          >
            <UserPlus className="h-4 w-4 mr-2" />
            Ajouter un Employé
          </button>
        </div>
      </form>

      {/* Tableau des employés avec largeur augmentée */}
      <div className="bg-white rounded-lg shadow">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Nom
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Taux de Production
              </th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Dates
              </th>
              <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Statut
              </th>
              <th className="px-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {employees.map((employee) => (
              <tr key={employee.id} className="hover:bg-gray-50">
                {editingEmployee?.id === employee.id ? (
                  // Mode édition
                  <>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="text"
                        name="name"
                        value={editingEmployee.name}
                        onChange={handleEditInputChange}
                        className="border rounded p-1 w-full"
                        required
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="email"
                        name="email"
                        value={editingEmployee.email}
                        onChange={handleEditInputChange}
                        className="border rounded p-1 w-full"
                        required
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="relative">
                        <input
                          type="number"
                          name="production_rate"
                          value={editingEmployee.production_rate}
                          onChange={handleEditInputChange}
                          min="0"
                          max="100"
                          className="border rounded p-1 w-24 pr-6"
                          required
                        />
                        <span className="absolute right-2 top-1.5 text-gray-500">%</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="relative">
                        <DatePicker
                          selected={editingEmployee.arrival_date ? new Date(editingEmployee.arrival_date) : null}
                          onChange={(date) => handleEditDateChange(date, 'arrival_date')}
                          dateFormat="dd/MM/yyyy"
                          className="border rounded p-2 w-full"
                          placeholderText="Date d'arrivée"
                        />
                        <CalendarDays className="absolute right-3 top-3 text-gray-400 h-4 w-4" />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex flex-col items-center">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          editingEmployee.is_active 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {editingEmployee.is_active ? 'Actif' : 'Inactif'}
                        </span>
                        {!editingEmployee.is_active && (
                          <div className="mt-2 relative">
                            <DatePicker
                              selected={editingEmployee.departure_date ? new Date(editingEmployee.departure_date) : new Date()}
                              onChange={(date) => handleEditDateChange(date, 'departure_date')}
                              dateFormat="dd/MM/yyyy"
                              className="border rounded p-1 w-full text-xs"
                              placeholderText="Date de départ"
                            />
                            <CalendarDays className="absolute right-1 top-1.5 text-gray-400 h-3 w-3" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={handleUpdate}
                          className="text-green-500 hover:text-green-700"
                          title="Enregistrer les modifications"
                        >
                          <Check className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => setEditingEmployee(null)}
                          className="text-gray-500 hover:text-gray-700"
                          title="Annuler"
                        >
                          <X className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </>
                ) : (
                  // Mode affichage
                  <>
                    <td className={`px-6 py-4 whitespace-nowrap ${!employee.is_active ? 'text-gray-400' : ''}`}>
                      {employee.name}
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap ${!employee.is_active ? 'text-gray-400' : ''}`}>
                      {employee.email}
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap ${!employee.is_active ? 'text-gray-400' : ''}`}>
                      {employee.production_rate}%
                    </td>
                    <td className={`px-6 py-4 whitespace-nowrap ${!employee.is_active ? 'text-gray-400' : ''}`}>
                      {employee.arrival_date ? new Date(employee.arrival_date).toLocaleDateString('fr-FR') : ''}
                      {!employee.is_active && employee.departure_date && (
                        <div className="text-xs text-gray-500 mt-1">
                          Départ: {new Date(employee.departure_date).toLocaleDateString('fr-FR')}
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        employee.is_active 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {employee.is_active ? 'Actif' : 'Inactif'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <div className="flex justify-center space-x-2">
                        <button
                          onClick={() => handleToggleActive(employee.id, employee.is_active, employee)}
                          className={`p-1 rounded ${
                            employee.is_active ? 'text-green-500 hover:text-green-700' : 'text-red-500 hover:text-red-700'
                          }`}
                          title={employee.is_active ? "Désactiver l'employé" : "Activer l'employé"}
                        >
                          <Power className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => setEditingEmployee(employee)}
                          className="text-blue-500 hover:text-blue-700"
                          title="Modifier l'employé"
                        >
                          <Edit2 className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDelete(employee.id)}
                          className="text-red-500 hover:text-red-700"
                          title="Supprimer l'employé"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </>
                )}
              </tr>
            ))}
            {employees.length === 0 && (
              <tr>
                <td colSpan="4" className="px-6 py-4 text-center text-gray-500">
                  Aucun employé trouvé
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmployeeManagement;